import { api } from './api'
import { IMarketplaceMerchant } from './marketplace'

export interface ITokenMultiplier {
  id: string
  marketplace_merchant_id: string
  num_tokens: number
  multiplier: number
}

export interface IMultiplierData {
  merchant: IMarketplaceMerchant
  tokenMultiplier: ITokenMultiplier
}

export interface IGetTokenMultipliersResponse {
  multiplier_data: IMultiplierData[]
  records_count: number
  next_page: number
  previous_page: number
}

export interface IGetMultipliersByBannerResponse {
  items: {
    merchant: IMarketplaceMerchant;
    tokenMultiplier: ITokenMultiplier | null;
  }[];
  limit: number;
  page: number;
  totalCount: number;
}

export const multipliersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTokenMultipliers: build.query<
      IGetTokenMultipliersResponse,
      { limit: number; page: number }
    >({
      query: ({ limit, page }) => {
        return {
          url: `/multipliers?limit=${limit}&page=${page}`,
          method: 'GET'
        }
      },
    //   providesTags: ['TokenMultipliers']
    }),
    getTokenMultiplier: build.query<{ token_multiplier: ITokenMultiplier }, string>({
      query: (tokenMultiplierId) => {
        return {
          url: `/multipliers/${tokenMultiplierId}`,
          method: 'GET'
        }
      }
    }),
    addTokenMultiplier: build.mutation<
      any,
      { marketplaceMerchantId: string; numTokens: number; multiplier: number }
    >({
      query: ({ marketplaceMerchantId, numTokens, multiplier }) => ({
        url: `/multipliers`,
        method: 'POST',
        body: {
          marketplace_merchant_id: marketplaceMerchantId,
          num_tokens: numTokens,
          multiplier: multiplier,
        }
      }),
    //   invalidatesTags: ['TokenMultipliers'],
    }),
    updateTokenMultiplier: build.mutation<
      any,
      { id: string; marketplaceMerchantId?: string; numTokens?: number; multiplier?: number }
    >({
      query: ({ id, marketplaceMerchantId, numTokens, multiplier }) => ({
        url: `/multipliers/${id}`,
        method: 'PUT',
        body: {
          marketplace_merchant_id: marketplaceMerchantId,
          num_tokens: numTokens,
          multiplier: multiplier,
        }
      }),
    //   invalidatesTags: ['TokenMultipliers'],
    }),
    getMultipliersByBanner: build.query<
        IGetMultipliersByBannerResponse,
        { bannerId: string; }
    >({
        query: ({ bannerId }) => {
        return {
            url: `/multipliers/banner/${bannerId}`,
            method: 'GET'
        }
        },
        // providesTags: ['TokenMultipliers']
    }),
    deleteTokenMultiplier: build.mutation<any, string>({
      query: (id) => ({
        url: `/multipliers/${id}`,
        method: 'DELETE'
      })
    })
  })
})

export const {
  useGetTokenMultipliersQuery,
  useGetTokenMultiplierQuery,
  useAddTokenMultiplierMutation,
  useUpdateTokenMultiplierMutation,
  useGetMultipliersByBannerQuery,
  useDeleteTokenMultiplierMutation
} = multipliersApi
