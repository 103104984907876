import { FC, useState } from 'react'
import Spinner from 'components/ui/Spinner'
import Table from 'components/ui/Table'
import Button from 'components/ui/Button'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import CustomerAddEntriesModal from './CustomerAddEntriesModal'
import { useGetCustomerEntriesQuery, IEntry } from 'services/customers'
import { useParams } from 'react-router-dom'
import { formatDateTime } from 'utils/intlDate'
import { getTablePageLimit } from 'utils/getTablePageLimit';

const LIMIT = getTablePageLimit()

const CustomerDetailsEntriesTable: FC = () => {
  const { customerId } = useParams()
  const [page, setPage] = useState(1)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { data, isLoading, isFetching } = useGetCustomerEntriesQuery({
    customerId: customerId || '',
    page,
    limit: LIMIT
  })

  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      render: (rowData: IEntry) =>
        rowData.created_at ? formatDateTime(new Date(rowData.created_at)) : null
    },
    {
      field: 'store_name',
      headerName: 'Store Name',
      render: (rowData: IEntry) => rowData?.merchant?.name || 'Manual Entry'
    },
    {
      field: 'order_number',
      headerName: 'Order Number',
      render: (rowData: IEntry) => rowData?.order?.order_number
    },
    {
      field: 'total_price',
      headerName: 'Total',
      render: (rowData: IEntry) => rowData?.order?.total_price
    },
    {
      field: 'entries',
      headerName: 'Entries',
      render: (rowData: IEntry) => rowData?.number_of_entries
    }
  ]

  return (
    <div>
      <CustomerAddEntriesModal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
      <div className="flex justify-between">
        <div className="text-lg font-semibold">Entries</div>
        <Button
          startIcon={<AddOutlinedIcon />}
          onClick={() => setIsOpenModal(true)}
        >
          Add entries
        </Button>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="mt-4">
          <Table
            columns={columns}
            rows={data?.entries || []}
            emptyTitleText="No entries yet"
            onPrevPage={() => setPage(page - 1)}
            onNextPage={() => setPage(page + 1)}
            isFetching={isFetching}
            page={page}
            setPage={setPage}
            totalPages={Math.ceil(Number(data?.records_count) / LIMIT)}
          />
        </div>
      )}
    </div>
  )
}

export default CustomerDetailsEntriesTable
