const GiftIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.2727 12V18C20.2727 20.21 18.4827 22 16.2727 22H8.27274C6.06274 22 4.27274 20.21 4.27274 18V12C4.27274 11.45 4.72274 11 5.27274 11H7.24274C7.79274 11 8.24274 11.45 8.24274 12V15.14C8.24274 15.88 8.65274 16.56 9.30274 16.91C9.59274 17.07 9.91274 17.15 10.2427 17.15C10.6227 17.15 11.0027 17.04 11.3327 16.82L12.2827 16.2L13.1627 16.79C13.7727 17.2 14.5527 17.25 15.2027 16.9C15.8627 16.55 16.2727 15.88 16.2727 15.13V12C16.2727 11.45 16.7227 11 17.2727 11H19.2727C19.8227 11 20.2727 11.45 20.2727 12Z" fill="#38B6FF"/>
      <path d="M21.7727 7V8C21.7727 9.1 21.2427 10 19.7727 10H4.77274C3.24274 10 2.77274 9.1 2.77274 8V7C2.77274 5.9 3.24274 5 4.77274 5H19.7727C21.2427 5 21.7727 5.9 21.7727 7Z" fill="#38B6FF"/>
      <path d="M11.9127 4.99994H6.39273C6.05273 4.62994 6.06273 4.05994 6.42273 3.69994L7.84273 2.27994C8.21273 1.90994 8.82273 1.90994 9.19273 2.27994L11.9127 4.99994Z" fill="#38B6FF"/>
      <path d="M18.1423 4.99994H12.6223L15.3423 2.27994C15.7123 1.90994 16.3223 1.90994 16.6923 2.27994L18.1123 3.69994C18.4723 4.05994 18.4823 4.62994 18.1423 4.99994Z" fill="#38B6FF"/>
      <path d="M14.2432 11C14.7932 11 15.2432 11.45 15.2432 12V15.13C15.2432 15.93 14.3532 16.41 13.6932 15.96L12.7932 15.36C12.4632 15.14 12.0332 15.14 11.6932 15.36L10.7532 15.98C10.0932 16.42 9.21317 15.94 9.21317 15.15V12C9.21317 11.45 9.66317 11 10.2132 11H14.2432Z" fill="#38B6FF"/>
      </svg>
  )
}

export default GiftIcon;