import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import * as Styles from './styles'

interface IEmpty {
  emptyTitleText?: string
  emptyText?: string
}

const Empty: React.FC<IEmpty> = ({ emptyTitleText, emptyText }) => {
  return (
    <Styles.EmptyContainer>
      <Styles.EmptyIcon>
        <SearchOutlinedIcon fontSize="inherit" />
      </Styles.EmptyIcon>
      <Styles.EmptyTitle>{emptyTitleText}</Styles.EmptyTitle>
      <div>{emptyText}</div>
    </Styles.EmptyContainer>
  )
}

export default Empty
