import { useAppSelector } from 'store/hooks'
import { Image, Divider, Skeleton } from '@nextui-org/react';
import { useGetPrizeEntriesQuery } from 'services/prizes';

const PrizeEntriesPage = () => {
  const customerId = useAppSelector((state) => state.auth.customerId)

  const { data: prizeEntries, error, isLoading } = useGetPrizeEntriesQuery({ customerId: customerId || '' });
  
  if (error) return <p>Error loading entries.</p>;

  if (!prizeEntries || prizeEntries.length === 0) return <p className="min-h-screen p-12">No Entries Yet.</p>;

  return (
    <div className="flex flex-col items-center w-full min-h-screen">
      <div className="w-10/12 mt-10">
        <div className="text-lg-semibold text-gray-800 mb-6 mt-3 flex flex-col gap-3 sm:flex-row items-start sm:items-center justify-between">
          <p>Your Entries</p>
        </div>
        <Divider />
        <div className="flex flex-col gap-4">
          {isLoading && 
            <div className="min-h-screen">
              <Skeleton className="mx-10 my-5 rounded-lg">
                <div className="h-96">
                  Loading banners
                </div>
              </Skeleton>

              <Skeleton className="mx-10 my-5 rounded-lg">
                <div className="h-96">
                  Loading banners
                </div>
              </Skeleton>
            </div>
          }

          {prizeEntries && prizeEntries.map((entry: any) => (
            <div key={entry.prize.name}>
              <div className="flex flex-col items-center md:flex-row gap-4 md:gap-2 w-full rounded-md p-4">
                <div className="w-full md:w-1/4">
                  <Image
                    removeWrapper
                    alt={entry.prize.name}
                    className="z-0 w-full h-full object-cover rounded-none rounded-md"
                    src={entry.prize.image_url}
                  />
                </div>
                <div className="w-full md:w-3/4 px-4">
                  <div className="flex flex-col gap-2 h-full justify-around">
                    <p className="text-4xl">{entry.prize.name}</p>
                    <div>
                      <p className="text-xl">Close Date: {new Date(entry.prize.end_date).toLocaleDateString()}</p>
                      <p className="text-xl">Entries: {entry.entries_count}</p>
                    </div>
                  </div>
                </div>
              </div>
              <Divider />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PrizeEntriesPage;
