import { FC } from 'react'
import { twMerge } from 'tailwind-merge'

interface IHorizontalFieldRow {
  children: React.ReactNode
  label: string
  labelClassname?: string
  showBorderBottom?: boolean
}

const HorizontalFieldRow: FC<IHorizontalFieldRow> = ({
  children,
  label,
  labelClassname,
  showBorderBottom
}) => {
  const borderButtonClasses = showBorderBottom
    ? 'border-b border-b-gray-200'
    : ''

  return (
    <div
      className={`border-t border-t-gray-200 ${borderButtonClasses} py-5 flex flex-col lg:flex-row gap-8`}
    >
      <label className={twMerge("text-sm w-52", labelClassname)}>{label}</label>
      {children}
    </div>
  )
}

export default HorizontalFieldRow
