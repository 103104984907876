import { useState } from 'react'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { ISidebarItem } from '.'
import * as Styles from './index.styles'

interface ISidebarItemProps extends ISidebarItem {
  closeMobileModal: () => void
}

const SidebarItem: React.FC<ISidebarItemProps> = ({
  url,
  icon,
  label,
  children,
  closeMobileModal
}) => {
  const navigate = useNavigate()
  const pathname = useLocation().pathname
  const isActivePage = pathname.includes(url)

  const [isOpenItem, setIsOpenItem] = useState(isActivePage)
  
  const handleClick = (urlItem: string) => {
    closeMobileModal()
    navigate(urlItem)
    const isParent = children?.length
    isParent && setIsOpenItem(!isOpenItem)
  }

  const renderIconArrow = () => {
    const arrowSpace = 'ml-auto z-20'
    if (isOpenItem) {
      return (
        <ArrowDropUpOutlinedIcon
          onClick={() => setIsOpenItem(false)}
          className={arrowSpace}
        />
      )
    } else {
      return (
        <ArrowDropDownOutlinedIcon
          onClick={() => setIsOpenItem(true)}
          className={arrowSpace}
        />
      )
    }
  }

  return (
    <li>
      <div className="relative">
        <NavLink
          to={url}
          onClick={() => handleClick(url)}
        >
          {({ isActive }) => (
            <Styles.NavItem className={` ${isActive ? 'bg-gray-100' : ''}`}>
              {icon && <Styles.NavItemIcon>{icon}</Styles.NavItemIcon>} {label}
            </Styles.NavItem>
          )}
        </NavLink>
        {children?.length ? (
          <Styles.NavItemArrow>
            {renderIconArrow()}
          </Styles.NavItemArrow>
        ) : null}
      </div>

      {children?.length && isOpenItem ? (
        <ul className="my-2">
          {children.map((itemChild, indexChild) => (
            <li key={`main-menu-children-${indexChild}`}>
              <NavLink onClick={closeMobileModal} to={itemChild.url}>
                {({ isActive }) => (
                  <Styles.NavItemChild
                    className={` ${isActive ? 'bg-gray-100' : ''}`}
                  >
                    <Styles.NavItemChildIcon /> {itemChild.label}
                  </Styles.NavItemChild>
                )}
              </NavLink>
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  )
}

export default SidebarItem
