import tw from 'twin.macro'
import { styled } from '@mui/material/styles'

export const PageContainer = styled('div')`
  ${tw`flex flex-col h-full`}
`
export const Main = styled('div')`
  ${tw` w-auto flex-1`}
`
export const SearchContainer = styled('div')`
  ${tw`border-b border-gray-200 flex flex-col sm:flex-row gap-5 justify-between pb-2`}
`
export const SearchContainerButtons = styled('div')`
  ${tw``}
`
export const PageTitleContainer = styled('div')`
  ${tw`text-lg-semibold text-gray-800 mb-6 mt-3 flex flex-col gap-3 sm:flex-row items-start sm:items-center justify-between`}
`
export const PageTitleContainerImage = styled('div')`
  ${tw`flex gap-4 items-center`}

  & img {
    ${tw`w-[60px] h-[60px] border-[3px] border-white rounded-lg shadow-md`}
  }
`
export const PageSubtitle = styled('div')`
  ${tw`text-sm-regular text-gray-600`}
`
export const PageTitleContainerButtons = styled('div')`
  ${tw`flex flex-wrap gap-3 items-center`}
`
export const PageSectionColumns = styled('div')`
  ${tw`flex flex-col md:flex-row gap-6 xl:gap-12`}
`
export const ColumnLeft = styled('div')`
  ${tw`w-full md:w-2/3`}
`
export const ColumnRight = styled('div')`
  ${tw`w-full md:w-1/3`}
`
export const SectionTitleContainer = styled('div')`
  ${tw`text-lg-semibold text-gray-900 flex flex-col gap-3 sm:flex-row justify-between items-start sm:items-center mb-3`}
`
export const SectionBoxSpace = styled('div')`
  ${tw`mb-6`}
`
export const SectionTwoColumns = styled('div')`
  ${tw`grid grid-cols-1 md:grid-cols-2 gap-6`}
`
export const SectionThreeColumns = styled('div')`
  ${tw`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6`}
`
export const SectionViewAll = styled('div')`
  ${tw`my-4 text-sm-semibold text-primary-700 flex justify-end`}
`
export const FormColumns = styled('div')`
  ${tw`flex flex-col md:flex-row gap-4 md:gap-8 border-b border-gray-200 py-5`}
`
export const FormColumnsFooter = styled('div')`
  ${tw`flex gap-8 py-5`}
`
export const FormColumnsFooterButtons = styled('div')`
  ${tw`flex justify-end gap-3`}
`
export const FormColumnLeft = styled('div')`
  ${tw`w-full md:w-1/3 xl:w-72 text-sm-medium text-gray-700`}
`
export const FormColumnLeftDescription = styled('div')`
  ${tw`text-sm-regular text-gray-600`}
`
export const FormColumnRight = styled('div')`
  ${tw`w-full md:w-2/3 xl:w-[512px] flex flex-col gap-4`}
`
export const ProfilePic = styled('img')`
  ${tw`w-40 h-40 lg:mr-auto rounded-full border-[4px] border-white shadow-lg object-cover`}
`
