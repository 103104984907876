import { WildlinkClient } from 'wildlink-js-client'

export const WILDFIRE_APP_ID = process.env.REACT_APP_WILDFIRE_APP_ID
export const WILDFIRE_APP_KEY = process.env.REACT_APP_WILDFIRE_APP_SECRET

let instance: WildlinkClient | null = null

export const getWildlinkClient = () => {
  const APP_ID = WILDFIRE_APP_ID
  const APP_KEY = WILDFIRE_APP_KEY || ''

  if (!instance) {
    instance = new WildlinkClient(APP_KEY, Number(APP_ID))
  }

  return instance
}
