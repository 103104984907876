import React from "react";

import {Card, CardBody, Image} from "@nextui-org/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ITokenMultiplier } from "services/multipliers";

export function AltHorizontalScrollCard(
  { itemId, imageUrl, generateLink, tokenMultiplier, aestheticBackgroundImage, aestheticCircleImage, description, name, tag, textColor }: 
  { itemId: string; 
    imageUrl: string; 
    generateLink: () => void; 
    tokenMultiplier: ITokenMultiplier | undefined; 
    aestheticBackgroundImage: string; 
    aestheticCircleImage: string; 
    name: string;
    description: string; 
    tag: string; 
    textColor: string;
  }
) {

  return (
    <div
      role="button"
      style={{
        userSelect: "none",
        color: textColor
      }}
      className="mx-2"
    >
      <div className="flex flex-col gap-2 p-4">
        <Card 
          isPressable 
          className="text-left p-0 shadow-none w-full overflow-visible" 
          onPress={generateLink}
        >
          <CardBody style={{padding: '0px'}} className="overflow-visible w-full flex flex-row items-center gap-2">
            <LazyLoadImage
              className="object-contain rounded-xl h-12 h-12 md:h-20 md:w-20 border border-gray-200 px-2"
              src={imageUrl || '/images/placeholder/product.jpg'}
              alt="Card background"
            />

            <div className="flex flex-col gap-1 mx-2" style={{color: textColor}}>
              <div>
                <p className="text-[10px] lg:text-lg font-semibold">{name}</p>
              </div>
              <div className="flex flex-row md:gap-2 gap-1 text-left">
                <Image
                alt="Card background"
                className="object-contain rounded-xl w-full h-4 w-4"
                  src={"/images/waveemblem.svg"}
              />
                <p className="text-sm">{tokenMultiplier?.num_tokens ?? 10} Tokens per $1</p>
              </div>  
              <div>
                 <p className="text-[10px] lg:text-lg font-semibold">{description}</p>
              </div>
            </div>  
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
