import { Remove, Timer } from '@mui/icons-material'
import Button from 'components/ui/Button'
import { useUnlinkCategoryFromBannerMutation } from 'services/marketplace'

interface RemoveBannerProps {
  bannerId: string
  categoryId: string
}
const RemoveBanner = ({ bannerId, categoryId }: RemoveBannerProps) => {
  const [unlinkBannerFromCategory, { isLoading: isUnlinkingBanner }] =
    useUnlinkCategoryFromBannerMutation()

  const handleRemoveBanner = async (bannerId: string) => {
    await unlinkBannerFromCategory({
      category_id: categoryId || '',
      banner_id: bannerId
    })
  }

  return (
    <Button
      disabled={isUnlinkingBanner}
      onClick={() => handleRemoveBanner(bannerId)}
      cc-color="red"
    >
      {isUnlinkingBanner ? <Timer /> : <Remove />}
    </Button>
  )
}

export default RemoveBanner
