import * as React from 'react'
import { Dispatch, SetStateAction } from 'react'
import TableMUI from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'
import TableFooter from '@mui/material/TableFooter'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import Head from './Head'
import Toolbar from './Toolbar'
import Empty from './Empty'
import Pagination from '@mui/material/Pagination'
import Spinner from '../Spinner'
import * as Styles from './styles'

export type Order = 'asc' | 'desc'

interface ITableProps {
  id?: string
  columns: Array<any>
  rows: Array<any>
  checkboxSelection?: boolean
  selected?: readonly any[]
  setSelected?: Dispatch<SetStateAction<readonly any[]>>
  emptyTitleText?: string
  emptyText?: string
  dense?: boolean
  page?: number
  onPrevPage?: () => void
  onNextPage?: () => void
  setPage?: (page: number) => void
  isFetching?: boolean
  showPagination?: boolean
  order?: Order
  setOrder?: (order: Order) => void
  orderBy?: string
  setOrderBy?: (orderBy: string) => void
  totalPages?: number
}

const Table: React.FC<ITableProps> = ({
  id = 'table',
  columns = [],
  rows = [],
  checkboxSelection = false,
  emptyTitleText = 'No applicants yet',
  emptyText = 'Read our growth guide and start sharing your application page',
  dense = false,
  selected = [],
  setSelected,
  onPrevPage,
  onNextPage,
  page,
  setPage,
  isFetching = false,
  showPagination = true,
  order = 'asc',
  setOrder,
  orderBy = 'id',
  setOrderBy,
  totalPages = 0
}) => {
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder?.(isAsc ? 'desc' : 'asc')
    setOrderBy?.(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n)
      setSelected?.(newSelected)
      return
    }
    setSelected?.([])
  }

  const handleClick = (
    event: React.MouseEvent<unknown>,
    id: string | number
  ) => {
    const selectedIndex = selected?.indexOf(id)
    let newSelected: readonly any[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected?.slice(1))
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected?.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      )
    }

    setSelected?.(newSelected)
  }

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage?.(value)
  }

  const isSelected = (name: string | number) => selected?.indexOf(name) !== -1

  return (
    <div>
      {rows.length ? (
        <Styles.TableBox>
          {checkboxSelection && <Toolbar numSelected={selected.length} />}
          <TableContainer>
            <TableMUI
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <Head
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={columns}
                checkboxSelection={checkboxSelection}
              />
              <TableBody data-testid={`${id}-body`}>
                {isFetching ? (
                  <TableRow>
                    <TableCell
                      size="small"
                      className="!border-none"
                      colSpan={
                        checkboxSelection ? columns.length + 1 : columns.length
                      }
                    >
                      <Spinner />
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    const isItemSelected = isSelected(row)
                    const labelId = `enhanced-table-checkbox-${index}`

                    return (
                      <TableRow
                        {...(checkboxSelection && {
                          hover: true,
                          onClick: (event: any) => handleClick(event, row),
                          role: 'checkbox',
                          'aria-checked': isItemSelected,
                          sx: { cursor: 'pointer' }
                        })}
                        tabIndex={-1}
                        key={row.id || row.marketplace_merchant_id}
                        selected={isItemSelected}
                      >
                        {checkboxSelection && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId
                              }}
                            />
                          </TableCell>
                        )}
                        {columns.map((column) => (
                          <TableCell
                            key={column.field}
                            align={column.numeric ? 'right' : 'left'}
                            padding={column.disablePadding ? 'none' : 'normal'}
                          >
                            {column.render
                              ? column.render(row)
                              : row[column.field]}
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  })
                )}
              </TableBody>
              <TableFooter>
                {showPagination && (
                  <TableRow>
                    <TableCell
                      className="!border-none"
                      colSpan={
                        checkboxSelection ? columns.length + 1 : columns.length
                      }
                    >
                      <Styles.FooterContainer
                        className={`${totalPages === 1 && '!justify-center'}`}
                      >
                        {totalPages > 1 && (
                          <Styles.FooterArrows
                            disabled={page === 1}
                            onClick={onPrevPage}
                          >
                            <ArrowBackOutlinedIcon color="inherit" /> Previous
                          </Styles.FooterArrows>
                        )}

                        <Pagination
                          count={totalPages}
                          page={page}
                          onChange={handlePaginationChange}
                          hidePrevButton
                          hideNextButton
                        />
                        {totalPages > 1 && (
                          <Styles.FooterArrows
                            disabled={page === totalPages}
                            onClick={onNextPage}
                          >
                            Next <ArrowForwardOutlinedIcon color="inherit" />
                          </Styles.FooterArrows>
                        )}
                      </Styles.FooterContainer>
                    </TableCell>
                  </TableRow>
                )}
              </TableFooter>
            </TableMUI>
          </TableContainer>
        </Styles.TableBox>
      ) : (
        <Empty emptyTitleText={emptyTitleText} emptyText={emptyText} />
      )}
    </div>
  )
}

export default Table
