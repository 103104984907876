import tw from 'twin.macro'
import { styled } from '@mui/material/styles'

export const Icon = styled('img')`
  ${tw`mx-auto mb-6`}
`
export const Title = styled('h2')`
  ${tw`text-gray-900 text-display-xs-semibold md:text-display-sm-semibold mb-16 md:mb-14`}
`
export const TitleSpace = styled('h2')`
  ${tw`text-gray-900 text-display-xs-semibold md:text-display-sm-semibold mb-3 text-center`}
`
export const TitleMerchant = styled('h2')`
  ${tw`text-gray-900 text-display-xs-semibold md:text-display-sm-semibold mb-3`}
`
export const Description = styled('div')`
  ${tw`text-md-regular text-gray-600 text-center`}
`
export const DescriptionMerchant = styled('div')`
  ${tw`text-md-regular text-gray-600 mb-8`}
`
export const Form = styled('form')`
  ${tw`flex flex-col gap-6`}
`
export const Footer = styled('div')`
  ${tw`mt-8 flex justify-center text-sm-regular text-gray-600`}
`
export const FooterLink = styled('span')`
  ${tw`text-sm-semibold text-primary-700 ml-1 cursor-pointer underline`}
`
export const FooterLinkMerchant = styled('span')`
  ${tw`text-sm-semibold flex justify-center text-primary-800 ml-1 underline`}
`
export const BackButton = styled('div')`
  ${tw`mt-5 flex justify-center`}
`
export const ProfilePic = styled('img')`
  ${tw`w-40 h-40 lg:mr-auto rounded-full border-[4px] border-white shadow-lg object-cover`}
`
