import { useEffect, useState, useMemo } from 'react'
import { useDebounce } from 'react-use'
import {
  IMarketplaceMerchant,
  useGetMarketplaceMerchantsQuery,
  useGetMerchantsByCategoryQuery,
  useGetCategoriesQuery,
} from 'services/marketplace'
import { useGetTokenMultipliersQuery } from 'services/multipliers'

const useFilterWall = () => {
  const [page, setPage] = useState(1)
  const [list, setList] = useState<IMarketplaceMerchant[]>([])
  const [inputSearch, setInputSearch] = useState('')
  const [debouncedValue, setDebouncedValue] = useState('')

  const [categoryActive, setCategoryActive] = useState<string>('all')
  const [categorySelectValue, setCategorySelectValue] = useState<
    IMarketplaceMerchant | null | undefined
  >(null)
  const [categoryActiveLabel, setCategoryActiveLabel] =
    useState<string>('All merchants')
  const [isFiltering, setIsFiltering] = useState<boolean>(false)
  const [isSearching, setIsSearching] = useState(false)

  const {
    data: merchants,
    isLoading,
    isError,
    isFetching
  } = useGetMarketplaceMerchantsQuery({
    limit: 20,
    page,
    filter_by: debouncedValue
  })

  // Setting the limit to a very high number that we will likely never reach
  const { data: multipliersData } = useGetTokenMultipliersQuery({ limit: 1000, page: 1 });

  const merchantsWithMultipliers = useMemo(() => {
    if (!merchants || !multipliersData) {
      console.error('There was an issue fetching the merchants or the multipliers');
      return [];
    }

    return merchants.marketplace_merchants.map(merchant => {
      // @ts-ignore
      const multiplier = multipliersData?.find(m => m.merchant.id === merchant.id);
      return {
        ...merchant,
        num_tokens: multiplier?.tokenMultiplier.num_tokens
      };
    });
  }, [merchants, multipliersData]);


  const {
    data: categories
  } = useGetCategoriesQuery({
    limit: 80,
    page: 1
  })

  const {
    data: parentCategories,
    isLoading: isLoadingParentCategories,
    isError: isErrorParentCategories
  } = useGetCategoriesQuery({
    limit: 80,
    page: 1,
    parents_only: true
  })

  const {
    data: merchantsByCategory,
    isLoading: isLoadingByCategory,
    isError: isErrorByCategory,
    isFetching: isFetchingByCategory
  } = useGetMerchantsByCategoryQuery({
    id: categoryActive,
    limit: 20,
    page,
    filter_by: debouncedValue
  })

  useDebounce(
    () => {
      setDebouncedValue(inputSearch)
    },
    500,
    [inputSearch]
  )

  const handleClearSearch = () => {
    setList([])
    setInputSearch('')
    setIsSearching(false)
  }

  const handleClearCategory = () => {
    setIsFiltering(false)
    setList([])
    setCategoryActive('all')
    setPage(1)
  }

  const handleClickCategory = (id: string, label: string) => {
    setIsFiltering(id !== 'all')
    setCategoryActive(id)
    setCategoryActiveLabel(label)
    setList([])
    setPage(1)
  }

  useEffect(() => {
    setList([])
    setPage(1)
    setIsSearching(debouncedValue !== '')
  }, [debouncedValue])

  useEffect(() => {
    if (merchants && !isFiltering) {
      setList((list) => [...list, ...merchants?.marketplace_merchants])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchants, isFiltering])

  useEffect(() => {
    if (merchantsByCategory && isFiltering) {
      const transformedList =
        merchantsByCategory?.marketplace_category_merchants.map(
          (item) => item.marketplace_merchant
        )
      setList((list) => [...list, ...transformedList])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantsByCategory, isFiltering])

  return {
    merchants,
    merchantsByCategory,
    debouncedValue,
    page,
    inputSearch,
    list: merchantsWithMultipliers,
    isSearching,
    isFiltering,
    isLoading: isLoading || isLoadingByCategory,
    isFetching: isFetching || isFetchingByCategory,
    isError: isError || isErrorByCategory,
    categoryActive,
    categorySelectValue,
    categoryActiveLabel,
    categories,
    parentCategories,
    handleClearSearch,
    handleClickCategory,
    setCategorySelectValue,
    handleClearCategory,
    setInputSearch,
    setList,
    setPage
  }
}

export default useFilterWall
