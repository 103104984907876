import React, { useEffect, useState } from 'react'
import { Card, Button, Link } from '@nextui-org/react'
import { IPrize } from 'services/prizes'
import { styled } from '@mui/material'
import tw from 'twin.macro'
import clsx from 'clsx'
import { getTimeZoneOffset, getUTCDate } from 'utils/intlDate'
import { endOfDay } from 'date-fns'
import { fromZonedTime, toDate } from 'date-fns-tz'
import { EST_TIMEZONE_ID } from 'constants/timezone'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const styles = {
  card: 'h-[300px] md:h-[400px]'
}

const CardStyled = styled(Card)`
  ${tw`z-[2] relative w-full flex flex-col justify-end items-center p-6 bg-no-repeat bg-cover bg-center rounded-2xl`}

  &::after {
    ${tw`rounded-2xl`}
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #01021000 60%, #010210 120%), 
      radial-gradient(circle at top left, rgba(0, 0, 0, 0.3), transparent 20%), 
      radial-gradient(circle at top right, rgba(0, 0, 0, 0.3), transparent 20%);
  }
`

const DrawCountDown = ({ hours, minutes, seconds }: { hours: string; minutes: string; seconds: string }) => {
  return (
    <div className='px-2 md:px-4 pt-2 uppercase rounded-b-2xl z-[1] w-full absolute bg-primary-400 h-full flex items-start rounded-t-2xl shadow-md'>
      <div className='flex items-center justify-between w-full'>
        <span className='text-sm md:text-xl font-bold text-white'>Daily Draw</span>
        <div className='flex gap-1'>
          <div className='bg-primary-300 shadow-xl p-1 rounded-xl'>
            <span className='text-xl md:text-3xl font-bold text-white'>{hours}</span>
            <span className='text-[9px] md:text-xs font-light text-white'>HR</span>
          </div>
          <div className='bg-primary-300 shadow-xl p-1 rounded-xl'>
            <span className='text-xl md:text-3xl font-bold text-white'>{minutes}</span>
            <span className='text-[9px] md:text-xs font-light text-white'>MIN</span>
          </div>
          <div className='bg-primary-300 shadow-xl p-1 rounded-xl'>
            <span className='text-xl md:text-3xl font-bold text-white'>{seconds}</span>
            <span className='text-[9px] md:text-xs font-light text-white'>SEC</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function ProductCard({
  prize,
  onCountDownEnd
}: {
  prize: IPrize
  onCountDownEnd?: () => void
}) {
  const [time, setTime] = useState({
    hours: '00',
    minutes: '00',
    seconds: '00'
  })

  useEffect(() => {
    // Parsing prize end date to UTC timezone
    const endOfDayPrizeDate = endOfDay(toDate(prize.end_date.replace('Z', getTimeZoneOffset(EST_TIMEZONE_ID))));
    const prizeEndDate = fromZonedTime(endOfDayPrizeDate, EST_TIMEZONE_ID);

    let interval: NodeJS.Timer;
    interval = setInterval(() => {
      const now = getUTCDate(new Date());
      const diff = prizeEndDate.getTime() - now.getTime();

      if(diff > 0) {
        const hours = Math.floor(diff / 1000 / 60 / 60).toString().padStart(2, '0');
        const minutes = Math.floor((diff / 1000 / 60) % 60).toString().padStart(2, '0');
        const seconds = Math.floor((diff / 1000) % 60).toString().padStart(2, '0');
        setTime({
          hours,
          minutes,
          seconds
        })
      } else if(prize.is_daily_draw) {
        clearInterval(interval);
        onCountDownEnd?.();
        setTime({
          hours: '00',
          minutes: '00',
          seconds: '00'
        });
      }
    }, 1000)

    return () => clearInterval(interval);
  }, [onCountDownEnd, prize.end_date, prize.is_daily_draw])

  const isDraw = prize.is_daily_draw || false;
  const imagePosition = prize?.prize_details_page_styles?.image?.position || 'center';

  return (
    <div className={clsx('select-none self-end relative min-w-[300px] md:min-w-[400px] flex items-end', {
      'h-[350px] md:h-[460px]': isDraw,
      [styles.card]: !isDraw
    })}>
      {isDraw && (
        <DrawCountDown {...time} />
      )}
      <CardStyled
        className={clsx(styles.card, {
          '!pt-0': imagePosition === 'top',
        })}
        isPressable
        as={Link}
        href={`/prize/${prize.id}`}
        style={{ backgroundImage: `url('${prize.detail_bg_image_url}')` }}
      >
        <div className='p-2 z-[5] absolute top-2 left-2 rounded-full bg-white flex items-center gap-2'>
          <span className='font-bold leading-4 text-primary-400'>{prize.token_cost}</span>
          <img src='/images/small-coin.png' alt='Token' />
        </div>
        <div className={clsx('flex h-full', {
          'items-start': imagePosition === 'top',
          'items-center': imagePosition === 'center',
        })}>
          <LazyLoadImage 
            src={prize.image_url} 
            alt="Product" 
            className="relative z-[3] max-h-36 md:max-h-56"
          />
        </div>
        <div className='relative z-[3] flex flex-col w-full'>
          <span className='text-lg md:text-xl font-bold text-center text-white mb-2 md:mb-4'>
            {prize.name}
          </span>
          <Button   
            as={Link}
            href={`/prize/${prize.id}`} 
            className='bg-primary-400 text-white'
          >
            Enter Now
          </Button>
        </div>
      </CardStyled>
    </div>
  )
}
