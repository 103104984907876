import { styled } from '@mui/material/styles'

export const Renderer = styled('div')`
  ul {
    list-style: revert;
    list-style-position: inside;
    /* For nested bullets */
    ul {
      padding-left: 25px;
    }
  }

  ol {
    list-style: decimal;
    list-style-position: inside;
    /* For nested bullets */
    ol {
      padding-left: 25px;
    }
  }

  /* Workaround for when adding empty line, editor adds a p tag, what doesn't break the line */
  p:after {
    content:"";
    display:inline-block;
    width:0px;
  }
`;

export const EditorWrapper = styled('div')`
  .rdw-editor-wrapper {
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    padding: 0 10px;
  }

  .rdw-editor-toolbar {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border: none;
    border-bottom: 1px solid #F1F1F1;
  }

  /* Toolbar item class */
  .rdw-option-wrapper {
    border-radius: 8px;
    width: 30px;
    height: 30px;
    border: none;
    box-shadow: none;

    /* Toolbar item class when active */
    &.rdw-option-active {
      background-color: #f4f4f4;
    }
  }

  .rdw-editor-main {
    padding: 0 5px;
  }

  /* Vertical margin to every element inside the editor */
  .public-DraftStyleDefault-block {
    margin: 0.5em 0;
  }
`;
