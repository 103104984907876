import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack'
import {NextUIProvider} from "@nextui-org/react";
import './index.css'
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import App from './App'
import reportWebVitals from './reportWebVitals';
import { initializeAmplitude } from './amplitudeClient';
import store from 'store'
import { ExtensionDetailsProvider } from 'context/extensionDetails'
import GoogleAnalytics from './google-analytics';

interface ClarityScriptProps {
  projectId: string;
}

const ClarityScript: React.FC<ClarityScriptProps> = ({ projectId }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "${projectId}");
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [projectId]);

  return null;
};

export default ClarityScript;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <NextUIProvider className='h-full'>
            <ExtensionDetailsProvider>
              <GoogleAnalytics measurementId={process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID} />
              <ClarityScript projectId="o8jmngp1a6" />
              <App />
            </ExtensionDetailsProvider>
          </NextUIProvider>
        </SnackbarProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
initializeAmplitude()
