import tw from 'twin.macro'
import { styled } from '@mui/material/styles'
import { Autocomplete } from '@mui/material'

export const SearchBar = styled(Autocomplete)`
  ${tw`w-full`}

  & .MuiOutlinedInput-root {
    ${tw`border-0 p-0 md:w-1/2 w-full`}
  }

  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    ${tw`border-transparent border-0`}
  }

  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    ${tw`!border-0`}
  }
`
