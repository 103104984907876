import React, { FC } from 'react'

interface IContentLoadingPlaceholderProps {
  children: React.ReactNode
  isLoading: boolean
}

const ContentLoadingPlaceholder: FC<IContentLoadingPlaceholderProps> = ({
  children,
  isLoading
}) => {
  return (
    <>
      {isLoading ? (
        <div role="status" className="max-w-sm animate-pulse">
          <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
        </div>
      ) : (
        children
      )}
    </>
  )
}

export default ContentLoadingPlaceholder
