import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

const PAGE_PARAM_KEY = 'page'

export const usePagination = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const pageParam: string = searchParams.get(PAGE_PARAM_KEY) || '1'
  let page = parseInt(pageParam, 10)

  const setPage = (newPage: number) => {
    setSearchParams(`${PAGE_PARAM_KEY}=${newPage}`)
  }

  useEffect(() => {
    if (!searchParams.has(PAGE_PARAM_KEY)) {
      setSearchParams(`${PAGE_PARAM_KEY}=1`)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      page = 1
    }
  }, [])

  return {
    page,
    setPage
  }
}
