import { init, setUserId, track } from '@amplitude/analytics-browser';

let amplitudeClient: ReturnType<typeof init> | null = null;

function initializeAmplitude(){
    const amplitudeApiKey: string = process.env.REACT_APP_AMPLITUDE_API_KEY ?? '';
    if (!amplitudeApiKey) return;
    amplitudeClient = init(amplitudeApiKey, {
        defaultTracking: {
        sessions: false,
        pageViews: false,
        formInteractions: false,
        fileDownloads: false,
        },
    });
      
}

function amplitudeLogEvent({ event_type, event_properties = {} }: AmplitudeEvent): void {
  if (!amplitudeClient) return;
  track(event_type, event_properties);
}

function amplitudeSetUserId(userId: string): void {
  if (!amplitudeClient) return;
  setUserId(userId);
}

export { initializeAmplitude, amplitudeSetUserId, amplitudeLogEvent };

interface AmplitudeEvent {
    event_type: string;
    event_properties?: Record<string, any>;
}

