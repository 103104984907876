import { FC, useState } from 'react'
import Button from 'components/ui/Button'
import TextField from 'components/ui/TextField'
import { IFormProps } from './interfaces'
import { Link, useLocation } from 'react-router-dom'
import * as Styles from './Forms.styles'
import Checkbox from '@mui/material/Checkbox'
// import { matchIsValidTel } from 'mui-tel-input';

const SignupForm: FC<IFormProps> = ({
  formId,
  title,
  buttonText,
  handleSubmit,
  isLoading = false,
  isError = false
}) => {
  const [agreeTerms, setAgreeTerms] = useState(false)
  // const [phone, setPhone] = useState('')
  // const [isPhoneValid, setIsPhoneValid] = useState(true)
  const location = useLocation()
  const currentSearchParams = new URLSearchParams(location.search)

  const handleAgreeTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreeTerms(event.target.checked)
  }

  // const handlePhoneChange = (value: string) => {
  //   setPhone(value);

  //   const isValidTel = matchIsValidTel(value);
  //   const isOnlyCode = /^\+\d+$/.test(value);
  //   const isValid = isOnlyCode || isValidTel;

  //   setIsPhoneValid(isValid);
  // };

  return (
    <div data-testid={formId}>
      <Styles.Form onSubmit={(event) => handleSubmit(event)}>
        <TextField id="name" label="Name" cc-width="full" required autoFocus />
        <TextField
          id="email"
          label="Email"
          type={'email'}
          cc-width="full"
          required
        />
        {/* <MuiTelInput
          id="phone"
          name="phone"
          label="Phone Number"
          value={phone}
          onChange={handlePhoneChange}
          defaultCountry="US"
          cc-width="full"
          error={!isPhoneValid && phone !== ''}
          helperText={!isPhoneValid && phone !== '' ? 'Please enter a valid phone number' : ''}
        /> */}
        <TextField
          id="password"
          label="Password"
          type={'password'}
          cc-width="full"
          helperText="Password must contain at least 8 characters and 1 number."
          required
        />
        <Button
          loading={isLoading}
          disabled={!agreeTerms || isError || isLoading}
          data-testid={`${formId}-submit-button`}
          type="submit"
          cc-color="purple"
          cc-width="full"
        >
          {buttonText}
        </Button>
      </Styles.Form>
      {isError ? (
        <Styles.Footer className="!text-red-500">
          Unable to find that merchant Name
        </Styles.Footer>
      ) : (
        <>
          <Styles.Footer className="items-start">
            <Checkbox
              checked={agreeTerms}
              onChange={handleAgreeTerms}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <div>
              {"I agree to the"}
              <Styles.FooterLink>
                <Link target="_blank" to={`https://joinwave.com/rules`}>
                  {"Terms of Use"}
                </Link>
              </Styles.FooterLink>{' '}
              and
              <Styles.FooterLink>
                <Link target="_blank" to={`https://joinwave.com/privacy`}>
                  {"Privacy Policy"}
                </Link>
              </Styles.FooterLink>
              {" and to receive periodic emails from Wave."}
            </div>
          </Styles.Footer>
          <Styles.Footer>
            Already have an account?{' '}
            <Styles.FooterLink>
              <Link to={`/?${currentSearchParams.toString()}`}>Log in</Link>
            </Styles.FooterLink>
          </Styles.Footer>
        </>
      )}
    </div>
  )
}

export default SignupForm
