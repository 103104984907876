import { api } from './api'
import { IOrder } from './orders'

export interface IMerchant {
  id: string
  merchant_id: string
  source: string
  name: string
  email: string
  orders_count: number
  created_at: string
  updated_at: string
}

export interface IGetMerchantsResponse {
  merchants: IMerchant[]
  records_count: number
  next_page: number
  previous_page: number
}

export interface IGetMerchantsOrdersResponse {
  merchant_orders: IOrder[]
  records_count: number
  next_page: number
  previous_page: number
}

export interface IMerchant {
  id: string
  merchant_id: string
  name: string
  email: string
}

export const merchantsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMerchants: build.query<
      IGetMerchantsResponse,
      { limit: number; page: number }
    >({
      query: ({ limit, page }) => {
        return { url: `/merchants?limit=${limit}&page=${page}`, method: 'GET' }
      }
    }),
    getMerchant: build.query<{ merchant: IMerchant }, string>({
      query: (merchantId) => {
        return { url: `/merchants/${merchantId}`, method: 'GET' }
      }
    }),
    getMerchantOrders: build.query<
      IGetMerchantsOrdersResponse,
      { merchantId: string; limit: number; page: number }
    >({
      query: ({ merchantId, limit, page }) => {
        return {
          url: `/merchants/${merchantId}/orders?limit=${limit}&page=${page}`,
          method: 'GET'
        }
      }
    })
  })
})

export const {
  useGetMerchantsQuery,
  useGetMerchantQuery,
  useGetMerchantOrdersQuery
} = merchantsApi
