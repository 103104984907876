import { Image, Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from '@nextui-org/react';
import { styled } from "@mui/material";
import tw from 'twin.macro'

import { useAddInitial500ExtTokensMutation } from 'services/customers'


export const ModalWrapper = styled('div')`
  ${tw`flex flex-col w-full h-full items-center overflow-hidden px-4`}

  background-image: url("/images/coins-rain.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
   
`;

export const ChromeExtensionOnboardingModal = ({ isOpen, onOpenChange, customerId }: { isOpen: boolean, onOpenChange: () => void, customerId: string }) => {
    const [addInitialExtTokens] = useAddInitial500ExtTokensMutation();

    const handleGetStarted = async () => {
        try {
            await addInitialExtTokens({customerId}).unwrap();
            onOpenChange();
        } catch (error) {
            console.error('Error adding initial tokens:', error);
        }
    }

    return (
      <Modal
        isOpen={isOpen}
        onClose={onOpenChange}
        size='full'
        className="z-[9999]"
      >
        <ModalContent className='p-8'>
          <ModalWrapper>
            <ModalHeader className="flex flex-col items-center gap-2 mt-28 w-full">
              <Image width={150} alt="Prizedraw Logo" src="/images/logo.png" />
              <h2 className='mt-2 text-3xl font-semibold text-center w-full'>Now the fun part - Using Wave</h2>
            </ModalHeader>
            <ModalBody className="mt-4 h-fit w-full">
              <div className="flex flex-row justify-around w-4/5 mx-auto">
                <div className="flex flex-col gap-4 w-1/3 items-center">
                  <Image src='/images/1.png' height={175} />
                  <p className="text-center text-lg w-[250px] font-bold">Click Activate Tokens and shop like normal</p>
                </div>
                <div className="flex flex-col gap-4 w-1/3 items-center">
                  <Image src='/images/2.png' height={175} />
                  <p className="text-center text-lg w-[250px] font-bold">We'll look for coupons at checkout</p>
                </div>
                <div className="flex flex-col gap-4 w-1/3 items-center">
                  <Image src='/images/3.png' height={175} />
                  <p className="text-center text-lg w-[250px] font-bold">Use your Tokens for a chance to win prizes</p>
                </div>
              </div>
            </ModalBody>
            <ModalFooter className='flex flex-row justify-center w-full'>
              <Button onClick={handleGetStarted} className="bg-primary-400 text-white p-2 rounded-lg font-semibold w-1/4">
                Get Started
              </Button>
            </ModalFooter>
          </ModalWrapper>
        </ModalContent>
    
      </Modal>
    );
  };