import TextField from 'components/ui/TextField'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import Button from 'components/ui/Button'
import { useNavigate } from 'react-router-dom'
import * as Styles from 'layouts/PrivateLayout.styles'
import HorizontalFieldRow from 'components/ui/HorizontalFieldRow'
import { useNotification } from 'hooks/useNotification'
import {
  useGetCustomerQuery,
  useUpdateCustomerMutation
} from 'services/customers'
import { updateUserFullName as updateUserFullNameAction } from 'store/authSlice'
import Switch from '@mui/material/Switch'
import Spinner from 'components/ui/Spinner'

const Account = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { sendSuccessNotification, sendErrorNotification } = useNotification()
  const [updateCustomer, { isLoading }] = useUpdateCustomerMutation()

  const customerId: any = useAppSelector((state) => state.auth.customerId)
  const { data, isLoading: isLoadingCustomer } = useGetCustomerQuery(customerId)

  const customer = data?.customer

  const handleCancel = () => navigate('/dashboard')

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const name = formData.get('full_name') as string
    const accepts_transactional_emails = formData.get(
      'accepts_transactional_emails'
    ) as string
    const accepts_marketing_emails = formData.get(
      'accepts_marketing_emails'
    ) as string

    if (!customerId) {
      sendErrorNotification('Customer is required')
      return
    }

    if (!name) {
      sendErrorNotification('Name is required')
      return
    }

    const response: any = await updateCustomer({
      customerId,
      name,
      accepts_transactional_emails: accepts_transactional_emails ? true : false,
      accepts_marketing_emails: accepts_marketing_emails ? true : false
    })

    if (response.data) {
      sendSuccessNotification('Updated Successfully')
      dispatch(updateUserFullNameAction(name))
    }
  }

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-11/12 mt-10 h-screen">
        <Styles.PageTitleContainer>Settings</Styles.PageTitleContainer>
        {/* Changed for development */}
        {isLoadingCustomer ? (
          <Spinner />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="w-full">
              <HorizontalFieldRow label="Full Name *">
                <div className="w-full lg:w-1/2">
                  <TextField
                    id="full_name"
                    hiddenLabel
                    className="w-52"
                    cc-width="full"
                    defaultValue={customer?.name}
                    inputProps={{ maxLength: 255 }}
                  />
                </div>
              </HorizontalFieldRow>
              <HorizontalFieldRow label="Email">
                <div className="flex gap-2 h-10">
                  <EmailOutlinedIcon />
                  <span>{customer?.email}</span>
                </div>
              </HorizontalFieldRow>
              <HorizontalFieldRow label="Accepts transactional emails">
                <div className="flex gap-2 h-10">
                  <Switch
                    name="accepts_transactional_emails"
                    defaultChecked={customer?.accepts_transactional_emails}
                  />
                </div>
              </HorizontalFieldRow>
              <HorizontalFieldRow label="Accepts marketing emails">
                <div className="flex gap-2 h-10">
                  <Switch
                    name="accepts_marketing_emails"
                    defaultChecked={customer?.accepts_marketing_emails}
                  />
                </div>
              </HorizontalFieldRow>
            </div>
            <div className="w-full pt-6 flex justify-end gap-3 border-t border-t-gray-200">
              <Button onClick={handleCancel}>Cancel</Button>
              <Button cc-color="purple" type="submit" disabled={isLoading}>
                Save
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default Account
