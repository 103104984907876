import { FC, useEffect, useState } from 'react'
import Spinner from 'components/ui/Spinner'
import { useParams } from 'react-router-dom'
import { getTablePageLimit } from 'utils/getTablePageLimit'
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
  TextField,
  Button
} from '@mui/material'
import { DragIndicator, Timer } from '@mui/icons-material'
import Table from 'components/ui/Table'
import {
  useGetBannersQuery,
  IBanner,
  useLinkCategoryToBannerMutation,
  useGetBannersFromCategoryQuery,
  useUpdateBannerMutation,
} from 'services/marketplace'
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder
} from '@hello-pangea/dnd'
import RemoveBanner from 'components/CategoryMerchants/RemoveBanner'
import Empty from 'components/ui/Table/Empty'

const LIMIT = getTablePageLimit()

interface ICategoryDetailsMerchantsTableProps {
  isEditing?: boolean
}

const CategoryDetailsBannersTable: FC<
  ICategoryDetailsMerchantsTableProps
> = ({ isEditing }) => {
  const { categoryId } = useParams()
  const [page, setPage] = useState(1)
  const [value, setValue] = useState<IBanner | null>(null)
  const [options, setOptions] = useState<IBanner[] | undefined>([])

  const { data, isLoading, isFetching } = useGetBannersFromCategoryQuery({
    bannerId: categoryId || '',
  })

  const {
    data: banners,
    isLoading: isLoadingBanners,
    isFetching: isFetchingBanners
  } = useGetBannersQuery({
    limit: 6000,
    page: 1
  })

  const [rows, setRows] = useState(data?.marketplace_banners || [])
  const [linkCategoryToBanner] = useLinkCategoryToBannerMutation()
  const [updateBanner] = useUpdateBannerMutation()

  const handleDragEnd: OnDragEndResponder = (e) => {
    if (!e.destination) return

    let tempData = Array.from(rows)
    let [source_data] = tempData.splice(e.source.index, 1)
    tempData.splice(e.destination.index, 0, source_data)
    setRows(tempData)
  }

  const handleSave = async () => {
    for (let i = 0; i < rows.length; i++) {
      await updateBanner({
        bannerId: rows[i].id,
        banner_name: rows[i].banner_name,
        active: rows[i].active,
        order: i + 1,
        num_lines: rows[i].num_lines.toString()
      })
    }
  }

  const columns = [
    {
      field: 'banner_name',
      headerName: 'Banner Name',
      render: (rowData: IBanner) => (
        <span>{rowData?.banner_name}</span>
      )
    },
    {
      field: 'active',
      headerName: 'Active?',
      render: (rowData: IBanner) =>
        rowData?.active
    },
  ]

  useEffect(() => {
    // This needs to be changed to get non-used banners
    const bannersCategory = banners?.marketplace_banners
    if (bannersCategory && isEditing) {
      // const noBannersSelected = banners?.marketplace_banners.filter(
      //   (banner: any) => {
      //     return !bannersCategory.find(
      //       (bannerCategory) =>
      //         bannerCategory.id === banner.id
      //     )
      //   }
      // )

      setOptions(bannersCategory)
      setRows(data?.marketplace_banners || [])
    }
  }, [isEditing, banners?.marketplace_banners, banners])

  return (
    <div>
      <div className="flex justify-between">
        <div className="text-lg font-semibold">Banners</div>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save Changes
        </Button>
      </div>
      {isEditing && (
        <div className="w-full lg:w-1/2">
          {isLoadingBanners ? (
            <Spinner />
          ) : (
            <Autocomplete
              options={options || []}
              getOptionLabel={(option: IBanner) => option.banner_name}
              id="autocomplete-banners"
              value={value}
              clearOnEscape
              onChange={async (
                event: any,
                newValue: IBanner | null
              ) => {
                setValue(newValue)

                if (newValue?.id) {
                  await linkCategoryToBanner({
                    category_id: categoryId || '',
                    banner_id: newValue?.id || '',
                    // order: 1
                  })

                  const clearButton = document.querySelector(
                    '.MuiAutocomplete-clearIndicator'
                  ) as HTMLElement
                  clearButton?.click()
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="autocomplete-banners-input"
                  label="Add banners to the category"
                  variant="filled"
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option.banner_name === value?.banner_name
              }
            />
          )}
        </div>
      )}
      {isLoading || isLoadingBanners ? (
        <Spinner />
      ) : (
        <div className="mt-4">
          {isEditing ? (
            <DragDropContext onDragEnd={handleDragEnd}>
              <TableContainer component={Paper}>
                <MuiTable sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {isFetchingBanners && (
                          <div className="text-primary-500">
                            <Timer className="!text-lg" />
                          </div>
                        )}
                      </TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="center">Source</TableCell>
                    </TableRow>
                  </TableHead>
                  <Droppable droppableId="droppable-2">
                    {(provider) => (
                      <TableBody
                        ref={provider.innerRef}
                        {...provider.droppableProps}
                      >
                        {rows.map((row, index) => (
                          <Draggable
                            key={row.id}
                            draggableId={row.id}
                            index={index}
                          >
                            {(provider, snapshot) => (
                              <TableRow
                                key={row.id}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0
                                  }
                                }}
                                {...provider.draggableProps}
                                {...provider.dragHandleProps}
                                className={
                                  snapshot.isDragging
                                    ? 'bg-primary-100 is-dragging !inline-table'
                                    : ''
                                }
                                ref={provider.innerRef}
                              >
                                <TableCell component="th" scope="row">
                                  <DragIndicator />
                                </TableCell>
                                <TableCell>
                                  {row.banner_name}
                                </TableCell>
                                <TableCell align="center">
                                  {row.active}
                                </TableCell>
                                <TableCell align="right">
                                  <RemoveBanner
                                    categoryId={categoryId || ''}
                                    bannerId={row.id}
                                  />
                                </TableCell>
                              </TableRow>
                            )}
                          </Draggable>
                        ))}
                        {rows.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={6}>
                              <Empty
                                emptyTitleText="No merchants yet"
                                emptyText="You can select merchants on the dropdown above."
                              />
                            </TableCell>
                          </TableRow>
                        )}
                        {provider.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </MuiTable>
              </TableContainer>
            </DragDropContext>
          ) : (
            <Table
              columns={columns}
              rows={[...(data?.marketplace_banners || [])].sort((a, b) => a.order - b.order)}
              emptyTitleText="No merchants yet"
              emptyText='You can add merchants by clicking on "Edit Category" button'
              onPrevPage={() => setPage(page - 1)}
              onNextPage={() => setPage(page + 1)}
              isFetching={isFetching}
              page={page}
              setPage={setPage}
              totalPages={Math.ceil(Number(data?.records_count) / LIMIT)}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default CategoryDetailsBannersTable
