export const SlickSettings = {
  dots: false,
  infinite: false,
  centerMode: false,
  slidesToShow: 7,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1900,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1740,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1525,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1330,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 830,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 665,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
}

export interface IDraggable {
  draggableId: string
  type: 'DEFAULT'
  source: {
    index: number
    droppableId: string
  }
  reason: 'DROP'
  mode: 'FLUID'
  destination: {
    droppableId: string
    index: number
  }
  combine: null
}
