import ForgotModule from 'modules/Forgot'

const Forgot = () => {
  return (
    <div data-testid="page-forgot">
      <ForgotModule />
    </div>
  )
}

export default Forgot
