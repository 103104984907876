import { styled, TextField } from "@mui/material";
import { Navbar } from "@nextui-org/react";
import tw from "twin.macro";

export const SearchInput = styled(TextField)`
  & .Mui-focused {
    & fieldset {
      ${tw`!border-primary-400`};
    }
  }
    
  & input[type="search"]::-webkit-search-cancel-button {
    ${tw`appearance-auto h-5 w-5 cursor-pointer`};
  }
`;

export const StyledNavbar = styled(Navbar)`
  & header {
    ${tw`lg:!p-0 gap-0 lg:gap-4 px-4`}
  }
`;