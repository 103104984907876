import { api } from './api'

export interface IGetCategoriesResponse {
  marketplace_categories: IMarketplaceCategory[]
  records_count: number
  next_page: number
  previous_page: number
}

export interface IGetBannersResponse {
  marketplace_banners: IBanner[]
  records_count: number
  next_page: number
  previous_page: number
}

interface CategoryQueryParams extends PaginationParams {
  parent_id?: string;
  parents_only?: boolean;
}

export interface IMarketplaceCategory {
  id: string
  source_merchant_id: string
  category_name: string
  order: number
  active: boolean
  created_at: string
  updated_at: string
  is_home: boolean
  num_lines: number
  image_url?: string
  show_in_nav: boolean
  category_icon: string | null
  parentCategory?: {
    id: string;
    category_name: string;
    active: boolean;
    order: number;
  };
}

export interface IBanner {
  id: string
  banner_name: string
  order: number
  active: boolean
  num_lines: number
  image_url?: string
  created_at: string
  updated_at: string
  allow_tag: boolean
  allow_description: boolean
  image_size?: "large_rectangle" | "large_square" | "small_square" | "rectangle"
  image_type?: string
  default_tag?: string
  banner_background_color?: string
  text_color?: string
}

interface IImageOptionsResponse {
  imageSizes: string[];
  imageTypes: string[];
}


export interface IGetMerchantsByCategoryResponse {
  marketplace_category_merchants: IMerchantByCategory[]
  records_count: number
  next_page: number
  previous_page: number
}

export interface IGetMerchantsByBannerResponse {
  marketplace_banner_merchants: IMerchantByBanner[]
  records_count: number
  next_page: number
  previous_page: number
}

export interface IMerchantByCategory {
  created_at: string
  marketplace_category_id: string
  marketplace_merchant: IMarketplaceMerchant
  marketplace_merchant_id: string
  order: number
  updated_at: string
}

export interface IMerchantByBanner {
  created_at: string
  marketplace_banner_id: string
  marketplace_merchant: IMarketplaceMerchant
  marketplace_merchant_id: string
  order: number
  updated_at: string
  tag: string | null
  description: string | null
  aesthetic_circle_image: string | null
  aesthetic_background_image: string | null
}

export interface IGetMerchantsResponse {
  marketplace_merchants: IMarketplaceMerchant[]
  records_count: number
  next_page: number
  previous_page: number
}

export interface IMarketplaceMerchant {
  id: string
  source_merchant_id: string
  source: string
  name: string
  url: string
  wildfire_categories: string
  score: number
  image_url1: string
  image_url2: string
  countries: string
  created_at: string
  updated_at: string
}

export interface PaginationParams {
  id?: string
  limit: number
  page: number
  sort_by?: string
  filter_by?: string
}

export interface ILinkCategoryToBanner {
  category_id: string
  banner_id: string
}

export interface IGetMarketplaceCategoryBannerResponse {
  links: ILinkCategoryToBanner[]
  records_count: number
  next_page: number
  previous_page: number
}

export const marketplaceApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMarketplaceMerchants: build.query<
      IGetMerchantsResponse,
      PaginationParams
    >({
      query: ({ limit, page, filter_by }) => {
        const filterByValueEncode = encodeURIComponent(
          `name:"*${filter_by}*" OR wildfire_categories:"*${filter_by}*"`
        )
        const filterBy = filter_by ? `&filter_by=${filterByValueEncode}` : ''
        return {
          url: `/marketplace/merchants?limit=${limit}&page=${page}${filterBy}`,
          method: 'GET'
        }
      },
      providesTags: ['MarketplaceMerchants']
    }),
    getCategories: build.query<IGetCategoriesResponse, CategoryQueryParams>({
      query: ({ limit, page, parent_id, parents_only }) => {
        let url = `/marketplace/categories?limit=${limit}&sort_by=order-asc&page=${page}`;
         
        if (parent_id) url += `&parent_id=${parent_id}`;
        if (parents_only) url += `&parents_only=true`;
        
        return {
          url,
          method: 'GET'
        }
      },
      providesTags: ['MarketplaceCategories']
    }),
    getCategory: build.query<
      { marketplace_category: IMarketplaceCategory },
      string
    >({
      query: (categoryId) => {
        return { url: `/marketplace/categories/${categoryId}`, method: 'GET' }
      },
      providesTags: (_result, _error, id) => {
        return [
          { type: 'MarketplaceCategoryDetail', id },
          { type: 'MarketplaceCategoryDetail', id: 'PARTIAL-LIST' }
        ]
      }
    }),
    createCategory: build.mutation<
      { marketplace_category: IMarketplaceCategory },
      { category_name: string; order?: number; active: boolean, parent_id?: string | null, image_url?: string | null, show_in_nav?: boolean, category_icon?: string | null }
    >({
      query: (credentials: any) => ({
        url: '/marketplace/categories',
        method: 'POST',
        body: credentials
      }),
      invalidatesTags: ['MarketplaceCategories']
    }),
    updateCategory: build.mutation<
      any,
      {
        categoryId: string
        category_name?: string
        active?: boolean
        is_home: boolean
        order?: number,
        parent_id?: string | null
        image_url?: string | null
        show_in_nav?: boolean,
        category_icon?: string | null
      }
    >({
      query: ({ categoryId, category_name, active, order, is_home, parent_id , image_url, show_in_nav, category_icon}) => ({
        url: `/marketplace/categories/${categoryId}`,
        method: 'PUT',
        body: {
          category_name,
          active,
          is_home,
          order,
          parent_id,
          image_url,
          show_in_nav,
          category_icon
        }
      }),
      invalidatesTags: (_result, _error, { categoryId }) => [
        { type: 'MarketplaceCategoryDetail', id: categoryId }
      ]
    }),
    updateCategoryOrder: build.mutation<
      any,
      {
        categoryId: string
        order: number
      }
    >({
      query: ({ categoryId, order }) => ({
        url: `/marketplace/categories/${categoryId}`,
        method: 'PUT',
        body: {
          order
        }
      })
    }),
    addMerchantToCategory: build.mutation<
      any,
      { categoryId: string; merchantId: string; order: number }
    >({
      query: ({ categoryId, merchantId, order }) => ({
        url: `/marketplace/categories/${categoryId}/merchants`,
        method: 'POST',
        body: {
          marketplace_merchant_id: merchantId,
          order
        }
      }),
      invalidatesTags: ['MarketplaceMerchants']
    }),
    removeMerchantFromCategory: build.mutation<
      { msg: string; id: string },
      { categoryId: string; merchantId: string }
    >({
      query({ categoryId, merchantId }) {
        return {
          url: `/marketplace/categories/${categoryId}/merchants/${merchantId}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: () => [
        { type: 'MarketplaceMerchants', id: 'PARTIAL-LIST' },
        { type: 'MarketplaceMerchants' }
      ]
    }),
    updateMerchantOrder: build.mutation<
      any,
      {
        categoryId: string
        merchantId: string
        order: number
      }
    >({
      query: ({ categoryId, merchantId, order }) => ({
        url: `/marketplace/categories/${categoryId}/merchants/${merchantId}`,
        method: 'PUT',
        body: {
          order
        }
      }),
      invalidatesTags: ['MarketplaceMerchants']
    }),
    removeCategory: build.mutation<{ msg: string; id: string }, string>({
      query(id) {
        return {
          url: `/marketplace/categories/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: () => [
        { type: 'MarketplaceCategories', id: 'PARTIAL-LIST' }
      ]
    }),
    getMerchantsByCategory: build.query<
      IGetMerchantsByCategoryResponse,
      PaginationParams
    >({
      query: ({ id, limit, page, filter_by }) => {
        const filterByValueEncode = encodeURIComponent(
          `name:"*${filter_by}*" OR wildfire_categories:"*${filter_by}*"`
        )
        const filterBy = filter_by ? `&filter_by=${filterByValueEncode}` : ''
        return {
          url: `/marketplace/categories/${id}/merchants?limit=${limit}&page=${page}${filterBy}`,
          method: 'GET'
        }
      },
      providesTags: ['MarketplaceMerchants']
    }),
    getBanner: build.query<{ marketplace_banner: IBanner }, string>({
      query: (bannerId) => {
        return { url: `/marketplace/banners/${bannerId}`, method: 'GET' }
      },
      providesTags: (_result, _error, id) => {
        return [
          { type: 'MarketplaceBannerDetail', id },
          { type: 'MarketplaceBannerDetail', id: 'PARTIAL-LIST' }
        ]
      }
    }),
    getBanners: build.query<IGetBannersResponse, PaginationParams>({
      query: ({ limit, page }) => {
        return {
          url: `/marketplace/banners?limit=${limit}&sort_by=order-asc&page=${page}`,
          method: 'GET'
        }
      },
      providesTags: ['MarketplaceBanners']
    }),
    getBannerImageOptions: build.query<IImageOptionsResponse, void>({
      query: () => ({
        url: `/marketplace/banners/banner-image-options`,
        method: 'GET'
      }),
      providesTags: ['BannerImageOptions']
    }),
    addMerchantDetails: build.mutation<
      {
        merchant_details:
        {
          merchantId: string;
          bannerId: string;
          description: string | null;
          tag: string | null;
          aesthetic_circle_image: string | null;
          aesthetic_background_image: string | null;
        }
      },
      {
        merchantId: string;
        bannerId: string;
        description?: string | null;
        tag?: string | null;
        aesthetic_circle_image?: string | null;
        aesthetic_background_image?: string | null;
      }
    >({
      query: ({ merchantId, bannerId, description = null, tag = null, aesthetic_circle_image = null, aesthetic_background_image = null }) => ({
        url: `/marketplace/banners/${merchantId}/details/${bannerId}`,
        method: 'POST',
        body: {
          description,
          tag,
          aesthetic_circle_image,
          aesthetic_background_image
        },
      }),
      invalidatesTags: ['MarketplaceBannerMerchants'],
    }),
    createBanner: build.mutation<
      { marketplace_banner: IBanner },
      {
        banner_name: string;
        order?: number;
        active: boolean;
        image_url: string | null;
        allow_tag?: boolean;
        allow_description?: boolean;
        image_type?: string | null;
        default_tag?: string | null;
        banner_background_color?: string | null;
        image_size?: string | null;
        text_color?: string | null;
      }
    >({
      query: (credentials: any) => ({
        url: '/marketplace/banners',
        method: 'POST',
        body: credentials
      }),
      invalidatesTags: ['MarketplaceBanners']
    }),
    updateBanner: build.mutation<
      any,
      {
        bannerId: string;
        banner_name?: string;
        active?: boolean;
        order: number;
        num_lines: string;
        image_url?: string | null;
        allow_tag?: boolean;
        allow_description?: boolean;
        image_type?: string | null;
        default_tag?: string | null;
        banner_background_color?: string | null;
        image_size?: string | null;
        text_color?: string | null;
      }
    >({
      query: ({ bannerId, banner_name, active, order, num_lines, image_url = null, allow_tag, allow_description, image_type ,image_size, default_tag, banner_background_color, text_color }) => ({
        url: `/marketplace/banners/${bannerId}`,
        method: 'PUT',
        body: {
          banner_name,
          active,
          order,
          num_lines,
          image_url,
          allow_tag,
          allow_description,
          image_type,
          default_tag,
          banner_background_color,
          image_size,
          text_color
        }
      }),
      invalidatesTags: (_result, _error, { bannerId }) => [
        { type: 'MarketplaceBannerDetail', id: bannerId }
      ]
    }),
    removeBanner: build.mutation<{ msg: string; id: string }, string>({
      query(id) {
        return {
          url: `/marketplace/banners/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: () => [
        { type: 'MarketplaceBanners', id: 'PARTIAL-LIST' }
      ]
    }),
    getMerchantsByBanner: build.query<
      IGetMerchantsByBannerResponse,
      PaginationParams
    >({
      query: ({ id, limit, page, filter_by }) => {
        const filterBy = filter_by ? `&filter_by=name:"*${filter_by}*"` : ''
        return {
          url: `/marketplace/banners/${id}/merchants?limit=${limit}&page=${page}${filterBy}`,
          method: 'GET'
        }
      },
      providesTags: ['MarketplaceBannerMerchants']
    }),
    addMerchantToBanner: build.mutation<
      any,
      { bannerId: string; merchantId: string; order: number }
    >({
      query: ({ bannerId, merchantId, order }) => ({
        url: `/marketplace/banners/${bannerId}/merchants`,
        method: 'POST',
        body: {
          marketplace_merchant_id: merchantId,
          order
        }
      }),
      invalidatesTags: ['MarketplaceBannerMerchants']
    }),
    removeMerchantFromBanner: build.mutation<
      { msg: string; id: string },
      { bannerId: string; merchantId: string }
    >({
      query({ bannerId, merchantId }) {
        return {
          url: `/marketplace/banners/${bannerId}/merchants/${merchantId}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: () => [
        { type: 'MarketplaceBannerMerchants', id: 'PARTIAL-LIST' },
        { type: 'MarketplaceBannerMerchants' }
      ]
    }),
    updateMerchantBannerOrder: build.mutation<
      any,
      {
        bannerId: string
        merchantId: string
        order: number
      }
    >({
      query: ({ bannerId, merchantId, order }) => ({
        url: `/marketplace/banners/${bannerId}/merchants/${merchantId}`,
        method: 'PUT',
        body: {
          order
        }
      }),
      invalidatesTags: ['MarketplaceBannerMerchants']
    }),
    linkCategoryToBanner: build.mutation<{ msg: string }, ILinkCategoryToBanner>({
      query: ({banner_id, category_id}) => ({
        url: '/marketplace/category_banners/link',
        method: 'POST',
        body: {
          banner_id,
          category_id
        }
      }),
      invalidatesTags: ['MarketplaceCategories', 'MarketplaceBanners']
    }),
    unlinkCategoryFromBanner: build.mutation<{ msg: string }, ILinkCategoryToBanner>({
      query: ({banner_id, category_id}) => ({
        url: '/marketplace/category_banners/unlink',
        method: 'DELETE',
        body: {
          banner_id,
          category_id
        }
      }),
      invalidatesTags: ['MarketplaceCategories', 'MarketplaceBanners']
    }),
    getBannersFromCategory: build.query<IGetBannersResponse, { bannerId: string }>({
      query: ({ bannerId }) => {
        return {
          url: `/marketplace/category_banners/banners/${bannerId}`,
          method: 'GET'
        }
      },
      providesTags: ['MarketplaceBanners']
    }),
  })
})

export const {
  useGetMarketplaceMerchantsQuery,
  useGetCategoriesQuery,
  useGetCategoryQuery,
  useGetMerchantsByCategoryQuery,
  useCreateCategoryMutation,
  useRemoveCategoryMutation,
  useUpdateCategoryMutation,
  useUpdateCategoryOrderMutation,
  useUpdateMerchantOrderMutation,
  useAddMerchantToCategoryMutation,
  useRemoveMerchantFromCategoryMutation,
  useGetBannersQuery,
  useGetBannerQuery,
  useCreateBannerMutation,
  useUpdateBannerMutation,
  useRemoveBannerMutation,
  useGetMerchantsByBannerQuery,
  useAddMerchantToBannerMutation,
  useRemoveMerchantFromBannerMutation,
  useUpdateMerchantBannerOrderMutation,
  useLinkCategoryToBannerMutation,
  useUnlinkCategoryFromBannerMutation,
  useGetBannersFromCategoryQuery,
  useGetBannerImageOptionsQuery,
  useAddMerchantDetailsMutation
} = marketplaceApi
