import { styled } from "@mui/material";
import { Button, Image, Modal, ModalContent } from "@nextui-org/react";
import tw from "twin.macro";

export type CollectTokensModalProps = {
  open: boolean;
  onClaim: () => any | Promise<any>;
  totalTokens: number;
  purchasesCount: number;
  isClaiming?: boolean;
};

const StyledButton = styled(Button)`
  ${tw`bg-white text-primary-400 font-semibold`};

  &:disabled {
    ${tw`text-black bg-gray-300 opacity-50 cursor-not-allowed`}
  }
`;

const CollectTokensModal = (props: CollectTokensModalProps) => {

  return (
    <Modal placement="center" size={'xs'} isOpen={props.open} isDismissable={false} hideCloseButton>
      <ModalContent className="p-6 pt-0 bg-primary-400">
        <div className="flex flex-col items-center gap-3 lg:gap-5">
          <Image src="/images/collect-tokens-coins.png" alt="Collect Tokens" />
          <div className="flex flex-col gap-2 items-center">
            <span className="text-4xl font-medium text-white">Nice!</span>
            <span className="text-md font-medium text-white text-center">You received {props.totalTokens} Tokens with your last {props.purchasesCount > 1 ? 'purchases' : 'purchase'}</span>
          </div>
          <StyledButton isLoading={props.isClaiming} disabled={props.isClaiming} onClick={props.onClaim}>Collect Tokens</StyledButton>
        </div>
      </ModalContent>
    </Modal>
  )
};

export default CollectTokensModal;