import React from "react";
import { FC } from 'react'

import Spinner from 'components/ui/Spinner'

import { useGetMerchantsByBannerQuery } from 'services/marketplace'
import { ITokenMultiplier, useGetMultipliersByBannerQuery } from 'services/multipliers'
import { HorizontalScrollCard } from 'components/NewComponents/HorizontalScrollCard'
import { HorizontalCard } from 'components/NewComponents/HorizontalCard';
import HorizontalScrollList from "./HorizontalScrollList";
import ThreeRowHorizontalScrollList from "./ThreeRowHorizontalScrollList";
import { AltHorizontalScrollCard } from "./AltHorizontalScrollCard";
import { HorizontalScrollCardBig } from "./HorizontalScrollCardBig";

interface IMerchantsByBanner {
  bannerId: string
  num_lines: number
  redirectToSplashScreen: (merchantName: string, merchantId: string, merchantUrl: string, merchantImage: string) => void
  type?: "small_square" | "rectangle" | "large_square" | "large_rectangle"
  textColor: string
}


const MerchantsByBannerNew: FC<IMerchantsByBanner> = ({
  bannerId,
  num_lines,
  redirectToSplashScreen,
  type,
  textColor,
}) => {
  const { data, isLoading } = useGetMerchantsByBannerQuery({
    id: bannerId,
    limit: 100,
    page: 1
  })

  const { data: multipliersData } = useGetMultipliersByBannerQuery({
    bannerId,
  })

  const merchantMultipliers = new Map<string, ITokenMultiplier>();
  if (multipliersData?.items) {
    multipliersData.items.forEach(item => {
      if (item.tokenMultiplier && item.merchant.id) {
        merchantMultipliers.set(item.merchant.id, item.tokenMultiplier);
      }
    });
  }
  console.log("MULTIPLIERS", multipliersData)
  console.log(merchantMultipliers)

  const merchants = data?.marketplace_banner_merchants
  console.log("MERCHANTS", merchants)
  if (isLoading) return <Spinner />

  return (
    <>
      <div>
        {type === "small_square" && merchants && merchants?.length > 0 &&
          <ThreeRowHorizontalScrollList slidesToShow={3} anchored={true}>
            {merchants.map((item) => (
              <AltHorizontalScrollCard
                itemId={item.marketplace_merchant.id} // NOTE: itemId is required for track items
                imageUrl={item.marketplace_merchant.image_url1 || item.marketplace_merchant.image_url2}
                key={item.marketplace_banner_id}
                generateLink={() => redirectToSplashScreen(
                  item.marketplace_merchant.name,
                  item.marketplace_merchant.source_merchant_id,
                  item.marketplace_merchant.url,
                  item.marketplace_merchant.image_url1,
                )}
                tokenMultiplier={merchantMultipliers.get(item.marketplace_merchant.id)}
                aestheticBackgroundImage={item.aesthetic_background_image || ''}
                aestheticCircleImage={item.aesthetic_circle_image || ''}
                name={item.marketplace_merchant.name}
                description={item.description || ''}
                tag={item.tag || ''}
                textColor={textColor}
              />
            ))}
          </ThreeRowHorizontalScrollList>
        }

        {type === "large_square" && merchants && merchants?.length > 0 &&
          <HorizontalScrollList slidesToShow={3} anchored={true}>
            {merchants.map((item) => (
              <HorizontalScrollCardBig
                itemId={item.marketplace_merchant.id} // NOTE: itemId is required for track items
                imageUrl={item.marketplace_merchant.image_url1 || item.marketplace_merchant.image_url2}
                key={item.marketplace_banner_id}
                generateLink={() => redirectToSplashScreen(
                  item.marketplace_merchant.name,
                  item.marketplace_merchant.source_merchant_id,
                  item.marketplace_merchant.url,
                  item.marketplace_merchant.image_url1,
                )}
                tokenMultiplier={merchantMultipliers.get(item.marketplace_merchant.id)}
                aestheticBackgroundImage={item.aesthetic_background_image}
                aestheticCircleImage={item.marketplace_merchant.image_url2}
                name={item.marketplace_merchant.name}
                description={item.description}
                tag={item.tag || ''}
                textColor={textColor}
              />
            ))}
          </HorizontalScrollList>
        }

        {type === "large_rectangle" && merchants && merchants?.length > 0 &&
          <HorizontalScrollList slidesToShow={4} anchored={true}>
            {merchants.map((item) => (
              <HorizontalScrollCard
                itemId={item.marketplace_merchant.id} // NOTE: itemId is required for track items
                imageUrl={item.marketplace_merchant.image_url1 || item.marketplace_merchant.image_url2}
                key={item.marketplace_banner_id}
                generateLink={() => redirectToSplashScreen(
                  item.marketplace_merchant.name,
                  item.marketplace_merchant.source_merchant_id,
                  item.marketplace_merchant.url,
                  item.marketplace_merchant.image_url1,
                )}
                tokenMultiplier={merchantMultipliers.get(item.marketplace_merchant.id)}
                aestheticBackgroundImage={item.aesthetic_background_image}
                aestheticCircleImage={item.aesthetic_circle_image}
                name={item.marketplace_merchant.name}
                description={item.description}
                tag={item.tag}
                textColor={textColor}
              />
            ))}
          </HorizontalScrollList>
        }

        {/* Displays all of the merchants using a react-slick scroll */}
        {type === "rectangle" && merchants && merchants?.length > 0 && // && num_lines == 1
          <HorizontalScrollList>
            {merchants.map((item) => (
              <HorizontalScrollCard
                itemId={item.marketplace_merchant.id} // NOTE: itemId is required for track items
                imageUrl={item.marketplace_merchant.image_url1 || item.marketplace_merchant.image_url2}
                key={item.marketplace_banner_id}
                generateLink={() => redirectToSplashScreen(
                  item.marketplace_merchant.name,
                  item.marketplace_merchant.source_merchant_id,
                  item.marketplace_merchant.url,
                  item.marketplace_merchant.image_url1,
                )}
                tokenMultiplier={merchantMultipliers.get(item.marketplace_merchant.id)}
                aestheticBackgroundImage={item.aesthetic_background_image}
                aestheticCircleImage={item.aesthetic_circle_image}
                name={item.marketplace_merchant.name}
                description={item.description}
                tag={item.tag}
                textColor={textColor}
              />
            ))}
          </HorizontalScrollList>
        }

        {/* Displays all the merchants in a grid */}
        {/* {num_lines > 1 && merchants && merchants?.length > 0 &&
           <div className="grid grid-cols-5 xl:grid-cols-6 gap-6 px-[3.5rem]">
            {merchants.map((item) => (
              <HorizontalCard
                itemId={item.marketplace_merchant.id} // NOTE: itemId is required for track items
                imageUrl={item.marketplace_merchant.image_url1 || item.marketplace_merchant.image_url2}
                key={item.marketplace_banner_id}
                generateLink={() => redirectToSplashScreen(
                  item.marketplace_merchant.name,
                  item.marketplace_merchant.source_merchant_id,
                  item.marketplace_merchant.url,
                  item.marketplace_merchant.image_url1,
                )}
                tokenMultiplier={merchantMultipliers.get(item.marketplace_merchant.id)}
                aestheticBackgroundImage={item.aesthetic_background_image || ''}
                aestheticCircleImage={item.aesthetic_circle_image || ''}
                description={item.description || ''}
                tag={item.tag || ''}
              />
            ))}
          </div>
        } */}
      </div>
      {/* <div className="lg:hidden">
        {merchants && merchants?.length > 0 &&
          <HorizontalScrollList>
            {merchants.map((item) => (
              <HorizontalScrollCard
                itemId={item.marketplace_merchant.id} // NOTE: itemId is required for track items
                imageUrl={item.marketplace_merchant.image_url1 || item.marketplace_merchant.image_url2}
                key={item.marketplace_banner_id}
                generateLink={() => redirectToSplashScreen(
                  item.marketplace_merchant.name,
                  item.marketplace_merchant.source_merchant_id,
                  item.marketplace_merchant.url,
                  item.marketplace_merchant.image_url1,
                )}
                tokenMultiplier={merchantMultipliers.get(item.marketplace_merchant.id)}
                aestheticBackgroundImage={item.aesthetic_background_image || ''}
                aestheticCircleImage={item.aesthetic_circle_image || ''}
                name={item.marketplace_merchant.name}
                description={item.description || ''}
                tag={item.tag || ''}
              />
            ))}
          </HorizontalScrollList>
        }
      </div> */}
    </>
  )
}

export default MerchantsByBannerNew
