import { api } from './api'

export interface IPrizePeriod {
  id: string
  name: string
  start_date: string
  end_date: string
  orders_count: number
  entries_count: number
  created_at: string
  updated_at: string
}

export interface IGetPrizePeriodsResponse {
  prize_periods: IPrizePeriod[]
  records_count: number
  next_page: number
  previous_page: number
}

export const prizePeriodsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPrizePeriods: build.query<
      IGetPrizePeriodsResponse,
      { limit: number; page: number }
    >({
      query: ({ limit, page }) => {
        return {
          url: `/prize-periods?limit=${limit}&page=${page}`,
          method: 'GET'
        }
      },
      providesTags: ['PrizePeriods']
    }),
    getPrizePeriod: build.query<{ prize_period: IPrizePeriod}, string>({
      query: (prizePeriodId) => {
        return {
          url: `/prize-periods/${prizePeriodId}`,
          method: 'GET'
        }
      }
    }),
    addPrizePeriod: build.mutation<
      any,
      { name: string; startDate: string; endDate: string }
    >({
      query: ({ name, startDate, endDate }) => ({
        url: `/prize-periods`,
        method: 'POST',
        body: {
          name,
          start_date: startDate,
          end_date: endDate
        }
      }),
      invalidatesTags: ['PrizePeriods'],
    })
  })
})

export const {
  useGetPrizePeriodsQuery,
  useGetPrizePeriodQuery,
  useAddPrizePeriodMutation
} = prizePeriodsApi
