import { api } from './api'
import { ICustomer } from './customers'
import { IMerchant } from './merchants'
import { IOrder } from './orders'

export type SearchOption = IOrder | ICustomer | IMerchant

export enum SearchObjectKey {
  orders = 'orders',
  customers = 'customers',
  merchants = 'merchants'
}

export interface ISearch {
  [SearchObjectKey.orders]?: IOrder[]
  [SearchObjectKey.customers]?: ICustomer[]
  [SearchObjectKey.merchants]?: IMerchant[]
}

export interface ISearchResponse {
  results: ISearch
}

export const searchApi = api.injectEndpoints({
  endpoints: (build) => ({
    search: build.query<ISearchResponse, string>({
      query: (searchTerm) => {
        return {
          url: `/search?q=${encodeURIComponent(searchTerm)}`,
          method: 'GET'
        }
      }
    })
  })
})

export const { useSearchQuery } = searchApi
