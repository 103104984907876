import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Checkbox from '@mui/material/Checkbox'
import TableSortLabel from '@mui/material/TableSortLabel'
import Box from '@mui/material/Box'
import { Order } from '.'
import { visuallyHidden } from '@mui/utils'

interface IHeadProps {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
  rowCount: number
  headCells: Array<any>
  checkboxSelection: boolean
}

const Head: React.FC<IHeadProps> = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  headCells,
  checkboxSelection
}) => {
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow>
        {checkboxSelection && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts'
              }}
            />
          </TableCell>
        )}

        {headCells.map(
          ({
            field = 'id',
            headerName = 'column',
            disablePadding = false,
            numeric = false,
            sortable = true
          }: any) => {
            const directionArrow = orderBy === field ? order : 'asc'
            const labelOrder =
              order === 'desc' ? 'sorted descending' : 'sorted ascending'
            const renderLabelOrder =
              orderBy === field ? (
                <Box component="span" sx={visuallyHidden}>
                  {labelOrder}
                </Box>
              ) : null

            return (
              <TableCell
                key={field}
                align={numeric ? 'right' : 'left'}
                padding={disablePadding ? 'none' : 'normal'}
                {...(sortable && {
                  sortDirection: orderBy === field ? order : false
                })}
              >
                {sortable ? (
                  <TableSortLabel
                    active={orderBy === field}
                    direction={directionArrow}
                    onClick={createSortHandler(field)}
                  >
                    {headerName}
                    {renderLabelOrder}
                  </TableSortLabel>
                ) : (
                  headerName
                )}
              </TableCell>
            )
          }
        )}
      </TableRow>
    </TableHead>
  )
}
export default Head
