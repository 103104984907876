import React from 'react'
import { ButtonProps } from '@mui/material/Button'
import * as Styles from './styles'

export interface IButtonProps extends ButtonProps {
  ['cc-color']?: 'purple' | 'blue' | 'red' | undefined
  ['cc-width']?: 'full' | undefined
  loading?: boolean | undefined
}

const Button: React.FC<IButtonProps> = (props) => {
  const propsWithoutCustom = { ...props }
  delete propsWithoutCustom.loading

  const isButtonStyleDisabled = props.disabled || props.loading

  return (
    <Styles.Button
      disabled={isButtonStyleDisabled}
      data-testid={props.id || 'button'}
      variant={props.variant || 'contained'}
      {...propsWithoutCustom}
    >
      {props.loading ? 'Loading...' : props.children}
    </Styles.Button>
  )
}

export default Button
