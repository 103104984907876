import React from "react";

import {Card, CardBody, Image} from "@nextui-org/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ITokenMultiplier } from "services/multipliers";

export function HorizontalScrollCard(
  { itemId, 
    imageUrl, 
    generateLink, 
    tokenMultiplier, 
    aestheticBackgroundImage, 
    aestheticCircleImage, 
    description, 
    name, 
    tag,
    type,
    textColor
  }: 
  { itemId: string; 
    imageUrl: string; 
    generateLink: () => void; 
    tokenMultiplier: ITokenMultiplier | undefined; 
    aestheticBackgroundImage?: string | null; 
    aestheticCircleImage?: string | null; 
    name: string;
    description?: string | null; 
    tag?: string | null; 
    type?: "small_square" | "rectangle" | "large_square" | "large_rectangle";
    textColor: string;
  }
) {

  return (
    <div
      role="button"
      style={{
        userSelect: "none",
      }}
      className="mx-2"
    >
      <div className="flex flex-col gap-2">
        <Card 
          isPressable 
          className="text-left border border-gray-200 p-0 shadow-none w-full relative overflow-visible" 
          onPress={generateLink}
        >
          <CardBody style={{padding: '0px'}} className="overflow-visible w-full flex-col items-center">
            {aestheticBackgroundImage ? <LazyLoadImage
              className="object-cover w-full rounded-xl h-20 md:h-32"
              src={aestheticBackgroundImage}
              alt="Card background"
            /> : 
            <LazyLoadImage
              className="object-contain rounded-xl h-20 md:h-32"
              src={imageUrl || '/images/placeholder/product.jpg'}
              alt="Card background"
            />
            }
            
            {aestheticCircleImage && <div className="absolute bottom-2 left-2 bg-gray-200 rounded-full p-1 w-12 h-12">
              <Image
                alt="Overlay icon"
                className="object-fill rounded-full w-full h-full"
                src={aestheticCircleImage}
              />
            </div>}
          </CardBody>
          {tag && <div className="absolute bottom-0 right-[10px] translate-y-1/2 bg-white rounded-full px-4 py-1 shadow-md z-[999]" style={{color: "#37B5FF"}}>
            <span className="text-sm font-medium">{tag || 'Shop'}</span>
            </div>
          }
        </Card>

        <div className="flex flex-col gap-1 mx-2" style={{color: textColor}}>
          <div>
            <p className="text-[10px] lg:text-lg font-semibold">{name}</p>
          </div>
          <div className="flex flex-row md:gap-2 gap-1 text-left">
            <Image
              alt="Card background"
            className="object-contain rounded-xl w-full h-4 w-4"
            src={"/images/waveemblem.svg"}
          />
          <p className="text-sm">{tokenMultiplier?.num_tokens ?? 10} Tokens per $1 Spent</p>
          </div>  
          <div>
            <p className="text-[10px] lg:text-lg font-semibold">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
