export const formatDate = (date: Date) => {
  if (isToday(date)) {
    const shortDate = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'UTC'
    }).format(date)

    return `Today at ${shortDate}`
  } else {
    return new Intl.DateTimeFormat('en-US', {
      dateStyle: 'medium',
      timeZone: 'UTC'
    }).format(date)
  }
}

export const formatDateTime = (date: Date) => {
  if (isToday(date)) {
    const shortDate = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'UTC'
    }).format(date)

    return `Today at ${shortDate}`
  } else {
    return new Intl.DateTimeFormat('en-US', {
      dateStyle: 'medium',
      timeStyle: 'short',
      timeZone: 'UTC'
    }).format(date)
  }
}

const isToday = (date: Date) => {
  const today = new Date()

  return today.toDateString() === date.toDateString()
}

export const getFirstDayWeek = () => {
  const curr = new Date()
  const firstDate = new Date(curr.setDate(curr.getDate() - curr.getDay()))
  const formatString = firstDate.toISOString().substring(0, 10)
  return { formatDate, formatString }
}

export const getLastDayWeek = () => {
  const curr = new Date()
  const lastDate = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6))
  const formatString = lastDate.toISOString().substring(0, 10)
  return { formatDate, formatString }
}

export const getUTCDate = (date: Date) => {
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds()))
}

export const getTimeZoneOffset = (timeZone: string) => {
  const timeZoneName = Intl.DateTimeFormat("ia", {
    timeZoneName: "shortOffset",
    timeZone,
  })
    .formatToParts()
    .find((i) => i.type === "timeZoneName")!.value;
  const offset = timeZoneName.slice(3);
  if (!offset) return "00:00";

  const matchData = offset.match(/([+-])(\d+)(?::(\d+))?/);
  if (!matchData) return "00:00";

  const [, sign, hour, minute] = matchData;

  return `${sign}${hour.padStart(2, "0")}:${minute ? minute.padStart(2, "0") : "00"}`;
};
