import tw from 'twin.macro'
import { styled } from '@mui/material/styles'
import TextFieldMUI from '@mui/material/TextField'
import { ITextField } from '.'

export const TextField = styled(TextFieldMUI)`
  & {
    ${tw`w-32`}
    ${(props: ITextField) => props['cc-width'] === 'full' && tw`w-full`}
    ${(props: ITextField) => props['cc-width'] === 'half' && tw`w-1/2`}
  }

  & input {
    ${(props: ITextField) =>
      props['cc-adornment-border'] === 'start' &&
      tw`border-l border-solid border-gray-300 pl-2`}

    ${(props: ITextField) =>
      props['cc-adornment-border'] === 'end' &&
      tw`border-r border-solid border-gray-300 pl-2`}

    ${(props: ITextField) => props['cc-width'] === 'half' && tw`w-1/2`}
  }

  & .MuiInputAdornment-root p {
    ${(props: ITextField) =>
      props['cc-adornment-border'] === 'start' && tw`pl-1 pr-2`}
    ${(props: ITextField) => props['cc-adornment-border'] === 'end' && tw`p-1`}
  }

  & .MuiInputBase-root {
    ${tw`rounded-lg pr-2`}
  }

  & label.Mui-focused {
    ${tw`text-primary-600`}
  }

  & .MuiInputBase-root.Mui-focused fieldset {
    ${tw`border-primary-600`}
  }

  & .MuiInputBase-root.Mui-disabled {
    ${tw`bg-gray-50 border-gray-300`}
  }
`
