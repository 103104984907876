import React, { ChangeEvent, useEffect, useState } from 'react'
import { styled } from "@mui/material";
import tw from "twin.macro";
import { Image, Skeleton, Card, CardHeader, CardFooter, Button, Link, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from '@nextui-org/react';
import { IoMdInformationCircleOutline } from "react-icons/io";
import { Breadcrumbs, BreadcrumbItem } from '@nextui-org/react';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cookies from 'universal-cookie'
import { useGetBannersFromCategoryQuery } from 'services/marketplace';


import MerchantsByBannerNew from 'components/NewComponents/MerchantsByBannerNew';
import useFilterWall from 'hooks/useFilterWall';
import { useParams } from 'react-router-dom';
import PrizesBannersSlider from 'components/NewComponents/PrizesBannersSlider';
import { useGetPrizesQuery } from 'services/prizes';
import InstallExtensionBanner from 'components/NewComponents/InstallExtensionBanner';
import { amplitudeSetUserId, amplitudeLogEvent } from '../../amplitudeClient';
import { useGetCustomerQuery } from 'services/customers';

import { ChromeExtensionOnboardingModal } from './ChromeExtensionSplashScreen';
import HorizontalScrollList, { LeftArrow, RightArrow } from 'components/NewComponents/HorizontalScrollList';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
      
const elemPrefix = "test";
const getId = (index: number) => `${elemPrefix}${index}`;

const productImages = [
  { title: "Stanley", subtitle: "The Quencher H2.0 FlowState™ Tumbler", cash_back: "5", image_url: "https://www.stanley1913.com/cdn/shop/files/B2B_Web_PNG-TheQuencherH2.OFlowStateTumbler40OZ-Azure-Front.png?v=1719332242&width=1920", url: "https://www.stanley1913.com/products/adventure-quencher-travel-tumbler-40-oz?variant=44559881109631"},
  { title: "Ninja", subtitle: "Ninja Ice Cream Maker", cash_back: "3", image_url: "https://c.shld.net/rpx/i/s/pi/mp/1363/prod_28130775603?src=http%3A%2F%2Fsite.unbeatablesale.com%2FACHR180933.JPG&d=1183f5450b975f10038a9ca98c7d70f359c5acfa", url: "https://www.sears.com/ninja-6077372-16-x-7-x-12-in-creami-0.5-qt-ice-cream-maker-44-silver/p-A124434163"},
  {title: "Dyson", 
  subtitle: "Dyson V15 Detect Absolute", 
  image_url: "https://dyson-h.assetsadobe2.com/is/image/content/dam/dyson/images/products/hero/447294-01.png?$responsive$&cropPathE=desktop&fit=stretch,1&wid=1920",
  url: "https://www.dyson.com/vacuum-cleaners/cordless/v15/detect-absolute-hepa-gold"
  },
  {title: "Yeti", 
  subtitle: "Yeti Tundra Cooler", 
  image_url: "https://cdn-tp3.mozu.com/24645-37138/cms/37138/files/45bebd2b-11d9-47ca-81d1-5c93c2174fee?quality=60&max=480&_mzcb=_1703879152693",
  url: "https://www.acehardware.com/departments/outdoor-living/coolers/hard-sided-coolers/8399107"
  },
  {title: "Emerson", 
  subtitle: "Emerson Sensi Black Smart Thermostat", 
  image_url: "https://mobileimages.lowes.com/productimages/34f64912-b735-4e95-a51b-899435a64842/62638479.png?size=pdhism",
  url: "https://www.lowes.com/pd/Emerson-Sensi-Black-Smart-Thermostat-with-Wi-Fi-Compatibility/5014020755"
  },
  {title: "Solo Stove", 
  subtitle: "Solo Stove Bonfire", 
  image_url: "https://cdn-tp3.mozu.com/24645-37138/cms/37138/files/d5775d2a-b91f-46c1-b54d-35d7b1069188?quality=60&max=480&_mzcb=_1703879152693",
  url: "https://www.acehardware.com/departments/outdoor-living/outdoor-heating/fire-pits/8087600?x429=true&gad_source=1&gclid=Cj0KCQjwwae1BhC_ARIsAK4Jfrxj0IxhwdGXV1uISgFFpDGbdfPjlErCFewSMsSW-NKY0Y8iKk9B6t4aAuGWEALw_wcB&gclsrc=aw.ds"
  },
  {title: "Sun Bum", 
  subtitle: "Travel Size Sunscreen Lotion", 
  image_url: "https://media.ulta.com/i/ulta/2303044?w=1080&h=1080&fmt=auto",
  url: "https://www.ulta.com/p/travel-size-sunscreen-lotion-spf-30-pimprod2014602?sku=2303044"
  },
  {title: "Nike", 
  subtitle: "Nike Dri-FIT Club Structured Swoosh Cap", 
  image_url: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/a7adb615-98ec-4d11-ac40-052058b24f0c/U+NK+DF+CLUB+CAP+S+CB+P.png",
  url: "https://www.nike.com/t/dri-fit-club-structured-swoosh-cap-kxvJ3j/FB5625-010"
  },
  {title: "L.L. Bean", 
  subtitle: "Boat and Tote®, Zip-Top", 
  image_url: "https://cdni.llbean.net/is/image/wim/112644_195_41?hei=1092&wid=950&resMode=sharp2&defaultImage=llbprod/112644_195_41",
  url: "https://www.llbean.com/llb/shop/37037?page=boat-and-tote-bag-zip-top&bc=509870&feat=509870-GN0&csp=f&attrValue_0=195&pos=1"
  },
  {title: "Bose", 
  subtitle: "Bose - SoundLink Revolve II", 
  image_url: "https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6452/6452107_sd.jpg;maxHeight=640;maxWidth=550",
  url: "https://www.bestbuy.com/site/bose-soundlink-revolve-ii-portable-bluetooth-speaker-luxe-silver/6452107.p?skuId=6452107&utm_source=feed&ref=212&loc=BoseSpeakers&gad_source=1&gclid=Cj0KCQjwwae1BhC_ARIsAK4Jfrzc-Aba0eFMAgWp7sManV8InBl42XNOhBMAPfuqrSChPwUa-PfNpCkaAiKbEALw_wcB&gclsrc=aw.ds"
  },
].map((item, index) => ({ ...item, id: getId(index) }));

// { title: "ZEDD", subtitle: "See Zedd Live", image_url: "https://dancingastronaut.com/wp-content/uploads/2021/12/257521232_3019884598339887_7127805698908392178_n-e1639506841522.jpg" },
//  { title: "Dubai Tours", subtitle: "Go on a trip to Dubai", image_url: "https://media.tacdn.com/media/attractions-splice-spp-674x446/0e/f2/49/44.jpg" },
// const generateLink = (merchantId: string, merchantUrl: string) => {
//   const DeviceID = localStorage.getItem('DeviceID')
//   const url = merchantUrl
//   const urlObject = new URL(url)
//   const trackingCode = localStorage.getItem('customerId')

//   const cookies = new Cookies(null, { path: '/' })
//   cookies.set(urlObject.hostname, true, {
//     expires: new Date(Date.now() + 1000 * 60 * 60 * 24)
//   })

//   const generatedLink = `https://wild.link/e?c=${merchantId}&d=${DeviceID}&url=${url}&tc=${trackingCode}`
//   console.log(generateLink)
//   window.open(generatedLink, '_blank')
// }

const MerchantsPageNew = () => {
  const navigate = useNavigate();

  const [bigItems, setBigItems] = React.useState(productImages);
  const [currentSubcategories, setCurrentSubcategories] = useState<any[]>([]);

  // const [extensionInstalled, setExtensionInstalled] = useState(false);
  const { data: prizesData, isLoading: isLoadingPrizes } = useGetPrizesQuery({
    limit: 1000,
    page: 1
  })

  const { categoryId = '' } = useParams()
  const { categories, setInputSearch } = useFilterWall()
  const category = categories?.marketplace_categories.find((category) => category.id === categoryId);

  const result: any[] = [];
  const findCategoryAndChildren = (categoryList: any[], id: string) => {
    for (const category of categoryList) {
      if (category.id === id) {
        // result.push(category);
        for (const child of categoryList) {
          if (child.parentCategory && child.parentCategory.id === id) {
            result.push(child);
          }
        }
        break;
      }
    }
  };

  useEffect(() => {
    findCategoryAndChildren(categories?.marketplace_categories || [], categoryId);
    setCurrentSubcategories(result);
  }, [categories?.marketplace_categories, categoryId]);

  
  const { data: banners, isLoading: isLoadingBanners, isFetching } = useGetBannersFromCategoryQuery({
    bannerId: categoryId || '',
  })

  console.log("BANNERS", banners)

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Check screen size on initial render
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const customerId: any = localStorage.getItem('customerId');
  const customerDetails = useGetCustomerQuery(customerId);
  
  const redirectToSplashScreen = (merchant_name: string, merchant_id: string, merchant_url: string, image_url: string) => {
    const DeviceID = localStorage.getItem('DeviceID')
    const url = merchant_url
    const urlObject = new URL(url)
    const trackingCode = localStorage.getItem('customerId')
  
    const cookies = new Cookies(null, { path: '/' })
    cookies.set(urlObject.hostname, true, {
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24)
    })
  
    const customerName = customerDetails?.data?.customer?.email || 'Unknown';
    amplitudeSetUserId(customerName);
    amplitudeLogEvent({
      event_type: `Merchant Visited`,
      event_properties: {
        merchant_id: merchant_id,
        merchant_url: merchant_url,
        merchant_name: merchant_name,
        device_id: DeviceID,
        customer_id: trackingCode,
        customer_name: customerName,
        timestamp: new Date().toISOString(),
      }
    });
  
    window.open(`/m?merchant_name=${merchant_name}&merchant_id=${merchant_id}&merchant_url=${merchant_url}&image_url=${image_url}`, '_blank');
  }

  const {isOpen, onOpen, onOpenChange} = useDisclosure();

  return (
    isLoadingBanners ? 
      <div className="min-h-screen">
        <Skeleton className="mx-10 my-5 rounded-lg">
          <div className="h-96">
            Loading banners
          </div>
        </Skeleton>

        <Skeleton className="mx-10 my-5 rounded-lg">
          <div className="h-96">
            Loading banners
          </div>
        </Skeleton>
      </div> 
      :
      <>
      <div className="flex flex-col w-full">
        {
          currentSubcategories.length <= 0 ?
            <>
              <PrizesBannersSlider prizesData={prizesData} isLoading={isLoadingPrizes} button_text="View Prizes" button_href="/dashboard" />
              <InstallExtensionBanner.Main />
            </>
            :
            <div className="mt-8">
              <div className="flex flex-col w-full items-center mb-6">
                <div className="px-2 lg:px-28 w-full max-w-[1700px]">
                  <Breadcrumbs separator="/">
                    <BreadcrumbItem><Link href={`/`} className="text-base lg:text-base font-semibold hover:underline hover:text-[#37B5FF]">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link className="text-base lg:text-base font-semibold hover:underline text-[#37B5FF]">{category?.category_name}</Link></BreadcrumbItem>  
                  </Breadcrumbs>
                </div>
              </div>

              <div className="flex flex-col w-full items-center mt-10">
                <div className=" px-2 lg:px-28 md:gap-16 w-full max-w-[1700px]">
                  <HorizontalScrollList slidesToShow={6}>
                      {currentSubcategories.map((category: any) => (
                      <div key={category.id} className="px-2">
                        <Card 
                          isPressable 
                          className="w-full text-left p-0 shadow-none rounded-none"
                          onPress={() => navigate(`/merchants/${categoryId}/${category.id}`)}
                          style={{color: category.text_color || 'black'}}
                      > 
                          <div className="">
                            <LazyLoadImage
                              className="w-full h-full object-contain h-32"
                              src={category.image_url}
                            />
                          </div>
                          <div className="mt-1 text-base md:text-sm font-semibold w-full flex flex-row items-start">
                            <p>{category.category_name}</p>
                          </div>
                        </Card>
                        </div>
                      ))}
                   </HorizontalScrollList>
                </div>
              </div>
            </div>
        }

        <div className="h-8 lg:h-8">
          {categoryId === 'fcb47d06-0bd0-40b7-9ac0-37d12eb83d45' && (
            <div className="flex flex-row justify-end w-full p-2 lg:p-4">
              <div className="flex flex-row gap-2 p-3 rounded-xl w-fit hover:bg-primary-400/[0.2] cursor-pointer" onClick={onOpen}>
                <p className="text-xs lg:text-sm text-gray-600">How we make money</p>
                <IoMdInformationCircleOutline className="text-gray-600" size={20} />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col w-full items-center">
        <div className="flex flex-col gap-6 px-2 lg:px-28 md:gap-6 w-full max-w-[1700px]">
            {banners?.marketplace_banners.slice().sort((a, b) => a.order - b.order).map((banner) => (
              <div 
                key={banner.id} 
                className={`relative -mx-2 lg:-mx-28 py-4 ${banner.banner_background_color ? 'lg:py-16' : 'lg:py-8'} px-2 lg:px-28`}
                style={{backgroundColor: banner.banner_background_color || ''}}
              >
                {/* Background element */}
                <div 
                  className="absolute inset-0 -z-10 w-screen left-1/2 -translate-x-1/2" 
                  style={{backgroundColor: banner.banner_background_color || ''}}
                />

                <div className="text-xl px-2 lg:px-[3.5rem] md:text-2xl font-semibold flex flex-row gap-2 items-center">
                  <p style={{color: banner.text_color || 'black'}}>{banner.banner_name}</p>
                </div>
                <div className="mt-4">
                  <MerchantsByBannerNew
                    bannerId={banner.id}
                    num_lines={banner.num_lines}
                    redirectToSplashScreen={redirectToSplashScreen}
                    type={banner.image_size || 'rectangle'}
                    textColor={banner.text_color || 'black'}
                  />
                </div>
              </div>
            ))}
            
            {/* Hot Products (Example) */}
            {/* <div>
              <div className="text-xl md:text-2xl font-semibold flex flex-row gap-2 items-center">
                  <p>Hot Products</p>
              </div>
              <div className="hidden md:block mt-4">
                <ScrollMenu
                    LeftArrow={LeftArrow}
                    RightArrow={RightArrow}
                    // onWheel={onWheel}
                  >
                    {bigItems.map((item) => (
                      <HorizontalScrollCardBig
                        title={item.title}
                        subTitle={item.subtitle}
                        itemId={item.id} // NOTE: itemId is required for track items
                        imageUrl={item.image_url}
                        cashBack={""}
                        url={item.url}
                        key={item.id}
                      />
                    ))}
              </ScrollMenu>
              </div>
              <div className="md:hidden block mt-4">
                <ScrollMenu
                  >
                    {bigItems.map((item) => (
                      <HorizontalScrollCardBig
                        title={item.title}
                        subTitle={item.subtitle}
                        itemId={item.id} // NOTE: itemId is required for track items
                        imageUrl={item.image_url}
                        cashBack={""}
                        url={item.url}
                        key={item.id}
                      />
                    ))}
              </ScrollMenu>
              </div>
            </div> */}
          
          </div>
      </div>
      <MakeMoneyModal isOpen={isOpen} onOpenChange={onOpenChange} />
      </>
  )
}

export default MerchantsPageNew;

const MakeMoneyModal = ({ isOpen, onOpenChange }: { isOpen: boolean, onOpenChange: () => void }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onOpenChange}
      scrollBehavior='inside'
      size='2xl'
    >
      <ModalContent className='p-8'>
        <ModalHeader>
          <h2 className='text-xl font-semibold text-left w-full'>How do we make money?</h2>
        </ModalHeader>
        <ModalBody className="text-sm">
          <p>
            {`
             Our business is based on a simple idea: When you follow our links to visit a store or click activate on our extension, that store pays us a commission on whatever you buy during your visit. We then use that commission to give away awesome prizes to our luckiest users (hopefully you!).
            `}
          </p>
          <p>
            {`The more you shop with us, the bigger the prizes we can give away. But don’t worry, even if you don’t have anything you want to buy right now, you can still earn tokens by claiming your free bonus every day.`}
          </p>
          <p>
            {`You can also earn tokens without making a purchase by sending us a letter that follows the instructions on our Rules and Terms page. THERE IS NO PURCHASE NECESSARY TO ENTER. Purchasing will not increase your chances of winning.`}
          </p>
          <p>
            {`And if you download our chrome extension, you’ll receive an instant token bonus as a reward.`}
          </p>
          <p>
            <p>Have a prize that you want to win? Send us an email at <span className="text-primary-400">support@joinwave.com</span> and {"we’ll"} look into giving it away next!</p>
          </p>
        </ModalBody>
        <ModalFooter className='flex flex-row justify-center mt-6 py-0'>
          <Button onClick={onOpenChange} size="lg" className="text-2xl bg-primary-400 text-white px-8 rounded-lg font-semibold">
            Got it
          </Button>
      </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

// This will need to be refactored with new API route
// const storeData = [
//   { name: "Walmart", cash_back: "5", image_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/Walmart_logo.svg/2560px-Walmart_logo.svg.png" },
//   { name: "Target", cash_back: "3", image_url: "https://purepng.com/public/uploads/large/purepng.com-target-logologobrand-logoiconslogos-251519939151pkaty.png" },
//   { name: "Apple", cash_back: "2", image_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Apple_logo_grey.svg/1724px-Apple_logo_grey.svg.png" },
//   { name: "Samsung", cash_back: "4", image_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Samsung_wordmark.svg/1200px-Samsung_wordmark.svg.png" },
//   { name: "Prada", cash_back: "7", image_url: "https://upload.wikimedia.org/wikipedia/commons/1/19/Prada.png" },
//   { name: "Nike", cash_back: "6", image_url: "https://i.pinimg.com/originals/20/60/2d/20602d43cc993811e5a6bd1886af4f33.png" },
//   { name: "Lululemon", cash_back: "5", image_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/22/Lululemon_Athletica_logo.svg/1024px-Lululemon_Athletica_logo.svg.png" },
//   { name: "Costco", cash_back: "2", image_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Costco_Wholesale_logo_2010-10-26.svg/2560px-Costco_Wholesale_logo_2010-10-26.svg.png" },
//   { name: "Playstation", cash_back: "3", image_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/PlayStation_logo.svg/1280px-PlayStation_logo.svg.png" },
//   { name: "Microsoft", cash_back: "3", image_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Microsoft_logo_%282012%29.svg/1024px-Microsoft_logo_%282012%29.svg.png" },
//   { name: "Nintendo", cash_back: "4", image_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Nintendo_red_logo.svg/2560px-Nintendo_red_logo.svg.png" }
// ].map((item, index) => ({ ...item, id: getId(index) }));

{/* Example */}
{/* <div className="overscroll-none">
  <div className="text-xl md:text-2xl font-semibold flex flex-row gap-2 items-center">
      <p>Other stores {"(Example)"}</p>
  </div>
  <div className="hidden md:block mt-4">
    <ScrollMenu
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        // onWheel={onWheel}
      >
        {items.map((item) => (
          <HorizontalScrollCard
            generateLink={() => console.log("clicked")}
            itemId={item.id} // NOTE: itemId is required for track items
            imageUrl={item.image_url}
            key={item.id}
          />
        ))}
    </ScrollMenu>
  </div>
  <div className="md:hidden mt-4">
    <ScrollMenu
      >
        {items.map((item) => (
          <HorizontalScrollCard
            generateLink={() => console.log("clicked")}
            itemId={item.id} // NOTE: itemId is required for track items
            imageUrl={item.image_url}
            key={item.id}
          />
        ))}
    </ScrollMenu>
  </div>
</div> */}