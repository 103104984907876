import { FC, useState, useEffect, useRef } from 'react' // Import useEffect and useRef
import SearchInput from 'components/ui/Search'
import * as Styles from 'layouts/PrivateLayout.styles'
import { useSearchQuery, ISearch, SearchOption } from 'services/search'

interface ISearchContainerProps {
  action?: React.ReactNode
}

const SearchContainer: FC<ISearchContainerProps> = ({ action }) => {
  const [inputValue, setInputValue] = useState('')
  const { data: searchResultsData, isFetching } = useSearchQuery(inputValue, {
    skip: !inputValue
  })

  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const debounceSetInputValue = (
    newInputValue: string,
    delay: number = 500
  ) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current)
    }

    debounceTimeoutRef.current = setTimeout(() => {
      setInputValue(newInputValue)
    }, delay)
  }

  const handleTextInputChange = (_: any, newInputValue: string) => {
    debounceSetInputValue(newInputValue)
  }

  const getOptions = () => {
    const results: ISearch | undefined = searchResultsData?.results

    if (!results) {
      return []
    }

    const entityKeys = Object.keys(results)

    const options: any = []
    entityKeys.forEach((key) => {
      //@ts-ignore
      const mappedResults = results[key].map((result: SearchOption) => ({
        ...result,
        type: key
      }))
      options.push(...mappedResults)
    })

    return options
  }

  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current)
      }
    }
  }, [])

  return (
    <Styles.SearchContainer>
      <SearchInput
        placeholder="Search email, Customer Name, Store Name"
        optionLabel="name"
        searchData={inputValue}
        resultData={getOptions()}
        isLoading={isFetching}
        onTextInput={handleTextInputChange}
      />
      {action}
    </Styles.SearchContainer>
  )
}

export default SearchContainer
