import SignupModule from 'modules/Signup'
import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import { useNavigate, useLocation } from 'react-router-dom';

const Signup = () => {
  const authToken = useAppSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const location = useLocation()
  useEffect(() => {
    if (authToken) {
      navigate(`/merchants/fcb47d06-0bd0-40b7-9ac0-37d12eb83d45${location.search}`);
    }
  }, [authToken, navigate, location.search]);

  return (
    <div data-testid="page-signup">
      <SignupModule />
    </div>
  )
}

export default Signup
