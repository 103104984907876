import { FC, ReactEventHandler } from 'react'
import { SearchOutlined } from '@mui/icons-material'
import { TextFieldProps, Chip } from '@mui/material'
import TextField from '../TextField'
import { Link } from 'react-router-dom'
import * as Styles from './styles'

export type TTSearchFieldProps = TextFieldProps & {
  placeholder: string
  optionLabel: string
  searchData?: string
  resultData: any
  isLoading: boolean
  onTextInput?: (props: any, e: any) => void
  onResultSelect?: ReactEventHandler<HTMLDivElement> | undefined
}
const SearchField: FC<TTSearchFieldProps> = ({
  placeholder = '',
  resultData = [],
  isLoading,
  onTextInput
}) => {
  return (
    <Styles.SearchBar
      data-testid="search-field"
      id="global-search-field"
      popupIcon={''}
      clearIcon={''}
      loading={isLoading}
      noOptionsText={'No result Found'}
      freeSolo
      autoComplete
      filterOptions={(x) => x}
      options={resultData}
      onInputChange={onTextInput}
      getOptionLabel={(option: any) => option.id}
      renderOption={(props, option: any) => {
        if (option.type === 'orders') {
          return (
            <Link key={option?.id} to={`/admin/orders/${option?.id}`}>
              <li className="flex gap-6 p-5 border-b hover:bg-gray-100">
                <span>{option?.order_number}</span>
                <Chip label={option.type} variant="outlined" size="small" />
              </li>
            </Link>
          )
        } else if (option.type === 'customers') {
          return (
            <Link key={option?.id} to={`/admin/customers/${option?.id}`}>
              <li className="flex gap-6 p-5 border-b hover:bg-gray-100">
                <span>{option?.name}</span>
                <Chip label={option.type} variant="outlined" size="small" />
              </li>
            </Link>
          )
        } else if (option.type === 'merchants') {
          return (
            <Link key={option?.id} to={`/admin/merchants/${option?.id}`}>
              <li className="flex gap-6 p-5 border-b hover:bg-gray-100">
                <span>{option?.name}</span>
                <Chip label={option.type} variant="outlined" size="small" />
              </li>
            </Link>
          )
        }
      }}
      renderInput={(params) => (
        <TextField
          placeholder={placeholder}
          cc-width={'full'}
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: <SearchOutlined color="disabled" />
          }}
        />
      )}
    />
  )
}
export default SearchField
