import { FC } from 'react'
import Button from 'components/ui/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import TextField from 'components/ui/TextField'
import { IFormProps } from './interfaces'

import * as Styles from './Forms.styles'

interface IForgotPasswordFormProps extends IFormProps {}

const ForgotPasswordForm: FC<IForgotPasswordFormProps> = ({
  formId,
  title,
  buttonText,
  isLoading,
  handleBackButton,
  handleSubmit
}) => {
  return (
    <div data-testid={formId}>
      <Styles.Icon src="/images/key-icon.png" alt="forgot password" />
      <Styles.TitleSpace data-testid={`${formId}-title`}>
        {title}
      </Styles.TitleSpace>
      <Styles.Description>
        <p className="mb-10">No worries, we’ll send you reset instructions.</p>
      </Styles.Description>

      <Styles.Form onSubmit={handleSubmit}>
        <TextField
          id="email"
          label="Email"
          type={'email'}
          cc-width="full"
          required
        />
        <Button
          data-testid={`${formId}-submit-button`}
          type="submit"
          cc-color="purple"
          cc-width="full"
          loading={isLoading}
        >
          {buttonText}
        </Button>
      </Styles.Form>
      <Styles.BackButton>
        <Button
          data-testid={`${formId}-back-button`}
          onClick={handleBackButton}
          variant="text"
          startIcon={<ArrowBackIcon />}
        >
          Back to log in
        </Button>
      </Styles.BackButton>
    </div>
  )
}

export default ForgotPasswordForm
