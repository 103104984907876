import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'
import { RootState } from 'store'
import { logout, setToken } from 'store/authSlice'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { enqueueSnackbar } from 'notistack'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_PRIZEDRAW_API,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token
    const user = (getState() as RootState).auth.user
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
      headers.set('cc-user-type', user?.type || '')
      headers.set('cc-user-id', user?.id || '')
    }
    
    return headers
  }
})

let isRefreshing = false;
let waitingRequests: {
  resolve: (value: QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>) => void;
  reject: (value: QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>) => void;
  args: Parameters<typeof baseQuery>;
}[] = [];

const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  const refreshToken = localStorage.getItem('refresh_token');
  const user = JSON.parse(localStorage.getItem('user') as string);

  if (result.error && result.error.status === 401 && refreshToken && user && !isRefreshing) {
    isRefreshing = true;
    // try to get a new token
    const refreshResult = await baseQuery({
      url: '/authentication/refresh-token',
      method: 'POST',
      body: {
        refresh_token: localStorage.getItem('refresh_token'),
        username: user.email
      }
    }, api, extraOptions) as QueryReturnValue<{token: string}, FetchBaseQueryError, FetchBaseQueryMeta>
  
    if (refreshResult.data) {
      // store the new token 
      api.dispatch(setToken(refreshResult.data.token))

      // retry the initial query
      result = await baseQuery(args, api, extraOptions)

      // calling waiting requests
      for (const w of waitingRequests) {
        const res = await baseQuery(...w.args);
        if(res.data) {
          w.resolve(res);
        } else {
          w.reject(res);
        }
      }

    } else {
      enqueueSnackbar('Session expired. Please log in again.', {
        variant: 'error',
      });
      api.dispatch(logout());

      // if refresh token fails, reject all waiting requests
      for (const w of waitingRequests) {
        w.reject(refreshResult);
      }
    }
    waitingRequests = [];
    isRefreshing = false;
  }

  // if already refreshing token, adding request info to a list that will be called after token is refreshed
  if(isRefreshing) {
    return new Promise((resolve, reject) => {
      waitingRequests.push({ resolve, reject, args: [args, api, extraOptions] });
    })
  }

  return result;
}

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReAuth,
  keepUnusedDataFor: 0,
  tagTypes: [
    'Customers',
    'Entries',
    'PrizePeriods',
    'Prizes',
    'Applications',
    'Affiliates',
    'AffiliateDetail',
    'Orders',
    'OrdersAffiliate',
    'OrdersMerchant',
    'Products',
    'ProductDetail',
    'Settings',
    'SettingsValues',
    'Tiers',
    'DashboardTabs',
    'DashboardGraph',
    'DashboardAffiliateTabs',
    'DashboardAffiliateGraph',
    'AffiliateUserTabs',
    'AffiliateUserGraph',
    'MerchantInfo',
    'UserInfo',
    'MarketplaceMerchants',
    'MarketplaceCategories',
    'MarketplaceCategoryDetail',
    'MarketplaceBanners',
    'MarketplaceBannerDetail',
    'MarketplaceBannerMerchants',
    'BannerImageOptions'
  ],
  endpoints: () => ({})
})
