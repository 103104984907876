import { FC, ReactNode } from 'react'
import Button from 'components/ui/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useAppSelector } from 'store/hooks'
import { useResendConfirmationMutation } from 'services/auth'
import { useNotification } from 'hooks/useNotification'
import { trimString } from 'utils/strings'
import * as Styles from './Forms.styles'

interface ICheckYourEmail {
  formId: string
  title: ReactNode | string
  children: React.ReactNode
  setVerificationCode?: (code: string) => void
  handleBackButton?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const CheckYourEmail: FC<ICheckYourEmail> = ({
  formId,
  title,
  children,
  handleBackButton,
  setVerificationCode
}) => {
  const { sendSuccessNotification } = useNotification()
  const email = useAppSelector((state) => state.formSteps.email)
  const [resendConfirmation] = useResendConfirmationMutation()

  const handleResendCode = async () => {
    const response: any = await resendConfirmation({ email: trimString(email) })
    if (response?.data) {
      sendSuccessNotification(
        `Your code has been successfully sent to ${email}.`
      )
      if (setVerificationCode) {
        setVerificationCode('')
      }
    }
  }

  return (
    <div data-testid={formId}>
      <Styles.Icon src="/images/email-icon.png" alt="email verification" />
      <Styles.TitleSpace data-testid={`${formId}-title`}>
        {title}
      </Styles.TitleSpace>
      <Styles.Description>
        <p className="mb-4">We sent a confirmation code to</p>
        <p className="mb-8">{email}</p>
      </Styles.Description>

      {children}

      <Styles.Footer>
        Didn't receive the code?{' '}
        <Styles.FooterLink onClick={handleResendCode}>
          Click to resend
        </Styles.FooterLink>
      </Styles.Footer>
      <Styles.BackButton>
        <Button
          data-testid={`${formId}-back-button`}
          onClick={handleBackButton}
          variant="text"
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
      </Styles.BackButton>
    </div>
  )
}

export default CheckYourEmail
