import {
    Footer,
} from "flowbite-react";

import { Image } from "@nextui-org/react";
export default function PrizedrawFooter() {
return (
    <Footer className="bg-[#37B5FF] rounded-none mt-24" container>
    <div className="w-full">
        <div className="flex flex-row w-full items-center justify-between">
            <div className="flex flex-col items-center h-full">
                <Image
                className="h-6 md:h-12"
                src="/images/logo-white.png"
                alt="Prizedraw Logo"
                />
            </div>
            <div className="flex flex-row gap-2 md:gap-10 justify-center text-sm md:text-base">
                <a href="https://joinwave.com/rules" target="_blank"><p className="text-black">Rules</p></a>
                <a href="https://joinwave.com/privacy" target="_blank"><p className="text-black">Privacy</p></a>
                <a href="/help"><p className="text-black">Help</p></a>
            </div>
        </div>
    </div>
    </Footer>
);
}
  