import { addHours, isAfter, isWithinInterval } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useGetCustomerDailyStreakBonusQuery } from "services/customers";

export default function useGetCustomerDailyStreakBonusData(customerId: string, nextClaimDateSubscriber?: (nextTime: string) => any) {
  const { data: customerDailyStreakData, isLoading: isLoadingCustomerDailyStreak, refetch, isFetching } = useGetCustomerDailyStreakBonusQuery(customerId);
  const [currentDay, setCurrentDay] = useState(1);
  const [status, setStatus] = useState({
    claimable: false,
    claimed: false,
    missed: false,
  });
  const currentStreakTotalEarned = customerDailyStreakData?.current_streak_total_earned;
  const lastClaimDate = customerDailyStreakData?.last_reward_claimed_at;
  const currentStreak = useMemo(() => customerDailyStreakData?.reward_streak_count!, [customerDailyStreakData?.reward_streak_count]);

  useEffect(() => {
    const now = new Date();

    // If it's loading show the default values assigned to the states already 
    if(isLoadingCustomerDailyStreak) {
      return;
    }

    if(!lastClaimDate) {
      setStatus({ claimable: true, claimed: false, missed: false });
      return;
    }

    const lastClaimedDatePlus24 = addHours(lastClaimDate, 24);
    const lastClaimedDatePlus48 = addHours(lastClaimDate, 48);
    const nowIsAfterLastClaimedDatePlus48 = isAfter(now, lastClaimedDatePlus48);

    let localCurrentDay = 1;

    const claimed = isWithinInterval(now, {
      start: lastClaimDate,
      end: lastClaimedDatePlus24,
    });

    const missed = nowIsAfterLastClaimedDatePlus48;

    const isWithinStreakWindow = isWithinInterval(now, {
      start: addHours(lastClaimDate, 24),
      end: addHours(lastClaimDate, 48),
    });

    const claimable = isWithinStreakWindow || missed;
    
    let interval: NodeJS.Timer;
    // If missed streak will reset, using default state
    if (claimable && !missed) {
      localCurrentDay = currentStreak + 1;

      // Counting down the time until end of the streak window
      interval = setInterval(() => {
        const now = new Date();
        const missedWindow = isAfter(now, addHours(lastClaimDate!, 48));

        if(missedWindow) {
          clearInterval(interval);
          setStatus({ claimable: true, claimed: false, missed: true });
          setCurrentDay(1);

          // Refetching data to update the tokens map as the streak has been reset
          refetch();
        }
      }, 1000);
    } else if (claimed) {
      localCurrentDay = currentStreak;

      // Counting down the time until the next claimable day
      interval = setInterval(() => {
        const now = new Date();
        const nextClaim = addHours(lastClaimDate, 24);
        const diff = nextClaim.getTime() - now.getTime();
        
        if(diff <= 0) {
          clearInterval(interval);
          setStatus({ claimable: true, claimed: false, missed: false });
          setCurrentDay(prev => {
            // Refetching data to update the tokens map as the streak has been incremented
            if((prev) % 7 === 0) refetch();
            return prev + 1;
          });
        } else {
          const hours = Math.floor(diff / 1000 / 60 / 60).toString().padStart(2, '0');
          const minutes = Math.floor((diff / 1000 / 60) % 60).toString().padStart(2, '0');
          const seconds = Math.floor((diff / 1000) % 60).toString().padStart(2, '0');
          if(nextClaimDateSubscriber) {
            nextClaimDateSubscriber(`${hours}:${minutes}:${seconds}`);
          }
        }
      }, 1000);
    }

    setStatus({
      claimable,
      claimed,
      missed,
    })
    setCurrentDay(localCurrentDay);

    return () => clearInterval(interval);
  }, [currentStreak, lastClaimDate, isLoadingCustomerDailyStreak, nextClaimDateSubscriber, refetch]);

  return {
    ...status,
    currentDay,
    currentStreak,
    currentStreakTotalEarned,
    tokensPerDayMap: customerDailyStreakData?.DailyStreakBonusMap,
    isLoading: isLoadingCustomerDailyStreak || isFetching,
    refetch,
  }
}