import React from "react";

import {Card, CardHeader, CardFooter, Image, Button, Link, CardBody} from "@nextui-org/react";
import { ITokenMultiplier } from "services/multipliers";
import { LazyLoadImage } from "react-lazy-load-image-component";

export function HorizontalScrollCardBig(
  { itemId, 
    imageUrl, 
    generateLink, 
    tokenMultiplier, 
    aestheticBackgroundImage, 
    aestheticCircleImage, 
    description, 
    name, 
    tag,
    textColor
  }: 
  { itemId: string; 
    imageUrl: string; 
    generateLink: () => void; 
    tokenMultiplier: ITokenMultiplier | undefined; 
    aestheticBackgroundImage?: string | null; 
    aestheticCircleImage?: string | null; 
    name: string;
    description?: string | null; 
    tag?: string | null; 
    textColor: string;
  } 
) {

  return (
    <div
      role="button"
      style={{
        userSelect: "none",
      }}
      className="mx-1"
    >
      <div className="flex flex-col items-center">
         <Card isPressable className="shadow-none" onPress={generateLink}>
            {/* <CardHeader className="absolute z-10 top-1 flex-col items-start bg-black/20">
                <p className="text-tiny text-white/60 uppercase font-bold">{title}</p>
                <h4 className="text-black/90 font-medium text-xl">{subTitle}</h4>
            </CardHeader> */}
            <CardBody className="w-full overflow-visible">
              <LazyLoadImage
                className="z-0 object-cover h-96 w-96 border border-gray-200 rounded-md"
                src={aestheticBackgroundImage || imageUrl || '/images/placeholder/product.jpg'}
              />

              {aestheticCircleImage && <div className="absolute bottom-8 left-8 border-1 rounded-full h-12 w-12 overflow-hidden flex items-center justify-center">
                <Image
                  alt="Overlay icon"
                  className="object-cover rounded-full h-12 w-12"
                  src={aestheticCircleImage}
                />
              </div>}
              {tag && <div className="absolute bottom-2 right-[10px] translate-y-1/2 bg-white rounded-full px-4 py-1 shadow-md z-[999]">
                <span style={{color: "#37B5FF"}} className="text-sm font-medium">{tag}</span>
                </div>
              }
            </CardBody>
          
            
            <CardFooter>
              <div className="flex flex-col gap-1 items-start" style={{color: textColor}}>
                <div>
                  <p className="text-[10px] lg:text-lg font-semibold">{name}</p>
                </div>
                <div className="flex flex-row md:gap-2 gap-1 text-left">
                  <Image
                    alt="Card background"
                  className="object-contain rounded-xl w-full h-4 w-4"
                  src={"/images/waveemblem.svg"}
                />
                <p className="text-sm">{tokenMultiplier?.num_tokens ?? 10} Tokens per $1 Spent</p>
                </div>  
                <div>
                  <p className="text-[10px] lg:text-lg font-semibold">{description}</p>
                </div>
              </div>
            </CardFooter>
        </Card>
      </div>
    </div>
  );
}