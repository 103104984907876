import useGetCustomerDailyStreakBonusData from "services/hooks/useGetCustomerDailyStreakBonus";
import { useAppSelector } from "store/hooks";
import { Image } from "@nextui-org/react";
import { StyledButton } from "../styles";
import { StreakDays, StreakNumber } from "./partials";
import { Skeleton } from "@mui/material";

export type DailyBonusModalContentProps = {
  onClaim: () => any | Promise<any>;
  isClaiming?: boolean;
};

const DailyBonusModalContent = (props: DailyBonusModalContentProps) => {
  const customerId: any = useAppSelector((state) => state.auth.customerId)
  const { currentStreak, claimed, currentDay, missed, tokensPerDayMap, isLoading, currentStreakTotalEarned } = useGetCustomerDailyStreakBonusData(customerId)
  
  if (isLoading) return <Skeleton className="w-full !h-[200px]" />

  return (
    <div className="p-3 !pt-10 lg:p-6 flex flex-col justify-end">
      <div className="w-full flex flex-col items-center gap-3 lg:gap-[1.5rem]">
        <div className="flex flex-col items-center gap-3">
          <h1 className="text-xl lg:text-2xl font-bold text-center">Daily Bonus Streak!</h1>
          <p className="text-xs-regular lg:text-md-regular text-gray-500 text-center max-w-96 md:max-w-[36rem]">
            {missed 
              ? "You lost your streak, but don't give up! Just make sure to claim your Daily Bonus every day to keep your streak alive and unlock better rewards."
              : "Claim your Daily Bonus to keep your streak alive and unlock even better rewards."
            }
          </p>
        </div>
        <div className="flex gap-5">
          <Image
            className="w-28 h-28 lg:w-auto lg:h-auto"
            src="/images/treasure.png"
            alt="Treasure"
            />
          <StreakNumber tokensPerDay={tokensPerDayMap!} currentStreak={currentStreak} currentTotal={currentStreakTotalEarned!} />
        </div>
        <StreakDays 
          tokensPerDay={tokensPerDayMap!} 
          onClaim={props.onClaim} 
          isClaiming={props.isClaiming} 
          currentClaimed={currentStreak} 
          currentDay={currentDay} 
        />
        <StyledButton
          isLoading={props.isClaiming} 
          fullWidth 
          onClick={props.onClaim} 
          disabled={claimed || props.isClaiming}
        >
          Claim Now!
        </StyledButton>
      </div>
    </div>
  )
}

export default DailyBonusModalContent;