import { getWildlinkClient } from 'utils/getWildLinkClient';
import { useEffect, useRef } from 'react';
import { useAppSelector } from 'store/hooks'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import PrizedrawNavbar from 'components/NewComponents/PrizedrawNavbar/index'
import * as Styles from './CustomerLayout.styles'
import PrizedrawFooter from 'components/NewComponents/PrizedrawFooter'
import { useGetCustomerQuery } from 'services/customers';
import { amplitudeSetUserId, amplitudeLogEvent } from '../amplitudeClient';


function CustomerLayout() {
  const { token } = useAppSelector((state) => state.auth)
  let location = useLocation()
  
  tempFixWildLink();
  const customerId: any = localStorage.getItem('customerId');
  const { data: customerDetails } = useGetCustomerQuery(customerId);
  const customerName = customerDetails?.customer?.email;

  useEffect(() => {
    if (customerName && location.pathname) {
      const path = location.pathname;
      if (path !== previousPath.current) {
        previousPath.current = path;
        amplitudeSetUserId(customerName);
        amplitudeLogEvent({
          event_type:`Page Viewed`,
          event_properties: {
            path,
            customer_id: customerId,
            customer_name: customerName,
            timestamp: new Date().toISOString(),
          }
        });
      }
    }
  }, [location, customerName]);

  const previousPath = useRef('');

  if (!localStorage.getItem('DeviceID')) {
    const WLClient = getWildlinkClient()
    WLClient.init().then(() => {
      const newDevice = WLClient.getDevice()
      const { DeviceID } = newDevice
      localStorage.setItem('DeviceID', String(DeviceID))
    })
  }


  if (!token) {
    const loginRoute = '/'
    return <Navigate to={loginRoute} state={{ from: location }} replace />
  }
  return (
    <Styles.PageContainer>
      <PrizedrawNavbar isShowingBottom={true} />
      <Styles.Main>
        <Outlet />
      </Styles.Main>
      <PrizedrawFooter />
    </Styles.PageContainer>
  )
}
export default CustomerLayout


function tempFixWildLink() {
  //temp fix for wildlink
  if (!localStorage.getItem('DeviceID')) {
    const WLClient = getWildlinkClient()
    WLClient.init().then(() => {
      const newDevice = WLClient.getDevice()
      const { DeviceID } = newDevice
      localStorage.setItem('DeviceID', String(DeviceID))
    })
  }
}
