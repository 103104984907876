import Button from 'components/ui/Button'
import TextField from 'components/ui/TextField'
import { FC, useEffect, useState } from 'react'
import Modal from 'components/ui/Modal'
import { useNotification } from 'hooks/useNotification'
import { useCreateBannerMutation, useGetBannerImageOptionsQuery } from 'services/marketplace'
import { Switch, MenuItem, Select, FormControl, InputLabel } from '@mui/material'

interface IAddBannerModalProps {
  open: boolean
  onClose: () => void
}

const AddBannerModal: FC<IAddBannerModalProps> = ({ open, onClose }) => {
  const { sendSuccessNotification, sendErrorNotification } = useNotification()
  const [createBanner, { isLoading, isError }] = useCreateBannerMutation()
  const { data: imageOptions } = useGetBannerImageOptionsQuery()

  const [allowTag, setAllowTag] = useState(false)
  const [allowDescription, setAllowDescription] = useState(false)
  const [imageSize, setImageSize] = useState('')
  const [imageType, setImageType] = useState('')
  const [bannerName, setBannerName] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [defaultTag, setDefaultTag] = useState('')
  const [bannerBackgroundColor, setBannerBackgroundColor] = useState('')
  const [textColor, setBannerTextColor] = useState('')

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!bannerName) {
      sendErrorNotification('Name is required')
      return
    }

    const response: any = await createBanner({
      banner_name: bannerName,
      image_url: imageUrl || null,
      allow_tag: allowTag,
      allow_description: allowDescription,
      active: true,
      image_size: imageSize || null,
      image_type: imageType || null,
      default_tag: defaultTag || null,
      banner_background_color: bannerBackgroundColor || null,
      text_color: textColor || null
    });

    if (response.data) {
      sendSuccessNotification('Banner Added Successfully')
      onClose()
    }
  }

  const resetForm = () => {
    setBannerName('')
    setImageUrl('')
    setAllowTag(false)
    setAllowDescription(false)
    setImageSize('')
    setImageType('')
    setDefaultTag('')
    setBannerBackgroundColor('')
  }

  useEffect(() => {
    if (open) {
      resetForm()
    }
  }, [open])

  const renderField = ({
    id,
    label,
    placeholder,
    type,
    value,
    onChange
  }: {
    id: string
    label: string
    placeholder: string
    type: string
    value: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  }) => (
    <div className="flex flex-col lg:flex-row mt-4">
      <label className="w-40 text-gray-700 mb-2">{label}</label>
      <TextField
        id={id}
        type={type}
        hiddenLabel
        cc-width="full"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  )

  const modalBody = (
    <>
      {renderField({
        id: 'banner_name',
        label: 'Name *',
        type: 'text',
        placeholder: 'Name',
        value: bannerName,
        onChange: (e) => setBannerName(e.target.value)
      })}
      {renderField({
        id: 'image_url',
        label: 'Image URL',
        type: 'text',
        placeholder: 'Image URL',
        value: imageUrl,
        onChange: (e) => setImageUrl(e.target.value)
      })}
      <div className="flex flex-col lg:flex-row mt-4">
        <label className="w-40 text-gray-700 mb-2">Image Size</label>
        <FormControl fullWidth>
          <InputLabel>Image Size</InputLabel>
          <Select
            value={imageSize}
            onChange={(e) => setImageSize(e.target.value as string)}
          >
            {imageOptions?.imageSizes?.map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="flex flex-col lg:flex-row mt-4">
        <label className="w-40 text-gray-700 mb-2">Image Type</label>
        <FormControl fullWidth>
          <InputLabel>Image Type</InputLabel>
          <Select
            value={imageType}
            onChange={(e) => setImageType(e.target.value as string)}
          >
            {imageOptions?.imageTypes?.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {renderField({
        id: 'default_tag',
        label: 'Default Tag',
        type: 'text',
        placeholder: 'Default Tag',
        value: defaultTag,
        onChange: (e) => setDefaultTag(e.target.value)
      })}
      {renderField({
        id: 'banner_background_color',
        label: 'Background Color',
        type: 'text',
        placeholder: '#FFFFFF',
        value: bannerBackgroundColor,
        onChange: (e) => setBannerBackgroundColor(e.target.value)
      })}
      {renderField({
        id: 'text_color',
        label: 'Text Color',
        type: 'text',
        placeholder: '#FFFFFF',
        value: textColor,
        onChange: (e) => setBannerTextColor(e.target.value)
      })}
      <div className="flex flex-col lg:flex-row mt-4">
        <label className="w-40 text-gray-700 mb-2">Allow Tag</label>
        <Switch
          checked={allowTag}
          onChange={(e) => setAllowTag(e.target.checked)}
        />
      </div>
      <div className="flex flex-col lg:flex-row mt-4">
        <label className="w-40 text-gray-700 mb-2">Allow Description</label>
        <Switch
          checked={allowDescription}
          onChange={(e) => setAllowDescription(e.target.checked)}
        />
      </div>
    </>
  )

  const modalActions = (
    <>
      <Button onClick={onClose} cc-width="full">
        Cancel
      </Button>
      <Button
        cc-color="purple"
        cc-width="full"
        type="submit"
        disabled={isLoading}
      >
        {isLoading ? 'Saving...' : 'Add Banner'}
      </Button>
    </>
  )

  useEffect(() => {
    if (isError) {
      sendErrorNotification('Failed to add banner')
    }
  }, [isError, sendErrorNotification])

  return (
    <Modal
      title="Add Banner"
      open={open}
      onClose={onClose}
      body={modalBody}
      actions={modalActions}
      onSubmit={handleSubmit}
    />
  )
}

export default AddBannerModal
