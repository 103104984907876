import React from 'react'
import { TextFieldProps } from '@mui/material/TextField'
import * as Styles from './styles'

export interface ITextField {
  id: string
  ['cc-width']?: 'full' | 'half' | undefined
  ['cc-adornment-border']?: 'start' | 'end' | undefined
}

export type TTextFieldProps = TextFieldProps & ITextField

const TextField: React.FC<TTextFieldProps> = (props) => {
  return (
    <Styles.TextField
      name={props.id}
      size="small"
      variant="outlined"
      inputProps={{ 'data-testid': `textField-${props.id}` }}
      {...props}
    />
  )
}

export default TextField
