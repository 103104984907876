import { Button, Image } from '@nextui-org/react'
import useExtensionDetails from 'hooks/useExtensionDetails'
import { twMerge } from 'tailwind-merge'

/**
 * Component used on all the other pages
 */
const MainExtensionBanner = () => {
  const { isInstalled } = useExtensionDetails();

  if(isInstalled) {
    return <></>
  }

  return (
    <div className="hidden lg:flex justify-center items-center h-[88px] bg-primary-400 px-8">
      <div className='h-full flex items-end'>
        <div className='relative w-[150px]'>
          <div className='z-10 absolute top-[-12px] right-[-8px]'>
            <Image src="/images/Coins.png" width={60} alt="Coins" />
          </div>
          <Image 
            className='z-1'
            src='/images/extension-on-chrome.png'
            width={150} 
            alt="Chrome With Extension"
          />
        </div>
      </div>
      <p className='text-md-semibold text-white'>
        Find Coupons and Earn Tokens at over 11,000 stores with our Chrome Extension
      </p>
      <Button
        onClick={() => window.open('https://chromewebstore.google.com/detail/wave/ifdpohocbpgbcnopccciobohojafffpl', '_blank')}
        className="!text-sm-semibold py-2 px-4 ml-6 lg:text-xl bg-white text-primary-800 rounded-lg"
        color="primary"
        radius="full"
      >
        Add to Chrome
      </Button>
    </div>
  )
}

/**
 * Component used on the prize details page
 */
const PrizeExtensionBanner = ({ className }: { className?: string }) => {
  const { isInstalled } = useExtensionDetails();

  if(isInstalled) {
    return <></>
  }

  return (
    <div className={twMerge("hidden lg:flex justify-center items-center h-[70px] bg-primary-400 px-8 gap-8", className)}>
      <div className='h-full flex items-center'>
        <Image 
          className='z-1'
          src='/images/extension-prizes.png'
          width={60} 
          alt="Chrome With Extension"
        />
      </div>
      <p className='text-md-semibold text-white'>
        Get 500 tokens instantly when you download our Chrome Extension
      </p>
      <Button
        onClick={() => window.open('https://chromewebstore.google.com/detail/wave/ifdpohocbpgbcnopccciobohojafffpl', '_blank')}
        className="!text-sm-semibold py-2 px-4 lg:text-xl bg-white text-primary-800 rounded-lg"
        color="primary"
        radius="full"
      >
        Add to Chrome - It's Free
      </Button>
    </div>
  )
}

const InstallExtensionBanner = {
  Main: MainExtensionBanner,
  Prize: PrizeExtensionBanner,
}

export default InstallExtensionBanner;