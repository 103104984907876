import Spinner from 'components/ui/Spinner'
import { FC } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useGetMerchantsByBannerQuery } from 'services/marketplace'
import Slider from 'react-slick'
import { SlickSettings } from 'utils/constants'

interface IMerchantsByBanner {
  bannerId: string
  generateLink: (merchantId: string, merchantUrl: string) => void
}

const MerchantsByBanner: FC<IMerchantsByBanner> = ({
  bannerId,
  generateLink
}) => {
  const { data, isLoading } = useGetMerchantsByBannerQuery({
    id: bannerId,
    limit: 100,
    page: 1
  })
  const merchants = data?.marketplace_banner_merchants

  if (isLoading) return <Spinner />

  return (
    <div className="p-8">
      {merchants && merchants?.length > 0 ? (
        <Slider {...SlickSettings}>
          {merchants.map((item) => (
            <div className="" key={item.marketplace_banner_id}>
              <div className="rounded-md overflow-hidden px-5">
                <LazyLoadImage
                  className="rounded-md mb-3 cursor-pointer object-contain w-full"
                  src={
                    item.marketplace_merchant.image_url1 ||
                    item.marketplace_merchant.image_url2 ||
                    '/images/placeholder/product.jpg'
                  }
                  alt={item.marketplace_merchant.name}
                  onClick={() =>
                    generateLink(
                      item.marketplace_merchant_id,
                      item.marketplace_merchant.url
                    )
                  }
                />
              </div>
              <span
                className="font-semibold cursor-pointer hover:underline pl-5"
                onClick={() =>
                  generateLink(
                    item.marketplace_merchant_id,
                    item.marketplace_merchant.url
                  )
                }
              >
                {item.marketplace_merchant.name}
              </span>
            </div>
          ))}
        </Slider>
      ) : (
        <div className="text-lg text-primary-200">No merchants found.</div>
      )}
    </div>
  )
}

export default MerchantsByBanner
