import * as Styles from 'layouts/PrivateLayout.styles'
import { useGetPrizePeriodQuery } from 'services/prizePeriods'
import { useParams } from 'react-router-dom'
import HorizontalFieldRow from 'components/ui/HorizontalFieldRow'
import { formatDate } from 'utils/intlDate'
import ContentLoadingPlaceholder from 'components/ui/ContentLoadingPlaceholder'
import InfoCard from 'components/ui/InfoCard'

const PrizePeriodDetails = () => {
  const { prizePeriodId } = useParams()
  const { data, isFetching } = useGetPrizePeriodQuery(prizePeriodId || '')
  const startDate = data?.prize_period?.start_date ? new Date(data.prize_period.start_date) : null
  const endDate = data?.prize_period?.end_date ? new Date(data.prize_period.end_date) : null

  return (
    <div className="pt-2">
      <Styles.PageTitleContainer>Prize Period</Styles.PageTitleContainer>
      <div>
        <HorizontalFieldRow label="Prize Period Name ">
          <ContentLoadingPlaceholder isLoading={isFetching}>
            {data?.prize_period?.name || ''}
          </ContentLoadingPlaceholder>
        </HorizontalFieldRow>
        <HorizontalFieldRow label="Start Date">
          <ContentLoadingPlaceholder isLoading={isFetching}>
            {startDate ? formatDate(startDate) : ''}
          </ContentLoadingPlaceholder>
        </HorizontalFieldRow>
        <HorizontalFieldRow label="End Date" showBorderBottom>
          <ContentLoadingPlaceholder isLoading={isFetching}>
            {endDate ? formatDate(endDate) : ''}
          </ContentLoadingPlaceholder>
        </HorizontalFieldRow>
      </div>
      <div className="flex flex-col lg:flex-row justify-between gap-6 mt-9">
        <InfoCard
          label="Total orders"
          value={data?.prize_period?.orders_count || 0}
          isLoading={isFetching}
          className="w-full"
        />
        <InfoCard
          label="Total entries per period"
          value={data?.prize_period?.entries_count || 0}
          isLoading={isFetching}
          className="w-full"
        />
      </div>
    </div>
  )
}

export default PrizePeriodDetails
