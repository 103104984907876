import { IconButton, styled } from "@mui/material";
import { Modal, ModalContent } from "@nextui-org/react";
import tw from "twin.macro";
import clsx from "clsx";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import Slider, { CustomArrowProps, Settings } from "react-slick";
import DailyBonusModalContent from "./DailyBonusModalContent";
import NumbersGameModalContent from "./NumbersGameModalContent";
import { IoMdClose } from "react-icons/io";
import { useEffect, useRef, useState } from "react";

export type DailyBonusAndNumbersGameModalProps = {
  open: boolean;
  onClaim: () => any | Promise<any>;
  onGuess: (numbers: number[]) => Promise<any>;
  onClose?: () => void;
  isLoading?: boolean;
  isClaiming?: boolean;
  isGuessingNumber?: boolean;
  contentToShow: 'dailyBonus' | 'numbersGame';
};

export function LeftArrow(props: CustomArrowProps) {
  const isDisabled = props.className?.includes('slick-disabled');
  return (
    <IconButton
      className="slick-arrow-left"
      disabled={isDisabled}
      style={props.style}
      onClick={props.onClick}
    >
      <IoChevronBackOutline size={20} className={clsx(" text-primary-400", { '!text-gray-300': isDisabled })}/>
    </IconButton>
  );
}

export function RightArrow(props: CustomArrowProps) {
  const isDisabled = props.className?.includes('slick-disabled');

  return (
    <IconButton
      className="slick-arrow-right"
      disabled={isDisabled}
      style={props.style}
      onClick={props.onClick}
    >
      <IoChevronForwardOutline size={20} className={clsx("text-primary-400", { '!text-gray-300': isDisabled })}/>
    </IconButton>
  );
}

const settings: Settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  touchThreshold: 5,
  initialSlide: 0,
  prevArrow: <LeftArrow />,
  nextArrow: <RightArrow />,
};

const SliderWrapper = styled('div')`
  overflow: hidden;
  & .slick-arrow-left {
    top: 8px;
    width: 36px;
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translateX(-180%);
    z-index: 10;
  }

  & .slick-arrow-right {
    top: 8px;
    position: absolute;
    width: 36px;
    left: 50%;
    right: 50%;
    transform: translateX(75%);
    z-index: 10;
  }

  & .slick-dots {
    top: 10px;
    bottom: unset;
    position: absolute;
    height: auto;

    li {
      margin: 0;
      &.slick-active {
        button {
          &::before {
            ${tw`text-primary-400`}
          }
        }
      }

      button {
        &::before {
          font-size: 10px;
        }
      }
    }
  }
`;

const DailyBonusAndNumbersGameModal = (props: DailyBonusAndNumbersGameModalProps) => {
  const sliderRef = useRef<Slider | null>(null);

  // WARN - this is a workaround to force the slider to go to the correct slide
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if(props.contentToShow === 'dailyBonus') {
      sliderRef.current?.slickGoTo(0);
    } else {
      sliderRef.current?.slickGoTo(1);
    }
  }, [props.contentToShow, trigger])
  
  return (
    <Modal placement="center" size={'2xl'} isOpen={props.open} isDismissable={!props.isLoading} onClose={props.onClose} hideCloseButton>
      <ModalContent className="relative">
        <IoMdClose onClick={props.onClose} size={20} className="z-[4] cursor-pointer absolute top-2 right-2 text-gray-500"/>
        <SliderWrapper>
          <Slider 
            {...settings}
            ref={ref => {
              sliderRef.current = ref;
              setTrigger(prev => !prev)
            }} 
          >
            <DailyBonusModalContent onClaim={props.onClaim} isClaiming={props.isClaiming} />
            <NumbersGameModalContent onGuess={props.onGuess} isGuessing={props.isGuessingNumber} />
          </Slider>
        </SliderWrapper>
      </ModalContent>
    </Modal>
  )
};

export default DailyBonusAndNumbersGameModal;