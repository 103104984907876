import { useAppSelector } from 'store/hooks';
import { Image, Divider, Skeleton } from '@nextui-org/react';
import { useGetCustomerEntriesQuery } from 'services/customers';
import { getTablePageLimit } from 'utils/getTablePageLimit';
import { useState } from 'react';
import Pagination from '@mui/material/Pagination';

const LIMIT = getTablePageLimit();

const TokenHistoryDetailsPage = () => {
    const customerId = useAppSelector((state) => state.auth.customerId);
    const [page, setPage] = useState(1);

    const { data: tokenHistory, error, isLoading, isFetching } = useGetCustomerEntriesQuery({
        customerId: customerId || '',
        page,
        limit: LIMIT
    });


    const totalPages = Math.ceil(Number(tokenHistory?.records_count) / LIMIT);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    if (error) return <p>Error loading token history.</p>;

    if (isLoading || isFetching) {
        return (
            <div className="min-h-screen">
                <Skeleton className="mx-10 my-5 rounded-lg">
                    <div className="h-96">
                        Loading banners
                    </div>
                </Skeleton>

                <Skeleton className="mx-10 my-5 rounded-lg">
                    <div className="h-96">
                        Loading banners
                    </div>
                </Skeleton>
            </div>
        );
    }

    if (!tokenHistory || tokenHistory.entries.length === 0) return <p className="min-h-screen p-12">No Token History Yet.</p>;

    return (
        <div className="flex flex-col items-center w-full min-h-screen">
            <div className="w-10/12 mt-10">
                <div className="text-lg-semibold text-gray-800 mb-6 mt-3 flex flex-col gap-3 sm:flex-row items-start sm:items-center justify-between">
                    <p>Your Token History</p>
                </div>
                <Divider />
                <div className="flex flex-col gap-4">
                    {tokenHistory.entries.map((entry: any) => (
                        <div key={entry?.id}>
                            <div className="flex flex-col items-center md:flex-row gap-4 md:gap-2 w-full rounded-md p-4">
                                <div className="w-full md:w-1/4">
                                    <Image
                                        src="/images/Coins.png"
                                        width={80}
                                        alt="Coins"
                                    />
                                    <p className="text-xl" style={{ color: '#4eb9ff', fontWeight: 'bold' }}>
                                        {entry?.number_of_entries} Tokens
                                    </p>
                                </div>
                                <div className="w-full md:w-3/4 px-4">
                                    <div className="flex flex-col gap-2 h-full justify-around">
                                        <p className="text-2xl">
                                            {entry?.merchant?.name ? entry.merchant.name : entry?.entry_type}
                                        </p>
                                        <div style={{ fontWeight: 300 }}>
                                            <p className="text-xl">
                                                Date: {new Date(entry?.created_at).toLocaleDateString()}
                                            </p>
                                            <p className="text-xl">
                                                Tokens earned: &nbsp;
                                                <span style={{ color:'green', fontWeight: 500 }}>
                                                    {Math.abs(entry?.number_of_entries)}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="w-full md:w-3/4 px-4">
                                    <div className="flex flex-col gap-2 h-full justify-around">
                                        <p className="text-xl">
                                            Available Tokens Before Transaction: 
                                            <span style={{ color: 'red', fontWeight: 500 }}> {entry?.prev_entries_total} </span>
                                        </p>
                                        <p className="text-xl">
                                            Available Tokens Now : 
                                            <span style={{ color: 'green', fontWeight: 500 }}> {entry?.new_entries_total} </span>
                                        </p>
                                    </div>
                                </div> */}
                            </div>
                            <Divider />
                        </div>
                    ))}
                    <div className="flex justify-center items-center mt-4">
                        <Pagination
                            count={totalPages}
                            page={page}
                            onChange={handlePageChange}
                            color="primary"
                            siblingCount={1}
                            boundaryCount={1}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TokenHistoryDetailsPage;
