import { IconButton, InputAdornment } from "@mui/material";
import { SearchInput } from "./styles";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { Image } from '@nextui-org/react'
import clsx from "clsx";
import { twMerge } from "tailwind-merge";
import { useState, useEffect } from "react";
import useGetCustomerDailyStreakBonusData from "services/hooks/useGetCustomerDailyStreakBonus";
import { useAppSelector } from "store/hooks";
import useDebouncedValue from "store/debounceHook";

export const SearchInputComponent = ({ value, onChange, handleSearch }: { value: string; onChange: (value: string) => void; handleSearch: () => void }) => {
  const debouncedSearchTerm = useDebouncedValue(value);

  useEffect(() => {
    if (debouncedSearchTerm) {
      handleSearch();
    }
  }, [debouncedSearchTerm, handleSearch]);

  return (
    <SearchInput
      slotProps={{
        input: {
          className: '!rounded-full h-[42px] lg:h-[52px] !pr-1 lg:!pr-1.5 hover:!border-none',
          endAdornment: (
            <InputAdornment position="end" className='cursor-pointer'>
              <IconButton
                className='!bg-primary-400 rounded-full !p-2 lg:!p-3'
                aria-label="toggle password visibility"
                onClick={handleSearch}
              >
                <FaMagnifyingGlass size={18} className="text-white" />
              </IconButton>
            </InputAdornment>
          )
        }
      }}
      className="w-full flex-grow h-[42px] lg:h-[52px] pl-4 pr-16 text-base bg-transparent rounded-lg focus:outline-none"
      placeholder="Search stores, products, and categories"
      type="search"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
    />
  );
}

type ClaimBonusButtonProps = { 
  onClick: () => void;
  className?: string;
  mobile?: boolean 
}
export const ClaimBonusButton = (props: ClaimBonusButtonProps) => {
  const customerId: any = useAppSelector((state) => state.auth.customerId)
  const [nextClaimDate, setNextClaimDate] = useState('');
  const { isLoading, claimable, currentStreak } = useGetCustomerDailyStreakBonusData(customerId, setNextClaimDate)

  const className = twMerge(
    clsx('cursor-pointer p-1 w-[60px] h-[60px] lg:w-[72px] lg:h-[72px] rounded-full border border-primary-400 bg-primary-100', {
      'lg:hidden flex': props.mobile,
      'hidden lg:flex': !props.mobile
    }), 
    props.className
  );

  if(isLoading) {
    return <></>
  }

  return (
    <div
      onClick={props.onClick} 
      className={className}
    >
      {!claimable ? (
        <div className="flex flex-col flex-1 justify-center items-center">
          <div className="flex gap-1">
            <span className="text-sm font-semibold leading-4">{currentStreak}</span>
            <Image
              width={15}
              height={15}
              alt="Fire streak"
              src={'/images/fire-emoji.png'}
            />
          </div>
          {!props.mobile && (
            <span className="text-[9px] text-center">Next Bonus</span>
          )}
          <span className="text-[10px] lg:text-[12px] font-semibold text-center text-primary-400">{nextClaimDate}</span>
        </div>
      ) : (
        <div className="flex flex-col flex-1 gap-1 justify-center items-center">
          <Image
            alt="Small Coin"
            src={'/images/small-coin.png'}
          />
          <span className='text-[10px] leading-tight font-bold whitespace-break-spaces text-center text-primary-400'>Claim Bonus</span>
        </div>
      )}
    </div>
  )
}
