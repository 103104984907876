import { FC } from 'react'
import Button from 'components/ui/Button'
import { IFormProps } from './interfaces'
import * as Styles from './Forms.styles'
import CheckYourEmail from './CheckYourEmail'
import TextField from 'components/ui/TextField'

interface IForgotPasswordVerificationCodeFormProps extends IFormProps {}

const ForgotPasswordVerificationCodeForm: FC<
  IForgotPasswordVerificationCodeFormProps
> = ({
  formId,
  title,
  buttonText,
  isLoading,
  handleSubmit,
  handleBackButton
}) => {
  return (
    <CheckYourEmail
      formId={formId}
      title={title}
      handleBackButton={handleBackButton}
    >
      <Styles.Form onSubmit={handleSubmit}>
        <TextField
          id="confirmation_code"
          label="Confirmation Code"
          type="text"
          cc-width="full"
          required
        />
        <TextField
          id="new_password"
          label="New Password"
          type="password"
          cc-width="full"
          helperText="Password must contain at least 8 characters and 1 number."
          required
        />
        <Button
          data-testid={`${formId}-submit-button`}
          type="submit"
          cc-color="purple"
          cc-width="full"
          loading={isLoading}
        >
          {buttonText}
        </Button>
      </Styles.Form>
    </CheckYourEmail>
  )
}

export default ForgotPasswordVerificationCodeForm
