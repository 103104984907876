import { keyframes, styled } from "@mui/material";
import tw from 'twin.macro'

const slideLeft = keyframes`
  0% {
    left: 0;
    opacity: 1;
  }
  100% {
    left: 30%;
    opacity: 0;
  }
`;

const slideRight = keyframes`
  0% {
    right: 0;
    opacity: 1;
  }
  100% {
    right: 50%;
    transform: translateX(50%);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const blink = keyframes`
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const animatedBackgroundMobile = keyframes`
  from {
    ${tw`bg-[center_-500px]`}
  }
  to {
    ${tw`bg-[center_-20px]`}
  }
`;

const animatedBackgroundTablet = keyframes`
  from {
    ${tw`bg-[center_-500px]`}
  }
  to {
    ${tw`bg-[center_-150px]`}
  }
`;

export const PageWrapper = styled('div')`
  ${tw`flex flex-col w-full h-full items-center overflow-hidden px-4`}

  background-image: url("/images/coins-rain2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;

  animation: ${animatedBackgroundMobile} 1s forwards;

  @media (min-width: 768px) {
    animation: ${animatedBackgroundTablet} 1s forwards;
  }
   
`;

export const LogoContainer = styled('div')`
  ${tw`
    relative w-full mt-[30vh] max-w-[400px] h-[100px] 
    sm:h-[120px] sm:max-w-[500px]
    md:h-[150px] md:max-w-[600px] md:mt-[14vw]
    lg:mt-[10vw]`
  }

  & .logo1 {
    left: 0;
  }

  & .logo2 {
    right: 0;
  }

  &.animate {
    & .dots {
      animation: ${fadeOut} 1s forwards;
    }

    & .logo1 {
      animation: ${slideLeft} 2s forwards;
    }

    & .logo2 {
      animation: ${slideRight} 2s forwards;
    }

    & .wave {
      animation: ${fadeIn} 2s forwards;
    }
  }
`;

export const Dots = styled('div')`
  ${tw`absolute right-0 left-0 top-0 bottom-0 m-auto flex justify-center items-center gap-3 md:gap-6`}

  & div {
    ${tw`rounded-full w-4 h-4 md:w-5 md:h-5 bg-primary-400`}
    animation: ${blink} 1s infinite;

    &:nth-child(1) {
      animation-delay: 0s;
    }

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }

    &:nth-child(4) {
      animation-delay: 0.6s;
    }
  }
`

export const LogoWrapper = styled('div')`
  ${
    tw`
      absolute rounded-full bg-white 
      flex items-center justify-center 
      shadow-[0px_4px_11px_#0a164633]
      w-[100px] h-[100px] p-4
      sm:w-[120px] sm:h-[120px] sm:p-6
      md:w-[150px] md:h-[150px] md:p-8
    `
  }
`;