import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IFormStepsStore {
  step: number
  email: string
  handle: string
  id: string
}

const initialState: IFormStepsStore = {
  step: 1,
  email: '',
  handle: '',
  id: ''
}

export const formStepsSlice = createSlice({
  name: 'formSteps',
  initialState,
  reducers: {
    changeStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload
    },
    changeEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload
    },
    changeHandle: (state, action: PayloadAction<string>) => {
      state.handle = action.payload
    },
    changeId: (state, action: PayloadAction<string>) => {
      state.id = action.payload
    },
    resetFormSteps: (state) => {
      state.step = 1
      state.handle = ''
      state.email = ''
      state.id = ''
    }
  }
})

export const {
  changeStep,
  changeEmail,
  changeHandle,
  changeId,
  resetFormSteps
} = formStepsSlice.actions

export default formStepsSlice.reducer
