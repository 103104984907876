import { Remove, Timer } from '@mui/icons-material'
import Button from 'components/ui/Button'
import { useRemoveMerchantFromBannerMutation } from 'services/marketplace'

interface RemoveMerchantProps {
  merchantId: string
  categoryId: string
}
const RemoveMerchant = ({ merchantId, categoryId }: RemoveMerchantProps) => {
  const [removeMerchantFromBanner, { isLoading: isRemovingMerchant }] =
    useRemoveMerchantFromBannerMutation()

  const handleRemoveMerchant = async (merchantId: string) => {
    await removeMerchantFromBanner({
      bannerId: categoryId || '',
      merchantId
    })
  }

  return (
    <Button
      disabled={isRemovingMerchant}
      onClick={() => handleRemoveMerchant(merchantId)}
      cc-color="red"
    >
      {isRemovingMerchant ? <Timer /> : <Remove />}
    </Button>
  )
}

export default RemoveMerchant
