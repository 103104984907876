import React, { useState, useEffect } from 'react';
import LoginForm from 'components/Forms/LoginForm'
import { useLoginMutation } from 'services/auth'
import { useNavigate, useLocation } from 'react-router-dom'
import { useLazyGetTiersQuery } from 'services/tiers'
import { login as loginStore, USER_TYPE_ADMIN } from 'store/authSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { jwtDecode } from 'jwt-decode'
import { getWildlinkClient } from 'utils/getWildLinkClient'
import { useGetCategoriesQuery } from 'services/marketplace'
import { IJwtDecoded } from 'components/Forms/interfaces'
import { amplitudeSetUserId, amplitudeLogEvent } from '../../amplitudeClient';
import SignUpVerificationCodeForm from 'components/Forms/SignUpVerificationCodeForm'
import { useConfirmSignupMutation } from 'services/auth'
import { changeEmail, resetFormSteps } from 'store/formStepsSlice';
import { useNotification } from 'hooks/useNotification';

const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [login, { isLoading }] = useLoginMutation()
  const [, { isLoading: isLoadingTiers }] = useLazyGetTiersQuery()
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userType, setUserType] = useState('');
  const [step, setStep] = useState(1)
  const [verificationCode, setVerificationCode] = useState('')
  const [isLimitedTries, setIsLimitedTries] = useState(false)
  const [confirmationSignup] = useConfirmSignupMutation()
  const { sendSuccessNotification } = useNotification()
  const [email, setEmail] = useState('')

  const dispatch = useAppDispatch()

  const handleGoogleSignIn = async () => {
    const redirectUri = `${process.env.REACT_APP_PRIZEDRAW_AUTH_CALLBACK_URL}`;
    const loginUrl = `${process.env.REACT_APP_AWS_COGNITO_DOMAIN_NAME}/oauth2/authorize` +
      `?response_type=code` +
      `&client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}` +
      `&redirect_uri=${encodeURIComponent(redirectUri)}` +
      `&identity_provider=Google`;

    window.location.href = loginUrl;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const formDataObject = Object.fromEntries(formData.entries())
    const response: any = await login(formDataObject)

    if (response.data && response.data?.status === "UNCONFIRMED") {
      // User is not confirmed, move to verification step
      const userEmail = formDataObject.username as string
      dispatch(changeEmail(userEmail))
      setEmail(userEmail)
      setStep(2)
      sendSuccessNotification(response.data.message)
    } else if (response.data) {
      const tokenDecoded: any = jwtDecode(response.data.token)
      const userInfo: IJwtDecoded = tokenDecoded
      const userType = userInfo['custom:type']
      setUserType(userType);
      const userId = userInfo['cognito:username']
      const customerId = response.data.customer_id
      let email: any = formDataObject.username;
      amplitudeSetUserId(email);
      amplitudeLogEvent({
        event_type: 'Auth - Sign In',
        event_properties: {
          customer_name: email,
          sign_in_method: 'Sign in via form',
          customer_id: customerId,
          timestamp: new Date().toISOString(),
        }
      });

      dispatch(
        loginStore({
          user: {
            email: userInfo.email,
            id: userId,
            type: userType,
            first_name: userInfo.name
          },
          token: response.data.token,
          refresh_token: response.data.refresh_token,
          merchantId: userId,
          merchantName: userInfo.name,
          customerId
        })
      )

      setIsAuthenticated(true);

      if (!localStorage.getItem('DeviceID')) {
        const WLClient = getWildlinkClient()
        WLClient.init().then(() => {
          const newDevice = WLClient.getDevice()
          const { DeviceID } = newDevice
          localStorage.setItem('DeviceID', String(DeviceID))
        })
      }
    }
  }

  const handleSubmitVerificationCode = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      const response: any = await confirmationSignup({
        username: email,
        confirmation_code: verificationCode,
      })

      if (response?.data) {
        handleSuccessfulVerification(response)
        amplitudeSetUserId(email);
        amplitudeLogEvent({
          event_type: 'Auth - Confirm Signup',
          event_properties: {
            customer_name: email,
            sign_up_method: 'Sign up via form',
            customer_id: response.data.id,
            timestamp: new Date().toISOString(),
          }
        });
      } else if (response.data === '') {
        handleEmptyResponse();
      } else {
        handleErrorResponse(response);
      }
    } catch (error) {
      console.error("Error during verification:", error)
      resetVerificationInputs()
    }
  }

  const handleSuccessfulVerification = (response: any) => {
    const tokenDecoded: any = jwtDecode(response.data.token)
    const userInfo: IJwtDecoded = tokenDecoded
    const userType = userInfo['custom:type']
    setUserType(userType);
    const userId = userInfo['cognito:username']
    const customerId = response.data.customer_id

    dispatch(
      loginStore({
        user: {
          email: userInfo.email,
          id: userId,
          type: userType,
          first_name: userInfo.name
        },
        token: response.data.token,
        refresh_token: response.data.refresh_token,
        merchantId: userId,
        merchantName: userInfo.name,
        customerId
      })
    )

    setIsAuthenticated(true);
  }

  const handleErrorResponse = (response: any) => {
    const error = response.error;
  
    if (error?.status === 401) {
      resetVerificationInputs();
      return;
    }
  
    if (error?.status === 500) {
      setIsLimitedTries(true);
      resetVerificationInputs();
      return;
    }

    if (error) {
      resetVerificationInputs();
    }
  }

  const handleEmptyResponse = () => {
    dispatch(resetFormSteps());
    sendSuccessNotification('Your email has been successfully verified.');
    navigate('/');
  };

  const resetVerificationInputs = () => {
    setVerificationCode('')
    setIsLimitedTries(false)
  }

    // Fetch categories after authentication
    const { data: categories} = useGetCategoriesQuery({
      limit: 80,
      page: 1
    }, {
      skip: !isAuthenticated // Skip this query until authenticated
    });
    
    const authToken = useAppSelector((state) => state.auth.token)
    console.log(authToken)
    // Effect to navigate after categories are loaded
    useEffect(() => {
      if (authToken) {
        navigate("/merchants/fcb47d06-0bd0-40b7-9ac0-37d12eb83d45")
      }
      if (categories && isAuthenticated) {
        const homeCategory = categories.marketplace_categories.find(category => category.is_home === true);
        const categoryId = homeCategory ? homeCategory.id : '4082b604-0ada-4376-89a2-83a79c54b829';
  
        const dashboardRoute = userType === USER_TYPE_ADMIN ? `/admin/dashboard` : `/merchants/${categoryId}`;
        navigate(`${dashboardRoute}${location.search}`);
      }
    }, [authToken, categories, isAuthenticated, navigate, location.search]);

  return (
    <div data-testid="module-login-merchant">
      {step === 1 ? (
        <>
          <p className="text-gray-900 text-display-xs-semibold md:text-display-sm-semibold mb-4 text-center">
            {"Log in"}
          </p>
          <p className="text-center">Welcome back!</p>
          <div className="w-full flex flex-col items-center mt-5 mb-3">
            <div className="flex flex-col gap-4">
              <button
                onClick={handleGoogleSignIn}
                className="bg-white text-gray-600 py-2 px-4 border border-gray-300 rounded-lg shadow-sm hover:bg-gray-100 hover:shadow-md transition-all duration-200 ease-in-out flex items-center justify-center w-60"
              >
                <img src={'/images/logo-google.png'} alt="Google Icon" className="w-5 h-5 mr-2" />
                Continue with Google
              </button>
            </div>
          </div>
          <p className="text-gray-900 mb-2 text-center">OR</p>
          <LoginForm
            formId="component-forms-login-merchant"
            title="Log in"
            buttonText="Sign in"
            isLoading={isLoading || isLoadingTiers}
            handleSubmit={handleSubmit}
          />
        </>
      ) : (
        <SignUpVerificationCodeForm
          formId="component-forms-verificationCode-login-merchant"
          title="Verify your account"
          buttonText="Verify code"
          handleBackButton={() => setStep(1)}
          handleSubmit={handleSubmitVerificationCode}
          verificationCode={verificationCode}
          setVerificationCode={setVerificationCode}
          isLoading={isLoading}
          isLimitedTries={isLimitedTries}
          setIsLimitedTries={setIsLimitedTries}
        />
      )}
    </div>
  )
}

export default Login
