import tw from 'twin.macro'
import { styled } from '@mui/material/styles'

export const SidebarContainer = styled('div')`
  ${tw`fixed ease-in-out duration-300 z-10 flex md:translate-x-0  md:flex w-full md:w-auto`}
`
export const SidebarElements = styled('div')`
  ${tw`w-10/12 md:w-[265px] xl:w-[320px] h-screen p-2 bg-white border-r border-gray-200 text-gray-700 text-sm-semibold flex flex-col`}
`
export const SidebarLogo = styled('div')`
  ${tw`px-4 py-6`}

  & > img {
    ${tw`w-40`}
  }
`
export const SidebarFooter = styled('div')`
  ${tw`mt-auto`}
`
export const NavContainer = styled('nav')`
  ${tw`mt-1`}
`
export const Nav = styled('ul')`
  ${tw`flex flex-col gap-1`}
`
export const UserContainer = styled('div')`
  ${tw`mt-5 border-t border-gray-200 px-2 py-6 grid gap-3 grid-cols-[1fr_20px] xl:grid-cols-[1fr_20px] items-center`}
`
export const UserContainerNoBorder = styled('div')`
  ${tw`flex gap-3 flex-wrap`}
`
export const UserAvatar = styled('picture')`
  ${tw`basis-10 h-10 min-w-[40px] flex justify-center items-center bg-gray-100 rounded-full`}

  & > img {
    ${tw`rounded-full w-full h-full object-cover`}
  }
`
export const UserContent = styled('div')`
  ${tw`text-sm font-normal text-gray-600 flex flex-col justify-center`}
`
export const UserTitle = styled('span')`
  ${tw`text-sm-semibold text-gray-800 capitalize truncate`}
`
export const SidebarClose = styled('div')`
  ${tw`w-2/12 bg-gray-500 md:hidden text-white text-center`}
`
export const SidebarDesktop = styled('div')`
  ${tw`flex md:hidden justify-between p-4 border-b border-gray-200`}

  & > img {
    ${tw`w-40`}
  }
`
export const NavItem = styled('div')`
  ${tw`px-3 py-[5px] cursor-pointer hover:bg-gray-100 rounded-md flex gap-1 items-center`}
`
export const NavItemIcon = styled('i')`
  & svg {
    ${tw`!text-[1.6rem] mr-1`}
  }
`
export const NavItemChild = styled('div')`
  ${tw`px-3 py-[5px] flex items-center cursor-pointer hover:bg-gray-100 rounded-md`}
`
export const NavItemChildIcon = styled('div')`
  ${tw`h-[30px] w-[30px] mr-2`}
`
export const NavItemArrow = styled('div')`
  ${tw`absolute top-2 right-0 cursor-pointer`}
`
