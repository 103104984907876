import { Button, Image, Modal, ModalContent, ModalBody, ModalFooter, ModalHeader } from "@nextui-org/react";

const ExtensionBonusModal = ({ isOpen, onOpenChange}: { isOpen: boolean, onOpenChange: () => void}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onOpenChange}
      size='lg'
    >
      <ModalContent className="p-4">
        <ModalHeader className="flex flex-col items-center gap-2 w-full">
          <p className="text-lg">Extension Bonus</p>
          <p className="font-normal text-center">
            {"Here's"} a bonus for downloading the extension!
          </p>
        </ModalHeader>
        <ModalBody className="flex flex-col items-center mt-4 h-fit w-full ">
            <Image src='/images/500tokens.png' className="h-52" />
        </ModalBody>
        <ModalFooter className='flex flex-row justify-center w-full'>
        <Button onClick={onOpenChange} size="lg" className="bg-primary-400 text-white px-8 rounded-lg font-semibold">
            Got it!
        </Button>
        </ModalFooter>
      </ModalContent>
  
    </Modal>
  );
};

export default ExtensionBonusModal;