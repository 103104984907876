import Account from "modules/Customer/Account"

const AccountPage = () => {
  return (
    <div data-testid="customerAccountPage">
      <Account />
    </div>
  )
}

export default AccountPage
