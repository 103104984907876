import { useState } from 'react'
import * as Styles from 'layouts/PrivateLayout.styles'
import Table from 'components/ui/Table'
import { useGetPrizesQuery, IPrize } from 'services/prizes'
import Spinner from 'components/ui/Spinner'
import { formatDate } from 'utils/intlDate'
import { Link } from 'react-router-dom'
import AddPrizeModal from './AddPrizeModal'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import Button from 'components/ui/Button'
import { getTablePageLimit } from 'utils/getTablePageLimit';
import { usePagination } from 'hooks/usePagination'

const LIMIT = getTablePageLimit()

const Prizes = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { page, setPage } = usePagination()
  const { data, isLoading, isFetching } = useGetPrizesQuery({
    limit: LIMIT,
    page
  })

  console.log(data);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      render: (dataRow: IPrize) => (
        <div className="underline">
          <Link to={`/admin/prizes/${dataRow.id}`}>{dataRow.name}</Link>
        </div>
      )
    },
    {
      field: 'start_date',
      headerName: 'Start Date',
      render: (rowData: IPrize) => {
        const startDate = rowData.start_date
          ? new Date(rowData.start_date)
          : null
        return startDate ? formatDate(startDate) : ''
      }
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      render: (rowData: IPrize) => {
        const endDate = rowData.end_date ? new Date(rowData.end_date) : null
        return endDate ? formatDate(endDate) : ''
      }
    }
  ]

  return (
    <div>
      <AddPrizeModal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
       <Button
        startIcon={<AddOutlinedIcon />}
        onClick={() => setIsOpenModal(true)}
      >
        Add Prize
      </Button>
      <div className="pt-2">
        <Styles.PageTitleContainer>Prizes</Styles.PageTitleContainer>
      </div>
      <div>
        {isLoading ? (
          <Spinner />
        ) : (
          <Table
            columns={columns}
            rows={data?.prizes || []}
            emptyTitleText="No Prizes yet"
            onPrevPage={() => setPage(page - 1)}
            onNextPage={() => setPage(page + 1)}
            isFetching={isFetching}
            page={page}
            setPage={setPage}
            totalPages={Math.ceil(Number(data?.records_count) / LIMIT)}
          />
        )}
      </div>
    </div>
  )
}

export default Prizes
