import { FC } from 'react'
import Button from 'components/ui/Button'
import VerificationCodeUI from 'components/ui/VerificationCode'
import { IFormProps } from './interfaces'
import * as Styles from './Forms.styles'
import CheckYourEmail from './CheckYourEmail'

interface ISignUpVerificationCodeFormProps extends IFormProps {
  verificationCode: string
  setVerificationCode: (code: string) => void
  isLimitedTries?: boolean
  setIsLimitedTries?: (state: boolean) => void
  setPhoneAccess?: (session: any) => void
}

const SignUpVerificationCodeForm: FC<ISignUpVerificationCodeFormProps> = ({
  formId,
  title,
  buttonText,
  verificationCode,
  isLimitedTries,
  isLoading,
  handleSubmit,
  handleBackButton,
  setVerificationCode,
  setIsLimitedTries
}) => {
  return (
    <CheckYourEmail
      formId={formId}
      title={title}
      handleBackButton={handleBackButton}
      setVerificationCode={setVerificationCode}
    >
      <Styles.Form onSubmit={handleSubmit}>
        <VerificationCodeUI
          value={verificationCode}
          onChange={setVerificationCode}
          isLimitedTries={isLimitedTries}
          setIsLimitedTries={setIsLimitedTries}
        />
        <Button
          data-testid={`${formId}-submit-button`}
          type="submit"
          cc-color="purple"
          cc-width="full"
          loading={isLoading}
        >
          {buttonText}
        </Button>
      </Styles.Form>
    </CheckYourEmail>
  )
}

export default SignUpVerificationCodeForm
