import { useEffect, useState } from 'react'
import Button from 'components/ui/Button'
import Spinner from 'components/ui/Spinner'
import SearchContainer from 'layouts/components/Search/SearchContainer'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import AddCategoryModal from './AddCategoryModal'
import { Cancel, CheckCircle, DragIndicator } from '@mui/icons-material'
import {
  useGetCategoriesQuery,
  useUpdateCategoryOrderMutation
} from 'services/marketplace'
import { formatDateTime } from 'utils/intlDate'
import { Link } from 'react-router-dom'
import { Chip } from '@mui/material'
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder
} from '@hello-pangea/dnd'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material'
import * as Styles from 'layouts/PrivateLayout.styles'
import Empty from 'components/ui/Table/Empty'

const Categories = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { data, isLoading } = useGetCategoriesQuery({
    limit: 80,
    page: 1
  })
  console.log(data);
  const [rows, setRows] = useState(data?.marketplace_categories || [])
  const [updateCategory] = useUpdateCategoryOrderMutation()

  const handleDragEnd: OnDragEndResponder = async (e) => {
    if (!e.destination) return

    let tempData = Array.from(rows)
    let [source_data] = tempData.splice(e.source.index, 1)
    tempData.splice(e.destination.index, 0, source_data)
    setRows(tempData)

    await updateCategory({
      categoryId: e.draggableId,
      order: e.destination.index + 1
    })
  }

  const addCustomerAction = (
    <Styles.SearchContainerButtons>
      <Button
        startIcon={<AddOutlinedIcon />}
        cc-color="purple"
        onClick={() => setIsOpenModal(true)}
      >
        New Category
      </Button>
    </Styles.SearchContainerButtons>
  )

  useEffect(() => {
    if (data?.marketplace_categories.length) {
      setRows(data.marketplace_categories)
    }
  }, [data])

  return (
    <div>
      <AddCategoryModal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
      <SearchContainer action={addCustomerAction} />
      <div className="pt-2">
        <Styles.PageTitleContainer>Categories</Styles.PageTitleContainer>
      </div>
      <div>
        {isLoading ? (
          <Spinner />
        ) : (
          <DragDropContext onDragEnd={handleDragEnd}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="right">Created at</TableCell>
                    <TableCell align="right">Active</TableCell>
                    <TableCell align="right">Home</TableCell>
                  </TableRow>
                </TableHead>
                <Droppable droppableId="droppable-1">
                  {(provider) => (
                    <TableBody
                      ref={provider.innerRef}
                      {...provider.droppableProps}
                    >
                      {rows.map((row, index) => (
                        <Draggable
                          key={row.id}
                          draggableId={row.id}
                          index={index}
                        >
                          {(provider, snapshot) => (
                            <TableRow
                              key={row.id}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0
                                }
                              }}
                              {...provider.draggableProps}
                              {...provider.dragHandleProps}
                              className={
                                snapshot.isDragging
                                  ? 'bg-primary-100 is-dragging !inline-table'
                                  : ''
                              }
                              ref={provider.innerRef}
                            >
                              <TableCell component="th" scope="row">
                                <DragIndicator />
                              </TableCell>
                              <TableCell>
                                <div className="underline">
                                  <Link to={`/admin/categories/${row.id}`}>
                                    {row.category_name}
                                  </Link>
                                </div>
                              </TableCell>
                              <TableCell align="right">
                                {formatDateTime(new Date(row.created_at))}
                              </TableCell>
                              <TableCell align="right">
                                {row.active ? (
                                  <Chip
                                    label={<CheckCircle />}
                                    color="success"
                                  />
                                ) : (
                                  <Chip label={<Cancel />} color="error" />
                                )}
                              </TableCell>
                              <TableCell align="right">
                                {row.is_home ? (
                                  <Chip
                                    label={<CheckCircle />}
                                    color="success"
                                  />
                                ) : (
                                  <Chip label={<Cancel />} color="error" />
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                      {rows.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <Empty
                              emptyTitleText="No categories yet"
                              emptyText='You can add categories by clicking on "New Category" button'
                            />
                          </TableCell>
                        </TableRow>
                      )}
                      {provider.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </Table>
            </TableContainer>
          </DragDropContext>
        )}
      </div>
    </div>
  )
}

export default Categories
