import { createContext, PropsWithChildren, useEffect, useState } from "react";

type ExtensionDetailsContextType = {
  isInstalled: boolean;
};

const initialData: ExtensionDetailsContextType = {
  isInstalled: false
};

export const ExtensionDetailsContext = createContext<ExtensionDetailsContextType>(initialData)

export const ExtensionDetailsProvider = (props: PropsWithChildren) => {
  const [extensionDetails, setExtensionDetails] = useState<ExtensionDetailsContextType>(initialData)

  useEffect(() => {
    const checkExtensionInstalled = () => {
      window.postMessage({ type: 'CHECK_EXTENSION_INSTALLED' }, '*');
    
      window.addEventListener('message', (event) => {
        if (event.source !== window) {
          return;
        }
    
        if (event.data && event.data.type === 'EXTENSION_STATUS') {
          if (event.data.installed) {
            console.log('[HERE!!!!]- Extension is installed -[HERE!!!!]');
            setExtensionDetails(prev => ({ ...prev, isInstalled: true }));
          }
        }
      });
    };
    
    checkExtensionInstalled();
  }, []);

  return (
    <ExtensionDetailsContext.Provider value={extensionDetails}>
      {props.children}
    </ExtensionDetailsContext.Provider>
  )
};