import * as Styles from 'layouts/PrivateLayout.styles'
import SearchContainer from 'layouts/components/Search/SearchContainer'
import { useGetOrdersQuery } from 'services/orders'
import OrdersTable from './OrdersTable'
import { getTablePageLimit } from 'utils/getTablePageLimit';
import { usePagination } from 'hooks/usePagination'

const LIMIT = getTablePageLimit()

const Orders = () => {
  const { page, setPage } = usePagination()
  const { data, isLoading, isFetching } = useGetOrdersQuery({
    limit: LIMIT,
    page
  })

  return (
    <div>
      <SearchContainer />
      <div className="pt-2">
        <Styles.PageTitleContainer>Orders</Styles.PageTitleContainer>
      </div>
      <OrdersTable
        data={data?.orders || []}
        isLoading={isLoading}
        isFetching={isFetching}
        recordsCount={data?.records_count}
        limit={LIMIT}
        page={page}
        setPage={setPage}
      />
    </div>
  )
}

export default Orders
