import React, { ChangeEvent, useEffect, useState } from 'react'
import { styled } from "@mui/material";
import tw from "twin.macro";
import { Image, Skeleton, Breadcrumbs, BreadcrumbItem, Button, Link, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from '@nextui-org/react';
import { IoMdInformationCircleOutline } from "react-icons/io";

import { HorizontalScrollCard } from 'components/NewComponents/HorizontalScrollCard'
import { HorizontalScrollCardBig } from 'components/NewComponents/HorizontalScrollCardBig'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cookies from 'universal-cookie'
import { useGetBannersFromCategoryQuery } from 'services/marketplace';

import {
  useGetBannersQuery,
} from 'services/marketplace'

import MerchantsByBannerNew from 'components/NewComponents/MerchantsByBannerNew';
import useFilterWall from 'hooks/useFilterWall';
import { useParams } from 'react-router-dom';
import PrizesBannersSlider from 'components/NewComponents/PrizesBannersSlider';
import { useGetPrizesQuery } from 'services/prizes';
import InstallExtensionBanner from 'components/NewComponents/InstallExtensionBanner';
import { amplitudeSetUserId, amplitudeLogEvent } from '../../amplitudeClient';
import { useGetCustomerQuery } from 'services/customers';
import { ChromeExtensionOnboardingModal } from './ChromeExtensionSplashScreen';
import { LeftArrow, RightArrow } from 'components/NewComponents/HorizontalScrollList';
import { useNavigate } from 'react-router-dom';
const elemPrefix = "test";
const getId = (index: number) => `${elemPrefix}${index}`;

const SubcategoryPage = () => {
  const navigate = useNavigate();

  const { categoryId = '', subcategoryId = '' } = useParams()

  const { categories } = useFilterWall()
  const subcategory = categories?.marketplace_categories.find((category) => category.id === subcategoryId)
  const category = categories?.marketplace_categories.find((category) => category.id === categoryId)
  const { data: banners, isLoading: isLoadingBanners, isFetching } = useGetBannersFromCategoryQuery({
    bannerId: subcategoryId || '',
  })
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Check screen size on initial render
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const customerId: any = localStorage.getItem('customerId');
  const customerDetails = useGetCustomerQuery(customerId);
  
  const redirectToSplashScreen = (merchant_name: string, merchant_id: string, merchant_url: string, image_url: string) => {
    const DeviceID = localStorage.getItem('DeviceID')
    const url = merchant_url
    const urlObject = new URL(url)
    const trackingCode = localStorage.getItem('customerId')
  
    const cookies = new Cookies(null, { path: '/' })
    cookies.set(urlObject.hostname, true, {
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24)
    })
  
    const customerName = customerDetails?.data?.customer?.email || 'Unknown';
    amplitudeSetUserId(customerName);
    amplitudeLogEvent({
      event_type: `Merchant Visited`,
      event_properties: {
        merchant_id: merchant_id,
        merchant_url: merchant_url,
        merchant_name: merchant_name,
        device_id: DeviceID,
        customer_id: trackingCode,
        customer_name: customerName,
        timestamp: new Date().toISOString(),
      }
    });
  
    window.open(`/m?merchant_name=${merchant_name}&merchant_id=${merchant_id}&merchant_url=${merchant_url}&image_url=${image_url}`, '_blank');
  }

  const Wrapper = styled('div')`
    position: relative;
    & .slick-slider {
      /* Warn: Dont remove this padding or the arrow will overlap the cards */
      ${tw`lg:px-12`}
    }
  `;

  

  const {isOpen, onOpen, onOpenChange} = useDisclosure();

  return (
    isLoadingBanners ? 
      <div className="min-h-screen">
        <Skeleton className="mx-10 my-5 rounded-lg">
          <div className="h-96">
            Loading banners
          </div>
        </Skeleton>

        <Skeleton className="mx-10 my-5 rounded-lg">
          <div className="h-96">
            Loading banners
          </div>
        </Skeleton>
      </div> 
      :
      <div className="overflow-x-hidden">
      <div className="flex flex-col w-full">
        <div className="h-8 lg:h-16">
          {categoryId === 'fcb47d06-0bd0-40b7-9ac0-37d12eb83d45' && (
            <div className="flex flex-row justify-end w-full p-2 lg:p-4">
              <div className="flex flex-row gap-2 p-3 rounded-xl w-fit hover:bg-primary-400/[0.2] cursor-pointer" onClick={onOpen}>
                <p className="text-xs lg:text-sm text-gray-600">How we make money</p>
                <IoMdInformationCircleOutline className="text-gray-600" size={20} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col w-full items-center mb-10">
        <div className="px-2 lg:px-28 w-full max-w-[1700px]">
          <Breadcrumbs separator="/">
            <BreadcrumbItem><Link href={`/`} className="text-base lg:text-lg font-semibold hover:underline hover:text-[#37B5FF]">Home</Link></BreadcrumbItem>
            <BreadcrumbItem><Link href={`/merchants/${category?.id}`} className="text-base lg:text-lg font-semibold hover:underline hover:text-[#37B5FF]">{category?.category_name}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link href={`/merchants/${category?.id}/${subcategory?.id}`} className="text-base lg:text-lg font-semibold text-[#37B5FF]">{subcategory?.category_name}</Link></BreadcrumbItem>  
          </Breadcrumbs>
        </div>
      </div>
      <div className="flex flex-col w-full items-center">
        <div className="flex flex-col gap-6 px-2 lg:px-28 md:gap-6 w-full max-w-[1700px]">
            {banners?.marketplace_banners.slice().sort((a, b) => a.order - b.order).map((banner) => (
              <div 
                key={banner.id} 
                className={`relative -mx-2 lg:-mx-28 py-4 ${banner.banner_background_color ? 'lg:py-16' : 'lg:py-8'} px-2 lg:px-28`}
                style={{backgroundColor: banner.banner_background_color || ''}}
              >
                {/* Background element */}
                <div 
                  className="absolute inset-0 -z-10 w-screen left-1/2 -translate-x-1/2" 
                  style={{backgroundColor: banner.banner_background_color || ''}}
                />

                  <div className="text-xl px-2 lg:px-[3.5rem] md:text-2xl font-semibold flex flex-row gap-2 items-center">
                    <p style={{color: banner.text_color || 'black'}}>{banner.banner_name}</p>
                  </div>
                  <div className="mt-4">
                    <MerchantsByBannerNew
                      bannerId={banner.id}
                      num_lines={banner.num_lines}
                      redirectToSplashScreen={redirectToSplashScreen}
                      type={banner.image_size || 'rectangle'}
                      textColor={banner.text_color || 'black'}
                  />
                </div>
              </div>
            ))}    
          </div>
      </div>
      <MakeMoneyModal isOpen={isOpen} onOpenChange={onOpenChange} />
      </div>
  )
}

export default SubcategoryPage;

const MakeMoneyModal = ({ isOpen, onOpenChange }: { isOpen: boolean, onOpenChange: () => void }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onOpenChange}
      scrollBehavior='inside'
      size='2xl'
    >
      <ModalContent className='p-8'>
        <ModalHeader>
          <h2 className='text-xl font-semibold text-left w-full'>How do we make money?</h2>
        </ModalHeader>
        <ModalBody className="text-sm">
          <p>
            {`
             Our business is based on a simple idea: When you follow our links to visit a store or click activate on our extension, that store pays us a commission on whatever you buy during your visit. We then use that commission to give away awesome prizes to our luckiest users (hopefully you!).
            `}
          </p>
          <p>
            {`The more you shop with us, the bigger the prizes we can give away. But don’t worry, even if you don’t have anything you want to buy right now, you can still earn tokens by claiming your free bonus every day.`}
          </p>
          <p>
            {`You can also earn tokens without making a purchase by sending us a letter that follows the instructions on our Rules and Terms page. THERE IS NO PURCHASE NECESSARY TO ENTER. Purchasing will not increase your chances of winning.`}
          </p>
          <p>
            {`And if you download our chrome extension, you’ll receive an instant token bonus as a reward.`}
          </p>
          <p>
            <p>Have a prize that you want to win? Send us an email at <span className="text-primary-400">support@joinwave.com</span> and {"we’ll"} look into giving it away next!</p>
          </p>
        </ModalBody>
        <ModalFooter className='flex flex-row justify-center mt-6 py-0'>
          <Button onClick={onOpenChange} size="lg" className="text-2xl bg-primary-400 text-white px-8 rounded-lg font-semibold">
            Got it
          </Button>
      </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
