import Button from 'components/ui/Button'
import TextField from 'components/ui/TextField'
import { FC, useRef, useState } from 'react'
import Modal from 'components/ui/Modal'
import { useNotification } from 'hooks/useNotification'
import { IPrize, useAddPrizeMutation } from 'services/prizes'
import RichText, { UncontrolledRichTextEditorRef } from 'components/ui/RichText'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { defaultDetailsPageStyles } from 'constants/prize-details'

interface IAddPrizeModalProps {
  open: boolean
  onClose: () => void
}

const AddPrizeModal: FC<IAddPrizeModalProps> = ({
  open,
  onClose
}) => {
  const { sendSuccessNotification, sendErrorNotification } = useNotification()
  const [addPrize] = useAddPrizeMutation()
  const richTextRef = useRef<UncontrolledRichTextEditorRef>(null)
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [detailPageStyle, setDetailPageStyle] = useState<IPrize['prize_details_page_styles']>({
    image: defaultDetailsPageStyles.image
  })

  const handleChangeDetailPageStyle = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setDetailPageStyle(prev => ({
      ...prev,
      image: {
        ...prev.image,
        [field]: value,
      }
    }))
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const name = formData.get('name') as string
    const imageURL = formData.get('imageURL') as string
    const headerImageURL = formData.get('headerImageURL') as string
    const mobileImageURL = formData.get('mobileImageURL') as string
    const detailBgImageURL = formData.get('detailBgImageURL') as string
    const startDate = formData.get('startDate') as string
    const endDate = formData.get('endDate') as string
    const tokenCost = parseInt(formData.get('tokenCost') as string)
    const linkName = formData.get('linkName') as string
    const linkURL = formData.get('linkUrl') as string
    const details = richTextRef.current?.getValue();
    const hexColor = formData.get('hexColor') as string
    const order = parseInt(formData.get('order') as string)

    // console.log(`Name: ${name}`);
    // console.log(`Image URL: ${imageURL}`);
    // console.log(`Start Date: ${startDate}`);
    // console.log(`End Date: ${endDate}`);
    // console.log(`Token Cost: ${tokenCost}`);
    // console.log(`Link Name: ${linkName}`);
    // console.log(`Link URL: ${linkURL}`);
    // console.log(`Details: ${details}`);

    if (!name || !imageURL || !detailBgImageURL || !startDate || !endDate || !tokenCost || !order || !linkName || !linkURL || !details || !hexColor || richTextRef.current?.isEmpty()) {
      sendErrorNotification('All fields are required')
      return
    }

    if (order < 1) {
      sendErrorNotification('Order number must be 1 or greater')
      return
    }

    if (new Date(endDate) < new Date(startDate)) {
      sendErrorNotification('End date should be greater than start date')
      return
    }

    try {
      setLoadingSubmit(true);
      const response: any = await addPrize({
        name,
        details,
        imageURL,
        headerImageURL,
        mobileImageURL,
        detailBgImageURL,
        detailsPageStyles: detailPageStyle,
        tokenCost,
        startDate,
        endDate,
        linkName,
        linkUrl: linkURL,
        hexColor,
        order
      })
      
      if (response.data) {
        sendSuccessNotification('Prize Added Successfully')
        onClose()
      }
    } catch (error) {
      sendErrorNotification('Failed to add prize') 
    } finally {
      setLoadingSubmit(false)
    }
  }

  const renderField = ({
    id,
    label,
    placeholder,
    type,
    autoFocus
  }: {
    id: string
    label: string
    placeholder: string
    type: string
    autoFocus?: boolean
  }) => (
    <div className="flex flex-col lg:flex-row mt-4">
      <label className="w-40 text-gray-700 mb-2">{label}</label>
      <div className='flex-1'>
        {id === 'details' ? (
          <RichText.UncontrolledEditor ref={richTextRef} />
        ) : (
          <TextField
            id={id}
            type={type}
            hiddenLabel
            cc-width="full"
            placeholder={placeholder}
            required
            autoFocus={autoFocus}
          />
        )}
      </div>
    </div>
  )

  const modalBody = (
    <>
      {renderField({
        id: 'name',
        label: 'Prize Name *',
        type: 'text',
        placeholder: 'Prize Name',
        autoFocus: true
      })}
      {renderField({
        id: 'imageURL',
        label: 'Image URL *',
        type: 'text',
        placeholder: 'Image URL',
        autoFocus: false
      })}
      {renderField({
        id: 'detailBgImageURL',
        label: 'Detail Bg Image *',
        placeholder: 'Detail Bg Image',
        type: 'text',
        autoFocus: false
      })}
      {renderField({
        id: 'headerImageURL',
        label: 'Header Image URL *',
        type: 'text',
        placeholder: 'Header Image URL',
        autoFocus: false
      })}
      {renderField({
        id: 'mobileImageURL',
        label: 'Mobile Image URL *',
        type: 'text',
        placeholder: 'Mobile Image URL',
        autoFocus: false
      })}
      {renderField({
        id: 'startDate',
        label: 'Start Date *',
        type: 'date',
        placeholder: 'Start Date'
      })}
      {renderField({
        id: 'endDate',
        label: 'End Date *',
        type: 'date',
        placeholder: 'End Date'
      })}
      {renderField({
        id: 'tokenCost',
        label: 'Token Cost *',
        type: 'number',
        placeholder: '20'
      })}
      {renderField({
        id: 'linkName',
        label: 'Link Name *',
        type: 'text',
        placeholder: 'Link Name'
      })}
      {renderField({
        id: 'linkUrl',
        label: 'Link URL *',
        type: 'text',
        placeholder: 'Link URL'
      })}
      {renderField({
        id: 'hexColor',
        label: 'Hex Color *',
        type: 'text',
        placeholder: '#1DA1F2'
      })}
      {renderField({
        id: 'order',
        label: 'Order *',
        type: 'number',
        placeholder: '1'
      })}
      {renderField({
        id: 'details',
        label: 'Details *',
        type: 'text',
        placeholder: 'Details'
      })}
      <details className='mt-4'>
        <summary>Style Customizations on Prize details page</summary>
        <div className='mt-4 grid grid-cols-2 gap-4'>
          <TextField
            id='width'
            type='number'
            value={detailPageStyle.image.width}
            label="Product Image Width"
            cc-width="full"
            onChange={handleChangeDetailPageStyle('width')}
          />
          <TextField
            id='height'
            type='number'
            value={detailPageStyle.image.height}
            label="Product Image Height"
            cc-width="full"
            onChange={handleChangeDetailPageStyle('height')}
          />
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Product Image Position</FormLabel>
            <RadioGroup
              onChange={handleChangeDetailPageStyle('position')}
              aria-labelledby="demo-radio-buttons-group-label"
              value={detailPageStyle.image.position}
              id="position"
            >
              <FormControlLabel value="center" control={<Radio />} label="Center" />
              <FormControlLabel value="top" control={<Radio />} label="Top" />
            </RadioGroup>
          </FormControl>
        </div>
      </details>
    </>
  )

  const modalActions = (
    <>
      <Button disabled={loadingSubmit} onClick={onClose} cc-width="full">
        Cancel
      </Button>
      <Button loading={loadingSubmit} disabled={loadingSubmit} cc-color="purple" cc-width="full" type="submit">
        Add Prize
      </Button>
    </>
  )

  return (
    <Modal
      title="Add Prize"
      open={open}
      onClose={loadingSubmit ? () => {} : onClose}
      body={modalBody}
      actions={modalActions}
      onSubmit={handleSubmit}
    />
  )
}

export default AddPrizeModal
