import Dashboard from 'modules/Admin/Dashboard'

function DashboardPage() {
  return (
    <div data-testid="adminDashboardPage">
      <Dashboard />
    </div>
  )
}

export default DashboardPage
