
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useFilterWall from 'hooks/useFilterWall';
import Spinner from 'components/ui/Spinner';
import Cookies from 'universal-cookie'
import useImageResolver from 'hooks/useImageResolver';
import {Card, CardBody, Image, Button} from "@nextui-org/react";
import { useGetCustomerQuery } from 'services/customers';
import { amplitudeLogEvent, amplitudeSetUserId } from 'amplitudeClient';

const generateLink = (merchantId: string, merchantUrl: string) => {
    const DeviceID = localStorage.getItem('DeviceID')
    const url = merchantUrl
    const urlObject = new URL(url)
    const trackingCode = localStorage.getItem('customerId')

    const cookies = new Cookies(null, { path: '/' })
    cookies.set(urlObject.hostname, true, {
        expires: new Date(Date.now() + 1000 * 60 * 60 * 24)
    })

    const generatedLink = `https://wild.link/e?c=${merchantId}&d=${DeviceID}&url=${url}&tc=${trackingCode}`
    console.log(generateLink)
    window.open(generatedLink, '_blank')
}

const SearchPage = () => {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const searchTerm = queryParams.get('search');
    const customerId: any = localStorage.getItem('customerId');
    const customerDetails = useGetCustomerQuery(customerId);

    const { merchants, list, isLoading, isFetching, isFiltering, setInputSearch, setPage } = useFilterWall();

    const { csvData } = useImageResolver();
    const getMerchantImage = (merchantId: string) => {
      const merchant = csvData.find(item => item['Merchant ID'] === merchantId);
      return merchant ? merchant['Image Link'] : null;
    };

    const [displayedSearchTerm, setDisplayedSearchTerm] = useState(searchTerm);

    useEffect(() => {
        setInputSearch(searchTerm || '');
    }, [searchTerm, setInputSearch]);

    useEffect(() => {
        if (!isLoading && !isFetching && !isFiltering) {
            setDisplayedSearchTerm(searchTerm);
        }
    }, [searchTerm, isLoading, isFetching, isFiltering]);

    const redirectToSplashScreen = (merchant_name: string, merchant_id: string, merchant_url: string, image_url: string) => {
        const DeviceID = localStorage.getItem('DeviceID')
        const url = merchant_url
        const urlObject = new URL(url)
        
        const cookies = new Cookies(null, { path: '/' })
        cookies.set(urlObject.hostname, true, {
            expires: new Date(Date.now() + 1000 * 60 * 60 * 24)
        })
        
        const customerName = customerDetails?.data?.customer?.email || 'Unknown';
        amplitudeSetUserId(customerName);
        amplitudeLogEvent({
            event_type: `Merchant Visited`,
            event_properties: {
            merchant_id: merchant_id,
            merchant_url: merchant_url,
            merchant_name: merchant_name,
            device_id: DeviceID,
            customer_id: customerId,
            customer_name: customerName,
            timestamp: new Date().toISOString(),
            }
        });
        
        window.open(`/m?merchant_name=${merchant_name}&merchant_id=${merchant_id}&merchant_url=${merchant_url}&image_url=${image_url}`, '_blank');
    }
        
    return (
        <div className="min-h-screen w-full flex flex-col p-4">
            {isLoading || isFetching || isFiltering ? (
                <Spinner />
            ) : (
                <div className="flex flex-col gap-2 w-full items-center mx-auto">
                    <div className="mb-5 ml-10 w-full">
                        <p className="text-2xl font-semibold">Merchants</p>
                        <p className="text-base text-default-900">{list.length} results for {displayedSearchTerm}</p>
                    </div>
                    <div className="flex flex-row flex-wrap justify-center w-fit gap-6">
                        {list.map((merchant) => (
                            <div
                                role="button"
                                style={{
                                    userSelect: "none",
                                }}
                                className=" mx-1 md:mx-3 card"
                                tabIndex={0}
                            >
                                <div className="flex flex-col items-center">
                                    <Card 
                                        isPressable 
                                        className={`h-20 md:w-36 md:h-32 md:w-60 w-fit text-left dark:bg-white shadow-xl p-0`} 
                                        style={{ 
                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                                            borderTop: '0.1px solid #d3d3d3',
                                            padding: '0'
                                        }}
                                        onPress={() => redirectToSplashScreen(
                                            merchant.name,
                                            merchant.source_merchant_id,
                                            merchant.url,
                                            merchant.image_url1,
                                        )}
                                    >
                                        <CardBody style={{padding: '0px'}} className="overflow-visible w-full flex-col items-center">
                                            <Image
                                                alt="Card background"
                                                className="object-contain rounded-xl h-20 w-28 md:h-32 md:w-56"
                                                src={getMerchantImage(merchant.id) || merchant.image_url1 || merchant.image_url2}
                                            />
                                        </CardBody>
                                    </Card>
                                    
                                    <div className="w-full p-2 flex flex-col flex-start text-xs md:text-sm items-center text-center">
                                        <p className="font-semibold">{merchant.name}</p>
                                        {/* @ts-ignore */}
                                        <p>{merchant.num_tokens ? `${merchant.num_tokens} Tokens per $1 Spent` : '10 Tokens per $1 Spent'}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                 
                    {list.length === 0 && (
                        <div className="text-lg text-primary-500">
                            No merchants found.
                        </div>
                    )}

                    {merchants?.next_page && !isFiltering && list.length !== 0 && (
                        <div className="flex justify-center mt-5 pb-20">
                            <Button
                            cc-color="purple"
                            onClick={() => setPage(merchants?.next_page || 1)}
                            type="submit"
                            disabled={isFetching}
                            >
                            {isFetching ? (
                                <div>
                                Loading...
                                </div>
                            ) : (
                                <div>
                                Load more
                                </div>
                            )}
                            </Button>
                        </div>
                        )}
                </div>
            )}
        </div>
    );
};

export default SearchPage;