import { api } from './api'
import { IMerchant } from './merchants'
import { IOrder } from './orders'

export interface IGetTotalEntriesResponse {
  count: number
}

export interface PrizePeriod {
  id: string
  name: string
  start_date: string
  end_date: string
}

export interface IGetPrizePeriodsResponse {
  prize_periods: PrizePeriod[]
}

export interface ICustomer {
  id: string
  cognito_id: string
  cognito_created_at: string
  cognito_verified: boolean
  name: string
  email: string
  phone: string
  orders_count: number
  created_at: string
  updated_at: string
  accepts_transactional_emails: boolean
  accepts_marketing_emails: boolean
  received_chrome_tokens: boolean
  received_initial_tokens: boolean
  first_login: boolean
}

export interface IGetCustomersResponse {
  customers: ICustomer[]
  records_count: number
  next_page: number
  previous_page: number
}

export interface PaginationParams {
  limit: number
  page: number
}

export interface IEntry {
  id: string
  prize_period_id: string
  order_id: string
  admin_id: string
  number_of_entries: number
  email: string
  phone: string
  order: IOrder
  merchant: IMerchant
  created_at: string
  updated_at: string
}

export interface IGetCustomerEntriesResponse {
  entries: IEntry[]
  records_count: number
  next_page: number
  previous_page: number
}

export interface IGetCustomerDailyStreakBonusResponse {
  DailyStreakBonusMap: Record<string, number>;
  reward_streak_count: number;
  last_reward_claimed_at: string | null;
  current_streak_total_earned: number;
}

export interface IClaimCustomerDailyStreakBonusParams {
  customerId: string;
}

export interface IGetCustomerUnclaimedEntriesResponse {
  unclaimed_entries_sum: number;
  unclaimed_entries_count: number;
}

export interface IGetCustomerNumbersGameResponse {
  numbers_game_last_played_numbers?: number[];
  numbers_game_last_played_at: string | null;
  total_placeholders: number;
  min_digit: number;
  max_digit: number;
}

export const customersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCustomers: build.query<IGetCustomersResponse, PaginationParams>({
      query: ({ limit, page }) => {
        return { url: `/customers?limit=${limit}&page=${page}`, method: 'GET' }
      },
      providesTags: ['Customers']
    }),
    getCustomer: build.query<{ customer: ICustomer }, string>({
      query: (customerId) => {
        return { url: `/customers/${customerId}`, method: 'GET' }
      }
    }),
    getCustomerEntries: build.query<
      IGetCustomerEntriesResponse,
      { customerId: string; limit: number; page: number }
    >({
      query: ({ customerId, page, limit }) => {
        return {
          url: `/customers/${customerId}/entries?page=${page}&limit=${limit}`,
          method: 'GET'
        }
      },
      providesTags: ['Entries']
    }),
    getTotalEntries: build.query<IGetTotalEntriesResponse, string>({
      query: (customerId) => {
        return { url: `/customers/${customerId}/entries/count`, method: 'GET' }
      }
    }),
    getCustomerNumbersGame: build.query<IGetCustomerNumbersGameResponse, string>({
      query: (customerId) => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return { 
          url: `/customers/${customerId}/numbers-game?timezone=${encodeURIComponent(timezone)}`, 
          method: 'GET'
        };
      },
    }),
    guessNumbersGame: build.mutation<any, {customerId: string, guess: number[]}>({
      query: ({ customerId, guess }) => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return {
          url: `/customers/${customerId}/numbers-game/guess`,
          method: 'POST',
          body: {
            guessed_number: guess.join('-'),
            timezone
          }
        }
      }
    }),
    getCustomerUnclaimedEntries: build.query<IGetCustomerUnclaimedEntriesResponse, string>({
      query: (customerId) => {
        return { url: `/customers/${customerId}/unclaimed_entries`, method: 'GET' }
      },
    }),
    claimUnclaimedEntries: build.mutation<{ success: boolean }, { customerId: string }>({
      query: ({ customerId }) => {
        return { url: `/customers/${customerId}/unclaimed_entries/claim`, method: 'POST' }
      },
    }),
    getCustomerDailyStreakBonus: build.query<IGetCustomerDailyStreakBonusResponse, string>({
      query: (customerId) => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return { 
          url: `/customers/${customerId}/daily-streak-bonus?timezone=${encodeURIComponent(timezone)}`, 
          method: 'GET'
        };
      },
    }),
    claimDailyStreakBonus: build.mutation<IGetCustomerDailyStreakBonusResponse, IClaimCustomerDailyStreakBonusParams>({
      query: ({ customerId }) => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return {
          url: `/customers/${customerId}/daily-streak-bonus/claim`,
          method: 'POST',
          body: {
            timezone
          }
        }
      },
    }),
    updateCustomer: build.mutation<
      any,
      {
        customerId: string
        name: string
        accepts_transactional_emails: boolean
        accepts_marketing_emails: boolean
      }
    >({
      query: ({
        customerId,
        name,
        accepts_transactional_emails,
        accepts_marketing_emails
      }) => ({
        url: `/customers/${customerId}`,
        method: 'PUT',
        body: {
          name,
          accepts_transactional_emails,
          accepts_marketing_emails
        }
      })
    }),
    claimInitialEntries: build.mutation<
      any,
      { customerId: string }
    >({
      query: ({ customerId }) => ({
        url: `/customers/initial_entries`,
        method: 'POST',
        body: {
          customer_id: customerId
        }
      })
    }),
    addCustomerEntries: build.mutation<
      any,
      { customerId: string; entries: number }
    >({
      query: ({ customerId, entries }) => ({
        url: `/customers/${customerId}/entries`,
        method: 'POST',
        body: {
          number_of_entries: entries
        }
      }),
      invalidatesTags: ['Entries']
    }),
    addInitial100Tokens: build.mutation<
      any,
      {
        customer_id: string
      }
    >({
      query: ({
        customer_id,
      }) => ({
        url: `/customers/initial_tokens`,
        method: 'POST',
        body: {
          customer_id: customer_id
        }
      })
    }),
    addInitial500ExtTokens: build.mutation<
      any,
      { customerId: string }
    >({
      query: ({ customerId }) => ({
        url: `/customers/initial_chrome_extension_entries`,
        method: 'POST',
        body: {
          customer_id: customerId
        }
      })
    }),
  })
})

export const {
  useGetTotalEntriesQuery,
  useGetCustomerEntriesQuery,
  useGetCustomersQuery,
  useGetCustomerQuery,
  useAddCustomerEntriesMutation,
  useUpdateCustomerMutation,
  useAddInitial100TokensMutation,
  useClaimDailyStreakBonusMutation,
  useGetCustomerDailyStreakBonusQuery,
  useClaimInitialEntriesMutation,
  useAddInitial500ExtTokensMutation,
  useGetCustomerUnclaimedEntriesQuery,
  useClaimUnclaimedEntriesMutation,
  useGetCustomerNumbersGameQuery,
  useGuessNumbersGameMutation
} = customersApi
