import { styled } from "@mui/material";
import { Button } from "@nextui-org/react";
import tw from "twin.macro";

export const StyledButton = styled(Button)`
  ${tw`bg-primary-400 text-white font-semibold`};

  &:disabled {
    ${tw`text-black bg-gray-300 opacity-50 cursor-not-allowed`}
  }
`;
