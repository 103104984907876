import { Button } from "@nextui-org/react";
import { StyledButton } from "../styles";
import { useState } from "react";
import { Skeleton, styled } from "@mui/material";
import tw from "twin.macro";
import clsx from "clsx";
import useGetNumbersGameData from "services/hooks/useGetNumbersGameData";
import { useAppSelector } from "store/hooks";

const NumberCircle = styled('div')`
  box-shadow: 0px 0px 30px 5px white, inset 0px 0px 12px -8px black;

  @media (min-width: 768px) {
    box-shadow: 0px 0px 30px 15px white, inset 0px 0px 12px -8px black;
  }
`;

const GuessNumber = ({ value, total }: { value: number[]; total: number; }) => {
  return (
    <div className="flex justify-center gap-2 sm:gap-6">
      {Array.from({ length: total }).fill(0).map((_, index) => (
        <NumberCircle 
          className={clsx("w-[60px] h-[60px] sm:w-20 sm:h-20 transition-all bg-white flex justify-center items-center text-3xl sm:text-4xl font-semibold rounded-full border-5 border-primary-400", {
            'opacity-100': !!value[index],
            'opacity-50': !value[index],
          })}
        >
          {value[index] || ' '}
        </NumberCircle>
      ))}
    </div>
  )
}

type NumbersProps =  { 
  interval: [number, number]; 
  totalPlaceholders: number;
  guessNumber: number[];
  disabled?: boolean;
  onClick: (value: number) => void;
};
const NumbersOptions = ({ interval, totalPlaceholders, guessNumber, onClick, disabled }: NumbersProps) => {
  const numbers = Array.from({ length: interval[1] - interval[0] + 1 }, (_, i) => i + 1);
  const isDisabled = (num: number) => guessNumber.length === totalPlaceholders || guessNumber.includes(num) || disabled;
  return (
    <div className="grid grid-cols-5 grid-rows-2 w-[200px] gap-5 sm:w-auto sm:flex sm:justify-center sm:gap-8">
      {numbers.map((numberValue) => (
        <div 
          className={clsx("text-xl text-center font-semibold cursor-pointer", {
            'opacity-30': isDisabled(numberValue)
          })}
          onClick={() => isDisabled(numberValue) ? undefined : onClick(numberValue)}
        >
          {numberValue}
        </div>      
      ))}
    </div>
  )
}

const Wrapper = styled('div')`
  ${tw`h-[612px] lg:h-[565px] p-3 lg:p-6 pt-5 flex flex-col justify-end`}
  position: relative;
  background: url('/images/numbers-game-bg-small.png'); 
  background-size: cover;
  background-position-x: center;
  background-position-y: -30px;

  @media (min-width: 640px) {
    background: url('/images/numbers-game-bg-big.png'); 
    background-size: cover;
    background-position-x: center;
    background-position-y: -70px;

    &::after {
      box-shadow: inset 0 -130px 70px -10px white, inset 0 56px 70px -10px white !important;
    }
  }

  &::after {
    content: '';
    z-index: 2;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    box-shadow: inset 0 -210px 70px -10px white, inset 0 56px 70px -10px white;
  }
`;

type NumbersGameModalContentProps = {
  onGuess: (value: number[]) => Promise<void>;
  isGuessing?: boolean;
}

const NumbersGameModalContent = ({ onGuess, isGuessing }: NumbersGameModalContentProps) => {
  const customerId: any = useAppSelector((state) => state.auth.customerId);
  const [nextGuessableTime, setNextGuessableTime] = useState('');
  const { data, isGuessable, isLoading } = useGetNumbersGameData(customerId, setNextGuessableTime);
  const [guessNumber, setGuessNumber] = useState<number[]>([])

  const handleChange = (value: number) => {
    setGuessNumber(prev => [...prev, value]);
  }
  return (
    <Wrapper>
      {isLoading ? <Skeleton className="w-full !h-[200px]" /> : (
        <div className="flex z-[3] flex-col items-center gap-4">
          <GuessNumber
            value={data?.numbers_game_last_played_numbers || guessNumber}
            total={data?.total_placeholders!}
          />
          <NumbersOptions 
            onClick={handleChange}
            disabled={!isGuessable}
            guessNumber={guessNumber}
            interval={[data?.min_digit!, data?.max_digit!]}
            totalPlaceholders={data?.total_placeholders!}
          />
          <div className="flex justify-center gap-2">
            <Button disabled={isGuessing || !isGuessable} variant="bordered" onClick={() => setGuessNumber([])}>
              Clear
            </Button>
            <StyledButton 
              isLoading={isGuessing} 
              disabled={guessNumber.length !== data?.total_placeholders || isGuessing} 
              onClick={() => onGuess(guessNumber)}
            >
              {isGuessable ? 'Submit' : nextGuessableTime}
            </StyledButton>
          </div>
          <span className="text-sm text-center text-gray-500">Pick {data?.total_placeholders} numbers and press Submit!</span>
        </div>
      )}
    </Wrapper>
  )
}

export default NumbersGameModalContent;