import { api } from './api'

export interface IPrize {
  id: string
  name: string
  details: string
  image_url: string
  header_image_url: string
  mobile_image_url: string
  detail_bg_image_url: string
  prize_details_page_styles: {
    image: {
      height: string;
      width: string;
      position: 'center' | 'top';
    }
  }
  token_cost: number
  start_date: string
  end_date: string
  link_name: string
  link_url: string
  hex_color: string
  is_header: boolean
  is_active: boolean
  is_daily_draw: boolean;
  created_at: string
  updated_at: string
}

interface IAddPrize { 
  name?: string; 
  details?: string; 
  imageURL?: string; 
  headerImageURL?: string; 
  mobileImageURL?: string; 
  detailBgImageURL?: string;
  detailsPageStyles: IPrize['prize_details_page_styles'];
  tokenCost?: number; 
  startDate?: string; 
  endDate?: string; 
  linkName?: string; 
  linkUrl?: string; 
  hexColor?: string; 
  order?: number;

}

interface IUpdatePrize extends IAddPrize {
  id: string; 
  isActive?: boolean; 
  isHeader?: boolean; 
  isDailyDraw?: boolean;
}

export interface IGetPrizesResponse {
  prizes: IPrize[]
  records_count: number
  next_page: number
  previous_page: number
}

export const prizesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPrizes: build.query<
      IGetPrizesResponse,
      { limit: number; page: number }
    >({
      query: ({ limit, page }) => {
        return {
          url: `/prizes?limit=${limit}&page=${page}`,
          method: 'GET'
        }
      },
      providesTags: ['Prizes']
    }),
    getPrize: build.query<{ prize: IPrize }, string>({
        query: (prizeId) => {
          return {
            url: `/prizes/${prizeId}`,
            method: 'GET'
          }
        }
    }),
    deletePrize: build.mutation<void, string>({
      query: (prizeId) => ({
        url: `/prizes/${prizeId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Prizes'],
    }),
    addPrize: build.mutation<
      any,
      IAddPrize
    >({
      query: ({ name, details, imageURL, headerImageURL, mobileImageURL, tokenCost, startDate, endDate, linkName, linkUrl, hexColor, detailBgImageURL, detailsPageStyles, order }) => ({
        url: `/prizes`,
        method: 'POST',
        body: {
          name,
          details,
          image_url: imageURL,
          header_image_url: headerImageURL,
          mobile_image_url: mobileImageURL,
          detail_bg_image_url: detailBgImageURL,
          prize_details_page_styles: detailsPageStyles,
          token_cost: tokenCost,
          start_date: startDate,
          end_date: endDate,
          link_name: linkName,
          link_url: linkUrl,
          hex_color: hexColor,
          order
        }
      }),
      invalidatesTags: ['Prizes'],
    }),
  updatePrize: build.mutation<
    any,
    IUpdatePrize
  >({
    query: ({ id, name, details, imageURL, headerImageURL, mobileImageURL, tokenCost, startDate, endDate, linkName, linkUrl, hexColor, isActive, isHeader, detailBgImageURL, detailsPageStyles, order, isDailyDraw }) => ({
      url: `/prizes/${id}`,
      method: 'PUT',
      body: {
        name,
        details,
        image_url: imageURL,
        header_image_url: headerImageURL,
        mobile_image_url: mobileImageURL,
        detail_bg_image_url: detailBgImageURL,
        prize_details_page_styles: detailsPageStyles,
        token_cost: tokenCost,
        start_date: startDate,
        end_date: endDate,
        link_name: linkName,
        link_url: linkUrl,
        hex_color: hexColor,
        is_active: isActive,
        is_header: isHeader,
        is_daily_draw: isDailyDraw,
        order
      }
    }),
    invalidatesTags: ['Prizes'],
  }),
  createPrizeEntry: build.mutation<any, { customerId: string; prizeId: string; entriesRequested: number }>({
    query: ({ customerId, prizeId, entriesRequested }) => ({
      url: `/prizes/entry/`,
      method: 'POST',
      body: {
        customer_id: customerId,
        prize_id: prizeId,
        entries_requested: entriesRequested
      }
    }),
    invalidatesTags: ['Prizes'],
  }),
  getPrizeEntries: build.query<any, { customerId: string }>({
    query: ({ customerId }) => ({
      url: `/prizes/entries/${customerId}`,
      method: 'GET'
    }),
    providesTags: ['Prizes'],
  }),
  })
})

export const {
  useGetPrizesQuery,
  useGetPrizeQuery,
  useDeletePrizeMutation,
  useAddPrizeMutation,
  useUpdatePrizeMutation,
  useCreatePrizeEntryMutation,
  useGetPrizeEntriesQuery,
} = prizesApi
