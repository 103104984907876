import { FC, useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Spinner from 'components/ui/Spinner'
import { useParams } from 'react-router-dom'
import { formatDateTime } from 'utils/intlDate'
import { getTablePageLimit } from 'utils/getTablePageLimit'
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton
} from '@mui/material'
import { DragIndicator, Settings, Timer } from '@mui/icons-material'
import Table from 'components/ui/Table'
import {
  IMarketplaceMerchant,
  IMerchantByCategory,
  useAddMerchantDetailsMutation,
  useAddMerchantToBannerMutation,
  useGetMarketplaceMerchantsQuery,
  useGetMerchantsByBannerQuery,
  useUpdateMerchantBannerOrderMutation
} from 'services/marketplace'
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder
} from '@hello-pangea/dnd'
import RemoveMerchant from 'components/BannerMerchants/RemoveMerchant'
import Empty from 'components/ui/Table/Empty'

const LIMIT = getTablePageLimit()

interface IBannerDetailsMerchantsTableProps {
  isEditing?: boolean
}

const BannerDetailsMerchantsTable: FC<IBannerDetailsMerchantsTableProps> = ({
  isEditing
}) => {
  const { bannerId } = useParams()
  const [page, setPage] = useState(1)
  const [value, setValue] = useState<IMarketplaceMerchant | null>(null)
  const [options, setOptions] = useState<IMarketplaceMerchant[] | undefined>([])
  const { data, isLoading, isFetching } = useGetMerchantsByBannerQuery({
    id: bannerId,
    limit: LIMIT,
    page
  })
  const { data: merchantsByBanner, isLoading: isLoadingMerchantsByBanner } =
    useGetMerchantsByBannerQuery({
      id: bannerId,
      limit: 9999,
      page: 1
    })
  const {
    data: merchants,
    isLoading: isLoadingMerchants,
    isFetching: isFetchingMerchants
  } = useGetMarketplaceMerchantsQuery({
    limit: 6000,
    page: 1
  })
  const [rows, setRows] = useState(data?.marketplace_banner_merchants || [])
  const [updateMerchantOrder] = useUpdateMerchantBannerOrderMutation()
  const [addMerchantToBanner] = useAddMerchantToBannerMutation()
  const [addMerchantDetails] = useAddMerchantDetailsMutation();
  const [openModal, setOpenModal] = useState(false)
  const [description, setDescription] = useState('')
  const [tag, setTag] = useState('')
  const [aesthetic_background_image, setAestheticBackgroundImage] = useState('')
  const [aesthetic_circle_image, setAestheticCircleImage] = useState('')
  const [selectedMerchantId, setSelectedMerchantId] = useState<string | null>(null)

  const handleOpenModal = (merchantId: string) => {
    setSelectedMerchantId(merchantId)

    // Prefill the modal if values are available
    const merchantDetails = rows.find(row => row.marketplace_merchant_id === merchantId)
    if (merchantDetails) {
      setDescription(merchantDetails.description || '')
      setTag(merchantDetails.tag || '')
      setAestheticBackgroundImage(merchantDetails.aesthetic_background_image || '')
      setAestheticCircleImage(merchantDetails.aesthetic_circle_image || '')
    }
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false);
    setDescription('');
    setTag('');
    setAestheticCircleImage('');
    setAestheticBackgroundImage('');
  };

  const handleSaveDetails = async () => {
    try {
      await addMerchantDetails({
        bannerId: bannerId as string,
        merchantId: selectedMerchantId as string,
        description: description || null,
        tag: tag || null,
        aesthetic_background_image: aesthetic_background_image || null,
        aesthetic_circle_image: aesthetic_circle_image || null,
      }).unwrap();
      handleCloseModal();
    } catch (error) {
      console.error('Error adding details:', error);
    }
  };

  const handleDragEnd: OnDragEndResponder = async (e) => {
    if (!e.destination) return

    let tempData = Array.from(rows)
    let [source_data] = tempData.splice(e.source.index, 1)
    tempData.splice(e.destination.index, 0, source_data)
    setRows(tempData)

    await updateMerchantOrder({
      bannerId: bannerId || '',
      merchantId: e.draggableId,
      order: e.destination.index + 1
    })
  }

  const columns = [
    {
      field: 'store_name',
      headerName: 'Name',
      render: (rowData: IMerchantByCategory) => (
        <span>{rowData?.marketplace_merchant?.name}</span>
      )
    },
    {
      field: 'id',
      headerName: 'id',
      render: (rowData: IMerchantByCategory) =>
        rowData?.marketplace_merchant?.id
    },
    {
      field: 'source',
      headerName: 'Source',
      render: (rowData: IMerchantByCategory) =>
        rowData?.marketplace_merchant?.source
    },
    {
      field: 'wildfire_categories',
      headerName: 'Wildfire Categories',
      render: (rowData: IMerchantByCategory) =>
        rowData?.marketplace_merchant?.wildfire_categories
    },
    {
      field: 'date',
      headerName: 'Created at',
      render: (rowData: IMerchantByCategory) =>
        rowData.marketplace_merchant.created_at
          ? formatDateTime(new Date(rowData.marketplace_merchant.created_at))
          : null
    },
    {
      field: 'image',
      headerName: 'Image',
      render: (rowData: IMerchantByCategory) => (
        <LazyLoadImage
          alt={rowData.marketplace_merchant.name}
          src={
            rowData.marketplace_merchant.image_url1 ||
            rowData.marketplace_merchant.image_url2 ||
            '/images/placeholder/product.jpg'
          }
          className="hover:scale-125 transition duration-500 cursor-pointer h-20 w-20 object-contain"
        />
      )
    }
  ]

  useEffect(() => {
    const merchantsBanner = merchantsByBanner?.marketplace_banner_merchants
    if (merchantsBanner && isEditing) {
      const noMerchantsSelected = merchants?.marketplace_merchants.filter(
        (merchant: any) => {
          return !merchantsBanner.find(
            (merchantBanner) =>
              merchantBanner.marketplace_merchant_id === merchant.id
          )
        }
      )

      setOptions(noMerchantsSelected)
      setRows(merchantsBanner)
    }
  }, [isEditing, merchants?.marketplace_merchants, merchantsByBanner])

  return (
    <div>
      <div className="flex justify-between">
        <div className="text-lg font-semibold">Merchants</div>
      </div>
      {isEditing && (
        <div className="w-full lg:w-1/2">
          {isLoadingMerchants ? (
            <Spinner />
          ) : (
            <Autocomplete
              options={options || []}
              getOptionLabel={(option: IMarketplaceMerchant) => option.name}
              id="autocomplete-merchants"
              value={value}
              clearOnEscape
              onChange={async (
                event: any,
                newValue: IMarketplaceMerchant | null
              ) => {
                setValue(newValue)

                if (newValue?.id) {
                  await addMerchantToBanner({
                    bannerId: bannerId || '',
                    merchantId: newValue?.id || '',
                    order: 1
                  })

                  const clearButton = document.querySelector(
                    '.MuiAutocomplete-clearIndicator'
                  ) as HTMLElement
                  clearButton?.click()
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="autocomplete-merchants-input"
                  label="Add merchants to the category"
                  variant="filled"
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option.name === value?.name
              }
            />
          )}
        </div>
      )}
      {isLoading || isLoadingMerchantsByBanner ? (
        <Spinner />
      ) : (
        <div className="mt-4">
          {isEditing ? (
            <DragDropContext onDragEnd={handleDragEnd}>
              <TableContainer component={Paper}>
                <MuiTable sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {isFetchingMerchants && (
                          <div className="text-primary-500">
                            <Timer className="!text-lg" />
                          </div>
                        )}
                      </TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Id</TableCell>
                      <TableCell align="center">Source</TableCell>
                      <TableCell align="left">Wildfire Categories</TableCell>
                      <TableCell align="right">Created at</TableCell>
                      <TableCell align="center">Image</TableCell>
                      <TableCell align="right">Add Details</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <Droppable droppableId="droppable-2">
                    {(provider) => (
                      <TableBody
                        ref={provider.innerRef}
                        {...provider.droppableProps}
                      >
                        {rows.map((row, index) => (
                          <Draggable
                            key={row.marketplace_merchant_id}
                            draggableId={row.marketplace_merchant_id}
                            index={index}
                          >
                            {(provider, snapshot) => (
                              <TableRow
                                key={row.marketplace_merchant_id}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0
                                  }
                                }}
                                {...provider.draggableProps}
                                {...provider.dragHandleProps}
                                className={
                                  snapshot.isDragging
                                    ? 'bg-primary-100 is-dragging !inline-table'
                                    : ''
                                }
                                ref={provider.innerRef}
                              >
                                <TableCell component="th" scope="row">
                                  <DragIndicator />
                                </TableCell>
                                <TableCell>
                                  {row.marketplace_merchant.name}
                                </TableCell>
                                <TableCell>
                                  {row.marketplace_merchant_id}
                                </TableCell>
                                <TableCell align="center">
                                  {row.marketplace_merchant.source}
                                </TableCell>
                                <TableCell align="left">
                                  {row.marketplace_merchant.wildfire_categories}
                                </TableCell>
                                <TableCell align="right">
                                  {formatDateTime(new Date(row.created_at))}
                                </TableCell>
                                <TableCell align="center">
                                  <LazyLoadImage
                                    src={
                                      row.marketplace_merchant.image_url1 ||
                                      row.marketplace_merchant.image_url2 ||
                                      '/images/placeholder/product.jpg'
                                    }
                                    alt={row.marketplace_merchant.name}
                                    className="h-20 w-20 object-contain mx-auto"
                                  />
                                </TableCell>
                                <TableCell align="right">
                                  <IconButton onClick={() => handleOpenModal(row.marketplace_merchant_id)}>
                                  <Settings />
                                  </IconButton>
                                </TableCell>
                                <TableCell align="right">
                                  <RemoveMerchant
                                    categoryId={bannerId || ''}
                                    merchantId={row.marketplace_merchant_id}
                                  />
                                </TableCell>
                              </TableRow>
                            )}
                          </Draggable>
                        ))}
                        {rows.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={6}>
                              <Empty
                                emptyTitleText="No merchants yet"
                                emptyText="You can select merchants on the dropdown above."
                              />
                            </TableCell>
                          </TableRow>
                        )}
                        {provider.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </MuiTable>
              </TableContainer>
            </DragDropContext>
          ) : (
            <Table
              columns={columns}
              rows={data?.marketplace_banner_merchants || []}
              emptyTitleText="No merchants yet"
              emptyText='You can add merchants by clicking on "Edit Banner" button'
              onPrevPage={() => setPage(page - 1)}
              onNextPage={() => setPage(page + 1)}
              isFetching={isFetching}
              page={page}
              setPage={setPage}
              totalPages={Math.ceil(Number(data?.records_count) / LIMIT)}
            />
          )}
        </div>
      )}

      {/* Modal for Adding Details into merchants */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Add Details</DialogTitle>
        <DialogContent>
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Tag"
            value={tag}
            onChange={(e) => setTag(e.target.value)}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Aesthetic Circle Image"
            value={aesthetic_circle_image}
            onChange={(e) => setAestheticCircleImage(e.target.value)}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Aesthetic Background Image"
            value={aesthetic_background_image}
            onChange={(e) => setAestheticBackgroundImage(e.target.value)}
            fullWidth
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveDetails} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default BannerDetailsMerchantsTable
