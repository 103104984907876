import { useState, useEffect } from "react";
import { 
  Modal, 
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Image,
  Button,
  Skeleton
} from "@nextui-org/react"

function Dot({ isActive }: { isActive: boolean }) {
  return (
    <div
      style={{
        width: '5px',
        height: '5px',
        borderRadius: '50%', // Makes the div a circle
        backgroundColor: isActive ? '#37B5FF' : '#ccc', // Blue for active, gray for inactive
        margin: '2px', // Adds some space around the dot
      }}
    />
  );
}

type WelcomeBonusModalProps = {
  open: boolean;
  onClaim: () => void;
  isClaiming?: boolean;
  referrer?: string;
}

const useImagePreloader = (imageSources: string[]) => {
  const [imagesLoaded, setImagesLoaded] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const loadImage = (src: string) => {
      const img = new window.Image();
      img.src = src;
      img.onload = () => setImagesLoaded(prev => ({ ...prev, [src]: true }));
      img.onerror = () => setImagesLoaded(prev => ({ ...prev, [src]: false }));
    };

    imageSources.forEach(loadImage);
  }, [imageSources]);

  return imagesLoaded;
};

const WelcomeBonusModal = (props: WelcomeBonusModalProps) => {
  // Welcome bonus slide counter
  const [currentSlide, setCurrentSlide] = useState(0);

  const imageSources = [
    '/images/waveemblem.svg',
    '/images/waveprizespageedits(13).png',
    '/images/waveprizespageedits(12).png',
    '/images/26.png',
    '/images/29.png',
    '/images/600tokens.png',
    '/images/27.png'
  ];

  const imagesLoaded = useImagePreloader(imageSources);

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => prevSlide + 1);
  };

  const handlePreviousSlide = () => {
    setCurrentSlide((prevSlide) => prevSlide - 1);
  };
  
  const renderImage = (src: string, className: string, alt: string) => {
    return imagesLoaded[src] ? (
      <Image className={className} alt={alt} src={src} />
    ) : (
      <Skeleton className={`rounded-lg ${className} h-64 bg-default-300`} />
    );
  };

  return (
    <Modal size={'2xl'} isOpen={props.open} onClose={props.onClaim} isDismissable={false} hideCloseButton>
      <ModalContent>
        {(onClose) => (
          <>
            {currentSlide === 0 && (
              <>
                <ModalHeader className="flex flex-col gap-2 text-center text-sm lg:text-lg p-6 pb-2 lg:pb-6">
                  <p className="text-lg">Welcome to Wave!</p>
                  <p className="font-normal w-full">
                  <span>Wave rewards you with </span>
                  <span className="w-fit text-primary-400 inline-block">
                    <div className="flex flex-row gap-1 font-medium items-center">
                    {renderImage('/images/waveemblem.svg', "inline object-contain rounded-xl w-full h-4 w-4", "Wave Icon")}
                      Tokens
                    </div>
                    </span>
                    <span> for every dollar spent at your favorite stores</span>
                  </p>
                </ModalHeader>
                <ModalBody>
                  <div className="w-full flex flex-col gap-4 items-center">
                    {renderImage("/images/waveprizespageedits(13).png", "hidden md:block", "Wave Rewards")}
                    {renderImage("/images/waveprizespageedits(12).png", "block md:hidden h-72", "Wave Rewards")}
                  </div>
                </ModalBody>
                <ModalFooter className="flex flex-row justify-between items-center">
                  <div className="flex gap-2">
                    <Dot isActive={currentSlide === 0} />
                    <Dot isActive={false} />
                    <Dot isActive={false} />
                  </div>
                  <div className="flex flex-row gap-4">
                    <Button variant="solid" className="bg-[#37B5FF] text-white" onPress={handleNextSlide}>
                      Next
                    </Button>
                  </div>
                </ModalFooter>
              </>
            )}
            {currentSlide === 1 && (
              <>
                <ModalHeader className="flex flex-col gap-2 text-center text-sm lg:text-lg p-6 pb-2 lg:pb-6">
                  <p className="text-lg">Win Prizes</p>
                  <p className="font-normal w-full">
                    <span>Use your </span>
                    <span className="w-fit text-primary-400 font-semibold inline-block">
                      <div className="flex flex-row gap-1 font-medium items-center">
                      {renderImage('/images/waveemblem.svg', "inline object-contain rounded-xl w-full h-4 w-4", "Wave Icon")}
                        Tokens
                      </div>
                      </span>
                      <span> on the Prizes page to enter the giveaways</span>
                  </p>
                </ModalHeader>
                <ModalBody>
                  <div className="w-full flex flex-col gap-4 items-center">
                    {renderImage("/images/26.png", "hidden md:block", "Win Prizes")}
                    {renderImage("/images/29.png", "block md:hidden h-64", "Win Prizes")}
                  </div>
                </ModalBody>
                <ModalFooter className="flex flex-row justify-between items-center">
                  <div className="flex gap-2">
                    <Dot isActive={false} />
                    <Dot isActive={currentSlide === 1} />
                    <Dot isActive={false} />
                  </div>
                  <div className="flex flex-row gap-4">
                    <Button variant="solid" className="bg-white text-gray-700 border-1 border-gray-300" onPress={handlePreviousSlide}>
                      Back
                    </Button>
                    <Button variant="solid" className="bg-[#37B5FF] text-white" onPress={handleNextSlide}>
                      Next
                    </Button>
                  </div>
                </ModalFooter>
              </>
            )}
            {currentSlide === 2 && (
              <>
                <ModalHeader className="flex flex-col gap-4 text-center text-sm lg:text-lg p-6 pb-2 lg:pb-6">
                  <p className="text-lg">Welcome Bonus</p>
                  <p className="font-normal text-center">
                    Here's a free special Welcome Bonus to start your adventure!
                  </p>
                </ModalHeader>
                <ModalBody className="">
                  <div className=" w-full flex flex-col gap-4 items-center">
                    {props.referrer === "extension"
                      ? renderImage("/images/600tokens.png", "h-52", "Wave Coins")
                      : renderImage("/images/27.png", "h-52", "Wave Coins")}
                  </div>
                </ModalBody>
                <ModalFooter className="flex flex-row justify-between items-center">
                  <div className="flex gap-2">
                    <Dot isActive={false} />
                    <Dot isActive={false} />
                    <Dot isActive={currentSlide === 2} />
                  </div>
                  <div className="flex flex-row gap-4">
                    <Button disabled={props.isClaiming} variant="solid" className="bg-white text-gray-700 border-1 border-gray-300" onPress={handlePreviousSlide}>
                      Back
                    </Button>
                    <Button isLoading={props.isClaiming} disabled={props.isClaiming} variant="solid" className="bg-[#37B5FF] font-semibold text-white" onPress={onClose}>
                      Claim Now!
                    </Button>
                  </div>
                </ModalFooter>
              </>
            )}
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default WelcomeBonusModal;