import React, { FC, useEffect, useState } from 'react'
import Button from 'components/ui/Button'
import TextField from 'components/ui/TextField'
import HorizontalFieldRow from 'components/ui/HorizontalFieldRow'
import ContentLoadingPlaceholder from 'components/ui/ContentLoadingPlaceholder'
import * as LuIcons from 'react-icons/lu';

import { useNavigate, useParams } from 'react-router-dom'
import { useNotification } from 'hooks/useNotification'
import { formatDateTime } from 'utils/intlDate'
import {
  useGetCategoriesQuery,
  useGetCategoryQuery,
  useRemoveCategoryMutation,
  useUpdateCategoryMutation
} from 'services/marketplace'
import {
  ArrowBack,
  Cancel,
  CheckCircle,
  Delete,
  Edit
} from '@mui/icons-material'
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  Switch
} from '@mui/material'
import * as Styles from 'layouts/PrivateLayout.styles'
import CategoryDetailsBannersTable from './CategoryDetailsBannersTable'

const CategoryDetails: FC = () => {
  const [open, setOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { sendSuccessNotification } = useNotification()

  const { categoryId = '' } = useParams()
  const navigate = useNavigate()

  const { data, isFetching } = useGetCategoryQuery(categoryId)
  const { data: allCategoriesData } = useGetCategoriesQuery({ limit: 100, page: 1 });
  console.log('category')
  console.log(data)
  const [removeCategory, { isLoading: isDeleting }] =
    useRemoveCategoryMutation()
  const [updateCategory, { isLoading: isSaving }] = useUpdateCategoryMutation()

  const category = data?.marketplace_category
  const allCategories = allCategoriesData?.marketplace_categories || [];

  const availableParentCategories = allCategories.filter(
    (cat) => cat.id !== categoryId && !cat.parentCategory
  );  
  const [selectedParentId, setSelectedParentId] = useState(category?.parentCategory?.id || '');
  const [imageUrl, setImageUrl] = useState(category?.image_url || '');
  const [selectedIcon, setSelectedIcon] = useState<string>('')

  useEffect(() => {
    if (isEditing && category) {
     setSelectedParentId(category.parentCategory?.id || '');
     setImageUrl(category.image_url || '');
    }
  }, [isEditing, category]);

  
  const createdDate = category?.created_at
    ? new Date(category?.created_at)
    : null

  const handleEditCategory = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const category_name = formData.get('category_name') as string
    const active = formData.get('active') as string
    const is_home = formData.get('is_home') as string
    const show_in_nav = formData.get('show_in_nav') as string

    const response: any = await updateCategory({
      categoryId,
      category_name,
      active: active ? true : false,
      is_home: is_home ? true : false,
      parent_id: selectedParentId || null,
      image_url: imageUrl || null,
      show_in_nav: show_in_nav ? true : false,
      category_icon: selectedIcon || null,
    })

    if (response.data) {
      sendSuccessNotification('Updated Successfully')
      setIsEditing(false)
    }
  }

  const handleRemoveCategoryConfirm = async () => {
    const response: any = await removeCategory(categoryId)

    if (response?.data === null) {
      sendSuccessNotification('Category removed successfully')
      setOpen(false)
      navigate(`/admin/categories`)
    }
  }


  const iconOptions = Object.keys(LuIcons)
    .filter((name) => name.startsWith('Lu'))
    .map((name) => ({ name, component: LuIcons[name as keyof typeof LuIcons] }));

  const handleCloseConfirm = () => {
    setOpen(false)
  }

  return (
    <div className="pt-2">
      <Styles.PageTitleContainer>
        View Category information
        <div className="flex flex-wrap gap-2">
          {isEditing ? (
            <Button onClick={() => setIsEditing(false)}>Cancel</Button>
          ) : (
            <>
              <Button variant="text" onClick={() => navigate(-1)}>
                <ArrowBack />
                Back
              </Button>
              <Button startIcon={<Delete />} onClick={() => setOpen(true)}>
                Delete Category
              </Button>
              <Button
                startIcon={<Edit />}
                cc-color="purple"
                onClick={() => setIsEditing(true)}
              >
                Edit Category
              </Button>
            </>
          )}
        </div>
      </Styles.PageTitleContainer>
      <div>
        <form onSubmit={handleEditCategory}>
          <HorizontalFieldRow label="Name">
            {isEditing ? (
              <div>
                <TextField
                  id="category_name"
                  type="text"
                  cc-width="full"
                  defaultValue={category?.category_name}
                  placeholder="Category Name"
                  required
                  autoFocus
                />
              </div>
            ) : (
              <div className="w-full lg:w-1/2 text-gray-600">
                <ContentLoadingPlaceholder isLoading={isFetching}>
                  {category?.category_name}
                </ContentLoadingPlaceholder>
              </div>
            )}
          </HorizontalFieldRow>
          <HorizontalFieldRow label="Image URL">
            {isEditing ? (
              <TextField
                id="image_url"
                type="text"
                cc-width="full"
                value={imageUrl}
                onChange={(e) => setImageUrl(e.target.value)}
                placeholder="Image URL"
              />
            ) : (
              <ContentLoadingPlaceholder isLoading={isFetching}>
                {category?.image_url ? (
                  <div className="flex justify-center items-center p-4 bg-gray-100 rounded-md">
                    <img
                      src={category.image_url}
                      alt="Category Image"
                      className="h-40 w-auto object-cover rounded-md shadow-lg"
                    />
                  </div>
                ) : (
                  <div className="text-gray-500 italic">No image available</div>
                )}
              </ContentLoadingPlaceholder>
            )}
          </HorizontalFieldRow>
          <HorizontalFieldRow label="Parent Category">
            <div className="w-48 sm:w-52 md:w-56">
              {isEditing ? (
                <Select
                  value={selectedParentId}
                  onChange={(e) => setSelectedParentId(e.target.value)}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="">None</MenuItem>
                  {availableParentCategories.map((parent) => (
                    <MenuItem key={parent.id} value={parent.id}>
                      {parent.category_name}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <ContentLoadingPlaceholder isLoading={isFetching}>
                   {category?.parentCategory ? category.parentCategory.category_name : 'None'}
                </ContentLoadingPlaceholder>
              )}
            </div>

          </HorizontalFieldRow>
          <HorizontalFieldRow label="Active">
            <div className="text-gray-600 flex gap-6 items-center">
              <ContentLoadingPlaceholder isLoading={isFetching}>
                {isEditing ? (
                  <Switch name="active" defaultChecked={category?.active} />
                ) : (
                  <>
                    <div>
                      {createdDate ? formatDateTime(createdDate) : null}
                    </div>
                    {category?.active ? (
                      <Chip label={<CheckCircle />} color="success" />
                    ) : (
                      <Chip label={<Cancel />} color="error" />
                    )}
                  </>
                )}
              </ContentLoadingPlaceholder>
            </div>
          </HorizontalFieldRow>
          <HorizontalFieldRow label="Home">
            <div className="text-gray-600 flex gap-6 items-center">
              <ContentLoadingPlaceholder isLoading={isFetching}>
                {isEditing ? (
                  <Switch name="is_home" defaultChecked={category?.is_home} />
                ) : (
                  <>
                    {category?.is_home ? (
                      <Chip label={<CheckCircle />} color="success" />
                    ) : (
                      <Chip label={<Cancel />} color="error" />
                    )}
                  </>
                )}
              </ContentLoadingPlaceholder>
            </div>
          </HorizontalFieldRow>
          <HorizontalFieldRow label="Show in Navbar">
            <div className="text-gray-600 flex gap-6 items-center">
              <ContentLoadingPlaceholder isLoading={isFetching}>
                {isEditing ? (
                  <Switch name="show_in_nav" defaultChecked={category?.show_in_nav} />
                ) : (
                  <>
                    {category?.show_in_nav ? (
                      <Chip label={<CheckCircle />} color="success" />
                    ) : (
                      <Chip label={<Cancel />} color="error" />
                    )}
                  </>
                )}
              </ContentLoadingPlaceholder>
            </div>
          </HorizontalFieldRow>
          <HorizontalFieldRow label="Category Icon">
            <div className="w-48 sm:w-52 md:w-56">
              <ContentLoadingPlaceholder isLoading={isFetching}>
                {isEditing ? (
                  <Select
                    value={selectedIcon || ''}
                    onChange={(e) => setSelectedIcon(e.target.value)}
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value="">None</MenuItem>
                    {iconOptions.map(({ name, component: IconComponent }) => (
                      <MenuItem key={name} value={name}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                          <IconComponent size={20} /> {name.replace('Lu', '')}
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  category?.category_icon ? (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      {React.createElement(
                        LuIcons[category.category_icon as keyof typeof LuIcons],
                        { size: 20 }
                      )}
                      {category.category_icon.replace('Lu', '')}
                    </div>
                  ) : (
                    <div className="text-gray-500 italic">No icon selected</div>
                  )
                )}
              </ContentLoadingPlaceholder>
            </div>
          </HorizontalFieldRow>
          <HorizontalFieldRow label="Id">
            <ContentLoadingPlaceholder isLoading={isFetching}>
              <p>{category?.id}</p>
            </ContentLoadingPlaceholder>
          </HorizontalFieldRow>

          {isEditing && (
            <div>
              <div className="text-gray-600 flex gap-2 items-center">
                <Button onClick={() => setIsEditing(false)}>Cancel</Button>
                <Button cc-color="purple" type="submit" disabled={isSaving}>
                  {isSaving ? 'Saving...' : 'Save'}
                </Button>
              </div>
            </div>
          )}
        </form>
      </div>
      
      <div className="mt-6">
        <CategoryDetailsBannersTable isEditing={isEditing} />
      </div>

      {/* <div className="mt-6">
        <CategoryDetailsMerchantsTable isEditing={isEditing} />
      </div> */}
      <Dialog
        open={open}
        onClose={handleCloseConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this category?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleCloseConfirm}>
            Cancel
          </Button>
          <Button
            cc-color="red"
            onClick={handleRemoveCategoryConfirm}
            disabled={isDeleting}
          >
            {isDeleting ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CategoryDetails
