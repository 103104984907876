import { api } from './api'
import { ICustomer } from './customers'
import { IMerchant  } from './merchants'


export interface IRefund {
  id: string
  order_id: string
  source_refund_id: string
  amount: number
  refunded_on: string
  created_at: string
  updated_at: string
}
export interface IOrder {
  id: string
  order_number: string
  customer: ICustomer
  merchant: IMerchant
  entries: number
  refunds: IRefund[]
  order_date: string
  currency: string
  total_price: number
  total_discount: number
  source_order_id: string
  merchant_id: string
  session_id: string
  prizedraw_active_pdid: string
  created_at: string
  updated_at: string
}

interface IGetOrdersResponse {
  orders: IOrder[]
  records_count: number
  next_page: number
  previous_page: number
}

interface IFilters {
  page: number
  limit: number
}

export const ordersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOrders: build.query<IGetOrdersResponse, IFilters>({
      query: (params) => {
        return { url: `/orders`, params }
      }
    }),
    getOrder: build.query<{ order: IOrder }, string>({
      query: (orderId) => {
        return { url: `/orders/${orderId}` }
      }
    })
  })
})

export const { useGetOrdersQuery, useGetOrderQuery } = ordersApi
