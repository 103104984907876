import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const USER_TYPE_ADMIN = 'admin'
export const USER_TYPE_CUSTOMER = 'customer'

export interface IUser {
  id: string
  first_name?: string
  last_name?: string
  email: string
  phone?: string
  type: string
}

interface IAuthStore {
  user: IUser | null
  token: string | null
  role: string | undefined | null
  merchantId: string
  merchantName: string
  customerId: string | null
}

const initialState: IAuthStore = {
  user: null || JSON.parse(localStorage.getItem('user') as string),
  token: null || localStorage.getItem('token'),
  role: null || (localStorage.getItem('role') as string),
  customerId: null || (localStorage.getItem('customerId') as string),
  merchantId: null || (localStorage.getItem('merchantId') as string),
  merchantName: null || (localStorage.getItem('merchantName') as string)
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (
      state,
      action: PayloadAction<{
        user: IUser | null
        token: string
        merchantId: string
        merchantName: string
        customerId: string
        refresh_token: string
      }>
    ) => {
      state.user = action.payload.user
      state.token = action.payload.token
      state.role = action.payload.user?.type
      state.merchantId = action.payload.merchantId
      state.merchantName = action.payload.merchantName
      state.customerId = action.payload.customerId
      localStorage.setItem('user', JSON.stringify(action.payload.user))
      localStorage.setItem('token', action.payload.token)
      localStorage.setItem('refresh_token', action.payload.refresh_token)
      localStorage.setItem('role', action.payload.user?.type || 'customer')
      localStorage.setItem('customerId', action.payload.customerId)
      localStorage.setItem('merchantId', action.payload.merchantId)
      localStorage.setItem('merchantName', action.payload.merchantName)
      document.cookie = `WAVE_AUTH_CUSTOMER_ID=${action.payload.customerId}; path=/; max-age=86400; secure; samesite=strict`;
      document.cookie = `WAVE_AUTH_CUSTOMER_TOKEN=${action.payload.token}; path=/; max-age=86400; secure; samesite=strict`;
    },
    logout: (state) => {
      state.user = null
      state.token = null
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('merchantId')
      localStorage.removeItem('merchantName')
      localStorage.removeItem('customerId')
      document.cookie = "WAVE_AUTH_CUSTOMER_ID=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
      document.cookie = "WAVE_AUTH_CUSTOMER_TOKEN=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    },
    updateUserFullName: (state, action: PayloadAction<string>) => {
      const currentUser: IUser = { ...state.user } as IUser
      const newUser = {
        ...currentUser,
        first_name: action.payload
      }
      state.user = newUser
      localStorage.setItem('user', JSON.stringify(newUser))
    },
    saveMerchantId: (state, action: PayloadAction<string>) => {
      state.merchantId = action.payload
      localStorage.setItem('merchantId', JSON.stringify(action.payload))
    },
    saveMerchantName: (state, action: PayloadAction<string>) => {
      state.merchantName = action.payload
      localStorage.setItem('merchantName', JSON.stringify(action.payload))
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token =  action.payload
      localStorage.setItem('token', action.payload)
    }
  }
})

export const {
  login,
  logout,
  saveMerchantId,
  saveMerchantName,
  updateUserFullName,
  setToken
} = authSlice.actions

export default authSlice.reducer
