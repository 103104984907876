import Button from 'components/ui/Button'
import TextField from 'components/ui/TextField'
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Switch} from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react'
import Modal from 'components/ui/Modal'
import { useNotification } from 'hooks/useNotification'
import { IPrize, useUpdatePrizeMutation } from 'services/prizes'
import RichText, { UncontrolledRichTextEditorRef } from 'components/ui/RichText'

interface IEditPrizeModalProps {
  open: boolean
  onClose: () => void
  onSubmitSuccess: () => Promise<void>
  initialData: {
    id: string,
    name: string,
    imageURL: string,
    headerImageURL: string,
    mobileImageURL: string,
    detailBgImageURL: string,
    detailsPageStyles: IPrize['prize_details_page_styles'],
    startDate: string,
    endDate: string,
    tokenCost: number,
    order: number,
    linkName: string,
    linkURL: string,
    details: string,
    hexColor: string,
    isActive?: boolean,
    isHeader?: boolean,
    isDailyDraw?: boolean,
  }
}

const EditPrizeModal: FC<IEditPrizeModalProps> = ({
  open,
  onClose,
  onSubmitSuccess,
  initialData
}) => {
  const { sendSuccessNotification, sendErrorNotification } = useNotification()
  const [updatePrize] = useUpdatePrizeMutation()
  const richTextRef = useRef<UncontrolledRichTextEditorRef>(null)
  const [formData, setFormData] = useState(initialData);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    setFormData(initialData);
  }, [initialData]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [id]: type === 'checkbox' ? checked : value
    });
  };

  const handleChangeDetailPageStyle = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setFormData(prev => ({
      ...prev,
      detailsPageStyles: {
        ...prev.detailsPageStyles,
        image: {
          ...prev.detailsPageStyles.image,
          [field]: value,
        }
      }
    }))
  }

  const handleToggleChange = (field: keyof typeof formData, checked: boolean) => {
    setFormData({
      ...formData,
      [field]: checked
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    
    try {
      if (Object.values(formData).some(value => value === '') || richTextRef.current?.isEmpty()) {
        sendErrorNotification('All fields are required')
        return
      }
      
      if (new Date(formData.endDate) < new Date(formData.startDate)) {
        sendErrorNotification('End date should be greater than start date')
        return
      }

      if (formData.order < 1) {
        sendErrorNotification('Order number must be 1 or greater')
        return
      }
      
      setLoadingSubmit(true);
      const response: any = await updatePrize({
        id: formData.id,
        name: formData.name,
        details: richTextRef.current?.getValue(),
        imageURL: formData.imageURL,
        headerImageURL: formData.headerImageURL,
        mobileImageURL: formData.mobileImageURL,
        detailBgImageURL: formData.detailBgImageURL,
        detailsPageStyles: formData.detailsPageStyles,
        tokenCost: formData.tokenCost,
        order: formData.order,
        startDate: formData.startDate,
        endDate: formData.endDate,
        linkName: formData.linkName,
        linkUrl: formData.linkURL,
        hexColor: formData.hexColor,
        isActive: formData.isActive,
        isHeader: formData.isHeader,
        isDailyDraw: formData.isDailyDraw,
      })
      
      if (response.data) {
        sendSuccessNotification('Prize Updated Successfully')
        await onSubmitSuccess();
        onClose()
      }
    } catch (error) {
      sendErrorNotification('Failed to update prize')
    } finally {
      setLoadingSubmit(false);
    }
  }

  const renderField = ({
    id,
    label,
    type,
    autoFocus
  }: {
    id: string
    label: string
    type: string
    autoFocus?: boolean
  }) => (
    <div className="flex flex-col lg:flex-row mt-4">
      <label className="w-40 flex-2 text-gray-700 mb-2">{label}</label>
      <div className='flex-1'>
        {id === 'details' ? (
          <RichText.UncontrolledEditor ref={richTextRef} value={formData['details']} />
        ) : (
          <TextField
            id={id}
            type={type}
            hiddenLabel
            cc-width="full"
            placeholder={label}
            required
            autoFocus={autoFocus}
            // @ts-ignore
            value={formData[id]}
            onChange={handleChange}
          />
        )}
      </div>
    </div>
  )

  const modalBody = (
    <>
      {renderField({
        id: 'name',
        label: 'Prize Name *',
        type: 'text',
        autoFocus: true
      })}
      {renderField({
        id: 'imageURL',
        label: 'Image URL *',
        type: 'text',
        autoFocus: false
      })}
      {renderField({
        id: 'detailBgImageURL',
        label: 'Detail Bg Image *',
        type: 'text',
        autoFocus: false
      })}
      {renderField({
        id: 'headerImageURL',
        label: 'Header Image URL *',
        type: 'text',
        autoFocus: false
      })}
      {renderField({
        id: 'mobileImageURL',
        label: 'Mobile Image URL *',
        type: 'text',
        autoFocus: false
      })}
      {renderField({
        id: 'startDate',
        label: 'Start Date *',
        type: 'date'
      })}
      {renderField({
        id: 'endDate',
        label: 'End Date *',
        type: 'date'
      })}
      {renderField({
        id: 'tokenCost',
        label: 'Token Cost *',
        type: 'number'
      })}
      {renderField({
        id: 'linkName',
        label: 'Link Name *',
        type: 'text'
      })}
      {renderField({
        id: 'linkURL',
        label: 'Link URL *',
        type: 'text'
      })}
      {renderField({
        id: 'hexColor',
        label: 'Hex Color *',
        type: 'text'
      })}
      {renderField({
        id: 'order',
        label: 'Order *',
        type: 'number'
      })}
      <div className="flex flex-col lg:flex-row mt-4">
        <label className="w-40 text-gray-700 mb-2">Is Header</label>
        <Switch
          checked={formData.isHeader || false}
          defaultChecked={formData.isHeader}
          onChange={(e) => handleToggleChange('isHeader', e.target.checked)}
        />
      </div>
      <div className="flex flex-col lg:flex-row mt-4">
        <label className="w-40 text-gray-700 mb-2">Is Active</label>
        <Switch
          checked={formData.isActive || false}
          defaultChecked={formData.isHeader}
          onChange={(e) => handleToggleChange('isActive', e.target.checked)}
        />
      </div>
      <div className="flex flex-col lg:flex-row mt-4">
        <label className="w-40 text-gray-700 mb-2">Is Daily Draw</label>
        <Switch
          checked={formData.isDailyDraw || false}
          defaultChecked={formData.isDailyDraw}
          onChange={(e) => handleToggleChange('isDailyDraw', e.target.checked)}
        />
      </div>
      {renderField({
        id: 'details',
        label: 'Details *',
        type: 'text'
      })}
     <details className='mt-4'>
        <summary>Style Customizations on Prize details page</summary>
        <div className='mt-4 grid grid-cols-2 gap-4'>
          <TextField
            id='width'
            type='number'
            value={formData.detailsPageStyles.image.width}
            label="Product Image Width"
            cc-width="full"
            onChange={handleChangeDetailPageStyle('width')}
          />
          <TextField
            id='height'
            type='number'
            value={formData.detailsPageStyles.image.height}
            label="Product Image Height"
            cc-width="full"
            onChange={handleChangeDetailPageStyle('height')}
          />
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Product Image Position</FormLabel>
            <RadioGroup
              onChange={handleChangeDetailPageStyle('position')}
              aria-labelledby="demo-radio-buttons-group-label"
              value={formData.detailsPageStyles.image.position}
              id="position"
            >
              <FormControlLabel value="center" control={<Radio />} label="Center" />
              <FormControlLabel value="top" control={<Radio />} label="Top" />
            </RadioGroup>
          </FormControl>
        </div>
      </details>
    </>
  )

  const modalActions = (
    <>
      <Button disabled={loadingSubmit} onClick={onClose} cc-width="full">
        Cancel
      </Button>
      <Button loading={loadingSubmit} disabled={loadingSubmit} cc-color="purple" cc-width="full" type="submit">
        Update Prize
      </Button>
    </>
  )

  return (
    <Modal
      title="Edit Prize"
      open={open}
      onClose={loadingSubmit ? () => {} : onClose}
      body={modalBody}
      actions={modalActions}
      onSubmit={handleSubmit}
    />
  )
}

export default EditPrizeModal
