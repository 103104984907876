import {
  ArrowDownward,
  Close,
  SearchOutlined,
  Timer
} from '@mui/icons-material'
import { Autocomplete, TextField } from '@mui/material'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Button from 'components/ui/Button'
import {
  IMarketplaceCategory,
  IMarketplaceMerchant,
  useGetBannersQuery,
  useGetCategoriesQuery,
} from 'services/marketplace'
import Spinner from 'components/ui/Spinner'
import useFilterWall from 'hooks/useFilterWall'
import { ChangeEvent, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Cookies from 'universal-cookie'
import MerchantsByBanner from 'components/BannerMerchants/MerchantsByBanner'

const MerchantsListPage = () => {
  const [value, setValue] = useState<IMarketplaceCategory | null>(null)
  const {
    merchants,
    merchantsByCategory,
    list,
    inputSearch,
    debouncedValue,
    isSearching,
    isFiltering,
    isLoading,
    isFetching,
    isError,
    handleClearSearch,
    handleClickCategory,
    handleClearCategory,
    setInputSearch,
    setPage
  } = useFilterWall()

  const { data: banners, isLoading: isLoadingBanners } = useGetBannersQuery({
    limit: 20,
    page: 1
  })

  const {
    data: categories,
    isLoading: isLoadingCategories,
    isError: isErrorCategories
  } = useGetCategoriesQuery({
    limit: 80,
    page: 1
  })

  const handleSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target
    setInputSearch(value)
  }

  const generateLink = (merchantId: string, merchantUrl: string) => {
    const DeviceID = localStorage.getItem('DeviceID')
    const url = merchantUrl
    const urlObject = new URL(url)
    const trackingCode = localStorage.getItem('customerId')

    const cookies = new Cookies(null, { path: '/' })
    cookies.set(urlObject.hostname, true, {
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24)
    })

    const generatedLink = `https://wild.link/e?c=${merchantId}&d=${DeviceID}&url=${url}&tc=${trackingCode}`
    window.open(generatedLink, '_blank')
  }

  console.log(categories);

  return (
    <>
      <TextField
        className="w-full"
        variant="standard"
        value={inputSearch}
        onChange={handleSearch}
        placeholder={'Search Merchants'}
        InputProps={{
          startAdornment: <SearchOutlined color="disabled" />,
          autoComplete: 'off'
        }}
      />

      <>
        {isLoadingCategories ? (
          <Spinner />
        ) : (
          <div className="mt-5 w-full md:w-80 lg:w-1/3">
            <Autocomplete
              options={categories?.marketplace_categories || []}
              getOptionLabel={(option: IMarketplaceCategory) =>
                option.category_name
              }
              id="autocomplete-categories"
              value={value}
              onChange={(event: any, newValue: IMarketplaceCategory | null) => {
                setValue(newValue)
                console.log('🚀 ~ MerchantsListPage ~ newValue:', newValue)

                if (newValue !== null) {
                  handleClickCategory(
                    newValue?.id || '',
                    newValue?.category_name || ''
                  )
                } else {
                  handleClearCategory()
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Categories" variant="filled" />
              )}
            />
          </div>
        )}
      </>
      {isErrorCategories && (
        <div className="w-full text-center bg-red-500 text-white py-5">
          Something went wrong.
        </div>
      )}

      <div className="mt-5">
        <h2 className="text-primary-500 mb-5 text-xl font-semibold">
          {isSearching || isFiltering ? (
            <>
              {isSearching && (
                <>
                  Results for{' '}
                  {debouncedValue && (
                    <span className="font-bold bg-primary-500 text-white px-3 pt-1 pb-2 rounded-full">
                      {debouncedValue}{' '}
                      <span
                        className="cursor-pointer"
                        onClick={handleClearSearch}
                      >
                        <Close />
                      </span>
                    </span>
                  )}
                </>
              )}
            </>
          ) : (
            'All Merchants'
          )}
        </h2>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-5">
              {list.map((merchant: IMarketplaceMerchant) => (
                <div
                  key={merchant.id}
                  onClick={() =>
                    generateLink(merchant.source_merchant_id, merchant.url)
                  }
                >
                  <div className="relative flex items-center flex-col justify-center overflow-hidden shadow-md rounded-md w-full h-full">
                    <LazyLoadImage
                      alt={merchant.name}
                      src={
                        merchant.image_url1 ||
                        merchant.image_url2 ||
                        '/images/placeholder/product.jpg'
                      }
                      className="hover:scale-125 transition duration-500 cursor-pointer h-full w-full object-contain"
                    />
                    <span className="absolute bottom-0 bg-gray-900 w-full opacity-40 h-10"></span>
                    <span className="absolute bottom-2 max-w-52 text-white truncate px-2">
                      {merchant.name}
                    </span>
                  </div>
                </div>
              ))}
              {list.length === 0 && !isFetching && (
                <div className="text-lg text-primary-500">
                  No merchants found.
                </div>
              )}
            </div>
            {merchants?.next_page && !isFiltering && list.length !== 0 && (
              <div className="flex justify-center mt-5 pb-20">
                <Button
                  cc-color="purple"
                  onClick={() => setPage(merchants?.next_page || 1)}
                  type="submit"
                  disabled={isFetching}
                >
                  {isFetching ? (
                    <>
                      Loading... <Timer />
                    </>
                  ) : (
                    <>
                      Load more <ArrowDownward />
                    </>
                  )}
                </Button>
              </div>
            )}

            {merchantsByCategory?.next_page &&
              isFiltering &&
              list.length !== 0 && (
                <div className="flex justify-center mt-5 pb-20">
                  <Button
                    cc-color="purple"
                    onClick={() => setPage(merchantsByCategory?.next_page || 1)}
                    type="submit"
                    disabled={isFetching}
                  >
                    {isFetching ? (
                      <>
                        Loading... <Timer />
                      </>
                    ) : (
                      <>
                        Load more <ArrowDownward />
                      </>
                    )}
                  </Button>
                </div>
              )}

            {isFetching && <Spinner />}
          </>
        )}
        {isError && (
          <div className="w-full text-center bg-red-500 text-white py-5">
            Something went wrong.
          </div>
        )}
      </div>

      <div className="mt-5">
        {isLoadingBanners ? (
          <Spinner />
        ) : (
          <div className="flex flex-col gap-20">
            {banners?.marketplace_banners.map((banner) => (
              <div
                key={banner.id}
                className="slider-container bg-primary-400 rounded-lg text-white py-5 px-5"
              >
                <h3 className="text-xl">{banner.banner_name}</h3>
                <MerchantsByBanner
                  bannerId={banner.id}
                  generateLink={generateLink}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default MerchantsListPage
