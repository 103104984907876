import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useNavigate } from 'react-router-dom'
import { changeEmail, changeStep, resetFormSteps } from 'store/formStepsSlice'
import {
  useForgotPasswordMutation,
  useResetPasswordMutation
} from 'services/auth'
import { useNotification } from 'hooks/useNotification'
import ForgotPasswordForm from 'components/Forms/ForgotPassword'
import ForgotPasswordVerificationCodeForm from 'components/Forms/ForgotPasswordVerificationCodeForm'

const emailStep = 1
const verificationCodeStep = 2

const Forgot = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { sendSuccessNotification } = useNotification()
  const email = useAppSelector((state) => state.formSteps.email)
  const step = useAppSelector((state) => state.formSteps.step)
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation()
  const [resetPassword, { isLoading: isLoadingResetPassword }] =
    useResetPasswordMutation()

  const handleEmailSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const emailForm = formData.get('email') as string

    const response: any = await forgotPassword({
      email: emailForm
    })
    if (response?.data) {
      dispatch(changeEmail(emailForm))
      dispatch(changeStep(verificationCodeStep))
    }
  }

  const handleSubmitVerificationCode = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const newPassword = formData.get('new_password') as string
    const confirmationCode = formData.get('confirmation_code') as string

    const response: any = await resetPassword({
      username: email,
      password: newPassword,
      confirmation_code: confirmationCode
    })

    if (response.data) {
      dispatch(resetFormSteps())
      sendSuccessNotification('Your password has been successfully changed.')
      navigate(`/`)
    }
  }

  const handleBackButton = () => {
    dispatch(resetFormSteps())
    navigate('/')
  }

  const handleCodeVerificationBackButton = () => {
    dispatch(changeStep(emailStep))
  }

  return (
    <div
      className="flex justify-center items-center h-full pt-14 md:h-screen md:pt-0"
      data-testid="module-forgot"
    >
      <div className="max-w-sm">
        {step === 1 && (
          <ForgotPasswordForm
            formId="component-forms-forgot-password"
            title={`Forgot password?`}
            buttonText="Reset password"
            handleSubmit={handleEmailSubmit}
            handleBackButton={handleBackButton}
            isLoading={isLoading}
          />
        )}
        {step === 2 && (
          <ForgotPasswordVerificationCodeForm
            formId="component-forms-forgot-password-verification-code"
            title="Check your email"
            buttonText="Reset Password"
            handleBackButton={handleCodeVerificationBackButton}
            handleSubmit={handleSubmitVerificationCode}
            isLoading={isLoadingResetPassword}
          />
        )}
      </div>
    </div>
  )
}

export default Forgot
