import { FC, useEffect, useState } from 'react'
import ReactInputVerificationCode, {
  ReactInputVerificationCodeProps
} from 'react-input-verification-code'
import * as Styles from './styles'

export const PLACEHOLDER_VERIFICATION_CODE = ' '

interface IVerificationCodeProps extends ReactInputVerificationCodeProps {
  isLimitedTries?: boolean
  setIsLimitedTries?: (state: boolean) => void
}

const VerificationCode: FC<IVerificationCodeProps> = ({
  value,
  onChange,
  isLimitedTries,
  setIsLimitedTries
}) => {
  const [disableInputs, setDisableInputs] = useState(false)
  const [seconds, setSeconds] = useState(15)

  useEffect(() => {
    const buttonSubmitElement = document.querySelector<HTMLButtonElement>(
      'form button[type=submit]'
    )!

    if (
      !value?.includes(PLACEHOLDER_VERIFICATION_CODE) &&
      value?.length === 6
    ) {
      buttonSubmitElement.disabled = false
      buttonSubmitElement.classList.remove('Mui-disabled')
      buttonSubmitElement?.click()
    } else {
      buttonSubmitElement.disabled = true
      buttonSubmitElement.classList.add('Mui-disabled')
    }
  }, [value])

  useEffect(() => {
    if (isLimitedTries) {
      if (seconds > 0) {
        setDisableInputs(true)
        const timerId = setTimeout(() => setSeconds(seconds - 1), 1000)
        return () => clearTimeout(timerId)
      } else {
        setDisableInputs(false)
        setIsLimitedTries?.(false)
      }
    }
  }, [isLimitedTries, seconds, setIsLimitedTries])

  return (
    <Styles.VerificationCodeContainer role={'none'}>
      {disableInputs ? (
        <span className="text-sm-medium">
          You will be able to retry in {seconds} seconds. Please wait...
        </span>
      ) : (
        <ReactInputVerificationCode
          length={6}
          value={value}
          onChange={onChange}
          autoFocus
          placeholder={PLACEHOLDER_VERIFICATION_CODE}
        />
      )}
    </Styles.VerificationCodeContainer>
  )
}

export default VerificationCode
