import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography } from '@mui/material';
import { useAddTokenMultiplierMutation, useDeleteTokenMultiplierMutation, useGetTokenMultipliersQuery } from '../../../services/multipliers';

const Multipliers = () => {
  const [marketplaceMerchantId, setMarketplaceMerchantId] = useState('');
  const [tokenAmount, setTokenAmount] = useState('');

  const { data: multipliersData, isLoading: isMultipliersLoading, refetch } = useGetTokenMultipliersQuery({ limit: 10, page: 1 });
  const [addTokenMultiplier, { isLoading: isAdding }] = useAddTokenMultiplierMutation();
  const [deleteTokenMultiplier, { isLoading: isDeleting }] = useDeleteTokenMultiplierMutation();

  console.log(multipliersData);
  const handleMarketplaceMerchantIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMarketplaceMerchantId(e.target.value);
  };

  const handleTokenAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTokenAmount(e.target.value);
  };

  const handleAddTokenMultiplier = async () => {
    if (marketplaceMerchantId && tokenAmount) {
      await addTokenMultiplier({ marketplaceMerchantId, numTokens: parseInt(tokenAmount), multiplier: 1 });
      refetch();
    }
  };

  const handleDeleteTokenMultiplier = async (id: string) => {
    await deleteTokenMultiplier(id);
    refetch();
  };

  return (
    <div>
      <div>
        <TextField
          label="Marketplace Merchant ID"
          variant="outlined"
          value={marketplaceMerchantId}
          onChange={handleMarketplaceMerchantIdChange}
          fullWidth
          margin="normal"
        />
      </div>
      <div>
        <TextField
          label="Token Amount"
          variant="outlined"
          value={tokenAmount}
          onChange={handleTokenAmountChange}
          fullWidth
          margin="normal"
        />
      </div>
      <Button variant="contained" color="primary" onClick={handleAddTokenMultiplier}>Add</Button>

      <TableContainer className='mt-10' component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Marketplace Merchant ID</TableCell>
              <TableCell>Token Cost</TableCell>
              <TableCell>Delete?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isMultipliersLoading ? (
              <TableRow>
                <TableCell colSpan={3}>Loading...</TableCell>
              </TableRow>
            ) : (
              // @ts-ignore
              multipliersData?.map((item: any) => (
                <TableRow key={item.tokenMultiplier.id}>
                  <TableCell>{item.merchant.name}</TableCell>
                  <TableCell>{item.tokenMultiplier.marketplace_merchant_id}</TableCell>
                  <TableCell>{item.tokenMultiplier.num_tokens}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="secondary" onClick={() => handleDeleteTokenMultiplier(item.tokenMultiplier.id)}>Delete</Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Multipliers;
