import { useEffect, useState } from 'react'
import Papa from 'papaparse';

const useImageResolver = () => {
  const [csvData, setCsvData] = useState([]);

  const csvURL = "https://docs.google.com/spreadsheets/d/e/2PACX-1vQ63dx2E29CXxmGaVq00V3lhySrIMboyrpgV-m9l0JkF_4Z9_b1Fh4vou0C6VuDL4zntAzAA8z34sVs/pub?output=csv"

  useEffect(() => {
    Papa.parse(csvURL, {
      download: true,
      header: true,
      complete: (result: any) => {
        setCsvData(result.data);
        console.log(result.data);
      },
      error: (error: any) => {
        console.error('Error fetching CSV data:', error);
      }
    });
  }, [csvURL]);

  return {
    csvData,
  }
}

export default useImageResolver
