import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { FC } from 'react'

interface IModalProps {
  title: string
  open: boolean
  onClose: () => void
  body?: React.ReactNode
  actions?: React.ReactNode
  onSubmit?: any
}

const Modal: FC<IModalProps> = ({
  title,
  open,
  onClose,
  body,
  actions,
  onSubmit
}) => {
  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose} disableRestoreFocus>
      <form onSubmit={onSubmit}>
        <DialogTitle className="border-b flex items-center justify-between">
          <div>{title}</div>
          <IconButton onClick={onClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="pb-3">{body}</div>
        </DialogContent>
        <DialogActions className="border-t">
          <div className="w-full flex gap-3 justify-between p-4">{actions}</div>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default Modal
