import { useState } from 'react'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import SidebarItem from './SidebarItem'
import LogoIcon from 'components/Icons/LogoIcon'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { logout } from 'store/authSlice'
import * as Styles from './index.styles'

export interface ISidebarItem {
  label: string
  url: string
  icon?: React.ReactNode
  children?: [{ label: string; url: string }]
}

interface ISideBar {
  mainMenu: Array<ISidebarItem>
  footerMenu?: Array<ISidebarItem>
}

const Sidebar: React.FC<ISideBar> = ({ mainMenu, footerMenu }) => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state) => state.auth)
  const [isMenuMobileOpen, setIsMenuMobileOpen] = useState(false)

  const closeMobileModal = () => {
    setIsMenuMobileOpen(false)
  }

  return (
    <>
      <Styles.SidebarContainer
        className={` ${
          isMenuMobileOpen ? '-translate-x-0' : '-translate-x-full'
        }`}
      >
        <Styles.SidebarElements>
          <Styles.SidebarLogo>
            <LogoIcon />
          </Styles.SidebarLogo>
          {mainMenu.length ? (
            <Styles.NavContainer>
              <Styles.Nav>
                {mainMenu.map((item, index) => (
                  <SidebarItem
                    closeMobileModal={closeMobileModal}
                    key={`main-menu-${index}`}
                    {...item}
                  />
                ))}
              </Styles.Nav>
            </Styles.NavContainer>
          ) : null}

          <Styles.SidebarFooter>
            {footerMenu?.length ? (
              <Styles.NavContainer>
                <Styles.Nav>
                  {footerMenu.map((item, index) => (
                    <SidebarItem
                      closeMobileModal={closeMobileModal}
                      key={`footer-menu-${index}`}
                      {...item}
                    />
                  ))}
                </Styles.Nav>
              </Styles.NavContainer>
            ) : null}

            <Styles.UserContainer>
              <Styles.UserContent>
                <Styles.UserTitle>{user?.first_name}</Styles.UserTitle>
                <span className="truncate">{user?.email}</span>
              </Styles.UserContent>
              <LogoutOutlinedIcon
                onClick={() => {
                  dispatch(logout())
                }}
                className="ml-auto cursor-pointer"
              />
            </Styles.UserContainer>
          </Styles.SidebarFooter>
        </Styles.SidebarElements>
        <Styles.SidebarClose>
          <CloseOutlinedIcon
            onClick={() => setIsMenuMobileOpen(false)}
            className="mt-1"
            fontSize="large"
          />
        </Styles.SidebarClose>
      </Styles.SidebarContainer>
      <Styles.SidebarDesktop>
        <LogoIcon />
        <MenuOutlinedIcon
          onClick={() => setIsMenuMobileOpen(true)}
          fontSize="large"
        />
      </Styles.SidebarDesktop>
    </>
  )
}

export default Sidebar
