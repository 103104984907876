import * as Styles from 'layouts/PrivateLayout.styles'
import SearchContainer from 'layouts/components/Search/SearchContainer'

const Dashboard = () => {
  return (
    <div>
      <SearchContainer />
      <div className="pt-2">
        <Styles.PageTitleContainer>Dashboard</Styles.PageTitleContainer>
      </div>
    </div>
  )
}

export default Dashboard
