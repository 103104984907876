import { api } from './api'

export const TIER_STATUS: any = {
  1: { label: 'Active', color: 'success' },
  0: { label: 'Inactive', color: 'error' }
}

export interface ITier {
  id: string | number
  name: string
  commission_level: string
  created_at: string
  updated_at: string
  merchant_id: string
  minimum_spend: number
  status: number
  type: string
}

interface ITiersListResponse<T> {
  tiers: {
    total: number
    count: number
    tiers: T[]
  }
}

export const tiersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTiers: build.query<ITiersListResponse<ITier>, void>({
      query: () => `/api/tiers`,
      providesTags: (result) =>
        result?.tiers
          ? [
              ...result.tiers?.tiers.map(({ id }) => ({
                type: 'Tiers' as const,
                id
              })),
              { type: 'Tiers', id: 'PARTIAL-LIST' }
            ]
          : [{ type: 'Tiers', id: 'PARTIAL-LIST' }]
    }),
    addTier: build.mutation<
      { msg: string; id: string },
      {
        id?: string
        name: string
        commission_level: string
        minimum_spend: string
        type: number
        status: number
      }
    >({
      query(body) {
        return {
          url: `/api/tiers`,
          method: 'POST',
          body
        }
      },
      invalidatesTags: (_result, _error, _ids) => [
        { type: 'Tiers', id: 'PARTIAL-LIST' }
      ]
    })
  })
})

export const { useGetTiersQuery, useLazyGetTiersQuery, useAddTierMutation } =
  tiersApi
