import Button from 'components/ui/Button'
import TextField from 'components/ui/TextField'
import { FC } from 'react'
import Modal from 'components/ui/Modal'
import { useNotification } from 'hooks/useNotification'
import { useAddPrizePeriodMutation } from 'services/prizePeriods'

interface IAddPrizePeriodModalProps {
  open: boolean
  onClose: () => void
}

const AddPrizePeriodModal: FC<IAddPrizePeriodModalProps> = ({
  open,
  onClose
}) => {
  const { sendSuccessNotification, sendErrorNotification } = useNotification()
  const [addPrizePeriod] = useAddPrizePeriodMutation()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const name = formData.get('name') as string
    const startDate = formData.get('startDate') as string
    const endDate = formData.get('endDate') as string

    if (!name) {
      sendErrorNotification('Name is required')
      return
    }

    if (!startDate) {
      sendErrorNotification('Start date is required')
      return
    }

    if (!endDate) {
      sendErrorNotification('End date is required')
      return
    }

    if (new Date(endDate) < new Date(startDate)) {
      sendErrorNotification('End date should be greater than start date')
      return
    }

    const response: any = await addPrizePeriod({
      name,
      startDate,
      endDate
    })

    if (response.data) {
      sendSuccessNotification('Prize Period Added Successfully')
      onClose()
    }
  }

  const renderField = ({
    id,
    label,
    placeholder,
    type,
    autoFocus
  }: {
    id: string
    label: string
    placeholder: string
    type: string
    autoFocus?: boolean
  }) => (
    <div className="flex flex-col lg:flex-row mt-4">
      <label className="w-40 text-gray-700 mb-2">{label}</label>
      <TextField
        id={id}
        type={type}
        hiddenLabel
        cc-width="full"
        placeholder={placeholder}
        required
        autoFocus={autoFocus}
      />
    </div>
  )

  const modalBody = (
    <>
      {renderField({
        id: 'name',
        label: 'Period Name *',
        type: 'text',
        placeholder: 'Period Name',
        autoFocus: true
      })}
      {renderField({
        id: 'startDate',
        label: 'Start Date *',
        type: 'date',
        placeholder: 'Start Date'
      })}
      {renderField({
        id: 'endDate',
        label: 'End Date *',
        type: 'date',
        placeholder: 'End Date'
      })}
    </>
  )

  const modalActions = (
    <>
      <Button onClick={onClose} cc-width="full">
        Cancel
      </Button>
      <Button cc-color="purple" cc-width="full" type="submit">
        Add Prize Period
      </Button>
    </>
  )

  return (
    <Modal
      title="Add Prize Period"
      open={open}
      onClose={onClose}
      body={modalBody}
      actions={modalActions}
      onSubmit={handleSubmit}
    />
  )
}

export default AddPrizePeriodModal
