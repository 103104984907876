import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authReducer from './authSlice'
import loginStepsReducer from './formStepsSlice'
import { api } from 'services/api'
import { errorHandler } from 'services/middlewares/errorHandler'

export const rootReducer = combineReducers({
  auth: authReducer,
  formSteps: loginStepsReducer,
  [api.reducerPath]: api.reducer
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([api.middleware, errorHandler])
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
