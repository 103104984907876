import { useState } from 'react'
import { jwtDecode } from 'jwt-decode'
import SignupForm from 'components/Forms/SignupForm'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import { changeEmail, changeStep, resetFormSteps } from 'store/formStepsSlice'
import { useConfirmSignupMutation, useSignupMutation } from 'services/auth'
import { useNotification } from 'hooks/useNotification'
import SignUpVerificationCodeForm from 'components/Forms/SignUpVerificationCodeForm'
import { login as loginStore, USER_TYPE_ADMIN } from 'store/authSlice'
import { useGetCategoriesQuery } from 'services/marketplace'
import { getWildlinkClient } from 'utils/getWildLinkClient'
import { IJwtDecoded } from 'components/Forms/interfaces'
import { amplitudeSetUserId, amplitudeLogEvent } from '../../amplitudeClient';
import { enqueueSnackbar } from 'notistack'

const emailStep = 1
const verificationCodeStep = 2

const Signup = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { sendSuccessNotification } = useNotification()
  const email = useAppSelector((state) => state.formSteps.email)
  const step = useAppSelector((state) => state.formSteps.step)
  const [verificationCode, setVerificationCode] = useState('')
  const [isLimitedTries, setIsLimitedTries] = useState(false)
  const [signup, { isLoading }] = useSignupMutation()
  const [confirmationSignup, { isLoading: isLoadingConfirmation }] =
    useConfirmSignupMutation()
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Chrome extension signup detection
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referrer = queryParams.get('referrer');
  
  // Fetch categories after authentication
  const { data: categories } = useGetCategoriesQuery({
    limit: 80,
    page: 1
  }, {
    skip: !isAuthenticated // Skip this query until authenticated
  });
  const handleEmailSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const nameField = event.currentTarget.querySelector('input[name="name"]') as HTMLInputElement;
    const name = formData.get('name') as string
    const emailForm = formData.get('email') as string
    const password = formData.get('password') as string
    let phone = formData.get('phone') as string
    const isOnlyCountryCode = /^\+\d+$/.test(phone);
    phone = isOnlyCountryCode ? '' : phone;

    if (!name || name.trim() === '') {
      nameField.value = '';
      enqueueSnackbar('Invalid name', {
        variant: 'error',
        autoHideDuration: 1500,
      });
      return;
    }

    const response: any = await signup({
      username: emailForm,
      full_name: name,
      password,
      phone
    })
    if (response?.data) {
      dispatch(changeEmail(emailForm))
      dispatch(changeStep(verificationCodeStep))
      amplitudeSetUserId(emailForm);
      amplitudeLogEvent({
        event_type: 'Auth - Sign Up',
        event_properties: {
          customer_name: emailForm,
          sign_up_method: 'Sign up via form',
          customer_id: response.data.id,
          timestamp: new Date().toISOString(),
        }
      });
    }
  }

  const handleSubmitVerificationCode = async ( event: React.FormEvent<HTMLFormElement> ) => {
    event.preventDefault();
    try {
      const response: any = await confirmationSignup({
        username: email,
        confirmation_code: verificationCode,
      });
  
      if (response?.data) {
        handleSuccessResponse(response);
        amplitudeSetUserId(email);
        amplitudeLogEvent({
          event_type: 'Auth - Confirm Signup',
          event_properties: {
            customer_name: email,
            sign_up_method: 'Sign up via form',
            customer_id: response.data.id,
            timestamp: new Date().toISOString(),
          }
        });
      } else if (response.data === '') {
        handleEmptyResponse();
      } else {
        handleErrorResponse(response);
      }
    } catch (error) {
      console.error("Error during verification:", error);
      resetVerificationInputs();
    }
  };

  const handleGoogleSignIn = async () => {
    const redirectUri = `${process.env.REACT_APP_PRIZEDRAW_AUTH_CALLBACK_URL}`;
    const loginUrl = `${process.env.REACT_APP_AWS_COGNITO_DOMAIN_NAME}/oauth2/authorize` +
      `?response_type=code` +
      `&client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}` +
      `&redirect_uri=${encodeURIComponent(redirectUri)}` +
      `&identity_provider=Google`;

    window.location.href = loginUrl;
  };
  
  const handleSuccessResponse = (response: any) => {
    dispatch(resetFormSteps());
    sendSuccessNotification('Your email has been successfully verified.');
  
    const tokenDecoded: any = jwtDecode(response.data.token);
    const userInfo: IJwtDecoded = tokenDecoded;
    const userType = userInfo['custom:type'];
    const userId = userInfo['cognito:username'];
    const customerId = response.data.customer_id;
  
    dispatch(
      loginStore({
        user: {
          email: userInfo.email,
          id: userId,
          type: userType,
          first_name: userInfo.name,
        },
        token: response.data.token,
        refresh_token: response.data.refresh_token,
        merchantId: userId,
        merchantName: userInfo.name,
        customerId,
      })
    );

    setIsAuthenticated(true);
    
    if (!localStorage.getItem('DeviceID')) {
      const WLClient = getWildlinkClient();
      WLClient.init().then(() => {
        const newDevice = WLClient.getDevice();
        const { DeviceID } = newDevice;
        localStorage.setItem('DeviceID', String(DeviceID));
      });
    }


    localStorage.setItem('customerId', customerId);
    document.cookie = `WAVE_AUTH_CUSTOMER_ID=${customerId}; path=/; max-age=86400; secure; samesite=strict`;
    const homeCategory = categories?.marketplace_categories?.find((category) => category.is_home === true);
    const categoryId = homeCategory ? homeCategory.id : '4082b604-0ada-4376-89a2-83a79c54b829';
    console.log("referrer", referrer)
    if (referrer === "extension") {
      const dashboardRoute = userType === USER_TYPE_ADMIN ? `/admin/dashboard` : `/merchants/${categoryId}?referrer=extension`;
      navigate(dashboardRoute);
    } else {
      const dashboardRoute = userType === USER_TYPE_ADMIN ? `/admin/dashboard` : `/merchants/${categoryId}`;
      navigate(dashboardRoute);
    }
  };
  
  const handleEmptyResponse = () => {
    dispatch(resetFormSteps());
    sendSuccessNotification('Your email has been successfully verified.');
    navigate('/');
  };
  
  const handleErrorResponse = (response: any) => {
    const error = response.error;
  
    if (error?.status === 401) {
      resetVerificationInputs();
      return;
    }
  
    if (error?.status === 500) {
      setIsLimitedTries(true);
      resetVerificationInputs();
      return;
    }

    if (error) {
      resetVerificationInputs();
    }
  }

  const handleCodeVerificationBackButton = () => {
    dispatch(changeStep(emailStep))
  }

  const resetVerificationInputs = () => {
    setVerificationCode('')
    const verificationCodeElement = document.querySelector(
      '.ReactInputVerificationCode__item'
    ) as HTMLElement
    verificationCodeElement.focus()
  }

  return (
    <div data-testid="module-signup-affiliate">
      {step === 1 && (
    <>
      <p className="text-gray-900 text-display-xs-semibold md:text-display-sm-semibold mb-8 text-center">Sign up</p>
      <div className="w-full flex flex-col items-center mt-5 mb-2">
        <div className="flex flex-col gap-4">
          <button
            onClick={handleGoogleSignIn}
            className="bg-white text-gray-600 py-2 px-4 border border-gray-300 rounded-lg shadow-sm hover:bg-gray-100 hover:shadow-md transition-all duration-200 ease-in-out flex items-center justify-center w-60"
          >
            <img src={'/images/logo-google.png'} alt="Google Icon" className="w-5 h-5 mr-2" />
            Continue with Google
          </button>
        </div>
      </div>
      <p className="text-gray-900 mb-2 text-center">OR</p>
      <SignupForm
        formId="component-forms-phone-signup-affiliate"
        title={`Sign up`}
        buttonText="Get started"
        handleSubmit={handleEmailSubmit}
        isLoading={isLoading}
      />
      
    </>
  )}
      {step === 2 && (
        <SignUpVerificationCodeForm
          formId="component-forms-verificationCode-signup-affiliate"
          title="Check your email"
          buttonText="Verify code"
          handleBackButton={handleCodeVerificationBackButton}
          handleSubmit={handleSubmitVerificationCode}
          verificationCode={verificationCode}
          setVerificationCode={setVerificationCode}
          isLoading={isLoadingConfirmation}
          isLimitedTries={isLimitedTries}
          setIsLimitedTries={setIsLimitedTries}
        />
      )}
    </div>
  )
}

export default Signup
