import { Link } from 'react-router-dom'
import * as Styles from 'layouts/PrivateLayout.styles'
import SearchContainer from 'layouts/components/Search/SearchContainer'
import Table from 'components/ui/Table'
import { useGetMerchantsQuery, IMerchant } from 'services/merchants'
import Spinner from 'components/ui/Spinner'
import { formatDateTime } from 'utils/intlDate'
import { getTablePageLimit } from 'utils/getTablePageLimit'
import { usePagination } from 'hooks/usePagination'

const LIMIT = getTablePageLimit()

const Merchants = () => {
  const { page, setPage } = usePagination()
  const { data, isLoading, isFetching } = useGetMerchantsQuery({
    limit: LIMIT,
    page
  })

  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      render: (dataRow: IMerchant) => (
        <div className="underline">
          <Link to={`/admin/merchants/${dataRow.id}`}>
            {dataRow.created_at
              ? formatDateTime(new Date(dataRow.created_at))
              : null}
          </Link>
        </div>
      )
    },
    {
      field: 'store_name',
      headerName: 'Store Name',
      render: (rowData: IMerchant) => rowData.name
    },
    {
      field: 'orders',
      headerName: 'Orders',
      render: (rowData: IMerchant) => rowData.orders_count
    }
  ]

  return (
    <div>
      <SearchContainer />
      <div className="pt-2">
        <Styles.PageTitleContainer>Merchants</Styles.PageTitleContainer>
      </div>
      <div>
        {isLoading ? (
          <Spinner />
        ) : (
          <Table
            columns={columns}
            rows={data?.merchants || []}
            emptyTitleText="No merchants yet"
            onPrevPage={() => setPage(page - 1)}
            onNextPage={() => setPage(page + 1)}
            isFetching={isFetching}
            page={page}
            setPage={setPage}
            totalPages={Math.ceil(Number(data?.records_count) / LIMIT)}
          />
        )}
      </div>
    </div>
  )
}

export default Merchants
