import { FC } from 'react'
import Button from 'components/ui/Button'
import { IFormProps } from './interfaces'
import TextField from 'components/ui/TextField'
import { Link } from 'react-router-dom'
import * as Styles from './Forms.styles'

const LoginForm: FC<IFormProps> = ({
  formId,
  title,
  buttonText,
  isLoading,
  handleSubmit
}) => {
  return (
    <div data-testid={formId}>
      <Styles.Form onSubmit={handleSubmit}>
        <TextField
          id="username"
          label="Email"
          type={'email'}
          cc-width="full"
          required
          autoFocus
        />
        <TextField
          id="password"
          label="Password"
          type={'password'}
          cc-width="full"
          required
        />
        <Button
          data-testid={`${formId}-submit-button`}
          type="submit"
          cc-color="purple"
          cc-width="full"
          loading={isLoading}
        >
          {buttonText}
        </Button>
        <Link to={'/forgot-password'}>
          <Styles.FooterLinkMerchant>Forgot password</Styles.FooterLinkMerchant>
        </Link>
        <Link
          className="text-sm-semibold flex justify-center text-gray-600"
          to={'/signup'}
        >
          Don't have an account?{' '}
          <Styles.FooterLinkMerchant>Sign up</Styles.FooterLinkMerchant>
        </Link>
      </Styles.Form>
    </div>
  )
}

export default LoginForm
