import { Routes, Route } from 'react-router-dom'
import PrivateLayout from 'layouts/PrivateLayout'
import PublicLayout from 'layouts/PublicLayout'
import CustomerLayout from 'layouts/CustomerLayout'
import AdminDashboardPage from 'pages/admin/DashboardPage'
import DashboardPage from 'pages/customer/DashboardPage'
import Login from 'pages/Login'
import Page404 from 'pages/Page404'
import Signup from 'pages/Signup'
import PublicSignupLayout from 'layouts/PublicSignupLayout'
import Forgot from 'pages/Forgot'
import RulesAndTermsPage from 'pages/customer/RulesAndTermsPage'
import AccountPage from 'pages/customer/AccountPage'
import CustomersPage from 'pages/admin/CustomersPage'
import CustomerDetailsPage from 'pages/admin/CustomerDetailsPage'
import OrdersPage from 'pages/admin/OrdersPage'
import OrderDetailsPage from 'pages/admin/OrderDetailsPage'
import MerchantsPage from 'pages/admin/MerchatsPage'
import PrizePeriodsPage from 'pages/admin/PrizePeriodsPage'
import MerchantDetailsPage from 'pages/admin/MerchantDetailsPage'
import MultipliersPage from 'pages/admin/MultipliersPage'
import PrizePeriodDetailsPage from 'pages/admin/PrizePeriodDetailsPage'
import MerchantsListPage from 'pages/customer/MerchantsListPage'
import CategoriesPage from 'pages/admin/CategoriesPage'
import CategoryDetailsPage from 'pages/admin/CategoryDetailsPage'
import BannersPage from 'pages/admin/BannersPage'
import BannerDetailsPage from 'pages/admin/BannerDetailsPage'
import PrizeDetailsPage from 'pages/customer/PrizeDetailsPage'
import MerchantsPageNew from 'pages/customer/MerchantsPageNew'
import HelpPage from 'pages/customer/HelpPage';
import SearchPage from 'pages/customer/SearchPage'
import PrizeEntriesPage from 'pages/customer/PrizeEntriesPage';
import PrizesPage from 'pages/admin/PrizesPage';
import PrizesDetailsPage from 'pages/admin/PrizesDetailsPage'
import TokenHistoryDetailsPage from 'pages/customer/TokenHistoryDetailsPage'
import AuthCallback from 'modules/Login/googleAuthCallBack'
import MerchantsSplashScreen from 'pages/customer/MerchantsSplashScreen/index'
import SubcategoryPage from 'pages/customer/SubcategoryPage'

function App() {
  return (
    <Routes>
      <Route path="/api/health" element={<h3>OK</h3>}></Route>
      <Route path="/forgot-password" element={<Forgot />} />

      <Route element={<PublicLayout />}>
        <Route path="/" element={<Login />} />
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="*" element={<Page404 />} />
      </Route>

      <Route element={<PublicSignupLayout />}>
        <Route path="/signup" element={<Signup />} />
      </Route>

      <Route element={<PrivateLayout />}>
        {/* Admin routes */}
        <Route path="/admin/dashboard" element={<AdminDashboardPage />} />
        <Route path="/admin/customers" element={<CustomersPage />} />
        <Route
          path="/admin/customers/:customerId"
          element={<CustomerDetailsPage />}
        />
        <Route path="/admin/orders" element={<OrdersPage />} />
        <Route path="/admin/orders/:orderId" element={<OrderDetailsPage />} />
        <Route path="/admin/merchants" element={<MerchantsPage />} />
        <Route
          path="/admin/merchants/:merchantId"
          element={<MerchantDetailsPage />}
        />
        <Route path="/admin/prize-periods" element={<PrizePeriodsPage />} />
        <Route
          path="/admin/prize-periods/:prizePeriodId"
          element={<PrizePeriodDetailsPage />}
        />
        <Route path="/admin/prizes" element={<PrizesPage />} />
        <Route
          path="/admin/prizes/:prizeId"
          element={<PrizesDetailsPage />}
        />
        <Route path="/admin/categories" element={<CategoriesPage />} />
        <Route
          path="/admin/categories/:categoryId"
          element={<CategoryDetailsPage />}
        />

        <Route path="/admin/banners" element={<BannersPage />} />
        <Route
          path="/admin/banners/:bannerId"
          element={<BannerDetailsPage />}
        />
        <Route path="/admin/multipliers" element={<MultipliersPage />} />
      </Route>
      <Route element={<CustomerLayout />}>
        {/* Customer Routes */}
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/rules-and-terms" element={<RulesAndTermsPage />} />
        <Route path="/merchants/:categoryId" element={<MerchantsPageNew />} />
        <Route path="/merchants/:categoryId/:subcategoryId" element={<SubcategoryPage />} />
        <Route path="/merchantsold" element={<MerchantsListPage />} />
        <Route path="/account" element={<AccountPage />} />
        <Route path="/prize/:prizeId" element={<PrizeDetailsPage />} />
        <Route path="/help/" element={<HelpPage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/entries" element={<PrizeEntriesPage />} />
        <Route path="/customers/token-history" element={<TokenHistoryDetailsPage />} />
      </Route>

      <Route path="/m" element={<MerchantsSplashScreen />} />
    </Routes>
  )
}

export default App
