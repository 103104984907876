import tw from 'twin.macro'
import { styled } from '@mui/material/styles'

export const TableBox = styled('div')`
  ${tw`border border-gray-200 shadow-sm rounded-xl`}
`
export const EmptyContainer = styled('div')`
  ${tw`w-full flex flex-col justify-center items-center text-md-regular text-gray-600 py-10 text-center`}
`
export const EmptyIcon = styled('div')`
  ${tw`bg-gray-100 border-[10px] border-gray-50 w-14 h-14 rounded-full text-3xl p-1 mb-5 flex`}
`
export const EmptyTitle = styled('div')`
  ${tw`text-xl-semibold text-gray-900 mb-2`}
`
export const FooterContainer = styled('div')`
  ${tw`flex justify-between items-center text-sm-medium text-gray-600`}
`
export const FooterArrows = styled('button')`
  ${tw`text-sm-semibold cursor-pointer`}

  &[disabled] {
    ${tw`cursor-not-allowed text-gray-300`}
  }
`
export const FooterNumbers = styled('div')`
  ${tw`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer`}
  &.is-active {
    ${tw`text-gray-800 bg-gray-50`}
  }
`
