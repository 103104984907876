import { useParams } from 'react-router-dom'
import Button from 'components/ui/Button'
import TextField from 'components/ui/TextField'
import { FC } from 'react'
import Modal from 'components/ui/Modal'
import { useAddCustomerEntriesMutation } from 'services/customers'
import { useNotification } from 'hooks/useNotification'

interface ICustomerAddEntriesModalProps {
  open: boolean
  onClose: () => void
}

const CustomerAddEntriesModal: FC<ICustomerAddEntriesModalProps> = ({
  open,
  onClose
}) => {
  const { customerId } = useParams()
  const { sendSuccessNotification, sendErrorNotification } = useNotification()
  const [addCustomerEntries, { isLoading }] = useAddCustomerEntriesMutation()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!customerId) {
      sendErrorNotification('Customer is required')
      return
    }

    const formData = new FormData(event.currentTarget)
    const entries = formData.get('entries') as string

    if (!entries) {
      sendErrorNotification('Entries are required')
      return
    }

    // entries should be a number
    if (!/^-?[0-9]\d*(\.\d+)?$/.test(entries)) {
      sendErrorNotification('Entries should be a number')
      return
    }

    const totalEntries = parseInt(entries, 10)

    const response: any = await addCustomerEntries({
      customerId,
      entries: totalEntries
    })

    if (response?.data) {
      sendSuccessNotification(`${entries} entries added`)
      onClose()
    }
  }

  const modalBody = (
    <div className="pb-3">
      <div className="flex flex-col lg:flex-row mt-4">
        <label className="w-40 text-gray-700 mb-2">Entries</label>
        <TextField
          id="entries"
          type="number"
          hiddenLabel
          cc-width="full"
          placeholder="Number of entries"
          required
          autoFocus
        />
      </div>
    </div>
  )

  const modalActions = (
    <>
      <Button onClick={onClose} cc-width="full">
        Cancel
      </Button>
      <Button cc-color="purple" type="submit" cc-width="full" disabled={isLoading}>
        Add entries
      </Button>
    </>
  )

  return (
    <Modal
      title="Add Entries"
      open={open}
      onClose={onClose}
      body={modalBody}
      actions={modalActions}
      onSubmit={handleSubmit}
    />
  )
}

export default CustomerAddEntriesModal
