import { IGetPrizesResponse } from "services/prizes";
import { Card, Image, Button, Link, Skeleton } from '@nextui-org/react'
import Slider, { Settings } from 'react-slick'
import { styled } from "@mui/material";
import { twMerge } from "tailwind-merge";
import tw from "twin.macro";


export type PrizesBannersSliderProps = {
  prizesData?: IGetPrizesResponse
  isLoading?: boolean
  className?: string
  button_text?: string
  button_href?: string
}

const StyledSlider = styled(Slider)`
  & .slick-slider {
    ${tw`relative`} 
  }

  &.slick-list,.slick-track {
    ${tw`h-[336px]`} 
  }

  & .slick-dots {
    ${tw`absolute bottom-4`}
    
    li {
      margin: 0;
      
      &.slick-active {
        button::before {  
          ${tw`text-primary-400`}
        }
      }

      button::before {  
        ${tw`text-[8px] text-gray-300 opacity-100`}
      }
    }
  }
`;


const PrizesBannersSlider = (props: PrizesBannersSliderProps) => {
  const activePrizes = props?.prizesData?.prizes.filter((prize) => prize.is_active)
  const headerPrizes = activePrizes?.filter((prize) => prize.is_header)

  if (props.isLoading) {
    return (
      <div className="min-h-screen">
        <Skeleton className="mx-10 my-5 rounded-lg">
          <div className="h-96">Loading banners</div>
        </Skeleton>

        <Skeleton className="mx-10 my-5 rounded-lg">
          <div className="h-96">Loading banners</div>
        </Skeleton>
      </div>
    )
  }

  const settings: Settings = {
    dots: true,
    infinite: true,
    lazyLoad: 'ondemand',
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    arrows: false
  };

  return (
    <StyledSlider {...settings} className={twMerge("h-[336px]", props.className)}>
      {headerPrizes &&
        headerPrizes.map((prize, index) => (
          <div key={index} className="bg-primary-400 h-[336px]">
            <div className="flex flex-col items-center w-full h-[336px]">
              <Card className="w-full h-[336px] rounded-none">
                <Image
                  removeWrapper
                  alt={prize.name}
                  // Depending on the image and where the main object is placed, object-[20%] might need to be adjusted
                  className="z-0 w-full h-full object-[20%] object-cover rounded-none brightness-90"
                  src={prize.header_image_url}
                />
                <div className="absolute max-w-[400px] z-10 top-0 bottom-0 my-[auto] left-6 md:left-auto md:right-[10%] flex flex-col !justify-center">
                  <p className="text-md lg:text-xl font-semibold text-white">WIN THIS</p>
                  <p className="text-3xl lg:text-4xl font-bold text-white">{prize.name.toUpperCase()}</p>
                  <p className="text-md lg:text-xl font-normal mt-4 text-white">One lucky winner will be getting this {prize.name}</p>
                  <Button
                    as={Link}
                    href={props.button_href ? props.button_href : `/prize/${prize.id}`}
                    className="text-md py-2 px-6 lg:text-xl self-start bg-primary-400 rounded-lg lg:p-6 mt-8"
                    color="primary"
                    radius="full"
                    size="lg"
                  >
                    {props.button_text ? props.button_text : "Enter Now"}
                  </Button>
                </div>
              </Card>
            </div>
          </div>
        ))}
    </StyledSlider>
  )
}

export default PrizesBannersSlider;