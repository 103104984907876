import tw from 'twin.macro'
import { styled } from '@mui/material/styles'
import ButtonMUI from '@mui/material/Button'
import { IButtonProps } from '.'

export const Button = styled(ButtonMUI)`
  border: 1px solid;

  ${tw`font-inter bg-white hover:bg-white text-gray-700 border border-gray-300 capitalize text-sm-semibold rounded-lg py-[9px] shadow-xs w-max`}

  &:disabled {
    ${(props: IButtonProps) =>
      props.variant === 'contained' &&
      props.disabled &&
      tw`!bg-gray-50 border-none !text-gray-600`}
  }

  ${(props: IButtonProps) =>
    props.variant === 'text' && tw`border-none shadow-none`}

  ${(props: IButtonProps) =>
    props['cc-color'] === 'purple' &&
    props.variant === 'contained' &&
    tw`bg-primary-400 hover:bg-primary-500 border-primary-400 text-sm-semibold text-white `}
  
  ${(props: IButtonProps) =>
    props['cc-color'] === 'red' &&
    props.variant === 'contained' &&
    tw`bg-red-500 hover:bg-red-600 border-red-500 text-sm-semibold text-white `}
  
  &:disabled {
    ${(props: IButtonProps) =>
      props['cc-color'] === 'red' &&
      props.variant === 'contained' &&
      props.disabled &&
      tw`!bg-red-200 border-red-200 !text-white`}
  }

  ${(props: IButtonProps) =>
    props['cc-color'] === 'purple' &&
    props.variant === 'text' &&
    tw`text-primary-600`}

  ${(props: IButtonProps) =>
    props['cc-color'] === 'purple' &&
    props.variant === 'outlined' &&
    tw`text-primary-600 border-primary-600`}

  &:disabled {
    ${(props: IButtonProps) =>
      props['cc-color'] === 'purple' &&
      props.variant === 'contained' &&
      props.disabled &&
      tw`!bg-primary-200 border-primary-200 !text-white`}
  }

  ${(props: IButtonProps) =>
    props['cc-color'] === 'blue' &&
    props.variant === 'contained' &&
    tw`bg-newPrimary-500 hover:bg-newPrimary-400 border-newPrimary-500 text-sm-semibold text-white `}

  ${(props: IButtonProps) =>
    props['cc-color'] === 'blue' &&
    props.variant === 'text' &&
    tw`text-newPrimary-500`}

  ${(props: IButtonProps) =>
    props['cc-color'] === 'blue' &&
    props.variant === 'outlined' &&
    tw`text-newPrimary-500 border-newPrimary-500`}

  &:disabled {
    ${(props: IButtonProps) =>
      props['cc-color'] === 'blue' &&
      props.variant === 'contained' &&
      props.disabled &&
      tw`!bg-newPrimary-200 border-newPrimary-200 !text-white`}
  }

  ${(props: IButtonProps) => props['cc-width'] === 'full' && tw`w-full`}
`
