import { Image } from "@nextui-org/react";
import { Check } from "@mui/icons-material";
import clsx from "clsx";
import { styled } from "@mui/material";

type StreakNumberProps = {
  currentStreak: number;
  tokensPerDay: Record<string, number>;
  currentTotal: number;
};

export const StreakNumber = (props: StreakNumberProps) => {
  return (
    <div className="flex flex-col justify-center gap-2">
      <div className="flex justify-center items-end gap-4">
        <span className="text-4xl font-bold leading-8">{props.currentStreak}</span>
        <Image
          src="/images/fire-emoji.png"
          alt="Fire streak"
          />
      </div>
      <span className="text-sm text-gray-500 text-center">Day Streak</span>
      <div className="flex items-center px-4 py-1 gap-1 rounded-full text-primary-400 bg-primary-100">
        <strong>{props.currentTotal}</strong>
        Tokens
      </div>
    </div>
  )
}

const streakDaysStyle = {
  text: {
    default: 'text-gray-400 text-center text-xs font-semibold',
    isClaimed: 'text-gray-600',
    isCurrent: '!text-primary-600',
  },
  circle: {
    isClaimed: '!bg-primary-400',
    isCurrent: 'shadow-md border border-primary-600',
    next: 'bg-white border border-gray-200',
  },
}

const GridContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  row-gap: 24px;
  grid-template-areas: 
    "day1 day1 day1 day2 day2 day2 day3 day3 day3 day4 day4 day4"
    ". . day5 day5 . day6 day6 . day7 day7 . .";

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: auto;
    grid-template-areas: 
      "day1 day2 day3 day4 day5 day6 day7";
  }
`;

type DayProps = { 
  index: number;
  onClaim: () => void;
  isClaiming?: boolean;
  currentClaimed: number; 
  currentDay: number;
  day: number;
  isLast: boolean;
  tokens: number;
};
const Day = ({ index, currentClaimed, currentDay, day, onClaim, isClaiming, isLast, tokens }: DayProps) => {
  const currentClaimedIsAfterOrEqualDay = currentClaimed >= day;
  const currentDayEqualsDay = currentDay === day;
  const currentDayIsBeforeDay = currentDay <= day;
  const canClaim = currentDayEqualsDay && currentClaimed !== currentDay && !isClaiming;

  return (
    <div className="relative flex justify-center" style={{ gridArea: `day${index + 1}`}}>
      <hr className={clsx("absolute top-2 left-[calc(-50%+25px)] right-[calc(50%+25px)] border-1 !border-gray-300", {
        'hidden': index === 0,
        'lg:block hidden': index === 4,
        'left-[calc(-50%-25px)] max-sm:left-[calc(-50%-15px)] max-[480px]:left-[calc(-50%-0px)] lg:left-[calc(-50%+25px)]': index > 4
      })}/>
      <div key={day} className="flex w-20 flex-col items-center gap-3 bg-primary-50">
        <div 
          className={clsx("flex flex-col items-center gap-1", streakDaysStyle.text.default, {
            [streakDaysStyle.text.isClaimed]: currentClaimedIsAfterOrEqualDay,
            [streakDaysStyle.text.isCurrent]: currentDayEqualsDay
          })}
        >
          <span>Day</span>
          <span>{day}</span>
        </div>
        {isLast ? 
          <Image 
            height={32}
            width={32}
            src="/images/closed-treasure.png"
            alt="Treasure"
          />
        : (
          <div 
            onClick={canClaim ? onClaim : undefined}
            className={clsx("w-8 h-8 rounded-full flex justify-center items-center transition-all", {  
              [streakDaysStyle.circle.isClaimed]: currentClaimedIsAfterOrEqualDay,
              [streakDaysStyle.circle.isCurrent]: currentDayEqualsDay,
              [streakDaysStyle.circle.next]: currentDayIsBeforeDay,
              'cursor-pointer': canClaim
            })}
          >
            {currentClaimedIsAfterOrEqualDay ? <Check className="text-white"/> : <></>}
          </div>
        )}
        <span 
          className={clsx(streakDaysStyle.text.default, {
            [streakDaysStyle.text.isClaimed]: currentClaimedIsAfterOrEqualDay,
            [streakDaysStyle.text.isCurrent]: currentDayEqualsDay
          })}
        >
          {tokens} Tokens
        </span>
      </div>
    </div>
  )
}

type StreakDaysProps = { 
  onClaim: () => void;
  isClaiming?: boolean;
  tokensPerDay: Record<string, number>;
  currentClaimed: number; 
  currentDay: number
};
export const StreakDays = ({ tokensPerDay, currentClaimed, currentDay, onClaim, isClaiming }: StreakDaysProps) => {
  return (
    <GridContainer className="p-5 bg-primary-50 w-full rounded-2xl">
      {Object.entries(tokensPerDay).map(([day, tokens], index, arr) => (
        <Day
          tokens={tokens}
          isLast={index === arr.length - 1}
          key={day}
          index={index}
          onClaim={onClaim}
          isClaiming={isClaiming}
          currentClaimed={currentClaimed}
          currentDay={currentDay}
          day={Number(day)}
        />
      ))}
    </GridContainer>    
  )
};