import { FC, useState } from 'react'
import { Outlet } from 'react-router-dom'
import BackgroundImage from './components/BackgroundImage'
import * as Styles from './PublicLayout.styles'

const PublicLayout: FC = () => {

  const [imageError, setImageError] = useState(false);

  // if (token) {
  //   const dashboardRoute =
  //     user?.type === USER_TYPE_ADMIN ? '/admin/dashboard' : '/dashboard'
  //   return <Navigate to={dashboardRoute} replace />
  // }

  return (
    <Styles.Container>
      <Styles.LeftColumn>
        <BackgroundImage />
      </Styles.LeftColumn>
      <Styles.RightColumn>
        <Styles.Header>
          {imageError ? (
              <div className="h-28 mx-auto mb-0 text-center font-bold text-2xl text-primary-400">Wave - Save Money, Win Prizes</div>
            ) : (
              <Styles.MobileLogo className="h-28 mx-auto mb-0" src="/images/wave_smwp.png" alt="Wave" onError={() => setImageError(true)} />
            )}
        </Styles.Header>
        <Styles.Content>
          <Outlet />
        </Styles.Content>
      </Styles.RightColumn>
    </Styles.Container>
  )
}
export default PublicLayout
