import { useState } from 'react'
import InfoCard from 'components/ui/InfoCard'
import * as Styles from 'layouts/PrivateLayout.styles'
import OrdersTable from '../orders/OrdersTable'
import {
  useGetMerchantQuery,
  useGetMerchantOrdersQuery
} from 'services/merchants'
import { useParams } from 'react-router-dom'
import { getTablePageLimit } from 'utils/getTablePageLimit';

const LIMIT = getTablePageLimit()

const MerchantDetails = () => {
  const [page, setPage] = useState(1)
  const { merchantId } = useParams()
  const { data: merchantData, isFetching: isFetchingMerchant } =
    useGetMerchantQuery(merchantId || '')

  const {
    data: merchantOrdersData,
    isLoading: isLoadingMerchantOrders,
    isFetching: isFetchingMerchantOrders
  } = useGetMerchantOrdersQuery({
    merchantId: merchantId || '',
    limit: LIMIT,
    page
  })

  return (
    <div className="pt-2">
      <Styles.PageTitleContainer>Merchant</Styles.PageTitleContainer>
      <div className="flex flex-col lg:flex-row justify-between gap-6">
        <InfoCard
          label="Merchant Name"
          value={merchantData?.merchant?.name || ''}
          isLoading={isFetchingMerchant}
          className="w-full"
        />
        <InfoCard
          label="Total Orders"
          value={merchantData?.merchant?.orders_count || 0}
          isLoading={isFetchingMerchant}
          className="w-full"
        />
      </div>
      <div className="mt-8">
        <Styles.PageTitleContainer>Orders</Styles.PageTitleContainer>
        <OrdersTable
          data={merchantOrdersData?.merchant_orders || []}
          isLoading={isLoadingMerchantOrders}
          isFetching={isFetchingMerchantOrders}
          recordsCount={merchantOrdersData?.records_count}
          limit={LIMIT}
          page={page}
          setPage={setPage}
        />
      </div>
    </div>
  )
}

export default MerchantDetails
