import IconButton from '@mui/material/IconButton'
import ToolbarMUI from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import DeleteIcon from '@mui/icons-material/Delete'

interface IToolbarProps {
  numSelected: number
}

const Toolbar: React.FC<IToolbarProps> = ({ numSelected }) => {
  return (
    <>
      {numSelected > 0 ? (
        <ToolbarMUI className="bg-primary-100">
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
          <Tooltip className="!hidden" title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </ToolbarMUI>
      ) : null}
    </>
  )
}

export default Toolbar
