import Button from 'components/ui/Button'
import TextField from 'components/ui/TextField'
import { FC, useEffect, useState } from 'react'
import Modal from 'components/ui/Modal'
import { useNotification } from 'hooks/useNotification'
import { useCreateCategoryMutation, useGetCategoriesQuery } from 'services/marketplace'
import { MenuItem, Select, Switch } from '@mui/material'
import * as LuIcons from 'react-icons/lu';

interface IAddCategoryModalProps {
  open: boolean
  onClose: () => void
}

const AddCategoryModal: FC<IAddCategoryModalProps> = ({ open, onClose }) => {
  const { sendSuccessNotification, sendErrorNotification } = useNotification()
  const [createCategory, { isLoading, isError }] = useCreateCategoryMutation()
  const { data: allCategoriesData } = useGetCategoriesQuery({ limit: 100, page: 1 })
  
  const [selectedParentId, setSelectedParentId] = useState<string | null>(null)
  const [imageUrl, setImageUrl] = useState('');
  const [showInNavbar, setShowInNavbar] = useState(false)
  const [selectedIcon, setSelectedIcon] = useState<string>('')
  const allCategories = allCategoriesData?.marketplace_categories || []

  const handleClose = () => {
    setSelectedParentId(null);
    setImageUrl('');
    setShowInNavbar(false);
    setSelectedIcon('');
    onClose();
  };


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const category_name = formData.get('category_name') as string

    if (!category_name) {
      sendErrorNotification('Name is required')
      return
    }

    const response: any = await createCategory({
      category_name,
      active: true,
      parent_id: selectedParentId || null,
      image_url: imageUrl || null,
      show_in_nav: showInNavbar || false,
      category_icon: selectedIcon || null,
    });

    if (response.data) {
      sendSuccessNotification('Category Added Successfully')
      onClose()
    }
  }

  const renderParentSelect = () => (
    <div className="flex flex-col lg:flex-row mt-4">
      <label className="w-40 text-gray-700 mb-2">Parent Category</label>
      <Select
        value={selectedParentId || ''}
        onChange={(e) => setSelectedParentId(e.target.value as string)}
        displayEmpty
        fullWidth
      >
        <MenuItem value="">None</MenuItem>
        {allCategories.map((parent) => (
          <MenuItem key={parent.id} value={parent.id}>
            {parent.category_name}
          </MenuItem>
        ))}
      </Select>
    </div>
  )

  const renderField = ({
    id,
    label,
    placeholder,
    type,
    autoFocus,
    value,
    onChange,
  }: {
    id: string
    label: string
    placeholder: string
    type: string
    autoFocus?: boolean
    value?: string
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  }) => (
    <div className="flex flex-col lg:flex-row mt-4">
      <label className="w-40 text-gray-700 mb-2">{label}</label>
      <TextField
        id={id}
        type={type}
        hiddenLabel
        cc-width="full"
        placeholder={placeholder}
        required
        autoFocus={autoFocus}
        value={value}
        onChange={onChange}
      />
    </div>
  );

  const iconOptions = Object.keys(LuIcons)
    .filter((name) => name.startsWith('Lu'))
    .map((name) => ({ name, component: LuIcons[name as keyof typeof LuIcons] }));

  const renderIconSelect = () => (
    <div className="flex flex-col lg:flex-row mt-4">
      <label className="w-40 text-gray-700 mb-2">Navbar Icon</label>
      <Select
        value={selectedIcon}
        onChange={(e) => setSelectedIcon(e.target.value as string)}
        displayEmpty
        fullWidth
      >
        <MenuItem value="">None</MenuItem>
        {iconOptions.map(({ name, component: IconComponent }) => (
          <MenuItem key={name} value={name}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <IconComponent size={20} />
              {name.replace('Lu', '')}
            </div>
          </MenuItem>
        ))}
      </Select>
    </div>
  );

  const renderShowInNavbarToggle = () => (
    <div className="flex flex-col lg:flex-row mt-4">
      <label className="w-40 text-gray-700 mb-2">Show in Navbar</label>
      <div className="text-gray-600 flex gap-6 items-center">
        <Switch
          checked={showInNavbar}
          onChange={(e) => setShowInNavbar(e.target.checked)}
          name="showInNavbar"
        />
      </div>
    </div>
  );

  const modalBody = (
    <>
      {renderField({
        id: 'category_name',
        label: 'Name *',
        type: 'text',
        placeholder: 'Name',
        autoFocus: true
      })}
      {renderField({
        id: 'image_url',
        label: 'Image URL',
        type: 'text',
        placeholder: 'Image URL',
        value: imageUrl,
        onChange: (e) => setImageUrl(e.target.value)
      })}
      {renderParentSelect()}
      {renderIconSelect()}
      {renderShowInNavbarToggle()}
    </>
  );

  const modalActions = (
    <>
      <Button onClick={onClose} cc-width="full">
        Cancel
      </Button>
      <Button
        cc-color="purple"
        cc-width="full"
        type="submit"
        disabled={isLoading}
      >
        {isLoading ? 'Saving...' : 'Add Category'}
      </Button>
    </>
  )

  useEffect(() => {
    if (isError) {
      sendErrorNotification('Failed to add category')
    }
  }, [isError, sendErrorNotification])

  return (
    <Modal
      title="Add Category"
      open={open}
      onClose={handleClose}
      body={modalBody}
      actions={modalActions}
      onSubmit={handleSubmit}
    />
  )
}

export default AddCategoryModal
