import { useState } from 'react';
import { Outlet } from 'react-router-dom'
import BackgroundImage from './components/BackgroundImage'
import * as Styles from './PublicLayout.styles'

const PublicSignupLayout = () => {
  const [imageError, setImageError] = useState(false);

  return (
    <Styles.Container>
      <div className="pr-4 hidden md:flex flex-col md:justify-center lg:items-center relative max-h-screen">
        <BackgroundImage src="/images/signup.png" alt="Signup" />
      </div>
      <div className="py-4 md:py-12 px-4 flex flex-col md:justify-center lg:items-center relative">
        {/* <Styles.Logo src="/images/logo.png" alt="Prize Draw" /> */}
        <Styles.Header>
          {imageError ? (
              <div className="h-28 mx-auto mb-0 text-center font-bold text-2xl text-primary-400">Wave - Save Money, Win Prizes</div>
            ) : (
              <Styles.MobileLogo className="h-28 mx-auto mb-0" src="/images/wave_smwp.png" alt="Wave" onError={() => setImageError(true)} />
            )}
        </Styles.Header>
        <Styles.Content>
          <Outlet />
        </Styles.Content>
      </div>

    </Styles.Container>
  )
}
export default PublicSignupLayout
