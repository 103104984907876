import { useState } from 'react'
import Table from 'components/ui/Table'
import Button from 'components/ui/Button'
import Spinner from 'components/ui/Spinner'
import SearchContainer from 'layouts/components/Search/SearchContainer'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import AddBannerModal from './AddBannerModal'
import { Cancel, CheckCircle } from '@mui/icons-material'
import { IBanner, useGetBannersQuery } from 'services/marketplace'
import { getTablePageLimit } from 'utils/getTablePageLimit'
import { formatDateTime } from 'utils/intlDate'
import { usePagination } from 'hooks/usePagination'
import { Link } from 'react-router-dom'
import { Chip } from '@mui/material'
import * as Styles from 'layouts/PrivateLayout.styles'

const LIMIT = getTablePageLimit()

const Banners = () => {
  const { page, setPage } = usePagination()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { data, isLoading, isFetching } = useGetBannersQuery({
    limit: LIMIT,
    page
  })

  const addBannerAction = (
    <Styles.SearchContainerButtons>
      <Button
        startIcon={<AddOutlinedIcon />}
        cc-color="purple"
        onClick={() => setIsOpenModal(true)}
      >
        New Banner
      </Button>
    </Styles.SearchContainerButtons>
  )

  const columns = [
    {
      field: 'name',
      headerName: 'Banner Name',
      render: (dataRow: IBanner) => (
        <div className="underline">
          <Link to={`/admin/banners/${dataRow.id}`}>{dataRow.banner_name}</Link>
        </div>
      )
    },
    {
      field: 'date',
      headerName: 'Created at',
      render: (rowData: IBanner) =>
        rowData.created_at ? formatDateTime(new Date(rowData.created_at)) : null
    },
    {
      field: 'active',
      headerName: 'Active',
      render: (dataRow: IBanner) => (
        <>
          {dataRow.active ? (
            <Chip label={<CheckCircle />} color="success" />
          ) : (
            <Chip label={<Cancel />} color="error" />
          )}
        </>
      )
    }
  ]

  return (
    <div>
      <AddBannerModal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
      <SearchContainer action={addBannerAction} />
      <div className="pt-2">
        <Styles.PageTitleContainer>Banners</Styles.PageTitleContainer>
      </div>
      <div>
        {isLoading ? (
          <Spinner />
        ) : (
          <Table
            columns={columns}
            rows={data?.marketplace_banners || []}
            emptyTitleText="No banners yet"
            emptyText='You can create a new banner by clicking on "New Banner" button.'
            onPrevPage={() => setPage(page - 1)}
            onNextPage={() => setPage(page + 1)}
            isFetching={isFetching}
            page={page}
            setPage={setPage}
            totalPages={Math.ceil(Number(data?.records_count) / LIMIT)}
          />
        )}
      </div>
    </div>
  )
}

export default Banners
