import tw from 'twin.macro'
import { styled } from '@mui/material/styles'

export const Container = styled('div')`
  ${tw`grid grid-cols-1 md:grid-cols-2 h-screen 2xl:container 2xl:mx-auto`}
`
export const LeftColumn = styled('div')`
  ${tw`hidden md:flex relative`}
`
export const BgImage = styled('img')`
  ${tw`h-full object-contain bg-primary-400`}
`
export const Brand = styled('div')`
  ${tw`w-[167px] h-[167px] absolute inset-0 m-auto border-4 border-white rounded-lg shadow-lg`}
`
export const BrandInitials = styled('div')`
  ${tw`absolute inset-0 m-auto bg-primary-600 flex justify-center items-center text-display-xl-regular text-white`}
`
export const BrandImage = styled('img')`
  ${tw`rounded-sm object-cover w-[167px] h-[160px]`}
`
export const BrandExtra = styled('div')`
  ${tw`absolute bottom-10 left-10 right-10 mx-auto p-5 rounded-lg border border-white border-solid bg-backdrop backdrop-blur-lg text-base xl:text-display-md-semibold text-white`}
`
export const BrandExtraOptions = styled('ol')`
  ${tw`text-sm xl:text-lg-semibold mt-4 leading-5 lg:leading-10`}
`
export const VerifiedImage = styled('img')`
  ${tw`absolute right-1 bottom-1`}
`
export const RightColumn = styled('div')`
  ${tw`py-4 md:py-12 px-4 flex flex-col md:justify-center lg:items-center relative`}
`
export const Header = styled('header')`
  ${tw`md:hidden`}
`
export const Logo = styled('img')`
  ${tw`w-56 absolute top-8 left-8 hidden md:block`}
`
export const MobileLogo = styled('img')`
  ${tw`mb-2`}
`
export const Content = styled('div')`
  ${tw`lg:w-[360px]`}
`
