import { FC } from 'react'
import { useParams } from 'react-router-dom'
import * as Styles from 'layouts/PrivateLayout.styles'
import HorizontalFieldRow from 'components/ui/HorizontalFieldRow'
import { Chip } from '@mui/material'
import CustomerDetailsEntriesTable from './CustomerDetailsEntriesTable'
import { useGetCustomerQuery } from 'services/customers'
import { formatDateTime } from 'utils/intlDate'
import ContentLoadingPlaceholder from 'components/ui/ContentLoadingPlaceholder'

const CustomerDetails: FC = () => {
  const { customerId = '' } = useParams()
  const { data, isFetching } = useGetCustomerQuery(customerId)
  const customer = data?.customer
  const createdDate = customer?.created_at
    ? new Date(customer?.created_at)
    : null

  return (
    <div className="pt-2">
      <Styles.PageTitleContainer>
        View Customer Account
      </Styles.PageTitleContainer>
      <div>
        <HorizontalFieldRow label="Account Status">
          <div className="text-gray-600 flex gap-6 items-center">
            <ContentLoadingPlaceholder isLoading={isFetching}>
              <>
                <div>{createdDate ? formatDateTime(createdDate) : null}</div>
                {customer?.cognito_verified ? (
                  <Chip label="Verified" color="success" />
                ) : (
                  <Chip label="Not verified" />
                )}
              </>
            </ContentLoadingPlaceholder>
          </div>
        </HorizontalFieldRow>
        <HorizontalFieldRow label="Full Name">
          <div className="w-full lg:w-1/2 text-gray-600">
            <ContentLoadingPlaceholder isLoading={isFetching}>
              {customer?.name}
            </ContentLoadingPlaceholder>
          </div>
        </HorizontalFieldRow>
        <HorizontalFieldRow label="Email" showBorderBottom>
          <div className="w-full lg:w-1/2 text-gray-600">
            <ContentLoadingPlaceholder isLoading={isFetching}>
              {customer?.email}
            </ContentLoadingPlaceholder>
          </div>
        </HorizontalFieldRow>
      </div>
      <div className="mt-6">
        <CustomerDetailsEntriesTable />
      </div>
    </div>
  )
}

export default CustomerDetails
