// @ts-nocheck

import * as Styles from 'layouts/PrivateLayout.styles'
import { useGetPrizeQuery, useDeletePrizeMutation } from 'services/prizes'
import { useParams, useNavigate } from 'react-router-dom'
import HorizontalFieldRow from 'components/ui/HorizontalFieldRow'
import { formatDate } from 'utils/intlDate'
import ContentLoadingPlaceholder from 'components/ui/ContentLoadingPlaceholder'
import { Button, Image } from '@nextui-org/react'
import EditPrizeModal from './EditPrizeModal'
import { useState } from 'react'
import {
  Cancel,
  CheckCircle,
} from '@mui/icons-material'
import {
  Chip,
} from '@mui/material'
import RichText from 'components/ui/RichText'
import { defaultDetailsPageStyles } from 'constants/prize-details'

const PrizesDetails = () => {
  const { prizeId } = useParams()
  const { data, isFetching, refetch } = useGetPrizeQuery(prizeId || '')
  const startDate = data?.prize?.start_date ? new Date(data.prize.start_date) : null
  const endDate = data?.prize?.end_date ? new Date(data.prize.end_date) : null
  const details = data?.prize.details ? data.prize.details : null
  const name = data?.prize?.name ? data.prize.name : null
  const cost = data?.prize?.token_cost ? data.prize.token_cost : null
  const order = data?.prize?.order ? data.prize.order : null
  const imageUrl = data?.prize?.image_url ? data.prize.image_url : null
  const headerImageUrl = data?.prize?.header_image_url ? data.prize.header_image_url : null
  const mobileImageUrl = data?.prize?.mobile_image_url ? data.prize.mobile_image_url : null
  const detailBgImageUrl = data?.prize?.detail_bg_image_url ? data.prize.detail_bg_image_url : null
  const detailsPageStyles = {
    image: {
      width: data?.prize?.prize_details_page_styles?.image?.width || defaultDetailsPageStyles.image.width,
      height: data?.prize?.prize_details_page_styles?.image?.height || defaultDetailsPageStyles.image.height,
      position: data?.prize?.prize_details_page_styles?.image?.position || defaultDetailsPageStyles.image.position
    }
  }
  const linkName = data?.prize?.link_name ? data.prize.link_name : null
  const linkUrl = data?.prize?.link_url ? data.prize.link_url : null
  const hexColor = data?.prize?.hex_color ? data.prize.hex_color : null
  const isHeader = data?.prize?.is_header ? data.prize.is_header : false
  const isActive = data?.prize?.is_active ? data.prize.is_active : false
  const isDailyDraw = data?.prize?.is_daily_draw ? data.prize.is_daily_draw : false

  const [deletePrize] = useDeletePrizeMutation();
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const navigate = useNavigate();

  const handleDelete = async () => {
    if (!prizeId) return;
    try {
      await deletePrize(prizeId).unwrap();
      navigate('/admin/prizes');
    } catch (error) {
      console.error('Failed to delete the prize:', error);
    }
  }

  const handleEdit = () => {
    setIsOpenEditModal(true);
  }

  console.log(linkUrl)
  return (
    <div className="pt-2">
      <Styles.PageTitleContainer>
        Prize
        <Button color="warning" className="ml-2" onClick={handleEdit}>Edit</Button>
      </Styles.PageTitleContainer>
      <EditPrizeModal
        open={isOpenEditModal}
        onClose={() => setIsOpenEditModal(false)}
        onSubmitSuccess={() => refetch()}
        initialData={{
          id: prizeId,
          name,
          imageURL: imageUrl,
          headerImageURL: headerImageUrl,
          mobileImageURL: mobileImageUrl,
          detailBgImageURL: detailBgImageUrl,
          detailsPageStyles: detailsPageStyles,
          startDate: startDate ? startDate.toISOString().split('T')[0] : '',
          endDate: endDate ? endDate.toISOString().split('T')[0] : '',
          tokenCost: cost,
          order,
          linkName,
          linkURL: linkUrl,
          details,
          hexColor,
          isHeader,
          isActive,
          isDailyDraw
        }}
      />
      <div>
        <HorizontalFieldRow label="Image">
          <ContentLoadingPlaceholder isLoading={isFetching}>
            <Image 
              src={imageUrl}
              className="h-24"
            />
          </ContentLoadingPlaceholder>
        </HorizontalFieldRow>
        <HorizontalFieldRow label="Detail Background Image">
          <ContentLoadingPlaceholder isLoading={isFetching}>
            <Image 
              src={detailBgImageUrl}
              className="h-24"
            />
          </ContentLoadingPlaceholder>
        </HorizontalFieldRow>
        <HorizontalFieldRow label="Header Image">
          <ContentLoadingPlaceholder isLoading={isFetching}>
            <Image 
              src={headerImageUrl}
              className="h-24"
            />
          </ContentLoadingPlaceholder>
        </HorizontalFieldRow>
        <HorizontalFieldRow label="Mobile Image">
          <ContentLoadingPlaceholder isLoading={isFetching}>
            <Image 
              src={mobileImageUrl}
              className="h-24"
            />
          </ContentLoadingPlaceholder>
        </HorizontalFieldRow>
        <HorizontalFieldRow label="Prize Period Name ">
          <ContentLoadingPlaceholder isLoading={isFetching}>
            {name}
          </ContentLoadingPlaceholder>
        </HorizontalFieldRow>
        <HorizontalFieldRow label="Details" labelClassname='shrink-0'>
          <ContentLoadingPlaceholder isLoading={isFetching}>
            <RichText.Renderer value={details} />
          </ContentLoadingPlaceholder>
        </HorizontalFieldRow>
        <HorizontalFieldRow label="Start Date">
          <ContentLoadingPlaceholder isLoading={isFetching}>
            {startDate ? formatDate(startDate) : ''}
          </ContentLoadingPlaceholder>
        </HorizontalFieldRow>
        <HorizontalFieldRow label="End Date" showBorderBottom>
          <ContentLoadingPlaceholder isLoading={isFetching}>
            {endDate ? formatDate(endDate) : ''}
          </ContentLoadingPlaceholder>
        </HorizontalFieldRow>
        <HorizontalFieldRow label="Token Cost" showBorderBottom>
          <ContentLoadingPlaceholder isLoading={isFetching}>
            {cost}
          </ContentLoadingPlaceholder>
        </HorizontalFieldRow>
        <HorizontalFieldRow label="Link Name" showBorderBottom>
          <ContentLoadingPlaceholder isLoading={isFetching}>
            {linkName}
          </ContentLoadingPlaceholder>
        </HorizontalFieldRow>
        <HorizontalFieldRow label="Link URL" showBorderBottom>
          <ContentLoadingPlaceholder isLoading={isFetching}>
            {linkUrl}
          </ContentLoadingPlaceholder>
        </HorizontalFieldRow>
        <HorizontalFieldRow label="Hex Color" showBorderBottom>
          <ContentLoadingPlaceholder isLoading={isFetching}>
            {hexColor}
          </ContentLoadingPlaceholder>
        </HorizontalFieldRow>
        <HorizontalFieldRow label="Order" showBorderBottom>
          <ContentLoadingPlaceholder isLoading={isFetching}>
            {order}
          </ContentLoadingPlaceholder>
        </HorizontalFieldRow>
        <HorizontalFieldRow label="Is Header" showBorderBottom>
          <ContentLoadingPlaceholder isLoading={isFetching}>
            {isHeader ? (
              <Chip label={<CheckCircle />} color="success" />
            ) : (
              <Chip label={<Cancel />} color="error" />
            )}
          </ContentLoadingPlaceholder>
        </HorizontalFieldRow>
        <HorizontalFieldRow label="Is Active" showBorderBottom>
          <ContentLoadingPlaceholder isLoading={isFetching}>
            {isActive ? (
                <Chip label={<CheckCircle />} color="success" />
              ) : (
                <Chip label={<Cancel />} color="error" />
              )}
          </ContentLoadingPlaceholder>
        </HorizontalFieldRow>
        <HorizontalFieldRow label="Is Daily Draw" showBorderBottom>
          <ContentLoadingPlaceholder isLoading={isFetching}>
            {isDailyDraw ? (
                <Chip label={<CheckCircle />} color="success" />
              ) : (
                <Chip label={<Cancel />} color="error" />
              )}
          </ContentLoadingPlaceholder>
        </HorizontalFieldRow>
        <Button color="danger" className="mt-3" onClick={handleDelete}>Delete</Button>
      </div>
    </div>
  )
}

export default PrizesDetails



