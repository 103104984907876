import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined'
import AppsIcon from '@mui/icons-material/Apps'
import Sidebar, { ISidebarItem } from './components/Sidebar/'
import { useAppSelector } from 'store/hooks'
import { USER_TYPE_ADMIN } from 'store/authSlice'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import * as Styles from './PrivateLayout.styles'

function PrivateLayout() {
  const { token, role } = useAppSelector((state) => state.auth)
  const isAdminUser = role === USER_TYPE_ADMIN
  let location = useLocation()

  if (!token) {
    const loginRoute = '/'
    return <Navigate to={loginRoute} state={{ from: location }} replace />
  }

  const mainAdminMenu: Array<ISidebarItem> = [
    {
      label: 'Dashboard',
      url: '/admin/dashboard',
      icon: <AssessmentOutlinedIcon />
    },
    {
      label: 'Customers',
      url: '/admin/customers?page=1',
      icon: <DescriptionOutlinedIcon />
    },
    {
      label: 'Orders',
      url: '/admin/orders?page=1',
      icon: <DescriptionOutlinedIcon />
    },
    {
      label: 'Merchants',
      url: '/admin/merchants?page=1',
      icon: <DescriptionOutlinedIcon />
    },
    {
      label: 'Prize Periods',
      url: '/admin/prize-periods?page=1',
      icon: <DescriptionOutlinedIcon />
    },
    {
      label: 'Prizes',
      url: '/admin/prizes?page=1',
      icon: <DescriptionOutlinedIcon />
    },
    {
      label: 'Categories',
      url: '/admin/categories?page=1',
      icon: <DescriptionOutlinedIcon />
    },
    {
      label: 'Banners',
      url: '/admin/banners?page=1',
      icon: <DescriptionOutlinedIcon />
    },
    {
      label: 'Multipliers',
      url: '/admin/multipliers?page=1',
      icon: <DescriptionOutlinedIcon />
    }
  ]
  const mainCustomerMenu: Array<ISidebarItem> = [
    {
      label: 'Dashboard',
      url: '/dashboard',
      icon: <AssessmentOutlinedIcon />
    },
    {
      label: 'Rules and Terms',
      url: '/rules-and-terms',
      icon: <DescriptionOutlinedIcon />
    },
    {
      label: 'Merchants List',
      url: '/merchants',
      icon: <AppsIcon />
    }
  ]

  const customerFooterMenu: Array<ISidebarItem> = [
    {
      label: 'My Account',
      url: '/account',
      icon: <Person2OutlinedIcon />
    }
  ]

  const adminFooterMenu: Array<ISidebarItem> = []

  return (
    <Styles.PageContainer>
      <Sidebar
        mainMenu={isAdminUser ? mainAdminMenu : mainCustomerMenu}
        footerMenu={isAdminUser ? adminFooterMenu : customerFooterMenu}
      />
      <Styles.Main>
        <Outlet />
      </Styles.Main>
    </Styles.PageContainer>
  )
}
export default PrivateLayout
