import { FaExternalLinkAlt } from "react-icons/fa";
import { Dots, LogoContainer, LogoWrapper, PageWrapper } from "./styles";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const redirectWithWildLink = (merchantId: string, merchantUrl: string) => {
  const DeviceID = localStorage.getItem('DeviceID')
  const url = merchantUrl
  const trackingCode = localStorage.getItem('customerId')

  const generatedLink = `https://wild.link/e?c=${merchantId}&d=${DeviceID}&url=${url}&tc=${trackingCode}`

  window.location.href = generatedLink;
}

const MerchantsSplashScreen = () => {
  const location = useLocation();
  const logoContainerRef = useRef<HTMLDivElement>(null);
  const searchParams = new URLSearchParams(location.search);
  const merchant_name = searchParams.get('merchant_name');
  const merchant_id = searchParams.get('merchant_id');
  const merchant_url = searchParams.get('merchant_url');
  const image_url = searchParams.get('image_url');
  
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      logoContainerRef.current?.classList.add('animate');
      setTimeout(() => {
        redirectWithWildLink(merchant_id!, merchant_url!);
      }, 2000);
    }, 1500);

    return () => clearTimeout(timeoutId);
  }, [merchant_id, merchant_name, merchant_url])

  return (
    <PageWrapper>
      <LogoContainer ref={logoContainerRef}>
        <LogoWrapper className='logo1'>
          <img className="w-[50px] md:w-[70px]" src={'/images/mobile-logo.png'} alt='wave big logo'/>
        </LogoWrapper>
        <Dots className='dots'>
          <div />
          <div />
          <div />
          <div />
        </Dots>
        <LogoWrapper className='relative logo2'>
          <img className='w-[30px] sm:w-[40px] md:w-[50px] absolute top-0 right-0 wave opacity-0' alt='wave small logo' src={'/images/mobile-logo.png'} />

          <img width={90} src={image_url ?? ''} alt='merchant logo'/>
        </LogoWrapper>

      </LogoContainer>
      <h1 className='text-3xl md:text-5xl font-semibold text-center mt-8'>Tokens activated</h1>
      <span className='text-lg md:text-xl font-normal'>at {merchant_name}</span>
      <div 
        className='text-sm md:text-md font-semibold flex items-center gap-2 text-primary-400 mt-6'
        onClick={() => window.open('https://www.joinwave.com/rules', '_blank')}
      >
        See Rules & Terms <FaExternalLinkAlt />
      </div>
    </PageWrapper>
  )
};

export default MerchantsSplashScreen;