import { useEffect, useState } from "react";
import { useGetCustomerNumbersGameQuery } from "services/customers";
import { addHours, isAfter } from "date-fns";

export default function useGetNumbersGameData(customerId: string, nextGuessTimeSubscriber?: (nextTime: string) => any) {
  const { data, isLoading, refetch } = useGetCustomerNumbersGameQuery(customerId);
  const [isGuessable, setIsGuessable] = useState(false);

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (!isLoading && !data?.numbers_game_last_played_at) {
      setIsGuessable(true);
    } else if (!isLoading && data?.numbers_game_last_played_at) {
      const now = new Date();
      const lastGuessDatePlus24 = addHours(data?.numbers_game_last_played_at, 24);
      const isAfter24Hours = isAfter(now, lastGuessDatePlus24);
      setIsGuessable(isAfter24Hours);
      if(!isAfter24Hours) {
        interval = setInterval(() => {
          const now = new Date();
          const canGuess = isAfter(now, lastGuessDatePlus24);

          if(canGuess) {
            refetch();
            clearInterval(interval);
          } else if (nextGuessTimeSubscriber) {
            const diff = lastGuessDatePlus24.getTime() - now.getTime();
            const hours = Math.floor(diff / 1000 / 60 / 60).toString().padStart(2, '0');
            const minutes = Math.floor((diff / 1000 / 60) % 60).toString().padStart(2, '0');
            const seconds = Math.floor((diff / 1000) % 60).toString().padStart(2, '0');
            nextGuessTimeSubscriber(`${hours}:${minutes}:${seconds}`);
          }
        });
      }
    } else {
      setIsGuessable(false);
    }

  }, [data, isLoading, nextGuessTimeSubscriber, refetch]);

  return {
    data,
    refetch,
    isGuessable,
    isLoading,
  };
}