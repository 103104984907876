import { OptionsObject, useSnackbar, VariantType } from 'notistack'

export const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const sendNotification = (text: string, variant: VariantType = 'default') => {
    enqueueSnackbar(text, { variant })
  }

  const sendSuccessNotification = (text: string, options?: OptionsObject<"success">) => {
    enqueueSnackbar(text, { variant: 'success', ...options })
  }

  const sendErrorNotification = (text: string, options?: OptionsObject<"error">) => {
    enqueueSnackbar(text, { variant: 'error', hideIconVariant: true, ...options })
  }

  return {
    sendNotification,
    sendSuccessNotification,
    sendErrorNotification,
    enqueueSnackbar,
    closeSnackbar
  }
}
