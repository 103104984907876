import { FC } from 'react'
import { Link } from 'react-router-dom'
import Table from 'components/ui/Table'
import { IOrder } from 'services/orders'
import Spinner from 'components/ui/Spinner'
import { formatDateTime } from 'utils/intlDate'

interface IOrdersTableProps {
  data: IOrder[]
  isLoading: boolean
  isFetching: boolean
  recordsCount?: number
  limit: number
  page: number
  setPage: (page: number) => void
}

const OrdersTable: FC<IOrdersTableProps> = ({
  data,
  isLoading,
  isFetching,
  recordsCount,
  limit,
  page,
  setPage
}) => {
  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      render: (dataRow: IOrder) => (
        <div className="underline">
          <Link to={`/admin/orders/${dataRow.id}`}>
            {dataRow.created_at
              ? formatDateTime(new Date(dataRow.created_at))
              : null}
          </Link>
        </div>
      )
    },
    {
      field: 'order_number',
      headerName: 'Order Number',
      render: (rowData: IOrder) => rowData.order_number
    },
    {
      field: 'customer_name',
      headerName: 'Customer Name',
      render: (rowData: IOrder) => rowData.customer.name
    },
    {
      field: 'customer_email',
      headerName: 'Email',
      sortable: false,
      render: (rowData: IOrder) => rowData.customer.email
    }
  ]

  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <Table
          columns={columns}
          rows={data || []}
          emptyTitleText="No orders yet"
          onPrevPage={() => setPage(page - 1)}
          onNextPage={() => setPage(page + 1)}
          isFetching={isFetching}
          page={page}
          setPage={setPage}
          totalPages={Math.ceil(Number(recordsCount) / limit)}
        />
      )}
    </div>
  )
}

export default OrdersTable
