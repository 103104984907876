import { useState } from 'react'
import * as Styles from 'layouts/PrivateLayout.styles'
import SearchContainer from 'layouts/components/Search/SearchContainer'
import Table from 'components/ui/Table'
import { useGetPrizePeriodsQuery, IPrizePeriod } from 'services/prizePeriods'
import Spinner from 'components/ui/Spinner'
import { formatDate } from 'utils/intlDate'
import { Link } from 'react-router-dom'
import AddPrizePeriodModal from './AddPrizePeriodModal'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import Button from 'components/ui/Button'
import { getTablePageLimit } from 'utils/getTablePageLimit';
import { usePagination } from 'hooks/usePagination'

const LIMIT = getTablePageLimit()

const PrizePeriods = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { page, setPage } = usePagination()
  const { data, isLoading, isFetching } = useGetPrizePeriodsQuery({
    limit: LIMIT,
    page
  })

  const addPrizePeriodAction = (
    <Styles.SearchContainerButtons>
      <Button
        startIcon={<AddOutlinedIcon />}
        onClick={() => setIsOpenModal(true)}
      >
        Add Prize Period
      </Button>
    </Styles.SearchContainerButtons>
  )

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      render: (dataRow: IPrizePeriod) => (
        <div className="underline">
          <Link to={`/admin/prize-periods/${dataRow.id}`}>{dataRow.name}</Link>
        </div>
      )
    },
    {
      field: 'start_date',
      headerName: 'Start Date',
      render: (rowData: IPrizePeriod) => {
        const startDate = rowData.start_date
          ? new Date(rowData.start_date)
          : null
        return startDate ? formatDate(startDate) : ''
      }
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      render: (rowData: IPrizePeriod) => {
        const endDate = rowData.end_date ? new Date(rowData.end_date) : null
        return endDate ? formatDate(endDate) : ''
      }
    }
  ]

  return (
    <div>
      <AddPrizePeriodModal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
      <SearchContainer action={addPrizePeriodAction} />
      <div className="pt-2">
        <Styles.PageTitleContainer>Prize Periods</Styles.PageTitleContainer>
      </div>
      <div>
        {isLoading ? (
          <Spinner />
        ) : (
          <Table
            columns={columns}
            rows={data?.prize_periods || []}
            emptyTitleText="No Prize Periods yet"
            onPrevPage={() => setPage(page - 1)}
            onNextPage={() => setPage(page + 1)}
            isFetching={isFetching}
            page={page}
            setPage={setPage}
            totalPages={Math.ceil(Number(data?.records_count) / LIMIT)}
          />
        )}
      </div>
    </div>
  )
}

export default PrizePeriods
