import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { login as loginStore, USER_TYPE_ADMIN } from 'store/authSlice';
import { useGoogleLoginMutation } from 'services/auth';
import { getWildlinkClient } from 'utils/getWildLinkClient';
import { useGetCategoriesQuery } from 'services/marketplace';

const AuthCallback = () => {
  const hasRun = useRef(false);
  const navigate = useNavigate();
  const location = useLocation()
  const dispatch = useAppDispatch();
  const [googleLogin] = useGoogleLoginMutation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userType, setUserType] = useState('');

  const authToken = useAppSelector((state) => state.auth.token);

  // Fetch categories after authentication
  const { data: categories } = useGetCategoriesQuery({
    limit: 80,
    page: 1
  }, {
    skip: !isAuthenticated
  });

  useEffect(() => {
    if (hasRun.current) return;
    hasRun.current = true; 
    async function handleAuthentication() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const redirect_uri = `${process.env.REACT_APP_PRIZEDRAW_AUTH_CALLBACK_URL}`;
      const cognito_oauth_exchange_token_url: any = `${process.env.REACT_APP_AWS_COGNITO_DOMAIN_NAME}/oauth2/token`;

      if (code) {
        try {
          const tokens = await googleLogin({ code, redirect_uri , cognito_oauth_exchange_token_url}).unwrap();
          const customerId = tokens.customer_id;

          const idToken = tokens.id_token;
          const userInfo: any = jwtDecode(idToken);

          dispatch(
            loginStore({
              user: {
                email: userInfo.email,
                id: userInfo['cognito:username'] || userInfo.sub,
                type: userInfo['custom:type'] || 'customer',
                first_name: userInfo.name,
              },
              token: idToken,
              refresh_token: tokens.refresh_token,
              merchantId: userInfo['cognito:username'] || userInfo.sub,
              merchantName: userInfo.name,
              customerId: customerId,
            })
          );

          if (!localStorage.getItem('DeviceID')) {
            const WLClient = getWildlinkClient()
            WLClient.init().then(() => {
              const newDevice = WLClient.getDevice()
              const { DeviceID } = newDevice
              localStorage.setItem('DeviceID', String(DeviceID))
            })
          }

          setUserType(userInfo['custom:type'] || 'customer');
          setIsAuthenticated(true);
        } catch (error) {
          console.error('Authentication failed:', error);
          navigate('/');
        }
      } else {
        navigate('/');
      }
    }

    handleAuthentication();
  }, [dispatch, googleLogin, navigate]);

  // Effect to navigate after authentication or categories are loaded
  useEffect(() => {
    if (authToken) {
      navigate("/merchants/fcb47d06-0bd0-40b7-9ac0-37d12eb83d45");
    }
    if (categories && isAuthenticated) {
      const homeCategory = categories.marketplace_categories.find(category => category.is_home === true);
      const categoryId = homeCategory ? homeCategory.id : '4082b604-0ada-4376-89a2-83a79c54b829';

      const dashboardRoute = userType === USER_TYPE_ADMIN ? `/admin/dashboard` : `/merchants/${categoryId}`;
      navigate(`${dashboardRoute}${location.search}`);
    }
  }, [authToken, categories, isAuthenticated, navigate, userType, location.search]);

  return (
    <div>
      <p>Processing authentication...</p>
    </div>
  );
};

export default AuthCallback;
