import Button from 'components/ui/Button'
import { Link } from 'react-router-dom'

const Page404 = () => {
  return (
    <div data-testid="page-404">
      <h2 className="text-gray-900 text-display-lg-medium font-bold mb-6">
        Page not found
      </h2>
      <p className="text-xl font-normal text-gray-500 mb-16">
        Sorry, the page you are looking for doesn't exist or has been moved.{' '}
      </p>
      <Link to={'/'}>
        <Button cc-color="purple">Go to Homepage</Button>
      </Link>
    </div>
  )
}

export default Page404
