//@ts-nocheck
import { useAppSelector } from 'store/hooks'
import { useGetPrizeQuery } from 'services/prizes'
import NumberInputWithButtons from 'components/ui/NumberInputWithButtons'
import { 
  Button,
  Divider,
  Image,
  Accordion,
  AccordionItem,
  Modal,
  ModalContent,
  ModalBody,
  useDisclosure,
  Skeleton
} from '@nextui-org/react'
import { useCreatePrizeEntryMutation } from 'services/prizes'
import { useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useGetTotalEntriesQuery } from 'services/customers'
import InstallExtensionBanner from 'components/NewComponents/InstallExtensionBanner';

import Realistic from "react-canvas-confetti/dist/presets/realistic";
import RichText from 'components/ui/RichText'
import useIsMobile from 'hooks/useIsMobile'

import useExtensionDetails from 'hooks/useExtensionDetails'
import { styled } from '@mui/material'
import clsx from 'clsx'
import tw from 'twin.macro'
import { format } from 'date-fns'
import { HiOutlineTrophy } from 'react-icons/hi2'
import { defaultDetailsPageStyles } from 'constants/prize-details'

const BackgroundImage = styled<{ url: string }>('div')`
  ${tw`relative overflow-hidden bg-cover bg-no-repeat bg-center w-full`} 
  background-image: ${props => `url('${props.url}')`};
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${p => p.shadowOffset}px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%);
  }
`

const PrizeDetailsPage = () => {
  const { isInstalled } = useExtensionDetails();
  const isMobile = useIsMobile();
  const [entries, setEntries] = useState<number | undefined>(0)
  const [createPrizeEntry, { isLoading, isSuccess }] =
    useCreatePrizeEntryMutation()
  const [error, setError] = useState()

  // const [showSuccess, setShowSuccess] = useState(false)
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  // useEffect(() => {
  //   if (isSuccess) {
  //     setShowSuccess(true)
  //     const timer = setTimeout(() => {
  //       setShowSuccess(false)
  //     }, 10000)
  //     return () => clearTimeout(timer)
  //   }
  // }, [isSuccess])

  // const customer = useAppSelector((state) => state.auth.user)
  const customerId = useAppSelector((state) => state.auth.customerId)

  const { data: entriesCountData, refetch } =
    useGetTotalEntriesQuery(customerId || '')

  const totalTokens =
    entriesCountData?.count === 0 || entriesCountData?.count
      ? entriesCountData?.count
      : 0

  const { prizeId } = useParams()
  const { data, isFetching } = useGetPrizeQuery(prizeId || '')

  const [endDate, setEndDate] = useState<string | null>(null);

  useEffect(() => {
    if (data?.prize.end_date) {
      setEndDate(new Date(data.prize.end_date).toLocaleDateString('en-US', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit'
      }));
    }
  }, [data]);


  const styles = useMemo(() => {
    const scale = isMobile ? 0.75 : 1;
    const backgroundImageHeight = 700;
    let pageContentTopOffset = isMobile ? 250 : 210;
    const imagePosition = data?.prize.prize_details_page_styles?.image?.position || defaultDetailsPageStyles.image.position;

    // Offset to avoid the "Winner is Guaranteed" div
    let baseTopOffset = isMobile ? 102 : 120;
    if(imagePosition === 'top') baseTopOffset = 0;

    const width = Number(data?.prize.prize_details_page_styles?.image?.width || defaultDetailsPageStyles.image.width) * scale;
    const height = Number(data?.prize.prize_details_page_styles?.image?.height || defaultDetailsPageStyles.image.height) * scale;
    
    let availableForProductPosition = backgroundImageHeight - pageContentTopOffset - baseTopOffset;

    // Adjusting the top offset of the page content to be near the product image when the image position is set to top
    if(imagePosition === 'top') { 
      pageContentTopOffset += availableForProductPosition - height - 40;
      availableForProductPosition = backgroundImageHeight - pageContentTopOffset - baseTopOffset;
    }

    return {
      pageContentTopOffset,
      backgroundImage: {
        height: `${backgroundImageHeight}px`
      },
      productImage: {
        wrapper: {
          top: `${baseTopOffset}px`,
          height: `${availableForProductPosition}px`,
          alignItems: imagePosition === 'top' ? 'flex-start' : 'center',
        },
        img: {
          width: `${width}px`,
          height: `${height}px`
        }
      },
      pageContent: {
        height: `calc(100% - ${pageContentTopOffset}px)`,
        top: `-${pageContentTopOffset}px`
      }
    } as const;
  }, [data, isMobile]);

  if (isFetching) {
    return (
      <div className="flex flex-col items-center p-12 lg:pt-28 gap-16 text-gray-900">
        <Skeleton className="w-full h-64" />
        <Skeleton className="w-full h-64" />
      </div>
    );
  }
  const handleEntry = async () => {
    const tokens = data?.prize.token_cost * entries

    if (!entries) {
      setError('Please enter a valid number of entries')
      return
    }

    if (tokens > totalTokens) {
      setError('Insufficient tokens')
      return
    } else {
      setError(undefined)
    }

    try {
      await createPrizeEntry({
        customerId: customerId,
        prizeId: prizeId,
        entriesRequested: entries
      }).unwrap()
      
      refetch().unwrap()
      
      onOpen();
    } catch (error) {
      setError('An error occurred. Please try again later.')
    }
  }


  const handleEntriesChange = (value: number) => {
    if (error) {
      setError(undefined)
    }
    
    setEntries(value)
  }

  return (
    <div className='relative flex flex-col'>
      <InstallExtensionBanner.Prize className='w-full' />
      <div className='relative'>
        <BackgroundImage shadowOffset={styles.pageContentTopOffset} sx={{ height: styles.backgroundImage.height }} url={data?.prize.detail_bg_image_url}>
          {endDate &&
            <div className='absolute top-5 left-0 right-0 mx-auto z-10 flex flex-col items-center justify-center'>
              <div className='px-4 py-2 text-[12px] md:text-medium bg-primary-400 text-white rounded-xl flex gap-2 items-center'>
                <HiOutlineTrophy size={18} />
                Winner is Guaranteed {format(endDate, 'MMMM do')}
              </div>
              <span className='text-3xl md:text-5xl font-bold text-center mt-5'>
                {data?.prize.name}
              </span>
            </div>
          }
          <div className='absolute left-0 right-0 mx-auto flex justify-center' style={styles.productImage.wrapper}>
            <img
              src={data?.prize.image_url}
              alt='Product'
              style={styles.productImage.img}
            />
          </div>
        </BackgroundImage>
        <div className='flex flex-col justify-center relative left-0 right-0 mx-auto max-w-[830px] px-4' style={styles.pageContent}>
          <div className='relative inline-flex justify-center items-center flex-wrap gap-y-5 gap-x-10 px-6 pt-6 pb-10 md:py-10 rounded-2xl border bg-white border-gray-200'>
            <span className='text-md md:text-xl font-medium text-center'>How many entries would you like?</span>
            <div className='flex items-center gap-3'>
              <div className='flex flex-col items-center relative'>
                <NumberInputWithButtons onChange={handleEntriesChange} value={entries} />
                <div className='absolute flex items-end bottom-[-25px]'>
                  <img src='/images/Coins.png' alt='Wave Icon' className='w-8 h-5' />
                  <span className='text-sm mt-2 text-ellipsis overflow-hidden whitespace-nowrap'>{data?.prize.token_cost * entries} Tokens</span>
                </div>
              </div>
              <Button 
                isLoading={isLoading}
                disabled={isLoading || !entries || entries === 0}
                onClick={handleEntry}
                className={clsx('w-1/2 text-lg text-white', {
                  'bg-gray-400 text-gray-700': isLoading || !entries || entries === 0
                })}
                style={{
                  backgroundColor: `${(isLoading || !entries || entries === 0) ? '#D3D3D3' : (data?.prize.hex_color || '#37B5FF')}`
                }}
              >
                Enter now
              </Button>
              {error && <p className="absolute top-2 left-0 right-0 mx-auto text-center text-red-400">{error}</p>}
            </div>
            <PrizeDetailsSuccess isOpen={isOpen} onOpenChange={onOpenChange} entries={entries} isInstalled={isInstalled} isMobile={isMobile} />
          </div>
          <div className='mt-10'>
            <h3 className='text-lg md:text-3xl font-bold uppercase'>Prize Details</h3>
            <Divider className="mt-2 h-[1px] bg-black" />
            <RichText.Renderer className='px-2 mt-2 text-sm md:text-medium text-gray-500' value={data?.prize.details}/>
          </div>
          <div className='mt-6'>
            <h3 className='text-lg md:text-3xl font-bold uppercase'>Frequently asked questions</h3>
            <Divider className="my-2 h-[1px] bg-black" />
            <Accordion className='!px-0' itemClasses={{
              title: 'text-sm md:text-lg'
            }}>
              <AccordionItem label key="1" aria-label="Accordion 1" title="How do I keep track of the prizes I have entered?">
                <p>Visit the <a href="https://app.joinwave.com/entries" className="text-blue-500">Entry History</a> page to view all your entries.</p>
              </AccordionItem>
              <AccordionItem className='text-sm' key="2" aria-label="Accordion 2" title="How do I keep track of my tokens?">
                <p>Visit the <a href="https://app.joinwave.com/customers/token-history" className="text-blue-500">Token History</a> page to view all your token earning events. Your current token balance can be seen in your wallet in the top right corner of the site.</p>
              </AccordionItem>
              <AccordionItem className='text-sm' key="3" aria-label="Accordion 3" title="How are the winners selected?">
                <p>Our winner selection process is completely random and unbiased. On drawing day, all qualified entries are sequentially numbered and a computer program is used to randomly pick the winner.</p>
              </AccordionItem>
              <AccordionItem className='text-sm' key="4" aria-label="Accordion 4" title="How will I know if I won?">
                <p>Winners will receive an email from <span className="text-blue-500">support@joinwave.com</span> on the day of the draw to notify you of your win!</p>
              </AccordionItem>
              <AccordionItem className='text-sm' key="5" aria-label="Accordion 5" title="How can I contact customer support?">
                <p>You can contact our customer support team by sending an email to <span className="text-blue-500">support@joinwave.com</span>. We are happy to help!</p>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  )
}
 
function PrizeDetailsSuccess({ isOpen, onOpenChange, entries, isInstalled, isMobile }) {
  useEffect(() => {
    let timer;
    if (isOpen) {
      timer = setTimeout(() => {
        onOpenChange(false);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [isOpen, onOpenChange]);

  return (
    <Modal 
      isOpen={isOpen} 
      onOpenChange={onOpenChange}
      classNames={{
        base: "bg-[#E4F4FF]/[0.96]"
      }}
      size="lg"
      >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalBody className="flex flex-col items-center text-center mb-4">
              <Realistic autorun={{ speed: 0.2, duration: 2000 }} />
              <p className="flex flex-col items-center text-center gap-1 text-2xl mt-2">
              <span className="font-semibold mt-2">Congratulations!</span>
              </p>
              <p className="font-medium text-lg mt-1"> 
                You've received <span className="font-bold text-[#37B5FF]">{entries} {entries === 1 ? 'entry' : 'entries'}</span> to win
              </p>

              {!isInstalled && !isMobile ? (
              <div className="flex flex-col items-center">
                <p className="font-medium mb-4 text-lg">
                  Good Luck!
                </p>
                <div className="flex flex-col items-center gap-2 bg-white p-4 rounded-lg w-11/12">
                  <p className="font-medium w-full">
                    <span>Earn more </span>
                    <span className="w-fit text-primary-400 font-semibold inline-block">
                      <div className="flex flex-row gap-1 items-center">
                      <Image
                          alt="Wave Icon"
                          className="inline object-contain rounded-xl w-full h-4 w-4"
                          src={'/images/waveemblem.svg'}
                        />
                        Tokens
                      </div>
                      </span>
                      <span> with the Wave Extension</span>
                  </p>
                  <Image
                    alt="Wave Icon"
                    className="inline object-contain rounded-xl w-full h-16 mx-auto"
                    src="/images/Coins.png"
                  />
                  <Button className="bg-[#37B5FF] font-bold px-8 py-y text-base text-white">
                    <a href="https://chromewebstore.google.com/detail/wave/ifdpohocbpgbcnopccciobohojafffpl" target="_blank" rel="noreferrer">
                      Add To Chrome
                    </a>
                  </Button>
                </div>
                </div>
              ) : (
              <div>
                <Image
                  alt="Wave Ticket"
                  className="object-contain rounded-xl w-full h-24 mx-auto"
                  src="/images/waveprizeticket.png"
                />

                <p className="font-medium mb-4 text-lg mt-4">Good Luck!</p>
              </div>
              )}

            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}

export default PrizeDetailsPage