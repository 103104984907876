const LogoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="161"
      height="27"
      viewBox="0 0 161 27"
      fill="none"
    >
      <path
        d="M38.6859 3.5719C40.5806 3.5719 42.1667 4.20478 43.4404 5.46657C44.7141 6.72836 45.3509 8.29868 45.3509 10.1775C45.3509 12.0564 44.7141 13.599 43.4404 14.8727C42.1667 16.1463 40.5845 16.7831 38.6859 16.7831H34.5801V23.6775H31.2773V3.57586H38.6859V3.5719ZM38.6859 13.6821C39.6629 13.6821 40.4659 13.3498 41.0988 12.6932C41.7316 12.0366 42.0481 11.1941 42.0481 10.1815C42.0481 9.16888 41.7316 8.33033 41.0988 7.66977C40.4659 7.0092 39.6629 6.6809 38.6859 6.6809H34.5801V13.69H38.6859V13.6821Z"
        fill="black"
      />
      <path
        d="M50.8316 11.728C51.6147 9.94808 53.0901 9.05811 55.2538 9.05811V12.4163C54.0671 12.3411 53.0308 12.622 52.1527 13.2628C51.2706 13.9036 50.8316 14.9636 50.8316 16.4351V23.6735H47.7305V9.31522H50.8316V11.728Z"
        fill="black"
      />
      <path
        d="M60.4951 6.57396C60.1035 6.96555 59.6368 7.16333 59.1028 7.16333C58.5688 7.16333 58.102 6.96555 57.7105 6.57396C57.3189 6.18237 57.1211 5.71563 57.1211 5.18164C57.1211 4.64765 57.3109 4.18091 57.6946 3.78932C58.0783 3.39773 58.5451 3.19995 59.1028 3.19995C59.6605 3.19995 60.1272 3.39773 60.5109 3.78932C60.8946 4.18091 61.0845 4.64765 61.0845 5.18164C61.0845 5.71563 60.8867 6.18237 60.4951 6.57396ZM57.5522 23.6774V9.31905H60.6533V23.6774H57.5522Z"
        fill="black"
      />
      <path
        d="M67.3768 20.7465H74.3542V23.6775H63.1523V21.6088L69.8727 12.2462H63.4411V9.31519H74.0655V11.3799L67.3807 20.7425L67.3768 20.7465Z"
        fill="black"
      />
      <path
        d="M78.8334 17.8155C79.0628 18.9072 79.5889 19.7458 80.4116 20.3272C81.2343 20.9126 82.239 21.2014 83.4257 21.2014C85.0711 21.2014 86.3052 20.6081 87.1319 19.4214L89.6872 20.9166C88.2711 23.0051 86.1747 24.0454 83.398 24.0454C81.0603 24.0454 79.1775 23.3334 77.7417 21.9055C76.3058 20.4775 75.5859 18.6738 75.5859 16.4904C75.5859 14.307 76.294 12.5508 77.71 11.1071C79.1261 9.66331 80.9456 8.93945 83.1686 8.93945C85.2729 8.93945 87.0014 9.67517 88.3502 11.1506C89.699 12.6259 90.3754 14.4138 90.3754 16.5221C90.3754 16.8464 90.3359 17.2776 90.2607 17.8155H78.8294H78.8334ZM78.8057 15.288H87.2506C87.041 14.1211 86.5584 13.239 85.7989 12.6457C85.0435 12.0524 84.1574 11.7557 83.1409 11.7557C81.9938 11.7557 81.0326 12.0722 80.2692 12.705C79.5019 13.3379 79.0153 14.2002 78.8057 15.288Z"
        fill="black"
      />
      <path
        d="M100.633 3.5719C103.39 3.5719 105.685 4.53704 107.512 6.47126C109.339 8.40548 110.253 10.7906 110.253 13.6227C110.253 16.4548 109.339 18.8163 107.512 20.7584C105.685 22.7005 103.39 23.6736 100.633 23.6736H92.7344V3.5719H100.633ZM100.633 20.5171C102.528 20.5171 104.075 19.8684 105.273 18.5631C106.468 17.2618 107.069 15.6163 107.069 13.6227C107.069 11.6292 106.472 9.95998 105.273 8.6705C104.079 7.37706 102.532 6.73232 100.633 6.73232H96.0372V20.5171H100.633Z"
        fill="black"
      />
      <path
        d="M116.171 11.728C116.955 9.94808 118.43 9.05811 120.594 9.05811V12.4163C119.407 12.3411 118.375 12.622 117.493 13.2628C116.61 13.9036 116.171 14.9636 116.171 16.4351V23.6735H113.07V9.31522H116.171V11.728Z"
        fill="black"
      />
      <path
        d="M134.175 9.31523H137.276V23.6736H134.175V21.6048C133.008 23.2305 131.331 24.0454 129.148 24.0454C127.174 24.0454 125.489 23.3136 124.092 21.8501C122.696 20.3866 121.996 18.5987 121.996 16.4944C121.996 14.3901 122.696 12.5785 124.092 11.1229C125.489 9.66725 127.174 8.93945 129.148 8.93945C131.331 8.93945 133.004 9.74241 134.175 11.3523V9.31523ZM126.391 19.7814C127.253 20.6516 128.333 21.0867 129.634 21.0867C130.935 21.0867 132.019 20.6516 132.878 19.7814C133.74 18.9112 134.171 17.8155 134.171 16.4944C134.171 15.1733 133.74 14.0776 132.878 13.2074C132.015 12.3372 130.935 11.9021 129.634 11.9021C128.333 11.9021 127.249 12.3372 126.391 13.2074C125.528 14.0776 125.097 15.1733 125.097 16.4944C125.097 17.8155 125.528 18.9112 126.391 19.7814Z"
        fill="black"
      />
      <path
        d="M157.437 9.31519H160.712L156.203 23.6735H153.157L150.111 13.8719L147.066 23.6735H143.775L139.598 9.31519H142.881L145.661 19.2236L148.675 9.31519H151.646L154.632 19.2197L157.437 9.31519Z"
        fill="black"
      />
      <path
        d="M10.0666 9.93229H6.59375V25.1015C2.6462 22.744 0 18.4286 0 13.5001C0 11.3879 0.486515 9.39434 1.35276 7.61438H13.5395C16.7633 7.63811 19.3818 10.2685 19.3818 13.5001C19.3818 16.7317 16.7435 19.3858 13.496 19.3858C12.8948 19.3858 12.2896 19.2909 11.7042 19.109C11.1109 18.9112 10.5571 18.6264 10.0627 18.2664V9.93229H10.0666Z"
        fill="#38B6FF"
      />
      <path
        d="M26.9999 13.5C26.9999 20.956 20.9599 27 13.4999 27C12.3132 27 11.1622 26.8457 10.0665 26.557V22.1981C10.5135 22.3761 10.9723 22.5224 11.447 22.6253C12.1115 22.7795 12.7998 22.8586 13.4999 22.8586C18.6578 22.8586 22.8585 18.6579 22.8585 13.5C22.8585 8.34208 18.9268 4.42616 13.9943 4.1651V4.14137H3.77734C6.22578 1.58614 9.67495 0 13.4999 0C20.9599 0 26.9999 6.04791 26.9999 13.5Z"
        fill="#38B6FF"
      />
    </svg>
  )
}

export default LogoIcon