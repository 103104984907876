import { IconButton, styled } from "@mui/material";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Slider, { CustomArrowProps, Settings } from "react-slick";
import clsx from "clsx";
import tw from "twin.macro";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef } from "react";

type HorizontalScrollListProps = { 
  children: React.ReactNode[];
  slidesToShow?: number;
  anchored?: boolean;
}

const Wrapper = styled('div')`
  position: relative;
  & .slick-slider {
    /* Warn: Dont remove this padding or the arrow will overlap the cards */
    ${tw`lg:px-12`}
  }
`;

const settings: Settings = {
  lazyLoad: "anticipated",
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  touchThreshold: 5,
  initialSlide: 0,
  prevArrow: <LeftArrow />,
  nextArrow: <RightArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        initialSlide: 0,
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        touchThreshold: 2,
      }
    },
    {
      breakpoint: 768,
      settings: {
        initialSlide: 0,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 2,
      }
    },
    {
      breakpoint: 600,
      settings: {
        initialSlide: 0,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 2,
      }
    },
  ]
};

// Feature flag to enable horizontal scroll with mouse wheel and touch pad
// It currently has an issue on touch pad, where scrolling left navigates the user back
const enableHorizontalScrollWithWheelAndTouchPad = false;

const HorizontalScrollList = (props: HorizontalScrollListProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<Slider | null>(null);

  const handleMouseOver = () => {
    const horizontalScrollThreshold = 10;

    // If hovering the list disabling horizontal scroll on body to avoid page swinging while scrolling
    document.body.style.overflowX = 'hidden';

    // Handling horizontal scroll with mouse wheel/touch pad
    ref.current?.addEventListener('wheel', (e) => {
      // Scrolling left (decreasing the threshold it will make less sensitive)
      if(e.deltaX < -horizontalScrollThreshold) {
        sliderRef.current?.slickPrev();
      } 
      
      // Scrolling right (increasing the threshold it will make less sensitive)
      if(e.deltaX > horizontalScrollThreshold) {
        sliderRef.current?.slickNext();
      }
    })
  };

  const handleMouseLeave = () => {
    // Enabling horizontal scroll on body if not hovering the the list
    document.body.style.overflowX = 'unset';
    ref.current?.removeEventListener('wheel', () => {})
  };
  
  const getSettings = (): Settings => {
    const baseSettings: Settings = {
      ...settings,
      slidesToShow: props.slidesToShow || settings.slidesToShow,
      slidesToScroll: props.slidesToShow || settings.slidesToScroll,
    };
  
    if (props.anchored) {
      baseSettings.responsive = [
        {
          breakpoint: 1024,
          settings: {
            initialSlide: 0,
            arrows: false,
            slidesToShow: 2.25,
            slidesToScroll: 2,
            touchThreshold: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            initialSlide: 0,
            arrows: false,
            slidesToShow: 1.25,
            slidesToScroll: 1,
            touchThreshold: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            initialSlide: 0,
            arrows: false,
            slidesToShow: 1.25,
            slidesToScroll: 1,
            touchThreshold: 1,
          },
        },
      ];
    }
  
    return baseSettings;
  };

  return (
    <Wrapper 
      ref={ref}
      onMouseOver={enableHorizontalScrollWithWheelAndTouchPad ? handleMouseOver : undefined}
      onMouseLeave={enableHorizontalScrollWithWheelAndTouchPad ? handleMouseLeave : undefined}
    >
      <Slider ref={r => sliderRef.current = r} {...getSettings()}>
        {props.children}
      </Slider>
    </Wrapper>
  );
};

export function LeftArrow(props: CustomArrowProps) {
  const isDisabled = props.className?.includes('slick-disabled');
  return (
    <IconButton
      className="!absolute left-0 top-0 h-full"
      disabled={isDisabled}
      style={props.style}
      disableRipple={true}
      onClick={props.onClick}
    >
      <FaChevronLeft className={clsx("text-primary-400", { '!text-gray-200': isDisabled })}/>
    </IconButton>
  );
}

export function RightArrow(props: CustomArrowProps) {
  const isDisabled = props.className?.includes('slick-disabled');

  return (
    <IconButton 
      className="!absolute right-0 top-0 h-full"
      disabled={isDisabled}
      disableRipple={true}
      style={props.style}
      onClick={props.onClick}
    >
      <FaChevronRight className={clsx("text-primary-400", { '!text-gray-200': isDisabled })}/>
    </IconButton>
  );
}


export default HorizontalScrollList;