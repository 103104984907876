import LoginModule from 'modules/Login'

const Login = () => {
  return (
    <div data-testid="page-merchant-login">
      <LoginModule />
    </div>
  )
}

export default Login
