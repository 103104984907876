import {Accordion, AccordionItem} from "@nextui-org/react";

const HelpPage = () => {
    return (
        <div className="flex flex-col items-center gap-3 min-h-screen">
            <p className="mt-10 mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">FAQ</p>
            <div className="w-2/3">
                <Accordion>
                    <AccordionItem key="1" aria-label="Accordion 1" title="What can I do with Wave?">
                        {"Wave helps you save money with coupons across the web and turns your online purchases into chances to win life changing prizes. To get the best experience and the most rewards, download our free Chrome Extension. We’ll pop up on thousands of popular shopping sites to help you automatically apply coupons and earn tokens to enter into our prize giveaways."}
                    </AccordionItem>
                    <AccordionItem key="2" aria-label="Accordion 2" title="How do I earn tokens on Wave?">
                        {"You can earn tokens by making purchases through Wave at any of our partner stores. Simply visit our site and click through to the store of your choice or download our Chrome Extension and click the activate button before purchasing. Each qualifying purchase will award you a specific number of tokens based on the purchase amount and the current token distribution rate. You can also earn tokens without making a purchase by sending us a letter request. See the details here "}
                        <span>{"("}<a href="https://www.joinwave.com/rules" className="text-blue-500">https://www.joinwave.com/rules</a>{")."}</span>
                    </AccordionItem>
                    <AccordionItem key="3" aria-label="Accordion 3" title="What do I do with my tokens?">
                        <p>Tokens can be used to enter our prize giveaways. Simply visit the <span><a href="https://app.joinwave.com/dashboard" className="text-blue-500">Prizes page</a></span>, click on the prize {"you’re"} most interested in, and exchange your tokens for entries.</p>
                    </AccordionItem>
                    <AccordionItem key="4" aria-label="Accordion 4" title="How do I keep track of the prizes I have entered?">
                        <p>Visit the <span><a href="https://app.joinwave.com/entries" className="text-blue-500">Entry History</a></span> page to view all your entries.</p>
                    </AccordionItem>
                    <AccordionItem key="5" aria-label="Accordion 5" title="How do I keep track of my tokens?">
                        <p>Visit the <span><a href="https://app.joinwave.com/customers/token-history" className="text-blue-500">Token History</a></span> page to view all your token earning events. Your current token balance can be seen in your wallet in the top right corner of the site.</p>
                    </AccordionItem>
                    <AccordionItem key="6" aria-label="Accordion 6" title="How long does it take for tokens to appear in my account?">
                        {"Tokens will usually be deposited between 2 and 8 hours after purchase but can take up to 48 hours in some cases. If you receive tokens from letter writing, they will be deposited the same day we process the letter."}
                    </AccordionItem>
                    <AccordionItem key="7" aria-label="Accordion 7" title="What happens to my tokens if I return an item?">
                        {"If you return an item, your tokens received along with that item will be voided."}
                    </AccordionItem>
                    <AccordionItem key="8" aria-label="Accordion 8" title="What should I do if my token balance is incorrect?">
                        <p>If you believe your token balance is incorrect, please contact us at <span className="text-blue-500">support@joinwave.com</span> with your account details and recent purchase history. We will investigate and make sure you get your tokens!</p>
                    </AccordionItem>
                    <AccordionItem key="9" aria-label="Accordion 9" title="How are the winners selected?">
                        <p>Our winner selection process is completely random and unbiased. On drawing day, all qualified entries are sequentially numbered and a computer program is used to randomly pick the winner.</p>
                    </AccordionItem>
                    <AccordionItem key="10" aria-label="Accordion 10" title="How can I contact customer support?">
                        <p>You can contact our customer support team by sending an email to <span className="text-blue-500">support@joinwave.com</span>. We are happy to help!</p>
                    </AccordionItem>
                    <AccordionItem key="11" aria-label="Accordion 11" title="How will I know if I won?">
                        <p>Winners will receive an email from <span className="text-blue-500">support@joinwave.com</span> on the day of the draw to notify you of your win!</p>
                    </AccordionItem>
                </Accordion>
            </div>

            <section className="bg-white dark:bg-gray-900 w-2/3 mt-10">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">Contact Us</h2>
                <p className="text-xl py-8 text-center">Need help? Send us an email at <span className="font-bold text-blue-500">support@joinwave.com</span>.</p>

                {/* <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">Contact Us</h2>
                    <form action="#" className="space-y-8">
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your name</label>
                            <input type="text" id="name" className="block p-3 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Your name" required />
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your email</label>
                            <input type="email" id="email" className="0 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="your@email.com" required />
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Subject</label>
                            <input type="text" id="subject" className="block p-3 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Let us know how we can help you" required />
                        </div>
                        <div className="sm:col-span-2">
                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Your message</label>
                            <textarea id="message" className=" p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Leave a comment..."></textarea>
                        </div>
                        <button type="submit" className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Send message</button>
                    </form>
                </div> */}
            </section>
        </div>
    );
  }
  
  export default HelpPage
  