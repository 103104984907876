import { isRejectedWithValue } from '@reduxjs/toolkit'
import { enqueueSnackbar } from 'notistack'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'

const EXPIRED_STATUS_CODE = [401]

const invalidRetriesMessage = {
  message: 'DefineAuthChallenge failed with error Invalid OTP.',
  replaceMessage: 'Sorry, you have exceeded the maximum number of attempts.'
}

export const errorHandler: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      console.log('Errors handle: ' + action.payload.data.error.message)

      if (action.payload.data.error.message === invalidRetriesMessage.message) {
        enqueueSnackbar(invalidRetriesMessage.replaceMessage, {
          variant: 'error'
        })
        return next(action)
      }

      const isValidationError =
        action.payload?.data?.error?.message === 'Validation error'

      if (isValidationError) {
        let errorMessage = ''

        if (action.meta.arg.endpointName === 'login') {
          errorMessage = 'Invalid Credentials'
        } else if (action.meta.arg.endpointName === 'signup') {
          errorMessage = 'Invalid Password length'
        }

        if (errorMessage) {
          enqueueSnackbar(errorMessage, {
            variant: 'error'
          })
          return next(action)
        }
      }

      // Refresh token logic is implemented on the api declaration
      if(EXPIRED_STATUS_CODE.includes(action.payload.status)) {
        return next(action);
      }

      if (action.payload.data.error.message === 'Validation error') {
        enqueueSnackbar(action.payload.data.error.errors[0].message, {
          variant: 'error'
        })
        return next(action)
      }

      enqueueSnackbar(
        action.payload.data.message ||
          action.payload.data.msg ||
          action.payload.data.error.message,
        {
          variant: 'error'
        }
      )

      return next(action)
    }

    return next(action)
  }