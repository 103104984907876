import { EditorState, convertToRaw, RawDraftContentState, ContentState } from 'draft-js';
import { ForwardedRef, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { isJsonString } from 'utils/strings';
import { EditorWrapper, Renderer } from './styles';
import draftToHtml from 'draftjs-to-html';
import sanitizeHtml from 'sanitize-html';

type RichTextRendererProps = {
  value: string | EditorState;
  className?: string;
}

const RichTextRenderer = ({ value, className }: RichTextRendererProps) => {
  let html;

  // In case of controlled editor, the value will have EditorState type
  if(value instanceof EditorState) {
    html = draftToHtml(convertToRaw(value.getCurrentContent()));
  } else {
    let rawContent = isJsonString(value) as RawDraftContentState;
    if(!rawContent) {
      rawContent = convertToRaw(ContentState.createFromText(value || ''));
    }
    html = draftToHtml(rawContent);
  }

  const sanitizedHtml = sanitizeHtml(html, { nonTextTags: [ 'style', 'script', 'textarea', 'option', 'noscript' ] });
  return <Renderer className={className} dangerouslySetInnerHTML={{ __html: sanitizedHtml }}></Renderer>
};

const toolbar = {
  options: ['inline', 'list'],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered', 'indent', 'outdent'],
  },
}

export type UncontrolledRichTextEditorRef = {
  getValue: () => string;
  isEmpty: () => boolean;
};

type UncontrolledRichTextEditorProps = {
  value?: string;
}

/**
 * Uncontrolled component to handle rich text editor
 * 
 * According to documentation, RawDraftContentState is suitable for storing in database, because it is a JSON object and
 * can be easily converted to HTML or markdown.
 * 
 * But RawDraftContentState is a not good approach when using controlled component, so it needs to use Uncontrolled approach
 */
const UncontrolledRichTextEditor = forwardRef((props: UncontrolledRichTextEditorProps, ref: ForwardedRef<UncontrolledRichTextEditorRef>) => {
  const [editorState, setEditorState] = useState(() => {
    if(props.value) {
      const jsonContent = isJsonString(props.value) as RawDraftContentState;
      
      if(jsonContent) {
        return jsonContent;
      } else {
        return convertToRaw(ContentState.createFromText(props.value));
      }
    }
    
    return { blocks: [], entityMap: {} } as RawDraftContentState;
  });

  const internalRef = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    getValue: () => {
      const currentRawValue = convertToRaw(internalRef.current!.state.editorState.getCurrentContent());

      // Serializing json to string to store on database easily
      return JSON.stringify(currentRawValue);
    },

    isEmpty: () => {
      return !internalRef.current!.state.editorState.getCurrentContent().hasText();
    }
  }), []);

  return (
    <EditorWrapper>
      <Editor 
        toolbar={toolbar}
        ref={internalRef} 
        initialContentState={editorState} 
        onContentStateChange={setEditorState} 
      />
    </EditorWrapper>
  )
});

type ControlledRichTextEditorProps = {
  value: EditorState;
  setValue: (value: EditorState) => void;
}

const ControlledRichTextEditor = (props: ControlledRichTextEditorProps) => {
  return (
    <EditorWrapper>
      <Editor toolbar={toolbar} editorState={props.value} onEditorStateChange={props.setValue} />
    </EditorWrapper>
  )
};


const RichText = {
  Renderer: RichTextRenderer,
  UncontrolledEditor: UncontrolledRichTextEditor,
  ControlledEditor: ControlledRichTextEditor
}

export default RichText;