import Dashboard from 'modules/Customer/Dashboard'

function DashboardPage() {
  return (
    // <div data-testid="customerDashboardPage">
    //   <Dashboard />
    // </div>
    <div>
      <Dashboard />
    </div>
  )
}

export default DashboardPage
