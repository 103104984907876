import * as Styles from 'layouts/PrivateLayout.styles'
import { useGetOrderQuery, IRefund } from 'services/orders'
import { useParams } from 'react-router-dom'
import { formatDateTime } from 'utils/intlDate'
import { formatCurrency } from 'utils/intlNumber'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import Timeline from '@mui/lab/Timeline'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import InfoCard from 'components/ui/InfoCard'

const OrderDetails = () => {
  const { orderId } = useParams()
  const { data, isFetching } = useGetOrderQuery(orderId || '')
  const order = data?.order
  const createdDate = order?.created_at ? new Date(order.created_at) : ''
  const totalPrice = order?.total_price ? formatCurrency(order.total_price) : ''
  const refunds = order?.refunds

  const renderRefunds = () =>
    refunds?.map((refund: IRefund) => {
      const refundDate = refund?.refunded_on ? new Date(refund.refunded_on) : ''

      return (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot>
              <MonetizationOnOutlinedIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ paddingTop: '9px' }}>
            <div className="text-sm-semibold text-gray-700">
              {refundDate ? formatDateTime(refundDate) : null}
            </div>
            <div className="text-sm text-gray-600">
              Refunded amount{' '}
              {refund?.amount ? formatCurrency(refund.amount) : null}
            </div>
          </TimelineContent>
        </TimelineItem>
      )
    })

  return (
    <div>
      <Styles.PageTitleContainer>Order</Styles.PageTitleContainer>
      <div className="flex flex-col lg:flex-row lg:justify-between lg:gap-8 lg:flex-nowrap">
        <div className="flex flex-col gap-6 basis-3/5">
          <InfoCard
            label="Date"
            value={createdDate ? formatDateTime(createdDate) : ''}
            isLoading={isFetching}
          />
          <InfoCard
            label="Store Name"
            value={order?.merchant?.name || ''}
            isLoading={isFetching}
          />
          <InfoCard
            label="Order Number"
            value={order?.order_number || ''}
            isLoading={isFetching}
          />
          <InfoCard
            label="Name"
            value={order?.customer?.name || ''}
            isLoading={isFetching}
          />
          <InfoCard
            label="Customer Email"
            value={order?.customer?.email || ''}
            isLoading={isFetching}
          />
          <InfoCard
            label="Total"
            value={totalPrice || ''}
            isLoading={isFetching}
          />
          <InfoCard
            label="Entries"
            value={order?.entries || 0}
            isLoading={isFetching}
          />
        </div>
        <div className="flex flex-col gap-3 mt-16 lg:mt-0 w-52">
          <div className="text-lg font-semibold text-gray-800">Refunds</div>
          <div>
            {refunds?.length ? (
              <Timeline
                sx={{
                  padding: 0,
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0
                  }
                }}
              >
                {renderRefunds()}
              </Timeline>
            ) : (
              'No refunds yet'
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderDetails
