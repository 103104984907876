import tw from 'twin.macro'
import { styled } from '@mui/material/styles'

export const VerificationCodeContainer = styled('div')`
  & .ReactInputVerificationCode__container {
    ${tw`gap-3 m-auto`}
  }

  & .ReactInputVerificationCode__item {
    ${tw`w-12 h-12 bg-white border-2 border-solid border-primary-600 
    rounded-lg shadow-xs text-4xl text-primary-600 flex justify-center items-center`}
  }
  & .ReactInputVerificationCode__item.is-active {
    ${tw`shadow-xs-focused-primary-600`}
  }
`
