import { FC, useState } from 'react'
import Button from 'components/ui/Button'
import TextField from 'components/ui/TextField'
import HorizontalFieldRow from 'components/ui/HorizontalFieldRow'
import ContentLoadingPlaceholder from 'components/ui/ContentLoadingPlaceholder'
import BannerDetailsMerchantsTable from './BannerDetailsMerchantsTable'

import {
  ArrowBack,
  Cancel,
  CheckCircle,
  Delete,
  Edit
} from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'
import { useNotification } from 'hooks/useNotification'
import { formatDateTime } from 'utils/intlDate'
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import {
  useGetBannerQuery,
  useRemoveBannerMutation,
  useUpdateBannerMutation,
  useGetBannerImageOptionsQuery
} from 'services/marketplace'
import * as Styles from 'layouts/PrivateLayout.styles'

const BannerDetails: FC = () => {
  const [open, setOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { sendSuccessNotification } = useNotification()

  const { bannerId = '' } = useParams()
  const navigate = useNavigate()

  const { data, isFetching } = useGetBannerQuery(bannerId)
  const [removeBanner, { isLoading: isDeleting }] = useRemoveBannerMutation()
  const [updateBanner, { isLoading: isSaving }] = useUpdateBannerMutation()
  const { data: imageOptions } = useGetBannerImageOptionsQuery()
  const banner = data?.marketplace_banner
  const [imageSize, setImageSize] = useState(banner?.image_size || '')
  const [imageType, setImageType] = useState(banner?.image_type || '')
  const createdDate = banner?.created_at ? new Date(banner?.created_at) : null

  const handleEditBanner = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const banner_name = formData.get('banner_name') as string
    const active = !!formData.get('active')
    const allow_tag = !!formData.get('allow_tag')
    const allow_description = !!formData.get('allow_description')
    const num_lines = formData.get('num_lines') as string
    const image_url = (formData.get('image_url') as string) || null
    const default_tag = formData.get('default_tag') as string || null
    const banner_background_color = formData.get('banner_background_color') as string || null
    const text_color = formData.get('text_color') as string || null

    const response: any = await updateBanner({
      bannerId,
      banner_name,
      active,
      allow_tag,
      allow_description,
      num_lines,
      image_url,
      image_size: imageSize || banner?.image_size || null,
      image_type: imageType || banner?.image_type || null,
      default_tag,
      banner_background_color,
      text_color,
      //@ts-ignore
      order: banner?.order,
    })

    if (response.data) {
      sendSuccessNotification('Updated Successfully')
      setIsEditing(false)
    }
  }

  const handleRemoveBannerConfirm = async () => {
    const response: any = await removeBanner(bannerId)

    if (response?.data === null) {
      sendSuccessNotification('Banner removed successfully')
      setOpen(false)
      navigate(`/admin/banners`)
    }
  }

  const handleCloseConfirm = () => {
    setOpen(false)
  }

  return (
    <div className="pt-2">
      <Styles.PageTitleContainer>
        View Banner information
        <div className="flex flex-wrap gap-2">
          {isEditing ? (
            <Button onClick={() => setIsEditing(false)}>Cancel</Button>
          ) : (
            <>
              <Button variant="text" onClick={() => navigate(-1)}>
                <ArrowBack />
                Back
              </Button>
              <Button startIcon={<Delete />} onClick={() => setOpen(true)}>
                Delete Banner
              </Button>
              <Button
                startIcon={<Edit />}
                cc-color="purple"
                onClick={() => setIsEditing(true)}
              >
                Edit Banner
              </Button>
            </>
          )}
        </div>
      </Styles.PageTitleContainer>
      <div>
        <form onSubmit={handleEditBanner}>
          <HorizontalFieldRow label="Name">
            {isEditing ? (
              <div>
                <TextField
                  id="banner_name"
                  type="text"
                  cc-width="full"
                  defaultValue={banner?.banner_name}
                  placeholder="Banner Name"
                  required
                  autoFocus
                />
              </div>
            ) : (
              <div className="w-full lg:w-1/2 text-gray-600">
                <ContentLoadingPlaceholder isLoading={isFetching}>
                  {banner?.banner_name}
                </ContentLoadingPlaceholder>
              </div>
            )}
          </HorizontalFieldRow>
          <HorizontalFieldRow label="Image URL">
            {isEditing ? (
              <TextField
                id="image_url"
                type="text"
                cc-width="full"
                defaultValue={banner?.image_url}
                placeholder="Image URL"
              />
            ) : (
              <ContentLoadingPlaceholder isLoading={isFetching}>
                {banner?.image_url ? (
                  <div className="flex justify-center items-center p-4 bg-gray-100 rounded-md">
                    <img
                      src={banner.image_url}
                      alt="Banner Image"
                      className="h-40 w-auto object-cover rounded-md shadow-lg"
                    />
                  </div>
                ) : (
                  <div className="text-gray-500 italic">No image available</div>
                )}
              </ContentLoadingPlaceholder>
            )}
          </HorizontalFieldRow>
          <HorizontalFieldRow label="Active">
            <div className="text-gray-600 flex gap-6 items-center">
              <ContentLoadingPlaceholder isLoading={isFetching}>
                {isEditing ? (
                  <Switch name="active" defaultChecked={banner?.active} />
                ) : (
                  <>
                    <div>
                      {createdDate ? formatDateTime(createdDate) : null}
                    </div>
                    {banner?.active ? (
                      <Chip label={<CheckCircle />} color="success" />
                    ) : (
                      <Chip label={<Cancel />} color="error" />
                    )}
                  </>
                )}
              </ContentLoadingPlaceholder>
            </div>
          </HorizontalFieldRow>
          <HorizontalFieldRow label="Id">
            <div className="text-gray-600 flex gap-6 items-center">
              <ContentLoadingPlaceholder isLoading={isFetching}>
                  <div>
                    {banner?.id}
                  </div>
              </ContentLoadingPlaceholder>
            </div>
          </HorizontalFieldRow>
          <HorizontalFieldRow label="Allow Tag">
            <div className="text-gray-600 flex gap-6 items-center">
              <ContentLoadingPlaceholder isLoading={isFetching}>
                {isEditing ? (
                  <Switch name="allow_tag" defaultChecked={banner?.allow_tag} />
                ) : (
                  <>
                    {banner?.allow_tag ? (
                      <Chip label={<CheckCircle />} color="success" />
                    ) : (
                      <Chip label={<Cancel />} color="error" />
                    )}
                  </>
                )}
              </ContentLoadingPlaceholder>
            </div>
          </HorizontalFieldRow>
          <HorizontalFieldRow label="Allow Description">
            <div className="text-gray-600 flex gap-6 items-center">
              <ContentLoadingPlaceholder isLoading={isFetching}>
                {isEditing ? (
                  <Switch name="allow_description" defaultChecked={banner?.allow_description} />
                ) : (
                  <>
                    {banner?.allow_description ? (
                      <Chip label={<CheckCircle />} color="success" />
                    ) : (
                      <Chip label={<Cancel />} color="error" />
                    )}
                  </>
                )}
              </ContentLoadingPlaceholder>
            </div>
          </HorizontalFieldRow>
          <HorizontalFieldRow label="Image Size">
            {isEditing ? (
              <FormControl className="w-48 sm:w-52 md:w-56">
                <InputLabel>Image Size</InputLabel>
                <Select
                  id="image_size"
                  defaultValue={banner?.image_size || ''}
                  placeholder="Select Image Size"
                  onChange={(e) => setImageSize(e.target.value as string)}
                >
                  {imageOptions?.imageSizes?.map((size) => (
                    <MenuItem key={size} value={size}>
                      {size}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <ContentLoadingPlaceholder isLoading={isFetching}>
                {banner?.image_size}
              </ContentLoadingPlaceholder>
            )}
          </HorizontalFieldRow>
          <HorizontalFieldRow label="Image Type">
            {isEditing ? (
              <FormControl className="w-48 sm:w-52 md:w-56">
                <InputLabel>Image Type</InputLabel>
                <Select
                  id="image_type"
                  defaultValue={banner?.image_type || ''}
                  onChange={(e) => setImageType(e.target.value as string)}
                  placeholder="Select Image Type"
                >
                  {imageOptions?.imageTypes?.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <ContentLoadingPlaceholder isLoading={isFetching}>
                {banner?.image_type}
              </ContentLoadingPlaceholder>
            )}
          </HorizontalFieldRow>
          <HorizontalFieldRow label="Default Tag">
            <div>
              {isEditing ? (
                <TextField
                  id="default_tag"
                  type="text"
                  cc-width="full"
                  defaultValue={banner?.default_tag}
                  placeholder="Default Tag"
                />
              ) : (
                <ContentLoadingPlaceholder isLoading={isFetching}>
                  {banner?.default_tag}
                </ContentLoadingPlaceholder>
              )}
            </div>
          </HorizontalFieldRow>
          <HorizontalFieldRow label="Background Color">
            <div>
              {isEditing ? (
                <TextField
                  id="banner_background_color"
                  type="text"
                  cc-width="full"
                  defaultValue={banner?.banner_background_color}
                  placeholder="(e.g., #FFFFFF)"
                />
              ) : (
                <ContentLoadingPlaceholder isLoading={isFetching}>
                  {banner?.banner_background_color}
                </ContentLoadingPlaceholder>
              )}
            </div>
          </HorizontalFieldRow>
          <HorizontalFieldRow label="Text Color">
            <div>
              {isEditing ? (
                <TextField
                  id="text_color"
                  type="text"
                  cc-width="full"
                  defaultValue={banner?.text_color}
                  placeholder="(e.g., #FFFFFF)"
                />
              ) : (
                <ContentLoadingPlaceholder isLoading={isFetching}>
                  {banner?.text_color}
                </ContentLoadingPlaceholder>
              )}
            </div>
          </HorizontalFieldRow>
          <HorizontalFieldRow label="Num Rows">
            <div>
              {isEditing ? (
                <TextField
                  id="num_lines"
                  type="number"
                  cc-width="full"
                  defaultValue={banner?.num_lines}
                  placeholder="Number of Rows"
                  required
                />
              ) : (
                <ContentLoadingPlaceholder isLoading={isFetching}>
                  {banner?.num_lines}
                </ContentLoadingPlaceholder>
              )}
            </div>
          </HorizontalFieldRow>
          {isEditing && (
            <div>
              <div className="text-gray-600 flex gap-2 items-center">
                <Button onClick={() => setIsEditing(false)}>Cancel</Button>
                <Button cc-color="purple" type="submit" disabled={isSaving}>
                  {isSaving ? 'Saving...' : 'Save'}
                </Button>
              </div>
            </div>
          )}
        </form>
      </div>
      <div className="mt-6">
        <BannerDetailsMerchantsTable isEditing={isEditing} />
      </div>
      <Dialog
        open={open}
        onClose={handleCloseConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this banner?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleCloseConfirm}>
            Cancel
          </Button>
          <Button
            cc-color="red"
            onClick={handleRemoveBannerConfirm}
            disabled={isDeleting}
          >
            {isDeleting ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default BannerDetails
