import { Button } from "@nextui-org/react";
import { ChangeEventHandler } from "react";
import { twMerge } from "tailwind-merge";
import tw from 'twin.macro'

const ButtonStyled = tw(Button)`rounded-lg min-w-0 w-9 h-9 absolute bg-white text-gray-700 border border-gray-300`

type NumberInputWithButtonsProps = {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  className?: string;
};
const NumberInputWithButtons = (props: NumberInputWithButtonsProps) => {

  const handleDecrement = () => {
    const newValue = Math.max(props.min || 0, props.value - 1);
    props.onChange(newValue);
  };

  const handleIncrement = () => {
    const newValue = Math.min(props.max || Number.POSITIVE_INFINITY, props.value + 1);
    props.onChange(newValue);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();

    let value = Number(e.target.value);

    if(value < (props.min || 0)) {
      value = props.min || 0;
    }

    if(props.max && value > props.max) {
      value = props.max;
    }

    props.onChange(value);
  }

  return (
    <div className={twMerge("flex relative max-w-[250px] h-9", props.className)}>
      <ButtonStyled className={'left-0'} onClick={handleDecrement}>-</ButtonStyled>
      <input
        className="text-center w-full bg-gray-100 rounded-lg text-gray-900 focus:outline-none"
        type="number"
        step={1}
        pattern="\d+"
        onKeyDown={(e) => {
          if(e.key === 'e' || e.key === '.' || e.key === ',' || e.key === '-') {
            e.preventDefault();
          }
        }}
        onChange={handleChange}
        value={Number(props.value || 0).toString()}
        min={props.min || 0}
        max={props.max}
      />
      <ButtonStyled className={'right-0'} onClick={handleIncrement}>+</ButtonStyled>
    </div>
  )
}

export default NumberInputWithButtons;