import { FC } from 'react'
import { BgImage } from 'layouts/PublicLayout.styles'

interface IBackgroundImageProps {
  src?: string
  alt?: string
}
const BackgroundImage: FC<IBackgroundImageProps> = () => {
  return (
    <BgImage
      data-testid="layout-background"
      src="/images/savemoneywinprizes3.png"
      alt="background"
      loading="lazy"
    />
  )
}

export default BackgroundImage
