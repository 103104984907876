import Button from 'components/ui/Button'
import TextField from 'components/ui/TextField'
import { FC } from 'react'
import Modal from 'components/ui/Modal'
import { useNotification } from 'hooks/useNotification'
import { useSignupMutation, useConfirmUserMutation } from 'services/auth'

interface IAddCustomerModalProps {
  open: boolean
  onClose: () => void
}

const AddCustomerModal: FC<IAddCustomerModalProps> = ({ open, onClose }) => {
  const { sendSuccessNotification, sendErrorNotification } = useNotification()
  const [signup] = useSignupMutation()
  const [confirmUser] = useConfirmUserMutation()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const name = formData.get('name') as string
    const email = formData.get('email') as string

    if (!name) {
      sendErrorNotification('Name is required')
      return
    }

    if (!email) {
      sendErrorNotification('Email is required')
      return
    }

    if (
      !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
        email
      )
    ) {
      sendErrorNotification('Email is not valid')
      return
    }

    const response: any = await signup({
      full_name: name,
      username: email,
      password: 'A-valid-password-123'
    })

    if (response.data) {
      sendSuccessNotification('Customer Added Successfully')
      confirmUser(email)
      onClose()
    }
  }

  const renderField = ({
    id,
    label,
    placeholder,
    type,
    autoFocus
  }: {
    id: string
    label: string
    placeholder: string
    type: string
    autoFocus?: boolean
  }) => (
    <div className="flex flex-col lg:flex-row mt-4">
      <label className="w-40 text-gray-700 mb-2">{label}</label>
      <TextField
        id={id}
        type={type}
        hiddenLabel
        cc-width="full"
        placeholder={placeholder}
        required
        autoFocus={autoFocus}
      />
    </div>
  )

  const modalBody = (
    <>
      {renderField({
        id: 'name',
        label: 'Name *',
        type: 'text',
        placeholder: 'Customer Name',
        autoFocus: true
      })}
      {renderField({
        id: 'email',
        label: 'Email *',
        type: 'email',
        placeholder: 'Email'
      })}
    </>
  )

  const modalActions = (
    <>
      <Button onClick={onClose} cc-width="full">
        Cancel
      </Button>
      <Button cc-color="purple" cc-width="full" type="submit">
        Add Customer
      </Button>
    </>
  )

  return (
    <Modal
      title="Add Customer"
      open={open}
      onClose={onClose}
      body={modalBody}
      actions={modalActions}
      onSubmit={handleSubmit}
    />
  )
}

export default AddCustomerModal
