import PrizePeriodDetails from 'modules/Admin/prize-periods/PrizePeriodDetails'

function PrizePeriodDetailsPage() {
  return (
    <div data-testid="adminPrizePeriodDetailsPage">
      <PrizePeriodDetails />
    </div>
  )
}

export default PrizePeriodDetailsPage
