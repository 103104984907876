import React, { FC } from 'react'
import { Card, CardContent, Typography } from '@mui/material'
import ContentLoadingPlaceholder from 'components/ui/ContentLoadingPlaceholder'

interface IInfoCardProps {
  label: string
  value: React.ReactNode
  isLoading: boolean
  className?: string
}

const InfoCard: FC<IInfoCardProps> = ({
  label,
  value,
  isLoading,
  className
}) => {
  return (
    <div className={className}>
      <Card>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {label}
          </Typography>
          <Typography variant="h5" component="div">
            <ContentLoadingPlaceholder isLoading={isLoading}>
              {value}
            </ContentLoadingPlaceholder>
          </Typography>
        </CardContent>
      </Card>
    </div>
  )
}

export default InfoCard
