//@ ts-nocheck
import { useEffect, useState } from 'react'
import { IPrize, useGetPrizesQuery } from 'services/prizes'
import ProductCard from 'components/NewComponents/ProductCard'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import InstallExtensionBanner from 'components/NewComponents/InstallExtensionBanner'
import { endOfDay, isBefore } from 'date-fns'
import { Skeleton, styled } from '@mui/material'
import { getTimeZoneOffset, getUTCDate } from 'utils/intlDate'
import { fromZonedTime, toDate } from 'date-fns-tz'
import { EST_TIMEZONE_ID } from 'constants/timezone'

const BannerBackground = styled('div')`
  background-image: url('/images/prize-page-banner-small.png');

  @media (min-width: 769px) {
    background-image: url('/images/prize-page-banner-big.png');
    
  }
`

const Dashboard = () => {
  const { data: prizesData, isLoading: isLoadingPrizes } = useGetPrizesQuery({
    limit: 1000,
    page: 1
  })
  const [activePrizes, setActivePrizes] = useState<IPrize[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if(prizesData?.prizes) {
      setActivePrizes(prizesData?.prizes.filter((prize) => {
        if(!prize.is_active) return false;
        const endOfDayPrizeDate = endOfDay(toDate(prize.end_date.replace('Z', getTimeZoneOffset(EST_TIMEZONE_ID))));
        const prizeEndDate = fromZonedTime(endOfDayPrizeDate, EST_TIMEZONE_ID);
        const now = getUTCDate(new Date());

        return isBefore(now, prizeEndDate);
      }))
    }
  }, [prizesData?.prizes])

  const onCountDownEnd = (prizeId: string) => () => {
    setActivePrizes((prev) => prev.filter((prize) => prize.id !== prizeId))
  }

  return (
    <div className='overflow-hidden'>
      <div className="mb-10">
        <BannerBackground className='h-[260px] md:h-[280px] bg-cover bg-no-repeat bg-center flex justify-center items-center px-2'>
          <div className='relative top-[-12px] flex flex-col justify-center items-center max-w-[600px]'>
            <span className='text-6xl font-bold text-white'>Prizes</span>
            <span className='text-md-regular text-white mt-6 text-center'>Use your tokens to enter to win the prizes of your choice. The more Tokens you redeem, the more chances you have to win big.</span>
          </div>
        </BannerBackground>
        <InstallExtensionBanner.Main />
      </div>

      <div className="overflow-y-auto no-scrollbar flex gap-4 px-6 md:grid md:px-10 md:justify-center md:grid-cols-responsive-400">
        {isLoadingPrizes && 
          Array.from({ length: 3 }).map((_, index) => (
            <Skeleton key={index} variant='rectangular' className='min-w-[300px] md:min-w-[400px] w-[300px] !h-[300px] md:w-[400px] md:!h-[400px] self-end rounded-2xl' />
          ))
        }
        {activePrizes.map((prize) => (
          <ProductCard onCountDownEnd={onCountDownEnd(prize.id)} prize={prize} />
        ))}
      </div>
    </div>
  )
}

export default Dashboard

/*
        <div className="bg-[#37B5FF] h-[400px] ">
          <div className="flex flex-col items-center w-full shadow-sm h-[400px]">
            <Card className="w-full h-[400px] rounded-none">
              <Image
                removeWrapper
                alt="eafc"
                className="z-0 w-full h-full object-cover rounded-none"
                src={"https://photos5.appleinsider.com/gallery/54793-110893-Vision-Pro-xl.jpg"}
              />
              <div className="absolute z-10 bottom-36 flex-col !items-start px-16">
                <p className="text-4xl lg:text-6xl font-semibold text-white">WIN THIS APPLE VISION PRO</p>
                <p className="text-3xl font-semibold text-[#37B5FF] mt-2">Live Now</p>
              </div>
              <CardFooter className="px-16 absolute bottom-0 z-10 justify-start rounded-none">
                <Button className="text-2xl bg-[#37B5FF] rounded-md p-6 mb-8" color="primary" radius="full" size="lg">
                  Enter Now
                </Button>
              </CardFooter>
              </Card>
          </div>
        </div>
        <div className="bg-[#37B5FF] h-[400px] ">
          <div className="flex flex-col items-center w-full shadow-sm h-[400px]">
            <Card className="w-full h-[400px] rounded-none">
              <Image
                removeWrapper
                alt="eafc"
                className="z-0 w-full h-full object-cover rounded-none"
                src={"https://images.squarespace-cdn.com/content/v1/52c0509ae4b0330e4569351f/1689667913399-4XXD3JOW3F2TJ2AKCTYI/PRADA_FW23_COLLATERAL_ON_MODEL_STILLS+LE+MILE+Magazine.jpg?format=2500w"}
              />
              <div className="absolute z-10 bottom-36 flex-col !items-start px-16">
                <p className="text-4xl lg:text-6xl font-semibold text-white">WIN THIS PRADA ARQUÉ BAG</p>
                <p className="text-3xl font-semibold text-[#37B5FF] mt-2">Live Now</p>
              </div>
              <CardFooter className="px-16 absolute bottom-0 z-10 justify-start rounded-none">
                <Button className="text-2xl bg-[#37B5FF] rounded-md p-6 mb-8" color="primary" radius="full" size="lg">
                  Enter Now
                </Button>
              </CardFooter>
              </Card>
          </div>
        </div>
        <div className="bg-[#37B5FF] h-[400px] ">
          <div className="flex flex-col items-center w-full shadow-sm h-[400px]">
            <Card className="w-full h-[400px] rounded-none">
              <Image
                removeWrapper
                alt="eafc"
                className="z-0 w-full h-full object-cover rounded-none"
                src={"https://www.apple.com/v/iphone-15-pro/c/images/meta/iphone-15-pro_overview__f8jz7aagka2q_og.png"}
              />
              <div className="absolute z-10 bottom-36 flex-col !items-start px-16">
                <p className="text-4xl lg:text-6xl font-semibold text-white">WIN THIS IPHONE 15 PRO MAX</p>
                <p className="text-3xl font-semibold text-[#37B5FF] mt-2">Live Now</p>
              </div>
              <CardFooter className="px-16 absolute bottom-0 z-10 justify-start rounded-none">
                <Button className="text-2xl bg-[#37B5FF] rounded-md p-6 mb-8" color="primary" radius="full" size="lg">
                  Enter Now
                </Button>
              </CardFooter>
              </Card>
          </div>
        </div>
*/
// const Dashboard = () => {
//   const customer = useAppSelector((state) => state.auth.user)
//   const customerId = useAppSelector((state) => state.auth.customerId)
//   const name = customer?.first_name

//   const { data: entriesCountData, isLoading: isLoadingCount } =
//     useGetTotalEntriesQuery(customerId || '')
//   const { data: prizePeriodsData, isLoading: isLoadingPeriods } =
//     useGetPrizePeriodsQuery({ limit: 1, page: 1 })

//   const prizeEndDate =
//     prizePeriodsData?.prize_periods?.[0]?.end_date &&
//     new Date(prizePeriodsData?.prize_periods?.[0]?.end_date)
//   const totalEntries =
//     entriesCountData?.count === 0 || entriesCountData?.count
//       ? entriesCountData?.count
//       : 'Not Available'

//   const renderData = ({
//     label,
//     value,
//     loading
//   }: {
//     label: string
//     value: string | number | undefined
//     loading: boolean
//   }) => (
//     <div className="w-full h-full flex flex-col gap-6 items-center">
//       {loading ? (
//         <Spinner />
//       ) : (
//         <>
//           <div className="text-center text-gray-600 font-bold text-xl md:text-2xl">
//             {label}
//           </div>
//           <div className="flex justify-center items-center w-52 h-52 lg:w-80 lg:h-80 rounded-full bg-white text-3xl font-semibold text-center shadow-lg">
//             {value}
//           </div>
//         </>
//       )}
//     </div>
//   )

//   return (
//     <div className="flex flex-col gap-6 text-gray-900">
//       <div className="w-full border border-gray-200 rounded-xl shadow-sm">
//         <div className="bg-gradient-to-r from-[#A8E1FF] to-[#529FFE] h-32 rounded-t-xl">
//           &nbsp;
//         </div>
//         <div className="text-3xl pt-2 pb-6 px-6 font-semibold">
//           {name && <div>Welcome back, {name}</div>}
//         </div>
//       </div>
//       <div className="flex flex-col gap-6">
//         <div className="text-lg font-semibold">Dashboard</div>
//         <div className="bg-gradient-to-r from-[#A7E0FF] to-[rgba(83,159,254,0.60)] flex flex-col md:flex-row gap-20 md:gap-6 justify-between p-10 rounded-xl">
//           {renderData({
//             label: 'Your entries for the $100,000 prize',
//             value: totalEntries,
//             loading: isLoadingCount
//           })}
//           {renderData({
//             label: 'Winner will be announced on',
//             value: prizeEndDate ? formatDate(prizeEndDate) : 'Not Available',
//             loading: isLoadingPeriods
//           })}
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Dashboard

// const products = [
//   {
//     "name": "Apple Vision Pro",
//     "metadata": "electronics",
//     "image_url": "https://assets-global.website-files.com/6640f2ac824038f972d8def9/66447940441d1eef27002f07_vr-p-500.webp",
//     "color": "blue"
//   },
//   {
//     "name": "Prada Arqué Bag",
//     "metadata": "fashion",
//     "image_url": "https://assets-global.website-files.com/6640f2ac824038f972d8def9/6644794123050e3ebbd5f4b6_bag-p-500.webp",
//     "color": "orange"
//   },
//   {
//     "name": "$100,000 Cash",
//     "metadata": "prizes",
//     "image_url": "https://assets-global.website-files.com/6640f2ac824038f972d8def9/664479b03d40c4bb0fa1e248_money-p-500.webp",
//     "color": "pink"
//   },
//   {
//     "name": "iPhone 15 Pro Max",
//     "metadata": "electronics",
//     "image_url": "https://assets-global.website-files.com/6640f2ac824038f972d8def9/664479b0b10722b3b07a3c57_iphone-p-500.webp",
//     "color": "blue"
//   },
// ];

{
  /* {products.map((product, index) => (
  <div className="md:w-1/4 xl:w-auto p-1 max-w-[180px] lg:max-w-[300px] 2xl:max-w-[700px]">
    <ProductCard
      key={`first-pass-${index}`}
      name={product.name}
      metadata={product.metadata}
      image_url={product.image_url}
      color={product.color}
    />
  </div>
))} */
}

{
  /* <div className="bg-[#37B5FF] h-[400px] ">
              <div className="flex flex-col items-center w-full shadow-sm h-[400px]">
                <Card className="w-full h-[400px] rounded-none">
                  <Image
                    removeWrapper
                    alt="eafc"
                    className="z-0 w-full h-full object-cover rounded-none"
                    src={"https://photos5.appleinsider.com/gallery/54793-110893-Vision-Pro-xl.jpg"}
                  />
                  <div className="absolute z-10 bottom-36 flex-col !items-start px-16">
                    <p className="text-4xl lg:text-6xl font-semibold text-white">WIN THIS APPLE VISION PRO</p>
                    <p className="text-3xl font-semibold text-[#37B5FF] mt-2">Live Now</p>
                  </div>
                  <CardFooter className="px-16 absolute bottom-0 z-10 justify-start rounded-none">
                    <Button className="text-2xl bg-[#37B5FF] rounded-md p-6 mb-8" color="primary" radius="full" size="lg">
                      Enter Now
                    </Button>
                  </CardFooter>
                  </Card>
              </div>
            </div>
            <div className="bg-[#37B5FF] h-[400px] ">
              <div className="flex flex-col items-center w-full shadow-sm h-[400px]">
                <Card className="w-full h-[400px] rounded-none">
                  <Image
                    removeWrapper
                    alt="eafc"
                    className="z-0 w-full h-full object-cover rounded-none"
                    src={"https://www.apple.com/v/iphone-15-pro/c/images/meta/iphone-15-pro_overview__f8jz7aagka2q_og.png"}
                  />
                  <div className="absolute z-10 bottom-36 flex-col !items-start px-16">
                    <p className="text-4xl lg:text-6xl font-semibold text-white">WIN THIS IPHONE 15 PRO MAX</p>
                    <p className="text-3xl font-semibold text-[#37B5FF] mt-2">Live Now</p>
                  </div>
                  <CardFooter className="px-16 absolute bottom-0 z-10 justify-start rounded-none">
                    <Button className="text-2xl bg-[#37B5FF] rounded-md p-6 mb-8" color="primary" radius="full" size="lg">
                      Enter Now
                    </Button>
                  </CardFooter>
                  </Card>
              </div>
            </div> */
}
