import { IUser } from 'store/authSlice'
import { api } from './api'

interface ILoginResponse {
  token: string
  user: IUser
}

export interface IPhoneLoginResponse {
  token?: {
    Session?: string
    ChallengeParameters?: {
      USERNAME: string
      phone: string
    }
  }
}

export interface IUserInfo {
  data: any
}

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<ILoginResponse, any>({
      query: (credentials: any) => ({
        url: 'authentication/login',
        method: 'POST',
        body: credentials
      })
    }),
    googleLogin: build.mutation<any, { code: string; redirect_uri: string , cognito_oauth_exchange_token_url: string}>({
      query: (body) => ({
        url: '/authentication/oauth-token-exchange',
        method: 'POST',
        body
      })
    }),
    confirmSignup: build.mutation<
      any,
      { username: string; confirmation_code: string }
    >({
      query: (body) => ({
        url: 'authentication/confirm-signup',
        method: 'POST',
        body
      })
    }),
    resendConfirmation: build.mutation<
      any,
      {
        email: string
      }
    >({
      query: (body) => ({
        url: 'authentication/resend-confirmation',
        method: 'POST',
        body
      })
    }),
    signup: build.mutation<
      ILoginResponse,
      { username: string; full_name: string; password: string, phone?: string; }
    >({
      query: (credentials: any) => ({
        url: 'authentication/signup',
        method: 'POST',
        body: credentials
      }),
      invalidatesTags: ['Customers']
    }),
    forgotPassword: build.mutation<any, { email: string }>({
      query: (credentials: any) => ({
        url: 'authentication/forgot-password',
        method: 'POST',
        body: credentials
      })
    }),
    resetPassword: build.mutation<
      any,
      { username: string; password: string; confirmation_code: string }
    >({
      query: (credentials: any) => ({
        url: 'authentication/reset-password',
        method: 'POST',
        body: credentials
      })
    }),
    confirmUser: build.mutation<any, string>({
      query: (email) => ({
        url: '/users/confirm',
        method: 'POST',
        body: {
          email
        }
      })
    }),
    getUser: build.query<IUserInfo, string>({
      query: (id) => `/api/user/${id}`,
      providesTags: (_result, _error, id) => {
        return [
          { type: 'UserInfo', id },
          { type: 'UserInfo', id: 'PARTIAL-LIST' }
        ]
      }
    })
  })
})

export const {
  useLoginMutation,
  useGoogleLoginMutation,
  useConfirmSignupMutation,
  useResendConfirmationMutation,
  useSignupMutation,
  useGetUserQuery,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useConfirmUserMutation
} = authApi
