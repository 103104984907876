import {
  NavbarItem,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  Button,
  Image,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownSection,
  DropdownItem,
  Divider,
  Tooltip,
  useDisclosure
} from '@nextui-org/react'

import { FaChevronDown, FaGift } from 'react-icons/fa6'
import { LuHeart, LuLaptop, LuTreeDeciduous, LuDog, LuStar, LuShirt, LuShieldQuestion, LuGift } from 'react-icons/lu'
import { RxHamburgerMenu } from 'react-icons/rx'

import useFilterWall from 'hooks/useFilterWall'
import { useState, useEffect, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useClaimDailyStreakBonusMutation, useClaimInitialEntriesMutation, useClaimUnclaimedEntriesMutation, useGetCustomerQuery, useGetCustomerUnclaimedEntriesQuery, useGetTotalEntriesQuery, useGuessNumbersGameMutation } from 'services/customers'
import { logout } from 'store/authSlice'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import CountUp from 'react-countup'
import GiftIcon from 'components/Icons/GiftIcon'
import { StyledNavbar } from './styles'
import { ClaimBonusButton, SearchInputComponent } from './partials'
import clsx from 'clsx'
import DailyBonusAndNumbersGameModal, { DailyBonusAndNumbersGameModalProps } from 'components/Modals/DailyBonusAndNumbersGameModal'
import { useNotification } from 'hooks/useNotification'
import useGetCustomerDailyStreakBonusData from 'services/hooks/useGetCustomerDailyStreakBonus'
import WelcomeBonusModal from 'components/Modals/WelcomeBonusModal'
import { amplitudeLogEvent, amplitudeSetUserId } from '../../../amplitudeClient';
import { ChromeExtensionOnboardingModal } from 'pages/customer/ChromeExtensionSplashScreen'
import ExtensionBonusModal from 'components/Modals/ExtensionBonusModal'
import CollectTokensModal from 'components/Modals/CollectTokensModal'
import useGetNumbersGameData from 'services/hooks/useGetNumbersGameData'

const pages = [
  {
    text: 'Popular',
    address: '/merchants/fcb47d06-0bd0-40b7-9ac0-37d12eb83d45',
    symbol: 'star'
  },
  {
    text: 'Clothing',
    address: '/merchants/4082b604-0ada-4376-89a2-83a79c54b829',
    symbol: 'shirt'
  },
  {
    text: 'Health & Beauty',
    address: '/merchants/6d7faf41-a3e9-4dbf-bf94-a0e2e0b2cb89',
    symbol: 'heart'
  },
  {
    text: 'Electronics',
    address: '/merchants/6035c8c3-f8b7-4aa8-8dbf-9c92f45d5fd6',
    symbol: 'laptop'
  },
  {
    text: 'Home & Garden',
    address: '/merchants/e42d20cb-d082-4328-8fbb-ab18c0d72db9',
    symbol: 'tree'
  },
  {
    text: 'Pets',
    address: '/merchants/f65b2d17-2665-43b8-81fa-b165e98c6561',
    symbol: 'pet'
  }
]

const generateComponentFromSymbol = (symbol: string, className?: string) => {
  switch (symbol) {
    case 'gift':
      return <LuGift className={className} />
    case 'laptop':
      return <LuLaptop className={className} />
    case 'shirt':
      return <LuShirt className={className} />
    case 'pet':
      return <LuDog className={className} />
    case 'tree':
      return <LuTreeDeciduous className={className} />
    case 'help':
      return <LuShieldQuestion className={className} />
    case 'star':
      return <LuStar className={className} />
    case 'heart':
      return <LuHeart className={className} />
  }
}

export default function PrizedrawNavbar({ isShowingBottom }: { isShowingBottom: boolean }) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams()
  const {sendSuccessNotification, sendErrorNotification} = useNotification()

  const referrer = new URLSearchParams(location.search).get('referrer');

  const [dailyBonusAndNumberGameContentToShow, setDailyBonusAndNumberGameContentToShow] = useState<DailyBonusAndNumbersGameModalProps['contentToShow']>('dailyBonus')

  const customerId: any = useAppSelector((state) => state.auth.customerId)

  const { data, refetch: refetchCustomerInfo } = useGetCustomerQuery(customerId)

  const customer = data?.customer

  const { data: entriesCountData, isLoading: isLoadingCount, refetch: refetchTotalEntries } = useGetTotalEntriesQuery(customerId || '')
  const { refetch: refetchDailyBonusData, claimable: dailyBonusIsClaimable, isLoading: isLoadingDailyStreakData } = useGetCustomerDailyStreakBonusData(customerId)
  const { data: unclaimedEntriesData, refetch: refetchUnclaimedEntries, isLoading: isLoadingUnclaimedEntries } = useGetCustomerUnclaimedEntriesQuery(customerId)
  const { isGuessable: numbersGameIsGuessable, refetch: refetchNumbersGame } = useGetNumbersGameData(customerId);

  useEffect(() => {
    if(!dailyBonusIsClaimable && numbersGameIsGuessable) {
      setDailyBonusAndNumberGameContentToShow('numbersGame');
    } else {
      setDailyBonusAndNumberGameContentToShow('dailyBonus');
    }
  }, [dailyBonusIsClaimable, numbersGameIsGuessable]);
  
  const [claimInitialEntries] = useClaimInitialEntriesMutation();
  // Warn - isLoading from useClaimInitialEntriesMutation is not working properly, keeps true after error.
  const [isClaimingWelcomeBonus, setIsClaimingWelcomeBonus] = useState(false);

  const [claimDailyBonus] = useClaimDailyStreakBonusMutation();
  // Warn - isLoading from useClaimDailyStreakBonusMutation is not working properly, keeps true after error.
  const [isClaimingDailyBonus, setIsClaimingDailyBonus] = useState(false);

  const [claimUnclaimedEntries] = useClaimUnclaimedEntriesMutation();
  // Warn - isLoading from useClaimUnclaimedEntriesMutation is not working properly, keeps true after error.
  const [isClaimingUnclaimedEntries, setIsClaimingUnclaimedEntries] = useState(false);

  const [guessNumberMutation] = useGuessNumbersGameMutation()
  // Warn - isLoading from useGuessNumbersGameMutation is not working properly, keeps true after error.
  const [isGuessingNumber, setIsGuessingNumber] = useState(false);


  const [totalEntries, setTotalEntries] = useState<number>(0)

  useEffect(() => {
    if (entriesCountData?.count !== undefined) {
      setTotalEntries(entriesCountData.count)
    }
  }, [entriesCountData?.count])

  console.log('Entries count data:', entriesCountData?.count)
  console.log('Total entries:', totalEntries)

  const { categories, parentCategories } = useFilterWall()

  const [isMenuOpen, setIsMenuOpen] = useState(false) // This is for mobile
  const [isCategoriesMenuOpen, setIsCategoriesMenuOpen] = useState(false) // This is to make the categories auto start on hover
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false) // This is to make the profile auto start on hover

  const handleMouseEnterCategories = () => {
    setIsCategoriesMenuOpen(true)
  }

  const handleMouseLeaveCategories = () => {
    setIsCategoriesMenuOpen(false)
  }

  const handleMouseEnterProfile = () => {
    setIsProfileMenuOpen(true)
  }

  const handleMouseLeaveProfile = () => {
    setIsProfileMenuOpen(false)
  }

  const [searchTerm, setSearchTerm] = useState('')
  const handleSearch = useCallback(() => {
    if (searchTerm.trim()) {
      const customer_name: any = customer?.email;
      amplitudeSetUserId(customer_name);
      amplitudeLogEvent({
        event_type: 'Search Performed',
        event_properties: {
          searchTerm,
          customerId,
          customer_name
        }
      });
    }
    navigate(`/search?search=${encodeURIComponent(searchTerm)}`)
  }, [navigate, searchTerm])

  useEffect(() => {
    const currentSearchTerm = searchParams.get('search') || ''
    setSearchTerm(currentSearchTerm)
  }, [searchParams])

  const clearSearch = useCallback(() => {
    setSearchTerm('')
    setSearchParams({})
  }, [setSearchParams])

  const clearSearchAndNavigate = useCallback((address: string) => {
    clearSearch()
    window.location.href = address
  }, [clearSearch])

  const { isOpen: isOpenWelcomeBonusModal, onOpen: openWelcomeBonusModal, onOpenChange: onWelcomeBonusModalChange } = useDisclosure()
  const { isOpen: isOpenDailyStreakAndNumberGameModal, onOpen: openDailyStreakAndNumberGameModal, onOpenChange: onDailyStreakAndNumberGameModalChange } = useDisclosure()
  const { isOpen: isOpenChromeExtensionSplashScreen, onOpen: openChromeExtensionSplashScreen, onOpenChange: onChromeExtensionSplashScreenChange } = useDisclosure()
  const { isOpen: isOpenChromeModal, onOpen: openChromeModal, onOpenChange: onChromeModalChange } = useDisclosure()
  const { isOpen: isOpenUnclaimedEntriesModal, onOpen: openUnclaimedEntriesModal, onOpenChange: onUnclaimedEntriesModalChange } = useDisclosure()

  const handleClaimBonus = async () => {
    try {
      setIsClaimingDailyBonus(true);
      const claimBonusResponse = await claimDailyBonus({ customerId }).unwrap();
      sendSuccessNotification('Daily streak bonus claimed successfully!', { autoHideDuration: 2000 })

      // Close Dialog or show numbers game if hasn't played already
      if(numbersGameIsGuessable) {
        setDailyBonusAndNumberGameContentToShow('numbersGame');
      } else {
        onDailyStreakAndNumberGameModalChange();
      }

      // Syncing local state with the server
      await Promise.all([
        refetchDailyBonusData().unwrap(),
        refetchTotalEntries().unwrap(), 
      ]);

      // Logging amplitude event
      const { reward_streak_count } = claimBonusResponse;
      amplitudeLogEvent({
        event_type: 'Daily Streak Bonus Claimed',
        event_properties: {
          customerId,
          customer_name: customer?.email,
          reward_streak_count,
          timestamp: new Date().toISOString(),
        }
      });
    } catch (error) {
      sendErrorNotification('Error claiming daily streak bonus, try again later!', { autoHideDuration: 2000 });
    } finally {
      setIsClaimingDailyBonus(false);
    }
  }

  const handleGuessNumber = async (guessNumber: number[]) => {
    try {
      setIsGuessingNumber(true);
      await guessNumberMutation({ customerId, guess: guessNumber }).unwrap();
      sendSuccessNotification('Number submitted successfully!', { autoHideDuration: 2000 });

      // Closing dialog 
      onDailyStreakAndNumberGameModalChange();

      // Syncing local state with the server
      await refetchNumbersGame().unwrap();

      // Logging amplitude event
      amplitudeLogEvent({
        event_type: 'Numbers game attempt submitted',
        event_properties: {
          customerId,
          customer_name: customer?.email,
          guess: guessNumber.join('-'),
          timestamp: new Date().toISOString(),
        }
      });
    } catch (error) {
      sendErrorNotification('Error submitting number, try again later!', { autoHideDuration: 2000 });
      console.log('Error submitting number', error);
    } finally {
      setIsGuessingNumber(false);
    }
  }
  
  const handleClaimWelcomeBonus = async () => {
    try {
      setIsClaimingWelcomeBonus(true);
      await claimInitialEntries({ customerId }).unwrap();
      await Promise.all([
        refetchCustomerInfo().unwrap(),
        refetchTotalEntries().unwrap(), 
      ]);
      onWelcomeBonusModalChange();
    } catch (error) {
      console.log('Error adding initial token', error);
    } finally {
      setIsClaimingWelcomeBonus(false);
    }
  }

  const [isChromeExtensionSplashScreenShown, setChromeExtensionSplashScreenShown] = useState(false);

  useEffect(() => {
    if (customer) {
      if (referrer === "extension") {
        if (!customer.received_chrome_tokens && !isChromeExtensionSplashScreenShown) {
          // Show ChromeExtensionSplashScreen for all extension users who haven't received chrome tokens
          openChromeExtensionSplashScreen();
          setChromeExtensionSplashScreenShown(true);
        } else if (!isOpenChromeExtensionSplashScreen && !customer.received_chrome_tokens && customer.received_initial_tokens) {
          // For users who have initial tokens but not chrome tokens, show ChromeModal
          openChromeModal();
        } else if (!customer.received_initial_tokens && !isOpenChromeExtensionSplashScreen) {
          // For new extension users who haven't received initial tokens, show WelcomeBonus
          openWelcomeBonusModal();
        }
      } else if (!customer.received_initial_tokens && referrer !== "extension") {
        // Show regular welcome bonus for new non-extension users
        openWelcomeBonusModal();
      }
    }
  }, [
    customer,
    referrer,
    isOpenChromeExtensionSplashScreen,
    isChromeExtensionSplashScreenShown,
    openChromeExtensionSplashScreen,
    openWelcomeBonusModal,
    openChromeModal
  ]);

  const handleExtensionBonusChange = async () => {
    await Promise.all([
      refetchTotalEntries().unwrap()
    ]);
    onChromeModalChange();
  }

  const handleClaimUnclaimedEntries = async () => {
    setIsClaimingUnclaimedEntries(true);
    try {
      await claimUnclaimedEntries({ customerId }).unwrap();
      await Promise.all([
        refetchUnclaimedEntries().unwrap(),
        refetchTotalEntries().unwrap()
      ]);
      onUnclaimedEntriesModalChange();
    } catch (error) {
      console.log('Error claiming unclaimed entries', error);
    } finally {
      setIsClaimingUnclaimedEntries(false);
    }
  }

  useEffect(() => {
    // Welcome bonus and Unclaimed Entries modals have priority over the daily streak modal
    if(customer?.received_initial_tokens 
        && dailyBonusIsClaimable 
        && !isOpenUnclaimedEntriesModal
        && !isLoadingUnclaimedEntries 
        && !isOpenChromeExtensionSplashScreen 
        && !isOpenChromeModal) {
      openDailyStreakAndNumberGameModal();
    }
  }, [dailyBonusIsClaimable, openDailyStreakAndNumberGameModal, customer?.received_initial_tokens, isOpenChromeExtensionSplashScreen, isOpenChromeModal, isLoadingUnclaimedEntries, isOpenUnclaimedEntriesModal])

  useEffect(() => {
    // Welcome bonus modal has priority over the unclaimed entries modal
    if(unclaimedEntriesData && unclaimedEntriesData?.unclaimed_entries_sum > 0 && customer?.received_initial_tokens) {
      openUnclaimedEntriesModal();
    }
  }, [openUnclaimedEntriesModal, unclaimedEntriesData, customer?.received_initial_tokens]);

  const navigateAndCloseMenu = (address: string) => {
    setIsMenuOpen(false);
    navigate(address);
  }

  const menuPages = [
    {
      text: 'Prizes',
      address: '/dashboard',
      symbol: 'gift'
    },
    ...pages,
    {
      text: 'Help',
      address: '/help',
      symbol: 'help'
    }
  ];

  return (
    <div className="pt-[var(--mobile-header-height)] lg:pt-[var(--desktop-header-height)]">
      <StyledNavbar
        isMenuOpen={isMenuOpen}
        maxWidth={'full'}
        className="lg:py-3 h-[var(--mobile-header-height)] lg:h-[var(--desktop-header-height)] fixed border-b border-gray-200 lg:px-14 justify-between"
        onMenuOpenChange={setIsMenuOpen}
        height={'var(--desktop-header-height)'}
      >
        <div className="hidden lg:block w-fit">
          <div className="cursor-pointer" onClick={() =>  {window.location.href = '/merchants/fcb47d06-0bd0-40b7-9ac0-37d12eb83d45'}}>
            <Image width={150} alt="Wave Logo" src="/images/logo.png" />
          </div>
        </div>

        {/* BEGIN MOBILE HEADER */}
        <div className="flex lg:hidden flex-row gap-4 justify-center items-center w-full">
          <div className="w-full flex flex-col gap-4">
            <div className="w-full flex flex-row items-center justify-between">
              <div
                onClick={() => navigateAndCloseMenu('/merchants/fcb47d06-0bd0-40b7-9ac0-37d12eb83d45')}
                className="flex gap-2 items-center justify-self-center"
              >
                <NavbarMenuToggle
                  aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
                  icon={<RxHamburgerMenu className="h-12" />}
                />
                <Image className='block min-[400px]:hidden' onClick={() => navigateAndCloseMenu('/merchants/fcb47d06-0bd0-40b7-9ac0-37d12eb83d45')} width={50} alt="Prizedraw Logo" src="/images/mobile-logo.png" />
                <Image className='hidden min-[400px]:block' onClick={() => navigateAndCloseMenu('/merchants/fcb47d06-0bd0-40b7-9ac0-37d12eb83d45')} width={120} alt="Prizedraw Logo" src="/images/logo.png" />
              </div>
              <div className="flex flex-row gap-0 items-center">
                <ClaimBonusButton 
                  className='mr-2'
                  onClick={openDailyStreakAndNumberGameModal}
                  mobile
                />
                <div className="flex flex-row gap-1 bg-primary-400 text-white pl-4 pr-6 py-1 rounded-full items-center">
                  <p className="text-sm font-bold">
                    {Intl.NumberFormat('en-US', {
                      notation: "compact",
                      maximumFractionDigits: 2
                    }).format(totalEntries)}
                  </p>
                </div>
                <div className="ml-[-42px]">
                  <Image src="/images/Coins.png" width={70} alt="Coins" />
                </div>
                <div>
                  <div onClick={() => navigateAndCloseMenu('/dashboard')}>
                    <GiftIcon />
                  </div>
                </div>
              </div>
            </div>

            <div className="relative flex items-center w-full max-w-full h-10">
              <SearchInputComponent
                value={searchTerm}
                onChange={setSearchTerm}
                handleSearch={handleSearch}
              />
            </div>
          </div>

          <NavbarMenu className="py-20 pb-48 overflow-scroll cursor-pointer">
            <NavbarMenuItem className="">
              <div>
                <div>
                  <h4 className="text-xl leading-none text-black mb-5">Categories</h4>
                </div>
                <div className="flex flex-col gap-3">
                  {parentCategories?.marketplace_categories?.map((category: any) => (
                    <div
                      className="cursor-pointer text-sm rounded-md w-fit"
                      onClick={() => navigateAndCloseMenu(`/merchants/${category.id}`)}
                      key={category.id}
                    >
                      {category.category_name}
                    </div>
                  )) || []}
                </div>
              </div>
            </NavbarMenuItem>

            <div className="mt-5">
              <Button onClick={() => navigateAndCloseMenu('/dashboard')} key={"prizeskey"} className="flex flex-row items-center bg-primary-400 gap-2 cursor-pointer px-4 py-1 mx-0 hover:text-primary-400 rounded-md">
                <FaGift color="white" />
                <p className="text-base text-white">Prizes</p>
              </Button>
            </div>

            <Divider />

            <NavbarMenuItem className="mt-10">
              <div>
                <div>
                  <h4 className="text-xl leading-none text-black mb-4">Account</h4>
                </div>
                <div>
                  <div className="flex flex-col gap-2 text-base">
                    <div key="entries" onClick={() => navigateAndCloseMenu('/entries')}>
                      Entry History
                    </div>
                    <div key="token_history" onClick={() => navigateAndCloseMenu('/customers/token-history')}>
                      Token History
                    </div>
                    <div key="my_settings" onClick={() => navigateAndCloseMenu('/account')}>
                      My Settings
                    </div>
                    <div key="help" onClick={() => navigateAndCloseMenu('/help')}>
                      Help
                    </div>
                    <div
                      key="sign_out"
                      onClick={() => {
                        dispatch(logout())
                      }}
                    >
                      Sign Out
                    </div>
                  </div>
                </div>
              </div>
            </NavbarMenuItem>
          </NavbarMenu>
        </div>
        {/* END MOBILE HEADER */}

        {/* BEGIN DESKTOP HEADER */}
        <div className="hidden lg:flex flex-row gap-4 justify-center items-center w-1/2">
          <div>
            <div onMouseEnter={handleMouseEnterCategories} onMouseLeave={handleMouseLeaveCategories}>
              <Dropdown
                isOpen={isCategoriesMenuOpen}
                onOpenChange={setIsCategoriesMenuOpen}
                placement="bottom-start"
                className="mr-2"
              >
                <DropdownTrigger className="text-sm">
                  <div className="flex flex-row gap-2">
                    <div className="flex flex-row gap-1 items-start justify-center cursor-pointer">
                      <h4 className="leading-none text-black">Categories</h4>
                      <FaChevronDown />
                    </div>
                  </div>
                </DropdownTrigger>
                <DropdownMenu aria-label="Profile Actions" variant="flat">
                  <DropdownSection>
                    {parentCategories?.marketplace_categories?.map((category: any) => (
                      <DropdownItem onClick={() => navigate(`/merchants/${category.id}`)} key={category.id}>
                        {category.category_name}
                      </DropdownItem>
                    )) || []}
                  </DropdownSection>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>

          <SearchInputComponent 
            value={searchTerm}
            onChange={setSearchTerm}
            handleSearch={handleSearch}
          />
        </div>
        {/* END DESKTOP HEADER */}
        <div className='flex gap-4'>
          <ClaimBonusButton onClick={openDailyStreakAndNumberGameModal} />
          <NavbarItem className="hidden lg:flex flex-row gap-10 items-center mr-3 grow-0 cursor-pointer">
            <Tooltip
              placement="bottom"
              content={
                <p className="w-48 p-3 bg-white flex flex-row flex-wrap">
                  Use your{' '}
                  <span className="ml-1.5 flex flex-row w-fit gap-1 text-primary-400 font-semibold inline-block">
                    <Image
                      alt="Wave Icon"
                      className="inline object-contain rounded-xl w-full h-4 w-4"
                      src={'/images/waveemblem.svg'}
                    />
                    Tokens
                  </span>{' '}
                  to enter giveaways and win real prizes!
                </p>
              }
            >
              <div className="relative cursor-pointer" onClick={() => navigate('/dashboard')}>
                <div className="flex flex-row gap-0 items-center">
                  <div className="flex flex-row gap-1 bg-primary-400 text-white pl-4 pr-6 py-1 rounded-full items-center">
                    <p className="text-lg font-bold">
                      <CountUp start={0} end={totalEntries} duration={1} separator="," />
                    </p>
                    <p>Tokens</p>
                  </div>
                  <div className="absolute right-[-30px]">
                    <Image src="/images/Coins.png" width={70} alt="Coins" />
                  </div>
                </div>
              </div>
            </Tooltip>

            <div onMouseEnter={handleMouseEnterProfile} onMouseLeave={handleMouseLeaveProfile}>
              <Dropdown isOpen={isProfileMenuOpen} onOpenChange={setIsProfileMenuOpen} placement="bottom-end">
                <DropdownTrigger>
                  <div className="flex flex-row gap-2">
                    <div className="flex flex-row gap-1 items-start justify-center">
                      <h4 className="text-md leading-none text-black">{customer?.name.split(' ')[0]}</h4>
                      <FaChevronDown />
                    </div>
                  </div>
                </DropdownTrigger>
                <DropdownMenu aria-label="Profile Actions" variant="flat">
                  <DropdownSection showDivider>
                    <DropdownItem key="profile" className="h-14 gap-2">
                      <p className="font-semibold">Signed in as</p>
                      <p className="font-semibold">{customer?.email}</p>
                    </DropdownItem>
                    <DropdownItem key="prizes" onClick={() => navigate('/dashboard')}>
                      Prizes
                    </DropdownItem>
                    <DropdownItem key="entries" onClick={() => navigate('/entries')}>
                      Entry History
                    </DropdownItem>
                    <DropdownItem key="token_history" onClick={() => navigate('/customers/token-history')}>
                      Token History
                    </DropdownItem>
                    <DropdownItem key="my_settings" onClick={() => navigate('/account')}>
                      My Settings
                    </DropdownItem>
                    <DropdownItem key="rules_and_terms" onClick={() => window.open('https://www.joinwave.com/rules', '_blank')}>
                      Rules {'&'} Terms
                    </DropdownItem>
                  </DropdownSection>

                  <DropdownSection>
                    <DropdownItem key="help" onClick={() => navigate('/help')}>
                      Help
                    </DropdownItem>
                    <DropdownItem
                      key="sign_out"
                      color="danger"
                      onClick={() => {
                        dispatch(logout())
                      }}
                    >
                      Sign Out
                    </DropdownItem>
                  </DropdownSection>
                </DropdownMenu>
              </Dropdown>
            </div>
          </NavbarItem>
        </div>
      </StyledNavbar>
      {isShowingBottom && (
        <div className="hidden lg:block border-b border-gray-400 p-6">
          <div className="flex flex-row justify-around mx-4 items-center">
            {menuPages.map((page, index) => (
              <div onClick={() => clearSearchAndNavigate(page.address)} key={index}>
                <div className="flex flex-row items-center text-sm gap-2 cursor-pointer hover:text-[#1DA1F2]">
                  {generateComponentFromSymbol(page.symbol, clsx({ 'text-primary-400': location.pathname === page.address }))}
                  <p className={clsx('text-sm', { 'text-primary-400 font-semibold': location.pathname === page.address })}>{page.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <WelcomeBonusModal 
        open={isOpenWelcomeBonusModal}
        onClaim={handleClaimWelcomeBonus}
        isClaiming={isClaimingWelcomeBonus}
        referrer={referrer || ''}
      />
     
      <DailyBonusAndNumbersGameModal
        open={isOpenDailyStreakAndNumberGameModal}
        onClaim={handleClaimBonus}
        onGuess={handleGuessNumber}
        contentToShow={dailyBonusAndNumberGameContentToShow}
        onClose={onDailyStreakAndNumberGameModalChange}
        isLoading={isLoadingDailyStreakData}
        isClaiming={isClaimingDailyBonus}
        isGuessingNumber={isGuessingNumber}
      />

      <ChromeExtensionOnboardingModal 
        isOpen={isOpenChromeExtensionSplashScreen} 
        onOpenChange={onChromeExtensionSplashScreenChange} 
        customerId={customer?.id || ''}
        />

      <ExtensionBonusModal
        isOpen={isOpenChromeModal}
        onOpenChange={handleExtensionBonusChange}
      />
      <CollectTokensModal
        open={isOpenUnclaimedEntriesModal}
        isClaiming={isClaimingUnclaimedEntries}
        onClaim={handleClaimUnclaimedEntries}
        purchasesCount={unclaimedEntriesData?.unclaimed_entries_count || 0}
        totalTokens={unclaimedEntriesData?.unclaimed_entries_sum || 0}
      />
    </div>
  )
}